<template>
  <div class="-mt-[64px] bg-orange-25 px-[50px] py-[64px] md:px-[24px] md:pb-[24px]">
    <Wrapper class="flex h-full justify-center gap-[5%] pt-[56px] md:flex-col md:gap-0 sm:pt-[24px]">
      <div class="flex-1">
        <Breadcrumb v-if="primary.showBreadcrumb" class="mt-[16px]" :custom-name="categoryName" :category-uid="categoryUid" />
        <Title class="mt-[16px] !font-[300] typo-mega-title sm:typo-extra-large-title" :title-params="primary.title" />
        <RichText class="!font-[450] text-gray-700 typo-headline [&>p]:mt-[16px]" :text-params="primary.subtitle" />
        <HeroCta class="mt-[40px]" :data="primary" />

        <ul class="mt-[24px]">
          <li v-for="(item, itemIndex) in items" :key="itemIndex" class="py-[8px]">
            <ArrowLink :link-object="item.ctaLink" class="text-bluko-500 path-bluko-500" :link-text="item.ctaText" />
          </li>
        </ul>
      </div>
      <PImage
        class="max-h-[460px] w-1/2 flex-1 rounded-br-[300px] object-cover md:mt-[16px] md:max-h-[256px] md:w-full md:rounded-br-[150px]"
        :image-object="primary.backgroundImage"
        sizes="sm:100vw"
      />
    </Wrapper>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import ArrowLink from '@/components/ArrowLink'
import HeroCta from '@/components/HeroCta'

export default {
  name: 'HeroAdviceCategory2021Q4',

  components: {
    Breadcrumb,
    HeroCta,
    ArrowLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
    categoryName: {
      type: String,
      default: '',
    },
    categoryUid: {
      type: String,
      default: '',
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
