<template>
  <SliceWrapper class="my-[128px]" :slice="slice">
    <Wrapper class="flex flex-col px-[20px]">
      <Title :title-params="primary.title" class="text-center !font-[300] typo-mega-title sm:typo-extra-large-title" />
      <div v-observe-visibility="isVisible" class="mt-[60px] flex flex-wrap justify-center">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="mb-[50px] flex min-w-[300px] flex-1 flex-col items-center px-[40px]"
          data-aos="fade-up"
        >
          <PImage :image-object="item.image" sizes="xs:64px" class="h-[64px] w-[64px]" lazy />
          <Counter :text="item.title" :launch="launchFlags[index]" class="mt-[16px] text-center typo-extra-large-title sm:typo-large-title" />
          <RichText :text-params="item.description" class="text-center text-gray-500" />
        </div>
      </div>
      <div class="mt-[30px] text-center sm:hidden">
        <ArrowButton :link-object="primary.ctaLink" is-outlined target="_blank" :link-text="primary.ctaText" />
      </div>
    </Wrapper>
  </SliceWrapper>
</template>

<script>
import { delay } from '@fxts/core'

import Counter from '@/components/Counter'
import ArrowButton from '@/components/ArrowButton'

export default {
  name: 'HighlightCounter',
  components: { Counter, ArrowButton },
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      launchFlags: new Array(this.slice.items.length).fill(false),
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    isVisible(visible) {
      if (visible) {
        return this.startCounter()
      }
      this.launchFlags = new Array(this.slice.items.length).fill(false)
    },
    async startCounter() {
      for (let i = 0; i < this.items.length; i++) {
        this.launchFlags.splice(i, 1, true)
        await delay(250)
      }
    },
  },
}
</script>
