<template>
  <div>
    <MediaCarouselDesktop :slice="slice" class="md:hidden" />
    <MediaCarouselMobile :slice="slice" class="up-md:hidden" />
  </div>
</template>

<script>
import MediaCarouselDesktop from './components/MediaCarouselDesktop'
import MediaCarouselMobile from './components/MediaCarouselMobile'

export default {
  name: 'MediaCarrouselDesktop',
  components: {
    MediaCarouselDesktop,
    MediaCarouselMobile,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
