export const componentLogic = {
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  mounted() {
    this.onActive(0)
  },
  methods: {
    onVisible(isVisible) {
      if (isVisible) {
        this.loadImage = true
      }
    },
    onActive(activeIdx) {
      if (Math.abs(this.activeItem) - 1 !== activeIdx) {
        this.activeItem = activeIdx + 1
      } else {
        this.activeItem = -this.activeItem
      }

      this.$refs.contents.forEach((c, idx) => {
        if (Math.abs(this.activeItem) - 1 === idx) {
          this.activeItem > 0 ? this.open(idx, c.offsetHeight) : this.close(idx)
        } else {
          this.close(idx)
        }
      })
    },
    open(idx, height) {
      this.$refs.bodies[idx].setAttribute('style', `height: ${height}px`)
    },
    close(idx) {
      this.$refs.bodies[idx].setAttribute('style', `height: 0`)
    },
  },
}
