<template>
  <div class="my-[128px] sm:my-[64px]">
    <Title :title-params="primary.title" class="text-center typo-large-title md:mb-[30px]" />
    <Wrapper class="p-[24px]">
      <div
        v-observe-visibility="{
          callback: sectionOnScreen,
          intersection: {
            threshold: 1,
          },
        }"
        class="flex items-center justify-center gap-[40px]"
      >
        <div class="md:hidden">
          <template v-for="(item, itemIndex) in items">
            <template v-if="item.video && item.video.url">
              <video
                v-show="isActive(itemIndex)"
                :key="'video-' + itemIndex"
                :ref="'video-' + itemIndex"
                class="w-[35vw] max-w-[470px]"
                muted="muted"
              >
                <source :src="item.video.url" type="video/mp4" />
              </video>
            </template>
            <template v-if="item.image && item.image.url">
              <PImage
                v-show="isActive(itemIndex)"
                :key="'image-' + itemIndex"
                :ref="'image-' + itemIndex"
                :image-object="item.image"
                class="w-[35vw] max-w-[470px]"
              />
            </template>
          </template>
        </div>

        <div class="w-[500px]">
          <div
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            :class="[$style.item, { [$style.itemActive]: isActive(itemIndex) }]"
            @click="setActiveIndex(itemIndex)"
            @mouseenter="onMouseEnter(itemIndex)"
            @mouseleave="onMouseLeave(itemIndex)"
          >
            <div class="mr-[24px] flex items-center justify-center rounded-[48px] p-[12px] md:m-0">
              <RadialProgressBar
                :class="$style.circleProgress"
                :completed-steps="getProgress(itemIndex)"
                :animate-speed="getProgressSpeed(itemIndex)"
                :diameter="68"
                :total-steps="100"
                inner-stroke-color="transparent"
                :stroke-width="4"
              />
              <div :class="[$style.itemIcon, { [$style.itemActive]: isActive(itemIndex) }]">
                {{ itemIndex + 1 }}
              </div>
            </div>

            <div class="max-w-[400px] md:max-w-[325px] md:text-center">
              <Title :title-params="item.title" class="mb-[4px] typo-headline-bold" />
              <RichText :text-params="item.description" class="text-gray-400 md:text-gray-700" :class="{ ['!text-gray-700']: isActive(itemIndex) }" />
              <ArrowLink
                v-if="item.ctaText && item.ctaLink.url"
                :link-object="item.ctaLink"
                class="mt-[20px] text-bluko-500 path-bluko-500"
                :link-text="item.ctaText"
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress/src/RadialProgressBar.vue'

import ArrowLink from '@/components/ArrowLink'

export default {
  name: 'PhoneCarouselDefault',

  components: {
    RadialProgressBar,
    ArrowLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hasPlayedOnce: false,
      activeIndex: 0,
      prog: {},
      timeoutFct: null,
      activeVideoProgression: 0,
      timerFct: null,
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    activeVideo() {
      const video = this.$refs['video-' + this.activeIndex]
      return video
        ? video[0]
        : {
            duration: 0,
            play() {},
            pause() {},
          }
    },

    progressions: {
      get() {
        return this.prog
      },
      set(newValue) {
        this.prog = newValue
      },
    },

    itemDuration() {
      return Math.round(this.activeVideo.duration + 4)
    },
  },

  watch: {
    slice() {
      this.activeIndex = 0
      this.activeVideoProgression = 0
    },
  },

  methods: {
    isUnderMediumScreen: () => window.matchMedia('(max-width: 960px)').matches,

    onMouseEnter(index) {
      if (this.isActive(index)) {
        this.activeVideo.pause()
        if (this.timerFct) clearInterval(this.timerFct)
      }
    },

    onMouseLeave(index) {
      if (this.isActive(index)) {
        this.activeVideo.play()
        this.startTimer()
      }
    },

    toNextVideo() {
      this.setActiveIndex((this.activeIndex + 1) % this.items.length)
    },

    sectionOnScreen(onScreen) {
      if (onScreen && !this.hasPlayedOnce) {
        this.setActiveIndex(0)
        this.hasPlayedOnce = true
      }
    },

    startTimer() {
      if (this.timerFct) clearInterval(this.timerFct)

      this.timerFct = setInterval(() => {
        if (this.isUnderMediumScreen()) return
        this.activeVideoProgression += 0.2

        if (this.activeVideoProgression > this.itemDuration) {
          this.activeVideoProgression = 0
          clearInterval(this.timerFct)
          this.toNextVideo()
        }

        this.onVideoProgress()
      }, 200)
    },

    onVideoProgress() {
      const dataProg = { ...this.prog }
      dataProg[this.activeIndex] = (this.activeVideoProgression * 100) / this.itemDuration
      this.progressions = dataProg
    },

    resetVideoTimers() {
      this.activeVideo.currentTime = 0
      this.activeVideoProgression = 0
      this.onVideoProgress()
    },

    setActiveIndex(index) {
      this.activeVideo.pause()
      this.resetVideoTimers()
      this.activeIndex = index
      this.startTimer()
      this.activeVideo.play()
    },

    isActive(index) {
      return index === this.activeIndex
    },

    getProgress(index) {
      if (!this.isActive(index)) return 0
      return this.progressions[index]
    },

    getProgressSpeed(index) {
      if (!this.isActive(index)) return 1000
      return 200
    },
  },
}
</script>

<style lang="scss" module>
.item {
  display: flex;
  align-items: center;
  max-width: 473px;
  margin: 0 auto;

  color: $gray-400;
  text-align: left;

  cursor: pointer;

  transition-duration: 0.3s;
  transition-property: color, background-colors;

  @include below(medium) {
    flex-direction: column;

    color: $gray-1000;
  }

  &:not(:first-child) {
    margin-top: 40px;
  }
}

.circleProgress {
  position: absolute;
  z-index: 4;

  @include below(medium) {
    display: none;
  }
}

.circleProgress circle:last-child {
  stroke: $gray-400 !important;
}

.itemActive .circleProgress circle:last-child {
  stroke: $bluko-500 !important;
}

.itemIcon {
  @include typo-title;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  color: $bluko-500;
  line-height: 48px;
  text-align: center;

  background-color: $bluko-50;

  transition-duration: 0.3s;
  transition-property: opacity, background-color;

  @include above(medium) {
    color: $gray-400;

    background-color: $gray-50;
    opacity: 0.5;
  }
  &.itemActive {
    color: $bluko-500;

    background-color: $bluko-50;
    opacity: 1;
  }
}
</style>
