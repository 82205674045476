<template>
  <SliceWrapper :slice="slice">
    <TrustpilotCarousel2021Q4 v-if="slice.variation === 'trustpilotCarousel2021Q4'" :slice="slice" />
    <RefundTrustpilotCarousel v-else-if="slice.variation === 'refundTrustpilotCarousel'" :slice="slice" />
    <TrustpilotCarouselDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import TrustpilotCarouselDefault from './default-slice/TrustpilotCarouselDefault.vue'
import TrustpilotCarousel2021Q4 from './TrustpilotCarousel2021Q4/TrustpilotCarousel2021Q4.vue'
import RefundTrustpilotCarousel from './refundTrustpilotCarousel/RefundTrustpilotCarousel.vue'

export default {
  name: 'TrustpilotCarousel',
  components: {
    TrustpilotCarouselDefault,
    TrustpilotCarousel2021Q4,
    RefundTrustpilotCarousel,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.section {
  position: relative;

  padding: 4em;

  color: #111;
  text-align: center;

  background: #f7f7f7;
}
a {
  color: #111;
}
.title {
  margin-bottom: 2em;
}
</style>
