<template>
  <SliceWrapper :slice="slice">
    <LogoBannerDefault :slice="slice" />
  </SliceWrapper>
</template>

<script>
import LogoBannerDefault from './default-slice'

export default {
  name: 'LogoBanner',
  components: {
    LogoBannerDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.section {
  position: relative;

  padding: 4em;

  color: #111;
  text-align: center;

  background: #f7f7f7;
}
a {
  color: #111;
}
.title {
  margin-bottom: 2em;
}
</style>
