<template>
  <section
    class="-mt-[64px] min-h-[615px] py-[64px] px-[80px] md:p-[24px] md:pt-[64px]"
    :style="{ backgroundColor: primary.backgroundColor || '#ebf5ff' }"
  >
    <Wrapper class="flex h-full items-stretch justify-between gap-[30px] pt-[56px] md:flex-col md:gap-0 sm:pt-[24px]">
      <div class="w-1/2 max-w-[600px] md:w-full md:max-w-none">
        <Breadcrumb v-if="primary.showBreadcrumb" class="mb-[16px]" :custom-name="primary.title[0].text" />
        <div>
          <Title
            class="inline !font-[300] typo-mega-title sm:typo-extra-large-title"
            :title-params="primary.title"
            :style="{ color: primary.titleColor || 'black' }"
          />
          <span v-if="primary.tag" class="mt-[10px] inline-block -translate-y-[10px] rounded-[20px] bg-gold-500 py-[8px] px-[16px] sm:hidden">
            {{ primary.tag }}
          </span>
        </div>
        <div v-if="primary.cobrandedLogo && primary.cobrandedLogo.url" class="felx mt-[16px] items-end gap-[8px] typo-headline">
          <p class="!text-bluko-300 typo-headline">
            {{ $t('with') }}
          </p>
          <PImage :image-object="primary.cobrandedLogo" class="h-[40px]" />
        </div>
        <RichText
          class="typo-headline [&>p]:mt-[16px]"
          :text-params="primary.description"
          :style="{ color: primary.descriptionColor || '#6b6651' }"
        />
        <HeroCta class="mt-[40px]" :data="primary" />
        <RichText class="mt-[16px] !text-bluko-300 typo-body" :text-params="primary.subDescription" />
        <HeroItems v-if="items.length" :items="items" />
      </div>

      <div
        class="m-0 max-h-[460px] min-h-[430px] overflow-hidden"
        :class="[{ [$style.designA]: slice.variation === 'designA' }, { [$style.designB]: slice.variation === 'designB' }]"
      >
        <PImage class="h-full w-full object-cover" :image-object="primary.backgroundImage" sizes="sm:100vw" />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import HeroCta from '@/components/HeroCta'

import { HeroItems } from './components'

export default {
  name: 'HeroLaunchAB',

  components: {
    HeroCta,
    HeroItems,
    Breadcrumb,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => item.text)
    },
  },
}
</script>

<style lang="scss" module>
.designA {
  width: 50%;
  border-bottom-left-radius: 300px;
  @include below(medium) {
    width: 100%;
    max-width: none;
    height: 256px;
    min-height: auto;
    margin-top: 16px;
    margin-right: -24px;
    margin-bottom: -24px;
    border-bottom-left-radius: 150px;
  }
}
.designB {
  width: 40%;
  max-width: 500px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  @include below(medium) {
    width: 100%;
    max-width: none;
    height: 256px;
    min-height: auto;
    margin-top: 16px;
    margin-right: -24px;
    margin-bottom: -24px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 150px;
  }
}
</style>
