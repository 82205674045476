<template>
  <div
    v-observe-visibility="{ callback: isVisible, once: true }"
    class="relative mt-[-64px] h-[800px] bg-[#015ce3] bg-cover bg-center p-0 px-[24px]"
    :class="{ [$style.pattern]: !!backgroundVideo }"
    :style="backgroundVideo ? {} : { backgroundImage: `url(${backgroundImage})` }"
  >
    <div v-if="backgroundVideo" :class="$style.videoContainer">
      <video ref="video" preload="none" playsinline muted="muted" class="h-full w-full object-cover" :poster="backgroundImage">
        <source :src="backgroundVideo2" type="video/webm" />
        <source :src="backgroundVideo" type="video/mp4" />
      </video>
    </div>
    <Wrapper :class="$style.inner" class="relative h-full pt-[146px] sm:pt-[96px]">
      <div class="flex max-w-[650px] flex-col justify-evenly sm:mx-auto">
        <div>
          <Title class="mb-[16px] !text-white typo-mega-title" :title-params="title" />
          <span
            v-if="primary.tag"
            class="mt-[10px] inline-block -translate-y-[10px] rounded-[30px] bg-gold-500 p-[8px] px-[16px] typo-body sm:hidden"
          >
            {{ primary.tag }}
          </span>
          <RichText :text-params="subtitle" :class="$style.subtitle" class="text-[20px] font-[450] leading-[32px] text-white opacity-80" />
        </div>
        <div>
          <HeroCta is-light :data="primary" class="mt-[48px]" />
          <div v-if="TpScore >= 4.3 || GoogleScore >= 4.3" class="mt-[24px] flex items-center gap-[24px]">
            <TrustpilotWidget class="inline-flex" color="white" />
            <GoogleReviewWidget is-dark class="inline-flex path-bluko-300" />
          </div>
        </div>
      </div>
      <!-- USP -->
      <div v-if="items.length" class="absolute bottom-0 flex w-full justify-evenly border-t-[1px] border-solid border-bluko-200">
        <RichText v-for="(item, index) in items" :key="index" :text-params="item.text" :class="$style.itemText" />
      </div>
      <div v-else-if="primary.logoStripe && primary.logoStripe.url" class="absolute bottom-0 border-t-[1px] border-solid border-bluko-200 py-[24px]">
        <PImage :image-object="primary.logoStripe" class="max-h-[40px]" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import TpScore from '@@/script/trustpilot/score.json'
import GoogleScore from '@@/script/googleReview/score.json'

import TrustpilotWidget from '@/components/TrustpilotWidget'
import GoogleReviewWidget from '@/components/GoogleReviewWidget'
import HeroCta from '@/components/HeroCta'

export default {
  components: {
    HeroCta,
    TrustpilotWidget,
    GoogleReviewWidget,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      intervalId: null,
      videoLoad: false,
      TpScore: TpScore.trustScore,
      GoogleScore: GoogleScore.stars,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((i) => i.text.length)
    },
    backgroundVideo() {
      return this.primary.backgroundVideo?.url
    },
    backgroundVideo2() {
      return this.primary.backgroundVideo2?.url
    },
    backgroundImage() {
      if (!this.primary.backgroundImage?.url) return ''

      return this.$img.getSizes(this.primary.backgroundImage.url, {
        sizes: 'lg:200vw',
        modifiers: {
          quality: 80,
        },
      }).src
    },
    title() {
      return this.$store.state.variations.values.HomePageTitle?.[process.env.CURRENT_DOMAIN]?.[this.$i18n.locale] || this.primary.title
    },
    subtitle() {
      return this.$store.state.variations.values.HomePageSubtitle?.[process.env.CURRENT_DOMAIN]?.[this.$i18n.locale] || this.primary.subtitle
    },
  },
  beforeUnmount() {
    this.stopVideo()
  },
  methods: {
    startVideo() {
      const isHidden = document.hidden || document.msHidden || document.webkitHidden
      if (!isHidden) this.$refs.video.play()

      this.intervalId = setInterval(() => {
        const isHidden = document.hidden || document.msHidden || document.webkitHidden
        if (!isHidden && this.videoLoad) {
          this.$refs.video.play()
        }
      }, 8000)
    },
    stopVideo() {
      clearInterval(this.intervalId)
    },
    isVisible(visible) {
      if (visible && this.$refs.video) {
        this.$refs.video.load()
        this.videoLoad = true
        this.startVideo()
      }
    },
  },
}
</script>

<style lang="scss" module>
.pattern {
  @media only screen and (min-width: 2200px) {
    background-image: url('./pattern.png') !important;
    background-repeat: repeat-x;
    background-size: contain;
  }
}

.videoContainer {
  position: absolute;

  top: 0;
  left: 50%;

  width: 100vw;
  height: 100%;

  transform: translateX(-50%);
  @media only screen and (min-width: 2200px) {
    width: 1430px;
    object-fit: contain;
  }
}

.subtitle {
  max-width: 650px;
  margin: 0;

  ul {
    padding: 0;
    li {
      padding-left: 35px;

      font-size: 24px;
    }
    li:before {
      content: '✓';
    }
  }
}

.itemText {
  padding: 20px 0;

  color: white;
  h3 {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
  }
  p {
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}
</style>
