<template>
  <SliceWrapper :slice="slice">
    <ProductCarouselLightBlue v-if="slice.variation === 'lightBlue'" :slice="slice" />
    <ProductCarouselDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import ProductCarouselDefault from './default/'
import ProductCarouselLightBlue from './lightBlue/'

export default {
  components: { ProductCarouselDefault, ProductCarouselLightBlue },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
