<template>
  <div class="m-0 my-[144px] lg:my-[80px]">
    <Wrapper class="flex gap-[10%] p-[24px] lg:max-w-[720px] lg:flex-col lg:gap-0" :class="{ ['flex-row-reverse']: primary.reverse }">
      <aside class="flex-1">
        <p class="mb-[8px] text-[14px] font-[700] uppercase leading-[16px] text-gray-200 sm:hidden">{{ primary.caption }}</p>
        <Title :title-params="primary.title" class="mb-[24px] text-[40px] font-[300] leading-[48px] sm:text-center" />
        <div class="mb-[16px] flex gap-[12px] lg:justify-center">
          <span
            v-for="(item, idx) in items"
            :key="idx"
            class="cursor-pointer rounded-[24px] bg-bluko-50 p-[8px] px-[12px] text-center text-[14px] font-[450] text-bluko-500"
            :class="{ [$style.active]: currentIndex === idx }"
            @click="onClickBadge(idx)"
          >
            {{ item.category }}
          </span>
        </div>
        <transition-group name="fade">
          <RichText :key="currentIndex" :text-params="currentItem.description" class="font-[450] sm:text-center [&>p]:mb-[8px] sm:[&>p]:mb-[24px]" />
        </transition-group>
        <div v-if="primary.ctaText" class="mt-[24px] lg:text-center">
          <ArrowButton :link-object="primary.ctaLink" is-outlined :link-text="primary.ctaText" />
        </div>
      </aside>
      <div
        v-observe-visibility="{
          callback: isVisible,
          once: true,
          intersection: {
            rootMargin: '-30% 0px -30% 0px',
          },
        }"
        class="flex flex-1 justify-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="640" height="512" viewBox="0 0 640 512" fill="none" class="h-full w-full">
          <defs>
            <radialGradient id="bluko" xmlns="http://www.w3.org/2000/svg" cx="210" cy="125" r="140" gradientUnits="userSpaceOnUse">
              <stop offset="0.499196" stop-color="#2D50E5" />
              <stop offset="0.501265" stop-color="#2D50E6" />
              <stop offset="0.800766" stop-color="#1B31A4" />
            </radialGradient>
            <radialGradient id="red" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse">
              <stop offset="0.499527" stop-color="#D95762" />
            </radialGradient>
          </defs>
          <circle cx="160" cy="256" r="100" fill="white" :stroke="theme.background" stroke-width="40" :class="$style.donut" />
          <circle
            cx="160"
            cy="160"
            r="100"
            fill="transparent"
            :stroke="`url(#${theme.gradient})`"
            stroke-width="40"
            :stroke-dasharray="strokeDasharray"
            transform="rotate(-90) translate(-416)"
          />
          <g>
            <circle :cx="marginCenter.x" :cy="marginCenter.y" r="20" :fill="theme.edge" />
            <text text-anchor="middle" :x="marginTextCenter.x" :y="marginTextCenter.y" fill="white" class="text-[14px] font-[900]">
              {{ Math.round(currentItem.marginRatio * 100) }}%
            </text>
          </g>
          <image :xlink:href="currentItem.logo.url" x="160" y="256" :class="$style.logo" />
          <text
            v-if="currentItem.reimbursementRatio"
            text-anchor="middle"
            :x="reimbursementTextCenter.x"
            :y="reimbursementTextCenter.y"
            fill="#1B31A4"
            class="text-[14px] font-[900]"
          >
            {{ Math.round(currentItem.reimbursementRatio * 100) }}%
          </text>

          <template v-if="currentIndex === 0">
            <path xmlns="http://www.w3.org/2000/svg" d="M252 165.5 L296 122 H365" :stroke="`url(#bluko)`" stroke-width="4" stroke-linecap="round" />
            <text
              v-for="(t, idx) in currentItem.marginText"
              :key="idx + 'marginText'"
              x="380"
              :y="130 + 30 * idx"
              :class="{
                [$style.category0]: true,
                [$style.heading]: t.type.includes('heading'),
                [$style.desc]: t.type.includes('paragraph'),
              }"
            >
              {{ t.text }}
            </text>
            <path xmlns="http://www.w3.org/2000/svg" d="M255 350 L275 362 H365" stroke="#E0ECFE" stroke-width="4" stroke-linecap="round" />
            <text
              v-for="(t, idx) in currentItem.reimbursementText"
              :key="idx + 'reimbursementText'"
              x="380"
              :y="360 + 30 * idx"
              :class="{
                [$style.category0]: true,
                [$style.heading]: t.type.includes('heading'),
                [$style.desc]: t.type.includes('paragraph'),
              }"
            >
              {{ t.text }}
            </text>
          </template>
          <template v-else>
            <g>
              <rect
                x="315"
                y="203"
                :width="descBadgeWidth(richToText(currentItem.marginText))"
                height="40"
                stroke="#a0353d"
                rx="22"
                ry="22"
                stroke-width="2"
              ></rect>
              <text x="330" y="230" :class="[$style.category1, $style.desc1]">
                {{ richToText(currentItem.marginText) }}
              </text>
            </g>
            <g>
              <rect
                x="315"
                y="273"
                :width="descBadgeWidth(richToText(currentItem.reimbursementText))"
                height="40"
                stroke="#ea979e"
                rx="22"
                ry="22"
                stroke-width="2"
              ></rect>
              <text x="330" y="300" :class="[$style.category1, $style.desc2]">
                {{ richToText(currentItem.reimbursementText) }}
              </text>
            </g>
          </template>

          <text v-if="primary.source" x="320" y="500" class="text-[8px]" fill="#c0beb8" text-anchor="middle">{{ primary.source }}</text>
        </svg>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton'
import { useSvg } from '@/composables/useSvg'

const { easeInOut } = useSvg()

export default {
  components: { ArrowButton },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      time: 0, // 0 ~ 1
      marginRatio: 0,
      animationId: null,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    marginCenter() {
      return {
        x: 100 * Math.sin(2 * Math.PI * this.marginRatio) + 160,
        y: -100 * Math.cos(2 * Math.PI * this.marginRatio) + 256,
      }
    },
    marginTextCenter() {
      return {
        x: 103 * Math.sin(2 * Math.PI * this.marginRatio - 0.01) + 160,
        y: -103 * Math.cos(2 * Math.PI * this.marginRatio - 0.01) + 256,
      }
    },
    reimbursementTextCenter() {
      return {
        x: 103 * Math.sin(2 * Math.PI * (this.currentItem.marginRatio + 0.07)) + 160,
        y: -103 * Math.cos(2 * Math.PI * (this.currentItem.marginRatio + 0.07)) + 256,
      }
    },
    strokeDasharray() {
      return `${Math.PI * 200 * this.marginRatio} ${Math.PI * 200}`
    },
    theme() {
      return this.currentIndex === 0
        ? { gradient: 'bluko', background: '#E0ECFE', edge: '#1c32a5' }
        : { gradient: 'red', background: '#FBD8DB', edge: '#D95762' }
    },
    currentItem() {
      return this.items[this.currentIndex]
    },
  },
  methods: {
    isVisible(visible) {
      if (visible) {
        this.onClickBadge(this.currentIndex)
      }
    },
    onClickBadge(idx) {
      this.currentIndex = idx
      this.marginRatio = 0
      this.time = 0
      window.cancelAnimationFrame(this.animationId)

      const augmentMarginRatio = () => {
        this.marginRatio = this.items[idx].marginRatio * easeInOut(this.time)
        this.time += 0.015

        if (this.time >= 1) {
          this.marginRatio = this.items[idx].marginRatio
          this.time = 0
          return
        }
        this.animationId = window.requestAnimationFrame(augmentMarginRatio)
      }
      this.animationId = window.requestAnimationFrame(augmentMarginRatio)
    },
    fillColor(t) {
      if (t.type.includes('heading') && this.currentIndex === 0) return '#1B31A4'
      if (t.type === 'paragraph') return '#807C6C'
    },
    richToText(rich) {
      return rich.reduce((res, r) => res + r.text, '')
    },
    descBadgeWidth(text) {
      return text.length * 9 + 40
    },
  },
}
</script>

<style lang="scss" module>
.active {
  color: white;

  background-color: $bluko-500;
}

.donut {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.logo {
  width: 14%;
  height: 10%;

  transform: translate(-7%, -5%);
}

.category0 {
  max-width: 200px;
  &.heading {
    font-weight: 500;
    font-size: 27px;

    fill: $bluko-700;
  }
  &.desc {
    fill: $gray-600;
  }
}

.category1 {
  &.desc1 {
    font-weight: 400;
    font-size: 20px;

    fill: #a0353d;
  }
  &.desc2 {
    font-weight: 400;
    font-size: 20px;

    fill: #ea979e;
  }
}
</style>
<style scoped>
/* slide right */
.fade-enter {
  transform: translateY(10px);

  opacity: 0;
}
.fade-enter-to {
  transform: initial;

  opacity: 1;
}
.fade-leave {
  display: none;
}
.fade-leave-to {
  display: none;
}
.fade-enter-active {
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}
.fade-leave-active {
  display: none;
}
</style>
