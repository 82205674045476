<template>
  <LkButton
    v-if="linkText"
    :simple-url="simpleUrl"
    :link-object="linkObject"
    :target="target"
    :rel="rel"
    :is-outlined="isOutlined"
    :class="$style.button"
    :is-white="isWhite"
    :has-icon-right="hasIconRight"
    v-bind="$attrs"
    class="transition-colors duration-200"
    :data-luko-tracking="dataLukoTracking"
    v-on="$listeners"
  >
    <template #icon>
      <ArrowRightIcon class="transition-transform duration-200" :class="$style.icon" />
    </template>
    {{ linkText }}
  </LkButton>
</template>
<script>
import ArrowRightIcon from 'LkIcons/ArrowRight.vue'

export default {
  name: 'ArrowButton',
  components: {
    ArrowRightIcon,
  },
  props: {
    simpleUrl: {
      type: String,
      default: null,
    },
    linkObject: {
      type: Object,
      default: null,
    },
    linkText: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    rel: {
      type: String,
      default: '',
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    hasIconRight: {
      type: Boolean,
      default: false,
    },
    dataLukoTracking: {
      type: String,
      default: undefined,
    },
  },
}
</script>
<style lang="scss" module>
.button:hover .icon {
  transform: translateX(5px);
}
</style>
