<template>
  <div class="my-[128px] sm:my-[80px]">
    <Wrapper class="flex items-center justify-between gap-[50px] px-[24px] md:flex-col">
      <aside class="flex-1 md:hidden">
        <PImage :image-object="primary.image" :class="$style.image" sizes="sm:100vw" lazy />
      </aside>

      <div class="flex-1 md:w-full md:px-[32px] md:pb-0 sm:px-[24px]">
        <Title class="mb-[16px] !font-[300] typo-extra-large-title md:text-center" :title-params="primary.title" />
        <RichText
          class="[&>p]:my-18px mb-[20px] max-w-[840px] text-[#808080] md:text-center [&_strong]:font-[450]"
          :text-params="primary.subDescription"
        />

        <ul class="mt-[48px] flex flex-col gap-[32px]">
          <li class="flex" :class="$style.itemContainer" data-aos="fade-up">
            <LinearProgressBar class="flex-shrink-0" :sec="2" :delay="0.3" prefill />
            <RichText :text-params="primary['30Description']" class="p-[10px] px-[20px] text-gray-800 [&_strong]:font-[450] [&_strong]:!text-black" />
          </li>
          <li class="flex" :class="$style.itemContainer" data-aos="fade-up">
            <LinearProgressBar class="flex-shrink-0" :sec="2" prefill />
            <RichText :text-params="primary['70Description']" class="p-[10px] px-[20px] text-gray-800 [&_strong]:font-[450] [&_strong]:!text-black" />
          </li>
          <li class="flex" :class="$style.itemContainer" data-aos="fade-up">
            <LinearProgressBar class="flex-shrink-0" :sec="2" prefill />
            <RichText
              :text-params="primary.remainingDescription"
              class="p-[10px] px-[20px] text-gray-800 [&_strong]:font-[450] [&_strong]:!text-black"
            />
          </li>
        </ul>
      </div>
    </Wrapper>
    <Wrapper v-if="primary.ctaText && primary.ctaLink && primary.ctaLink.url" class="mt-[40px] px-[24px] text-center">
      <ArrowButton is-outlined :link-object="primary.ctaLink" :link-text="primary.ctaText" />
    </Wrapper>
  </div>
</template>

<script>
import LinearProgressBar from '@/components/LinearProgressBar'
import ArrowButton from '@/components/ArrowButton'

export default {
  components: {
    ArrowButton,
    LinearProgressBar,
  },
  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.image {
  width: calc(min(520px, 50vw));
  height: 730px;
  border-top-left-radius: 250px;
  border-top-right-radius: 250px;
  object-fit: cover;
  object-position: center;

  @include below(medium) {
    width: 100%;
    height: 338px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
