<template>
  <div data-luko-tracking="Counter">
    {{ done ? text : countedText }}
  </div>
</template>

<script>
const INTERVAL = 20

export default {
  name: 'Counter',
  props: {
    text: {
      type: String,
      required: true,
    },
    launch: {
      type: Boolean,
      default: false,
    },
    sec: {
      type: Number,
      default: 1.2,
    },
  },
  data() {
    return {
      countedText: this.text,
      done: true,
    }
  },
  watch: {
    launch(newVal) {
      if (newVal) {
        this.done = false
        setTimeout(() => {
          clearInterval(this.intervalId)
          this.done = true
        }, this.sec * 1000)
        this.counting()
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    counting() {
      this.intervalId = setInterval(() => {
        this.countedText = Array.from(this.countedText)
          .map((char) => {
            const num = parseInt(char)
            if (num || num === 0) {
              return parseInt(num + Math.random() * 10) % 10
            }
            return char
          })
          .join('')
      }, INTERVAL)
    },
  },
}
</script>

<style lang="scss" scoped></style>
