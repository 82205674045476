<template>
  <div class="py-[128px] px-[32px] sm:py-[64px] sm:px-[24px]">
    <Wrapper>
      <Title :title-params="primary.title" class="text-center !font-[300] typo-mega-title md:typo-large-title" />
      <div class="mt-[40px] flex justify-center gap-[100px] lg:mx-auto lg:my-[40px] lg:max-w-[500px] lg:flex-col lg:items-center lg:gap-[80px]">
        <template v-for="(item, itemIndex) in items">
          <div
            v-if="itemIndex % 2"
            :key="'seperator_' + itemIndex"
            class="w-0 items-stretch border-[1px] border-solid border-gray-75 lg:hidden"
          ></div>
          <div :key="itemIndex" :class="[hasSecondIllustration(item) ? 'flex-[2]' : 'flex-[1.2]']">
            <Title :title-params="item.title" class="text-center typo-title md:typo-headline-bold" />
            <RichText :text-params="item.description" class="mt-[8px] mb-[40px] text-center text-gray-700 [&>strong]:text-black" />

            <Title :title-params="item.illustrationsTitle" class="text-center text-gray-700" />
            <div class="flex justify-evenly gap-[32px] sm:flex-col">
              <div class="flex flex-col items-center">
                <PImage v-if="item.illustration" :image-object="item.illustration" class="h-[292px] w-[282px]" sizes="xs:350px" />
                <RichText :text-params="item.illustrationDescription" class="max-w-[210px] text-center text-gray-500" />
              </div>
              <div v-if="hasSecondIllustration(item)" class="flex flex-col items-center">
                <PImage :image-object="item.illustration2" class="h-[292px] w-[282px]" sizes="xs:350px" />
                <RichText :text-params="item.illustration2Description" class="max-w-[210px] text-center text-gray-500" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </Wrapper>
  </div>
</template>

<script>
export default {
  name: 'RefundSchema',

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },

  methods: {
    hasSecondIllustration(item) {
      return item.illustration2 && item.illustration2.url
    },
  },
}
</script>
