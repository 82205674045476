var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('LkLabel',{attrs:{"label":_vm.label}}),_vm._v(" "),_c('VueSlider',_vm._b({class:( _obj = {}, _obj['mb-[48px]'] = _vm.tooltipPlacement === 'bottom', _obj['mt-[48px]'] = _vm.tooltipPlacement === 'top', _obj ),attrs:{"tooltip":_vm.localTooltipActive,"tooltip-placement":_vm.tooltipPlacement,"value":_vm.value},on:{"change":function (v) { return _vm.$emit('input', v); },"drag-start":function($event){_vm.isDragging = true},"drag-end":function($event){_vm.isDragging = false}},scopedSlots:_vm._u([{key:"dot",fn:function(){return [_c('div',{staticClass:"flex h-full w-full items-center justify-center rounded-[50%]",class:[_vm.isDragging && _vm.$style.dotShadow]},[_c('SliderSvg',{staticClass:"h-[40px] w-[40px] cursor-pointer"})],1)]},proxy:true},{key:"tooltip",fn:function(ref){
var _obj, _obj$1;

var sliderValue = ref.value;return [_c('div',{staticClass:"flex flex-col items-center gap-0",class:( _obj = {}, _obj['!flex-col-reverse'] = _vm.tooltipPlacement === 'bottom', _obj )},[_c('div',{class:[_vm.$style.tooltip]},[_vm._v(_vm._s(_vm.tooltipFormatter(sliderValue)))]),_vm._v(" "),_c('TooltipTailBottomSvg',{staticClass:"m-[-1px] h-[8px] w-[24px]",class:( _obj$1 = {}, _obj$1['rotate-180'] = _vm.tooltipPlacement === 'bottom', _obj$1 )})],1)]}},{key:"label",fn:function(ref){
var sliderLabel = ref.label;
var active = ref.active;
return [_c('div',{staticClass:"vue-slider-mark-label"},[(sliderLabel)?_c('div',{class:[_vm.$style.label, _vm.$style.labelContainer, active && _vm.$style.active]},[_vm._v("\n          "+_vm._s(sliderLabel)+"\n        ")]):_vm._e()])]}}])},'VueSlider',_vm.sliderOptions,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }