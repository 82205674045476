<template>
  <div class="my-[128px] lg:my-[64px]">
    <Wrapper class="flex gap-[4%] p-[24px] lg:!flex-col lg:gap-[60px]" :class="{ ['flex-row-reverse']: primary.reverse }">
      <aside class="mx-auto flex max-w-[800px] flex-1 flex-col justify-center lg:items-center">
        <h3 v-if="primary.title" class="mb-[24px] !font-[300] typo-extra-large-title lg:text-center sm:typo-large-title">{{ primary.title }}</h3>
        <RichText :text-params="primary.description" class="font-[450] lg:text-center [&>p]:mb-[8px] lg:[&>p]:mb-[24px]" />
        <ul class="my-[16px] flex gap-[8px] lg:justify-center sm:gap-[4px]">
          <li :class="$style.scoreDemoItem">
            <ScoreLow />
            <span class="typo-caption-bold">{{ primary.lowScoreText }}</span>
          </li>
          <li :class="$style.scoreDemoItem">
            <ScoreMedium />
            <span class="typo-caption-bold">{{ primary.mediumScoreText }}</span>
          </li>
          <li :class="$style.scoreDemoItem">
            <ScoreHigh />
            <span class="typo-caption-bold"> {{ primary.highScoreText }}</span>
          </li>
        </ul>
        <RichText :text-params="primary.subDescription" class="font-[450] lg:text-center [&>p]:mb-[8px] lg:[&>p]:mb-[24px]" />

        <div class="mt-[8px] lg:text-center">
          <ArrowButton is-outlined :link-object="primary.ctaLink" :link-text="primary.ctaText" />
        </div>

        <InfoTooltip v-if="primary.info" :tooltip-text-params="primary.infoText" class="mt-[40px]">
          {{ primary.info }}
        </InfoTooltip>
      </aside>

      <figure
        v-observe-visibility="{
          callback: isVisible,
          once: true,
          intersection: {
            rootMargin: '-30% 0px -30% 0px',
          },
        }"
        class="flex flex-1 flex-col items-center justify-center"
      >
        <div class="flex w-[320px] flex-col justify-center gap-[24px] rounded-[8px] bg-bluko-50 py-[32px] px-[24px] text-center">
          <p class="font-[450] uppercase text-bluko-800">{{ primary.cardTitle }}</p>
          <span class="text-[120px] font-[300] leading-[100px]" :text="primary.cardScore" :launch="visible" :sec="2">
            {{ parseInt(score) }}
          </span>
          <div class="flex items-center gap-[8px] rounded-[24px] bg-white py-[8px] px-[16px]">
            <TriangleIcon
              :class="{
                [$style.cardScoreIcon]: true,
                [$style.isAugmented]: primary.isScoreAugmented,
                [$style.isDecreased]: !primary.isScoreAugmented,
              }"
            />

            <RichText :text-params="primary.scoreResult" class="[&>p>strong]:font-500 text-[14px] leading-[20px]" />
          </div>
        </div>
        <figcaption class="mt-[48px] text-center text-[8px] text-gray-300">{{ primary.source }}</figcaption>
      </figure>
    </Wrapper>
  </div>
</template>

<script>
import InfoTooltip from '@/components/InfoTooltip'
import ArrowButton from '@/components/ArrowButton'

import ScoreLow from './scoreLow.svg?inline'
import ScoreMedium from './scoreMedium.svg?inline'
import ScoreHigh from './scoreHigh.svg?inline'
import TriangleIcon from './triangle.svg?inline'

export default {
  components: {
    ScoreLow,
    ScoreMedium,
    ScoreHigh,
    ArrowButton,
    TriangleIcon,
    InfoTooltip,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      score: 0,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    isVisible(visible) {
      const counting = () => {
        this.score += 0.5
        if (this.score >= this.primary.cardScore) return
        requestAnimationFrame(counting)
      }

      if (visible) {
        requestAnimationFrame(counting)
      }
    },
  },
}
</script>
<style lang="scss" module>
.scoreDemoItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:nth-of-type(1) {
    color: $bluko-200;
  }
  &:nth-of-type(2) {
    color: $bluko-400;
  }
  &:nth-of-type(3) {
    color: $bluko-500;
  }

  @include below(xsmall) {
    &:nth-of-type(1) {
      svg {
        width: 54vw;
      }
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      svg {
        width: 14vw;
      }
    }
  }
}

.isAugmented {
  path {
    fill: #2a8c7c;
  }
}
.isDecreased {
  path {
    fill: red;
  }
  transform: rotate(180deg);
}
</style>
