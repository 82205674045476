<template>
  <PLink
    v-if="linkText && (linkObject || simpleUrl)"
    data-luko-tracking="ArrowLink"
    :link-object="linkObject"
    :simple-url="simpleUrl"
    :target="target"
    :class="$style.link"
    class="flex w-fit items-center pl-[10px] text-[16px] font-[500] leading-[24px] no-underline"
    v-on="$listeners"
  >
    <ArrowRightIcon
      class="h-[14px] w-[14px] shrink-0 -translate-x-[10px] transition-transform duration-200"
      :class="$style.icon"
      :width="iconSize"
      :height="iconSize"
    />
    {{ linkText }}
  </PLink>
</template>

<script>
import ArrowRightIcon from 'LkIcons/ArrowRight.vue'

export default {
  name: 'ArrowLink',

  components: {
    ArrowRightIcon,
  },

  props: {
    linkObject: {
      type: Object,
      default: null,
    },
    simpleUrl: {
      type: String,
      default: '',
    },
    targetedSection: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '14',
    },
    target: {
      type: String,
      default: undefined,
    },
    linkText: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" module>
.link {
  &:hover {
    .icon {
      transform: translateX(-5px);
    }
  }
}
</style>
