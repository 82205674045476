<template>
  <div class="my-[144px] mx-0 lg:my-[80px]">
    <Wrapper class="flex flex-col gap-[60px] p-[24px]">
      <aside class="mx-auto flex max-w-[750px] flex-1 flex-col content-center items-center">
        <Title :title-params="primary.title" class="mb-[24px] text-center text-[40px] font-[300] leading-[50px]" />
        <RichText :text-params="primary.description" class="opacity-[0.6] [&>p]:mb-[20px] [&>p]:text-center [&>p]:text-[16px] [&>p]:leading-[24px]" />

        <ArrowButton is-outlined :link-object="primary.ctaLink" :link-text="primary.ctaText" class="mt-[32px] sm:mt-[24px]" />
      </aside>
      <figure class="flex flex-1 flex-col content-center items-center">
        <Title :title-params="primary.graphTitle" class="text-center text-[22px] font-[500] leading-[32px] [&>strong]:text-bluko-500" />
        <svg
          v-observe-visibility="{
            callback: onVisible,
            once: true,
            intersection: {
              rootMargin: '-10% 0px -10% 0px',
            },
          }"
          xmlns="http://www.w3.org/2000/svg"
          :width="graph.width"
          :height="graph.height"
          :viewBox="`0 0 ${graph.width} ${graph.height}`"
          fill="none"
          class="h-full w-full"
          :style="{ maxWidth: `${graph.width}px` }"
        >
          <defs>
            <linearGradient
              id="linearGray"
              xmlns="http://www.w3.org/2000/svg"
              :x1="coordinates[0].x"
              :y1="graph.height - graph.baseHeight - graph.paddingHeight"
              :x2="coordinates[0].x"
              :y2="(coordinates[0].value / maxData.value) * (graph.height - graph.baseHeight - graph.paddingHeight) - 30"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DFDED9" stop-opacity="0.65" />
              <stop offset="1" stop-color="#DFDED9" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="linearLightBlue" x1="40" y1="210" x2="460" y2="290" gradientUnits="userSpaceOnUse">
              <stop stop-color="transparent" offset="0" />
              <stop stop-color="#acbef7" offset="0.5" />
              <stop stop-color="#acbef7" offset="1" />
            </linearGradient>
          </defs>
          <!-- Gap highlight -->

          <g v-if="primary.gapText && primary.gapText.length && primary.gapText[0].text">
            <rect
              id="gapHighlight"
              :x="graph.paddingWidth"
              :width="graph.barWidth"
              height="0"
              rx="8"
              ry="8"
              fill="url(#linearGray)"
              :style="{
                transform: `scale(1, -1) translate(0, ${-graph.height + graph.baseHeight}px)`,
              }"
            >
              <animate
                id="gapHighlightAnimate"
                attributeName="height"
                begin="pathAnimate.end+0.3s"
                from="0"
                :to="graph.height - graph.baseHeight - graph.paddingHeight"
                dur="0.8s"
                calcMode="spline"
                keyTimes="0; 0.25; 0.5; 0.75; 1"
                keySplines="0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1"
                fill="freeze"
              />
            </rect>
            <text
              v-for="(p, idx) in primary.gapText"
              :key="'p-' + idx"
              fill="#2D50E6"
              text-anchor="middle"
              :x="coordinates[0].x"
              :y="maxCoordinate.y + 20 * (idx + 1) + 5"
              class="text-[16px] font-[500] leading-[19px] opacity-0"
            >
              <animate attributeType="CSS" attributeName="opacity" begin="gapHighlightAnimate.end" from="0" to="1" fill="freeze" dur="0.2s" />
              {{ p.text }}
            </text>
          </g>
          <g v-for="(d, idx) in graph.data" :key="'bar-' + idx">
            <image
              ref="logos"
              :href="d.logo.url"
              :x="idx * graph.xGap + graph.paddingWidth + graph.barWidth / 2 - logosWidth[idx] / 2"
              :y="graph.height - graph.paddingHeight"
              height="20px"
              :style="{ opacity: showImage ? '1' : '0' }"
            />
            <rect
              :x="idx * graph.xGap + graph.paddingWidth"
              :y="0"
              :width="graph.barWidth"
              :height="0"
              rx="8"
              ry="8"
              :fill="idx === 0 ? '#2D50E6' : '#FFEDE3'"
              :style="{
                transform: `scale(1, -1) translate(0, ${-graph.height + graph.baseHeight}px)`,
              }"
            >
              <animate
                :id="`barAnimate${idx}`"
                ref="barAnimate"
                begin="indefinite"
                attributeName="height"
                from="0"
                :to="(d.value / maxData.value) * (graph.height - graph.baseHeight - graph.paddingHeight)"
                dur="0.8s"
                calcMode="spline"
                keyTimes="0; 0.25; 0.5; 0.75; 1"
                keySplines="0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1"
                fill="freeze"
              />
            </rect>
            <text
              text-anchor="middle"
              :fill="idx === 0 ? 'white' : '#807C6C'"
              :x="coordinates[idx].x"
              :y="coordinates[idx].y + 25"
              class="font-[600] opacity-0"
            >
              {{ d.value_text }}
              <animate
                :id="`barTextAnimate${idx}`"
                :begin="`barAnimate${idx}.end`"
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                fill="freeze"
                dur="0.2s"
              />
            </text>
            <text
              text-anchor="middle"
              :fill="idx === 0 ? 'white' : '#807C6C'"
              :x="coordinates[idx].x"
              :y="graph.height - graph.baseHeight - 30"
              class="text-[14px] font-[600] opacity-0"
            >
              <animate :begin="`barAnimate${idx}.end`" attributeType="CSS" attributeName="opacity" from="0" to="1" fill="freeze" dur="0.2s" />
              {{ d.bottom_value_text }}
            </text>
            <text
              text-anchor="middle"
              :fill="idx === 0 ? 'white' : '#807C6C'"
              :x="coordinates[idx].x"
              :y="graph.height - graph.baseHeight - 15"
              class="text-[12px] opacity-0"
            >
              <animate :begin="`barAnimate${idx}.end`" attributeType="CSS" attributeName="opacity" from="0" to="1" fill="freeze" dur="0.2s" />
              {{ d.bottom_sub_text }}
            </text>
          </g>
          <line
            :x1="maxCoordinate.x - graph.barWidth / 2 - 5"
            :y1="maxCoordinate.y + 2"
            :x2="coordinates[0].x"
            :y2="maxCoordinate.y + 2"
            stroke="url(#linearLightBlue)"
            stroke-dasharray="0.2 11"
            stroke-linecap="round"
            :stroke-dashoffset="coordinates[0].x + graph.barWidth + 10 - maxCoordinate.x"
            stroke-width="2"
            class="opacity-0"
          >
            <animate
              id="pathAnimate"
              :begin="`barAnimate0.end+0.5s`"
              attributeType="CSS"
              attributeName="opacity"
              from="0"
              to="1"
              fill="freeze"
              dur="0.2s"
            />
          </line>
          <text :x="graph.paddingWidth" :y="graph.height" fill="" class="">{{ primary.source }}</text>
        </svg>
        <figcaption class="w-full max-w-[550px] text-[10px] font-[500] leading-[24px] text-gray-300">
          {{ primary.source }}
        </figcaption>
        <InfoTooltip v-if="primary.info" :tooltip-text-params="primary.infoText">
          {{ primary.info }}
        </InfoTooltip>
      </figure>
    </Wrapper>
  </div>
</template>

<script>
//    |------------------------
//    |       |  paddingHeight
//    |------------------------
// H  |
//    | -----------------------
//    | | baseHeight
//    | | ---------------------
//    | |    | paddingHeight
//    | | ---------------------
import InfoTooltip from '@/components/InfoTooltip'
import ArrowButton from '@/components/ArrowButton'

export default {
  components: {
    InfoTooltip,
    ArrowButton,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      logosWidth: new Array(this.slice.items.length).fill(0),
      showImage: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    graph() {
      const data = this.slice.items.filter((d) => d.value).map((d) => ({ ...d, value: parseFloat(d.value) }))
      const width = 640
      const barWidth = 65
      const paddingWidth = 40
      const paddingHeight = 40
      const xGap = (width - barWidth - paddingWidth * 2) / (data.length - 1)

      return {
        width,
        paddingWidth,
        height: 360,
        baseHeight: 60,
        paddingHeight,
        xGap,
        barWidth,
        data,
      }
    },
    maxDataIdx() {
      let maxIdx = 0

      this.graph.data.forEach((d, idx) => {
        maxIdx = this.graph.data[maxIdx].value <= d.value ? idx : maxIdx
      })

      return maxIdx
    },
    maxData() {
      return this.graph.data[this.maxDataIdx]
    },
    coordinates() {
      return this.graph.data.map((d, idx) => ({
        ...d,
        x: idx * this.graph.xGap + this.graph.paddingWidth + this.graph.barWidth / 2,
        y:
          this.graph.height -
          (d.value / this.maxData.value) * (this.graph.height - this.graph.baseHeight - this.graph.paddingHeight) -
          this.graph.baseHeight,
      }))
    },
    maxCoordinate() {
      return this.coordinates[this.maxDataIdx]
    },
  },
  methods: {
    onVisible(visible) {
      if (visible) {
        this.logosWidth = this.$refs.logos.map((el) => el.getBBox().width)
        this.showImage = true
        this.$refs.barAnimate.map((el) => el.beginElement())
      }
    },
  },
}
</script>
