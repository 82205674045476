<template>
  <SliceWrapper :slice="slice">
    <HighlightSectionDarkblue v-if="slice.variation === 'darkblue'" :slice="slice" />
    <HighlightSectionLightBlue v-else-if="slice.variation === 'lightBlue'" :slice="slice" />
    <HighlightSectionLightBlue2 v-else-if="slice.variation === 'lightBlue2'" :slice="slice" />
    <HighlightSectionDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import HighlightSectionDefault from './default-slice/'
import HighlightSectionDarkblue from './darkblue'
import HighlightSectionLightBlue from './lightBlue'
import HighlightSectionLightBlue2 from './lightBlue2'

export default {
  name: 'HighlightSection',
  components: {
    HighlightSectionDefault,
    HighlightSectionDarkblue,
    HighlightSectionLightBlue,
    HighlightSectionLightBlue2,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
