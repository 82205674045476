<template>
  <div
    class="inline-flex items-center justify-center rounded-[50%] bg-bluko-500"
    :style="{
      backgroundColor: randomColor,
      width: `${size}px`,
      height: `${size}px`,
    }"
  >
    <span v-if="!image" class="font-[700] text-white">{{ initial }}</span>
    <img v-else :src="image" class="h-full w-full rounded-[50%]" />
  </div>
</template>

<script>
export default {
  name: 'ProfileThumbnail',
  props: {
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 80,
    },
  },
  computed: {
    initial() {
      if (!this.name) return ''

      const initials = this.name.split(' ')
      if (initials.length === 1) {
        return initials[0].charAt(0).toUpperCase() + initials[0].charAt(1).toUpperCase()
      }
      return initials[0].charAt(0).toUpperCase() + initials[1].charAt(0).toUpperCase()
    },
    randomColor() {
      const colors = [
        '#b72b2b',
        '#2bb756',
        '#b72bac',
        '#2b43b7',
        '#d9bf0a',
        '#5fb72b',
        '#504f13',
        '#135035',
        '#133850',
        '#502d13',
        '#2e3a51',
        '#79567e',
      ]

      if (this.name) {
        const c = this.name[0].charCodeAt()
        return colors[c % colors.length]
      }
      return colors[Math.floor(Math.random() * colors.length)]
    },
  },
}
</script>
