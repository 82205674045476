<template>
  <div
    v-observe-visibility="{
      callback: onVisible,
      intersection: {
        rootMargin: '100% 0px 100% 0px',
      },
      once: true,
    }"
    class="overflow-x-hidden bg-orange-50 py-[64px] sm:py-[40px]"
  >
    <Title :title-params="primary.title" class="mx-auto mb-[16px] max-w-[1024px] text-center !font-[300] typo-mega-title sm:typo-extra-large-title" />
    <RichText :text-params="primary.description" class="mx-auto mt-[10px] mb-[32px] max-w-[1024px] px-[24px] text-center typo-body" />
    <div v-if="primary.ctaText && primary.ctaLink && primary.ctaLink.url" class="mb-[54px] px-[24px] text-center">
      <ArrowButton is-outlined :link-object="primary.ctaLink" :target="primary.ctaLink.target" class="sm:!w-full" :link-text="primary.ctaText" />
    </div>

    <Wrapper class="min-h-[80px] overflow-hidden">
      <div ref="carousel" :class="$style.carousel">
        <div v-for="(item, index) in items" :key="index" :class="$style.logoContainer">
          <PImage :image-object="imageLoad ? item.logo : null" :class="$style.logo" />
        </div>
        <div v-for="(item, index) in items" :key="'second-' + index" :class="$style.logoContainer" lazy>
          <PImage :image-object="imageLoad ? item.logo : null" :class="$style.logo" />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton'

import { useSlice } from '../useSlice'

const { makeMoreThan10 } = useSlice()

export default {
  name: 'PressListCarouselDefault',
  components: {
    ArrowButton,
  },
  mixins: [],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageLoad: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return makeMoreThan10(this.slice.items).filter((item) => item.logo?.url)
    },
  },
  mounted() {
    const carousel = this.$refs.carousel
    if (!carousel) return
    carousel.style.display = 'block'
    carousel.style.setProperty('--carousel-items', this.items.length)
  },
  methods: {
    onVisible(visible) {
      if (!this.imageLoad && visible) {
        this.imageLoad = true
      }
    },
  },
}
</script>

<style lang="scss" module>
.carousel {
  --carousel-items: 0;
  --carousel-item-width: 220px;

  display: none;
  width: calc(var(--carousel-items) * var(--carousel-item-width) * 2 + 4.1px); // I don't know why there is 4.1px

  animation: scroll 50s linear infinite;
}

.logoContainer {
  display: inline-block;
  width: var(--carousel-item-width);
  margin: 0;
  padding: 0;

  text-align: center;
}
.logo {
  width: var(--carousel-item-width);
  height: 80px;
  margin: 0;
  padding: 0;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * var(--carousel-items) * var(--carousel-item-width)));
  }
}
</style>
