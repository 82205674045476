<template>
  <div :class="[$style.heroContainer]">
    <Wrapper class="flex h-full justify-around pt-[128px] md:flex-col md:items-center md:gap-[60px]">
      <div class="w-1/2 max-w-[600px] md:w-full">
        <Title class="mt-[16px] inline !font-[300] typo-mega-title hyphens" :title-params="primary.title" />
        <RichText :class="$style.subtitle" :text-params="primary.subtitle" />
        <div class="mt-[40px] md:flex md:justify-center">
          <ArrowButton :link-object="primary.ctaLink" class="sm:!w-full" :link-text="primary.ctaText" />
        </div>
      </div>
      <div class="h-[400px] w-[400px] overflow-hidden rounded-tr-[100px] rounded-bl-[100px] sm:hidden">
        <PImage class="h-full w-full object-cover" :image-object="primary.backgroundImage" sizes="sm:100vw" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import PImage from '@/components/PImage'
import ArrowButton from '@/components/ArrowButton'

export default {
  name: 'HeroPriceDefault',

  components: {
    PImage,
    ArrowButton,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.heroContainer {
  margin-top: -64px;
  padding: 0 80px 40px;

  background-image: linear-gradient(0deg, rgba(167, 209, 255, 0.5), #fff);

  @include below(medium) {
    padding: 0 40px 40px;
  }
  @include below(small) {
    padding: 0 20px 40px;
  }
}

.subtitle {
  p {
    @include typo-headline;
    margin-top: 16px;

    color: $gray-700;
  }
  ul {
    margin: 16px 0;
    padding: 0;
  }
  li {
    @include typo-headline;
    padding: 5px 0 0 5px !important;

    color: $gray-700;

    list-style-type: none;
    &::before {
      display: none;
    }
  }
}
</style>
