<template>
  <section class="-mt-[64px] min-h-[500px] bg-orange-25 py-[46px] px-[80px] md:p-[24px] md:pt-[64px]">
    <Wrapper class="mt-[56px] flex h-full items-end justify-between gap-[40px] md:flex-col">
      <div class="flex min-h-[450px] flex-1 flex-col justify-center md:min-h-[auto] md:w-full">
        <Title class="mt-[16px] !font-[300] typo-mega-title sm:!font-[300] sm:typo-extra-large-title" :title-params="primary.title" />
        <RichText class="mt-[16px] !text-gray-700 typo-headline" :text-params="primary.subtitle" />
      </div>
      .
      <PImage class="w-1/2 flex-1 md:h-[400px] sm:h-[50vw] sm:w-[auto]" :image-object="primary.backgroundImage" sizes="sm:120vw" />
    </Wrapper>
  </section>
</template>

<script>
import PImage from '@/components/PImage'
export default {
  name: 'HeroConfirm',

  components: {
    PImage,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
