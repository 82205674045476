<template>
  <ul class="my-[8px] flex flex-col gap-[8px]">
    <li v-for="(item, idx) in items" :key="idx">
      <div class="flex items-center gap-[8px]">
        <PImage v-if="item.icon && item.icon.url" sizes="xs:32px" class="h-[28px] w-[28px] shrink-0" :image-object="item.icon" />
        <span
          v-else
          class="flex h-[28px] w-[28px] shrink-0 items-center justify-center rounded-[50%] bg-bluko-75 p-[10px] text-[20px] font-[500] text-bluko-500"
        >
          {{ idx + 1 }}
        </span>
        <RichText :text-params="item.description" class="font-[450] text-gray-600 [&_strong]:font-[500] [&_strong]:text-gray-800" />
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
