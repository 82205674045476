import defaultSEO from '@@/script/prismic/seoData.json'

const getGlobalVariables = (lang) => {
  const globalVariables = defaultSEO[lang]?.find((s) => s.slice_type === 'global_variables')?.primary || {}

  return Object.entries(globalVariables).map((entry) => ['\\$\\{' + entry[0] + '\\}', entry[1]])
}

export const useGlobalVariables = () => {
  return {
    getGlobalVariables,
    replaceVariables: (obj, lang) => {
      let str = JSON.stringify(obj)
      const globalVariables = getGlobalVariables(lang)

      globalVariables.forEach(([key, value]) => {
        str = str.replace(new RegExp(key, 'g'), value)
      })

      return JSON.parse(str)
    },
  }
}
