var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-[128px] lg:my-[64px]"},[_c('Wrapper',{staticClass:"flex gap-[4%] p-[24px] lg:!flex-col lg:gap-[60px]",class:( _obj = {}, _obj['flex-row-reverse'] = _vm.primary.reverse, _obj )},[_c('aside',{staticClass:"mx-auto flex max-w-[800px] flex-1 flex-col justify-center lg:items-center"},[(_vm.primary.title)?_c('h3',{staticClass:"mb-[24px] !font-[300] typo-extra-large-title lg:text-center sm:typo-large-title"},[_vm._v(_vm._s(_vm.primary.title))]):_vm._e(),_vm._v(" "),_c('RichText',{staticClass:"font-[450] lg:text-center [&>p]:mb-[8px] lg:[&>p]:mb-[24px]",attrs:{"text-params":_vm.primary.description}}),_vm._v(" "),_c('ul',{staticClass:"my-[16px] flex gap-[8px] lg:justify-center sm:gap-[4px]"},[_c('li',{class:_vm.$style.scoreDemoItem},[_c('ScoreLow'),_vm._v(" "),_c('span',{staticClass:"typo-caption-bold"},[_vm._v(_vm._s(_vm.primary.lowScoreText))])],1),_vm._v(" "),_c('li',{class:_vm.$style.scoreDemoItem},[_c('ScoreMedium'),_vm._v(" "),_c('span',{staticClass:"typo-caption-bold"},[_vm._v(_vm._s(_vm.primary.mediumScoreText))])],1),_vm._v(" "),_c('li',{class:_vm.$style.scoreDemoItem},[_c('ScoreHigh'),_vm._v(" "),_c('span',{staticClass:"typo-caption-bold"},[_vm._v(" "+_vm._s(_vm.primary.highScoreText))])],1)]),_vm._v(" "),_c('RichText',{staticClass:"font-[450] lg:text-center [&>p]:mb-[8px] lg:[&>p]:mb-[24px]",attrs:{"text-params":_vm.primary.subDescription}}),_vm._v(" "),_c('div',{staticClass:"mt-[8px] lg:text-center"},[_c('ArrowButton',{attrs:{"is-outlined":"","link-object":_vm.primary.ctaLink,"link-text":_vm.primary.ctaText}})],1),_vm._v(" "),(_vm.primary.info)?_c('InfoTooltip',{staticClass:"mt-[40px]",attrs:{"tooltip-text-params":_vm.primary.infoText}},[_vm._v("\n        "+_vm._s(_vm.primary.info)+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('figure',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: _vm.isVisible,
        once: true,
        intersection: {
          rootMargin: '-30% 0px -30% 0px',
        },
      }),expression:"{\n        callback: isVisible,\n        once: true,\n        intersection: {\n          rootMargin: '-30% 0px -30% 0px',\n        },\n      }"}],staticClass:"flex flex-1 flex-col items-center justify-center"},[_c('div',{staticClass:"flex w-[320px] flex-col justify-center gap-[24px] rounded-[8px] bg-bluko-50 py-[32px] px-[24px] text-center"},[_c('p',{staticClass:"font-[450] uppercase text-bluko-800"},[_vm._v(_vm._s(_vm.primary.cardTitle))]),_vm._v(" "),_c('span',{staticClass:"text-[120px] font-[300] leading-[100px]",attrs:{"text":_vm.primary.cardScore,"launch":_vm.visible,"sec":2}},[_vm._v("\n          "+_vm._s(parseInt(_vm.score))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"flex items-center gap-[8px] rounded-[24px] bg-white py-[8px] px-[16px]"},[_c('TriangleIcon',{class:( _obj$1 = {}, _obj$1[_vm.$style.cardScoreIcon] = true, _obj$1[_vm.$style.isAugmented] = _vm.primary.isScoreAugmented, _obj$1[_vm.$style.isDecreased] = !_vm.primary.isScoreAugmented, _obj$1 )}),_vm._v(" "),_c('RichText',{staticClass:"[&>p>strong]:font-500 text-[14px] leading-[20px]",attrs:{"text-params":_vm.primary.scoreResult}})],1)]),_vm._v(" "),_c('figcaption',{staticClass:"mt-[48px] text-center text-[8px] text-gray-300"},[_vm._v(_vm._s(_vm.primary.source))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }