<template>
  <ul class="my-[20px] flex flex-col gap-[20px]">
    <li v-for="(item, idx) in items" :key="idx">
      <div class="mb-[12px] flex items-center gap-[16px]">
        <PImage v-if="item.icon && item.icon.url" :image-object="item.icon" class="h-[32px] w-[32px] shrink-0" />
        <span
          v-else
          class="flex h-[32px] w-[32px] shrink-0 items-center justify-center rounded-[50%] bg-bluko-75 p-[10px] text-[20px] font-[500] text-bluko-500"
        >
          {{ idx + 1 }}
        </span>
        <Title :title-params="item.title" class="text-[24px] font-[450] text-gray-1000" />
      </div>
      <RichText :text-params="item.description" class="font-[450] text-gray-600" />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
