var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({ callback: _vm.isVisible, once: true }),expression:"{ callback: isVisible, once: true }"}],staticClass:"my-[128px] lg:my-[64px]"},[_c('Wrapper',{staticClass:"flex gap-[4%] p-[24px] lg:!flex-col lg:gap-[60px]",class:( _obj = {}, _obj['flex-row-reverse'] = _vm.primary.reverse, _obj )},[_c('aside',{staticClass:"mx-auto flex max-w-[800px] flex-1 flex-col justify-center lg:items-center"},[(_vm.primary.title)?_c('Counter',{staticClass:"mb-[24px] !font-[300] typo-extra-large-title lg:text-center sm:typo-large-title",attrs:{"text":_vm.primary.title,"launch":_vm.visible,"sec":2}}):_vm._e(),_vm._v(" "),_c('RichText',{staticClass:"font-[450] lg:text-center [&>p]:mb-[8px] lg:[&>p]:mb-[24px]",attrs:{"text-params":_vm.primary.description}}),_vm._v(" "),(_vm.primary.info)?_c('InfoTooltip',{staticClass:"mt-[24px]",attrs:{"tooltip-text-params":_vm.primary.infoText}},[_vm._v("\n        "+_vm._s(_vm.primary.info)+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('figure',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: _vm.isVisibleGraph,
        once: true,
        intersection: {
          rootMargin: '-30% 0px -30% 0px',
        },
      }),expression:"{\n        callback: isVisibleGraph,\n        once: true,\n        intersection: {\n          rootMargin: '-30% 0px -30% 0px',\n        },\n      }"}],staticClass:"flex flex-1 flex-col items-center justify-center"},[_c('svg',{staticClass:"h-full w-full max-w-[700px]",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":_vm.graph.width,"height":_vm.graph.height,"viewBox":("0 0 " + (_vm.graph.width) + " " + (_vm.graph.height)),"fill":"none"}},[_vm._l((_vm.coordinates),function(c,idx){return _c('text',{key:'x-' + idx,staticClass:"text-[12px]",attrs:{"x":c.x,"y":_vm.graph.height - 10,"fill":"black","text-anchor":"middle"}},[_vm._v("\n          "+_vm._s(_vm.xAxis[idx])+"\n        ")])}),_vm._v(" "),_vm._l((_vm.coordinates),function(c,idx){return _c('path',{key:'xv-' + idx,attrs:{"d":("M" + (c.x) + " " + (_vm.graph.paddingHeight) + " V" + (_vm.graph.height - _vm.graph.paddingHeight)),"stroke":"#2C23024D","stroke-width":"2","stroke-dasharray":"2, 8"}})}),_vm._v(" "),_c('path',{class:( _obj$1 = {}, _obj$1[_vm.$style.drawPath] = _vm.visibleGraph, _obj$1 ),attrs:{"d":_vm.smoothD(_vm.pathCoordinates),"stroke-width":"4","stroke":"#2D50E6","stroke-linecap":"round","stroke-dasharray":"1000","stroke-dashoffset":"1000"}}),_vm._v(" "),_vm._l((_vm.coordinates),function(c,idx){
      var _obj;
return _c('g',{key:'circle-' + idx,staticClass:"scale-0 opacity-0 transition-transform delay-[2s] duration-1000",class:( _obj = {}, _obj[_vm.$style.circleAnimation] = _vm.visibleGraph, _obj )},[_c('circle',{attrs:{"cx":c.x,"cy":c.y,"r":"16","fill":"white","filter":"drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.15))"}}),_vm._v(" "),_c('text',{staticClass:"translate-y-[4px] text-[12px] font-[600]",attrs:{"x":c.x,"y":c.y,"text-anchor":"middle","fill":"#122182"}},[_vm._v("\n            "+_vm._s(c.text)+"\n          ")])])})],2),_vm._v(" "),(_vm.primary.graphDescription)?_c('span',{staticClass:"mt-[30px] rounded-[36px] border-[1px] border-solid border-bluko-500 py-[6px] px-[18px] text-center text-bluko-500 sm:text-[2vw]"},[_vm._v("\n        "+_vm._s(_vm.primary.graphDescription)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.primary.source)?_c('figcaption',{staticClass:"mt-[48px] text-center text-[8px] text-gray-300"},[_vm._v(_vm._s(_vm.primary.source))]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }