<template>
  <Wrapper class="my-[128px] px-[24px]">
    <Title :title-params="primary.title" class="mx-auto max-w-[800px] text-center !font-[300] typo-mega-title sm:typo-large-title" />
    <TimelineDesktop :items="items" class="md:hidden" />
    <TimelineMobile :items="items" class="up-md:hidden" />
  </Wrapper>
</template>

<script>
import TimelineDesktop from './components/TimelineDesktop.vue'
import TimelineMobile from './components/TimelineMobile.vue'

export default {
  components: {
    TimelineDesktop,
    TimelineMobile,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      const res = []
      const _items = [...this.slice.items].map((item, idx) => ({ ...item, highBar: idx % 2 === 1 }))
      let year = null

      while (_items.length) {
        const itemYear = new Date(_items[0].date).getFullYear()
        res.push({ bridge: true, year: !year || year !== itemYear ? itemYear : null })
        res.push(_items.shift())
        year = itemYear
      }
      res.push({ bridge: true })

      return res
    },
  },
}
</script>
