<template>
  <div class="my-[128px] sm:my-[64px]">
    <Title
      v-if="primary.title"
      :title-params="primary.title"
      class="mx-auto mb-[80px] max-w-[800px] px-[24px] text-center !font-[300] typo-mega-title md:typo-extra-large-title"
    />
    <Wrapper class="flex max-w-[1440px] flex-col">
      <div
        v-for="(item, itemIndex) in items"
        :id="blockId(item.title[0].text)"
        :key="itemIndex"
        class="flex items-center justify-center"
        :class="{ [$style.reverseContent]: primary.is_reverse || isReverse, [$style.content]: true }"
        data-aos="fade-up"
      >
        <figure class="w-1/2 max-w-[760px] md:w-full">
          <PImage :image-object="item.image" sizes="sm:90vw" :quality="80" class="max-h-[640px] object-cover md:w-full" />
        </figure>

        <div class="w-1/2 py-[24px] px-[64px] md:w-full md:max-w-[760px] md:p-[32px]">
          <PImage v-if="item.icon && item.icon.url" class="mb-[16px] h-[56px] w-[56px] sm:hidden" :image-object="item.icon" />
          <div :class="$style.titleContainer">
            <Title class="mb-[10px] inline !font-[300] typo-extra-large-title sm:typo-title [&>h3]:inline" :title-params="item.title" />
            &nbsp;
            <span
              v-if="item.tag"
              class="inline-block -translate-y-[4px] rounded-[4px] bg-gold-500 p-[8px] text-[14px] font-[500] uppercase text-white"
            >
              {{ item.tag }}
            </span>
          </div>

          <RichText :text-params="item.text" :class="$style.contentDescription" class="mt-[16px] typo-body" />
          <div v-if="item.trustpilot" class="mt-[10px] flex items-center">
            <RichText :text-params="item.trustpilot_text" class="text-black" />
            <img src="@/assets/icons/socials/trustpilot-stars.svg" alt="trustpilot icon" class="mx-[10x]" />
            <img src="@/assets/icons/socials/trustpilot-star.svg" alt="trustpilot icon" />
          </div>

          <CtaBox :item="item" class="mt-[40px]" />

          <div v-if="item.has_app_link" class="flex gap-[24px] sm:justify-center sm:gap-[16px]">
            <PLink :link-object="primary.cta_app_store">
              <PImage :image-object="primary.image_app_store" :class="$style.app_badge" sizes="xs:200px" />
            </PLink>
            <PLink :link-object="primary.cta_google_play">
              <PImage :image-object="primary.image_google_play" :class="$style.app_badge" sizes="xs:200px" />
            </PLink>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import PImage from '@/components/PImage'
import PLink from '@/components/PLink'

import CtaBox from './components/CtaBox.vue'

export default {
  name: 'QuinconceSlice',
  components: {
    PImage,
    PLink,
    CtaBox,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },

  methods: {
    blockId(title) {
      const formatedTitle = title
        .split(' ')
        .map((e) => e.split(''))
        .filter((e) => e.length > 1)
        .map((e, i, a) => e.join(''))
        .join('-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      return formatedTitle
    },
  },
}
</script>

<style lang="scss" module>
.content {
  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
  }

  @include below(medium) {
    flex-direction: column !important;
    &:first-child {
      margin-top: 0;
    }
    margin-top: 60px;
  }
}

.reverseContent {
  &:nth-child(2n + 1) {
    flex-direction: row !important;
  }
  &:nth-child(2n) {
    flex-direction: row-reverse;
  }
  @include below(medium) {
  }
}

.contentDescription {
  ul > li {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.app_badge {
  height: 48px;

  @media screen and (max-width: 374px) {
    width: 40vw;
    height: auto;
  }
}
</style>
