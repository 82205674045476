import defaultSEO from '@@/script/prismic/seoData.json'

import { useGlobalVariables } from './useGlobalVariables'

const { replaceVariables } = useGlobalVariables()

export const useMetaData = () => {
  return {
    getMetaData(metaData, heroData, path, hreflangs = [], lang) {
      if (!metaData) metaData = {}
      if (!heroData) heroData = {}

      const defaultMetaData = replaceVariables(defaultSEO[lang].find((s) => s.slice_type === 'meta_data').primary, lang)

      const title = metaData.title || heroData.title || defaultMetaData.title
      const description = metaData.description || heroData.description || defaultMetaData.description
      const ogTitle = metaData.ogTitle || title || defaultMetaData.ogTitle
      const ogDescription = metaData.ogDescription || description || defaultMetaData.description
      const ogImage = metaData.ogImage?.url || heroData.ogImage?.url || defaultMetaData.ogImage?.url
      const ogUrl = `${process.env.URL}${path}/`.replace(/\/\/$/, '/')

      return {
        title,
        meta: [
          {
            name: 'robots',
            content: process.env.IS_DEV === 'true' || metaData.noIndex ? 'noindex' : 'index',
          },
          {
            name: 'description',
            hid: 'description',
            content: description,
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: ogTitle,
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: ogImage,
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: ogDescription,
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: ogUrl,
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: metaData.ogType || 'website',
          },
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            hid: 'twitter:site',
            name: 'twitter:site',
            content: '@getluko',
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: ogTitle,
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: ogDescription,
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: ogImage,
          },
          {
            hid: 'twitter:url',
            name: 'twitter:url',
            content: ogUrl,
          },
        ],
        link: [...hreflangs],
      }
    },
  }
}
