<template>
  <div class="-mt-[64px] bg-orange-25 px-[50px] pb-[32px] md:p-[24px] md:pt-[64px]">
    <Wrapper class="flex h-full items-center justify-between gap-[5%] pt-[128px] md:flex-col md:pt-[24px]">
      <div class="max-w-[550px] flex-1 md:w-full md:min-w-[unset] md:max-w-none">
        <Breadcrumb v-if="primary.showBreadcrumb" class="mb-[16px]" :custom-name="primary.title[0].text" />
        <div>
          <Title
            class="mr-[8px] inline-block !font-[300] typo-mega-title hyphens lg:max-w-[600px] sm:w-[90vw] sm:!font-[300] sm:typo-extra-large-title"
            :title-params="primary.title"
          />
          <span
            v-if="primary.tag"
            class="mt-[10px] inline-block -translate-y-[10px] rounded-[30px] bg-gold-500 p-[8px] px-[16px] typo-body sm:hidden"
          >
            {{ primary.tag }}
          </span>
        </div>
        <p v-if="primary.price" class="!font-[300] opacity-60 typo-large-title">{{ primary.price }}</p>
        <div class="flex flex-col justify-between">
          <RichText class="mt-[16px] list-none !text-gray-700 typo-headline [&>p]:pb-[16px]" :text-params="primary.subtitle" />
          <div v-if="items.length">
            <div v-for="(item, index) in items" :key="index" class="flex items-center gap-[10px] p-[8px]">
              <PImage class="h-[36px] w-[36px]" :image-object="item.icon" />
              <RichText class="list-none !text-gray-700 typo-headline" :text-params="item.text" />
            </div>
          </div>
          <div class="mt-[24px]">
            <HeroCta :data="primary" is-product-page />
            <div v-if="primary.show_review_widget && (TpScore >= 4.3 || GoogleScore >= 4.3)" class="mt-[24px] flex items-center gap-[24px]">
              <TrustpilotWidget class="inline-flex" />
              <GoogleReviewWidget class="inline-flex path-gray-200" />
            </div>
            <PLink v-if="primary.bottom_link_text" :link-object="primary.bottom_link" class="mt-[20px] text-gray-700 underline hover:text-gray-800">
              {{ primary.bottom_link_text }}
            </PLink>
          </div>
        </div>
        <div v-if="primary.trustBadge && primary.trustBadge.url">
          <PImage class="mt-[32px] h-[80px]" :image-object="primary.trustBadge" />
        </div>
      </div>
      <div class="flex h-full max-w-[500px] flex-1 md:w-full md:max-w-none">
        <PImage
          class="
            aspect-[500/438]
            w-full
            rounded-tl-[50%] rounded-tr-[50%]
            object-cover
            md:mt-[32px] md:aspect-auto md:h-[256px] md:w-[calc(100vw-24px)] md:max-w-none md:rounded-bl-[150px] md:rounded-tr-[0] md:rounded-tl-[0]
          "
          :image-object="primary.backgroundImage"
          sizes="sm:100vw"
        />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import TpScore from '@@/script/trustpilot/score.json'
import GoogleScore from '@@/script/googleReview/score.json'

import Breadcrumb from '@/components/Breadcrumb'
import TrustpilotWidget from '@/components/TrustpilotWidget'
import GoogleReviewWidget from '@/components/GoogleReviewWidget'
import HeroCta from '@/components/HeroCta'

export default {
  name: 'HeroProduct2021Q4',

  components: {
    HeroCta,
    Breadcrumb,
    TrustpilotWidget,
    GoogleReviewWidget,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      TpScore: TpScore.trustScore,
      GoogleScore: GoogleScore.stars,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
