<template>
  <div class="relative max-h-full w-[3px] bg-gray-150">
    <div
      ref="timer"
      :style="{ animationDuration: `${sec}s`, animationDelay: `${delay}s` }"
      class="absolute bottom-0 left-0 w-[3px] bg-bluko-500 transition-all"
      :class="[{ ['h-full']: prefill }, { [$style.counting]: isActive }]"
    />
  </div>
</template>
<script>
export default {
  name: 'LinearProgressBar',

  props: {
    callback: {
      type: Function,
      default: () => () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    sec: {
      type: Number,
      default: 4,
    },
    delay: {
      type: Number,
      default: 0,
    },
    prefill: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$refs.timer.addEventListener('animationend', () => {
      this.callback()
    })
  },
}
</script>
<style lang="scss" module>
.counting {
  animation: tictoc linear forwards;
}

@keyframes tictoc {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
</style>
