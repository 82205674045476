<template>
  <Title
    :id="titleId"
    class="mb-[20px] pt-[128px] text-gray-1000 typo-large-title hyphens"
    :title-params="primary.text"
    data-luko-tracking="SecondTitle"
  />
</template>

<script>
import { throttle } from 'lodash-es'
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      scrollCallback: null,
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    titleId() {
      const formatedTitle = this.primary.text[0].text
        .split(' ')
        .map((e) => e.split(''))
        .filter((e) => e.length > 1)
        .map((e, i, a) => e.join(''))
        .join('-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      return formatedTitle
    },
  },

  mounted() {
    this.scrollCallback = throttle(this.storeScroll, 100)

    document.addEventListener('scroll', this.scrollCallback, {
      passive: true,
    })

    this.storeScroll()
  },

  methods: {
    storeScroll() {
      const element = document.getElementById(this.titleId)
      if (element) {
        const offset = window.scrollY - element.offsetTop + 200

        if (offset > 0) this.$emit('title-active', this.primary.text[0].text)
        else this.$emit('title-inactive', this.primary.text[0].text)
      }
    },
  },
}
</script>
