<template>
  <SliceWrapper :slice="slice">
    <LogoListOneImage v-if="slice.variation === 'oneImage'" :slice="slice" />
    <LogoListDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import LogoListOneImage from './oneImage/LogoListOneImage.vue'
import LogoListDefault from './default-slice/LogoListDefault.vue'
export default {
  components: {
    LogoListOneImage,
    LogoListDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
