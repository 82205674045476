<template>
  <div class="flex bg-bluko-800 sm:!flex-col" :class="{ 'flex-row-reverse': primary.imagePosition }">
    <PImage lazy :image-object="primary.image" class="w-1/2 object-cover sm:w-full" sizes="sm:100vw" />
    <div class="flex flex-col justify-center p-0 px-[64px] lg:py-[80px] lg:px-[32px] sm:px-[30px] sm:py-[24px]">
      <Title v-if="primary.title" :title-params="primary.title" class="!font-[300] !text-white typo-mega-title sm:!text-[36px] sm:!leading-[38px]" />
      <RichText :text-params="primary.description" class="text-white [&>p]:mt-[16px]" />
      <div class="mt-[16px]">
        <div v-for="(item, index) in items" :key="index" class="mt-[16px] flex gap-[14px] text-gray-700">
          <PImage :image-object="item.icon" class="h-[24px] w-[24px]" />
          <RichText :text-params="item.text" class="text-white" />
        </div>
      </div>
      <CtaBox :primary="primary" class="mt-[64px]" is-white is-outlined />
    </div>
  </div>
</template>

<script>
import CtaBox from '../components/CtaBox'

export default {
  name: 'CustomerService2021Q4',

  components: {
    CtaBox,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
