<template>
  <components
    :is="link ? 'a' : 'div'"
    class="relative block overflow-hidden rounded-[8px]"
    :href="link"
    :rel="link ? 'noreferrer nofollow' : undefined"
    target="_blank"
  >
    <PImage :image-object="item.image" lazy sizes="xs:300px" class="h-full w-full object-cover" />
    <span class="absolute bottom-2 right-2 rounded-[24px] bg-[#F5FAFF] px-[8px] py-[2px] text-[12px] text-bluko-800">{{ item.name }}</span>
  </components>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      return this.item.link?.url
    },
  },
}
</script>
