<template>
  <form class="flex gap-[16px] sm:flex-col" @submit.prevent="onClickPetFlow">
    <LkInput v-model="petName" :placeholder="placeholder" />
    <LkButton class="shrink-0 sm:!w-full" :is-light="isLight" :is-white="isWhite" :is-outlined="isOutlined" data-luko-tracking="Onboarding">
      {{ text }}
    </LkButton>
  </form>
</template>
<script>
import LkInput from '@/components/Lukomponents/LkInput'
import { getOnboardingLink } from '@/mixins/getOnboardingLink'
import { getNavCtaLinkByProductUid } from '@/utils/navbarCta'
import { useUrl } from '@/composables/useUrl'

const { appendQuery, forwardUrl } = useUrl()

export default {
  components: {
    LkInput,
  },
  mixins: [getOnboardingLink],

  props: {
    type: {
      type: String,
      default: 'None',
    },
    text: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      petName: null,
    }
  },

  methods: {
    onClickPetFlow() {
      const onboardingLink = getNavCtaLinkByProductUid(this.$route.params.slug, this.$i18n.locale)
      window.location.href = forwardUrl(appendQuery(onboardingLink.url, { '$.pet.main_asset.name': this.petName }))
    },
  },
}
</script>
