<template>
  <div class="-mt-[64px] min-h-[615px] bg-orange-25 px-[50px] pt-[64px] pb-[46px] md:p-[24px]">
    <Wrapper class="flex h-full items-end justify-between gap-[40px] pt-[56px] md:flex-col">
      <div class="flex min-h-[450px] flex-1 flex-col justify-evenly md:min-h-[auto] md:w-full">
        <Breadcrumb v-if="primary.showBreadcrumb" class="mb-[16px]" :custom-name="$route.path.match()[0]" />
        <div>
          <Title
            class="mr-[8px] inline-block !font-[300] typo-mega-title hyphens lg:max-w-[600px] sm:w-[90vw] sm:!font-[300] sm:typo-extra-large-title"
            :title-params="primary.title"
          />
          <span
            v-if="primary.tag"
            class="mt-[10px] inline-block -translate-y-[10px] rounded-[30px] bg-gold-500 p-[8px] px-[16px] typo-body sm:hidden"
          >
            {{ primary.tag }}
          </span>
        </div>
        <RichText class="mt-[16px] !text-gray-700 typo-headline [&>p]:pb-[16px]" :text-params="primary.subtitle" />
        <HeroCta class="mt-[40px]" :data="primary" />
        <ul v-if="items.length" class="mt-[40px] grid grid-cols-1 gap-[16px]">
          <li v-for="(link, linkIndex) in items" :key="linkIndex">
            <ArrowLink :link-object="link.link" class="text-bluko-500 path-bluko-500" :link-text="link.text" />
          </li>
        </ul>
      </div>

      <div class="flex-1">
        <PImage class="w-full md:h-[400px] sm:h-[50vw]" :image-object="primary.backgroundImage" sizes="sm:120vw" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import ArrowLink from '@/components/ArrowLink'
import HeroCta from '@/components/HeroCta'

export default {
  name: 'HeroTestimonials2021Q4',

  components: {
    HeroCta,
    Breadcrumb,
    ArrowLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => item.text && item.link && item.link.url)
    },
    breadcrumbName() {
      const matched = this.$route.path.match(/\/\w*\/$/)
      return matched ? matched[0].replace(/\//g, '') : undefined
    },
  },
}
</script>
