<template>
  <SliceWrapper
    v-observe-visibility="{
      callback: onVisible,
    }"
    :slice="slice"
    :class="{ ['bg-bluko-800']: isDarkblue }"
  >
    <Wrapper class="px-[24px]" :class="{ ['py-[160px]']: !isDarkblue, ['py-[80px]']: isDarkblue }">
      <Title
        :title-params="primary.title"
        class="mx-auto max-w-[840px] text-center !font-[300] typo-mega-title xlg:typo-extra-large-title"
        :class="{ ['text-white']: isDarkblue }"
      />
      <RichText
        :text-params="primary.description"
        class="mx-auto mt-[8px] max-w-[840px] text-center"
        :class="{ ['text-black text-opacity-60']: !isDarkblue, ['text-bluko-200']: isDarkblue }"
      />

      <div class="mt-[64px] flex gap-[56px] md:mt-[40px] md:flex-col">
        <div class="w-[60%] xlg:w-full">
          <div class="flex items-center gap-[32px] xlg:flex-col">
            <ul class="flex flex-col items-center gap-[16px] xlg:hidden">
              <li
                v-for="(_, idx) in items"
                :key="idx"
                :class="{
                  ['!h-[10px] !w-[10px]']: idx === activeIndex,
                  ['!bg-white']: idx === activeIndex && isDarkblue,
                  ['!bg-bluko-500']: idx === activeIndex && !isDarkblue,
                  ['bg-bluko-300']: isDarkblue,
                  ['bg-bluko-75']: !isDarkblue,
                }"
                class="h-[8px] w-[8px] cursor-pointer rounded-[50%]"
                @click="toNextVideo(idx)"
              ></li>
            </ul>

            <div class="w-full">
              <VueSlickCarousel v-bind="slickOptions" ref="carousel" class="w-full" @beforeChange="onBeforeChange">
                <div v-for="(item, idx) in items" :key="'video-' + idx" class="h-[405px] overflow-hidden rounded-[4px] xlg:h-auto">
                  <figure class="relative h-full xlg:mx-auto xlg:max-w-[680px]">
                    <video
                      :autoplay="idx === 0"
                      preload="none"
                      muted
                      class="relative mx-auto h-full w-full rounded-[4px] object-fill xlg:h-auto xlg:object-contain"
                      playsinline
                      :src="item.video.url"
                    >
                      <source type="video/mp4" />
                    </video>
                    <figcaption>
                      <progress
                        class="absolute bottom-0 left-0 z-10 h-[4px] w-full xlg:h-[2px]"
                        :class="$style.progress"
                        :value="progressions[idx]"
                        max="100"
                      />
                    </figcaption>
                  </figure>

                  <div class="mx-auto mt-[64px] max-w-[680px] text-center sm:mt-[16px] up-xlg:hidden">
                    <Title
                      :title-params="item.title"
                      class="typo-large-title sm:text-[20px] sm:leading-[28px]"
                      :class="{ ['text-bluko-500']: !isDarkblue, ['text-white']: isDarkblue }"
                    />
                    <RichText :text-params="item.description" class="mt-[4px] typo-sub-text" :class="{ ['text-white']: isDarkblue }" />
                  </div>
                </div>
              </VueSlickCarousel>
            </div>

            <div class="w-full up-xlg:hidden">
              <ul class="mt-[25px] flex items-center justify-center gap-[16px] md:gap-[8px] up-xlg:hidden">
                <li
                  v-for="(_, idx) in items"
                  :key="idx"
                  :class="{
                    ['!h-[10px] !w-[10px]']: idx === activeIndex,
                    ['!bg-bluko-500']: idx === activeIndex && !isDarkblue,
                    ['!bg-white']: idx === activeIndex && isDarkblue,
                    ['bg-bluko-75']: !isDarkblue,
                    ['bg-bluko-300']: isDarkblue,
                  }"
                  class="h-[8px] w-[8px] cursor-pointer rounded-[50%]"
                  @click="toNextVideo(idx)"
                ></li>
              </ul>
            </div>
          </div>
        </div>

        <ul class="flex w-[40%] flex-col justify-center gap-[24px] xlg:hidden">
          <li v-for="(item, idx) in items" :key="idx" class="cursor-pointer" @click="toNextVideo(idx)">
            <Title
              :title-params="item.title"
              class="transition-all duration-300 typo-headline"
              :class="{
                ['!text-[24px] !leading-[32px]']: idx === activeIndex,
                ['!text-bluko-500 !text-opacity-100']: !isDarkblue && idx === activeIndex,
                ['!text-white']: isDarkblue && idx === activeIndex,
                ['text-black text-opacity-40']: !isDarkblue,
                ['text-bluko-300']: isDarkblue,
              }"
            />
            <Transition name="pop">
              <RichText v-if="idx === activeIndex" :text-params="item.description" class="mt-[4px]" :class="{ ['text-white']: isDarkblue }" />
            </Transition>
          </li>
        </ul>
      </div>

      <div class="mt-[64px] text-center md:mt-[40px]">
        <LkButton :link-object="primary.cta_link" :is-white="isDarkblue">{{ primary.cta_text }}</LkButton>
      </div>
    </Wrapper>
  </SliceWrapper>
</template>
<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import VueSlickCarousel from 'vue-slick-carousel'
import throttle from 'lodash-es/throttle'

export default {
  components: {
    VueSlickCarousel,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      activeIndex: 0,
      animationId: null,
      screen: null,
      playPromise: null,
      progressions: {},
      isDarkblue: this.slice.variation === 'darkblue',
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    videos() {
      if (this.screen) return this.$refs.carousel.$el.querySelectorAll('video')
      return this.$refs.carousel.$el.querySelectorAll('video')
    },
    slickOptions() {
      const mobile = {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        adaptiveHeight: true,
      }
      const desktop = {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        centerPadding: '0px',
      }
      if (this.screen === 'small') return mobile
      return desktop
    },
  },
  mounted() {
    if (window.innerWidth >= 1024) {
      this.screen = 'big'
    } else {
      this.screen = 'small'
    }

    window.addEventListener('resize', this.throttleResize)
  },
  unmounted() {
    cancelAnimationFrame(this.animationId)
    this.throttleResize.cancel()
    window.removeEventListener('resize', this.throttleResize)
  },
  methods: {
    throttleResize: throttle(function () {
      if (window.innerWidth >= 1024) {
        this.screen = 'big'
      } else {
        this.screen = 'small'
      }
    }, 200),
    render() {
      this.setVideoProgress(this.activeIndex)

      if (this.progressions[this.activeIndex] >= 100) {
        this.progressions = {
          ...this.progressions,
          [this.activeIndex]: 100,
        }

        this.toNextVideo()
      }

      this.animationId = requestAnimationFrame(this.render)
    },
    onVisible(visible) {
      this.visible = visible
      if (visible) {
        this.onBeforeChange(null, this.activeIndex)
        this.animationId = requestAnimationFrame(this.render)
      } else {
        this.playPromise?.then(() => this.videos[this.activeIndex + 1]?.pause())
        cancelAnimationFrame(this.animationId)
      }
    },
    setActiveIndex(idx) {
      const nextIdx = idx % this.items.length
      this.activeIndex = nextIdx
    },
    setVideoProgress(index) {
      const dataProg = { ...this.progressions }
      const video = this.videos[index + 1]

      if (!video) return

      dataProg[index] = Math.round((video.currentTime * 100) / video.duration)
      this.progressions = dataProg
    },
    toNextVideo(idxToGo) {
      const nextIndex = idxToGo === undefined ? (this.activeIndex + 1) % this.items.length : idxToGo

      this.$refs.carousel.goTo(nextIndex)
    },
    onBeforeChange(idx, newIdx) {
      this.setActiveIndex(newIdx)
      const video = this.videos[(newIdx % this.items.length) + 1]
      const nextVideo = this.videos[((newIdx + 1) % this.items.length) + 1]

      this.prepareVideo(video, this.items[newIdx].video.url)
      this.playVideo(video, nextVideo, newIdx)
    },
    prepareVideo(video, url) {
      if (!video) return

      const source = video.querySelector('source')
      if (source && !source.src) {
        source.src = url
        video.load()
      }
      video.currentTime = 0
    },
    playVideo(video, nextVideo, curIndex) {
      this.playPromise = video
        .play()
        .then((_) => {
          return video
        })
        .catch((e) => console.log(e))
      this.prepareVideo(nextVideo, this.items[(curIndex + 1) % this.items.length]?.video.url)
    },
  },
}
</script>
<style lang="scss" module>
.progress[value] {
  @apply rounded bg-[#000640] bg-opacity-10 transition-all duration-[450] ease-linear;
  border: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-progress-bar {
    @apply rounded bg-[#000640] bg-opacity-10;
  }

  &::-moz-progress-bar {
    @apply rounded bg-bluko-500 transition-all duration-[400] ease-linear;
  }

  &::-webkit-progress-value {
    @apply rounded bg-bluko-500 transition-all duration-[400] ease-linear;
  }
}
</style>
<style scoped>
/* slide right */
.pop-enter {
  transform: translateY(4px);

  opacity: 0;
}
.pop-enter-to {
  transform: initial;

  opacity: 1;
}
.pop-leave {
  display: none;
}
.pop-leave-to {
  display: none;
}
.pop-enter-active {
  transition: all 0.3s ease-in-out;
}
.pop-leave-active {
  display: none;
}
</style>
