<template>
  <SliceWrapper :slice="slice">
    <MediaCarouselPhone v-if="slice.variation === 'phone'" :slice="slice" />
    <MediaCarouselDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import MediaCarouselDefault from './default-slice/MediaCarouselDefault.vue'
import MediaCarouselPhone from './phone/MediaCarouselPhone.vue'

export default {
  name: 'MediaCarousel',
  components: {
    MediaCarouselDefault,
    MediaCarouselPhone,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
