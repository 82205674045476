<template>
  <div class="p-0 pt-[128px] pb-[24px]">
    <Wrapper class="text-center">
      <Title :title-params="primary.title" class="mb-[8px] !font-[300] typo-mega-title sm:typo-extra-large-title" />
      <RichText :text-params="primary.description" :class="$style.description" />
      <div class="mt-[60px] flex items-center justify-center lg:flex-col">
        <template v-for="(item, itemIndex) of items">
          <div
            :key="itemIndex"
            class="
              align-center
              m-[24px]
              flex
              min-h-[230px]
              max-w-[320px]
              flex-1
              shrink-0
              flex-col
              justify-center
              rounded-[8px]
              border-[1px] border-solid border-gray-100
              p-[24px]
              px-[32px]
            "
            data-aos="fade-up"
            :data-aos-delay="itemIndex * 150"
            data-aos-duration="800"
          >
            <PImage :image-object="item.icon" class="mb-[16px] h-[48px] w-[48x]" />
            <Title :title-params="item.title" class="m-0 typo-headline-bold" />
            <RichText :text-params="item.description" :class="$style.description" />
            <div v-if="item.ctaLink && item.ctaLink.url && item.ctaText" class="mt-[24px] flex justify-center">
              <ArrowLink
                :link-object="item.ctaLink"
                class="text-bluko-500 path-bluko-500"
                :targeted-section="item.targetedSection"
                :link-text="item.ctaText"
              />
            </div>
          </div>
          <ArrowRight
            v-if="!primary.hideArrow && itemIndex !== items.length - 1"
            :key="'arrow' + itemIndex"
            name="arrow-right"
            class="m-[10px] path-gray-200 lg:rotate-90"
            width="20px"
            height="20px"
          />
        </template>
      </div>

      <PLink :link-object="primary.ctaLink" class="mt-[48px] inline-block no-underline lg:hidden">
        <LkButton v-if="primary.ctaText">{{ primary.ctaText }}</LkButton>
      </PLink>
    </Wrapper>
  </div>
</template>

<script>
import ArrowRight from 'LkIcons/ArrowRight.vue'

import ArrowLink from '@/components/ArrowLink'

export default {
  name: 'ProcessArrowBlock',

  components: {
    ArrowLink,
    ArrowRight,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style module lang="scss">
.description {
  p {
    @include typo-body;

    color: $gray-700;
  }
  h3 {
    @include typo-headline-bold;
    margin-bottom: 8px;
  }
}
</style>
