export const componentLogic = {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      load: false,
      hideArrow: true,
      minimumTextHeight: 0,
      slideIndex: 0,
      textHeights: new Array(this.slice.items.length).fill(0),
      areResponsiveSettingsApplied: false,
      currentSetting: {},
      responsiveSettings: [
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '38px',
            swipe: true,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            swipe: false,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            swipe: false,
            initialSlide: 0,
          },
        },
      ],
      slickOptions: {
        arrows: false,
        infinite: false,
        centerMode: false,
        speed: 500,
        autoplay: false,
        swipe: false,
        slidesToShow: this.slice.items.length,
      },
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    dummySlide() {
      return this.currentSetting.centerMode && this.currentSetting.slidesToShow !== 1 ? 1 : 0
    },
  },
  watch: {
    currentSetting() {
      this.minimumTextHeight = 0
      this.$nextTick(() => {
        const els = Array.from(this.$refs.carousel.$el.querySelectorAll('.itemTextContainer'))
        const maxHeight = Math.max(...els.map((el) => el.clientHeight)) + 2
        this.minimumTextHeight = maxHeight
      })
    },
  },
  methods: {
    hasUsp(item) {
      return item.usp1_icon?.url || item.usp2_icon?.url || item.usp3_icon?.url || item.usp4_icon?.url
    },
    onVisible(visible) {
      if (!this.load && visible) {
        this.load = true
      }
    },
    onInit() {
      if (this.areResponsiveSettingsApplied === false) {
        this.slickOptions.responsive = this.responsiveSettings
        this.areResponsiveSettingsApplied = true
      }
      this.setupArrow()
    },
    setupArrow() {
      if (process.client) {
        this.currentSetting = this.responsiveSettings.find((s) => s.breakpoint >= window.innerWidth)?.settings || this.slickOptions
        this.hideArrow = this.currentSetting.slidesToShow >= this.items.length
        this.slideIndex = this.currentSetting.initialSlide
      }
    },
    onBeforeChange(_, slideIndex) {
      this.slideIndex = slideIndex
    },
    goPrev() {
      this.$refs.carousel.prev()
    },
    goNext() {
      this.$refs.carousel.next()
    },
  },
}
