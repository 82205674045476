<template>
  <div :class="$style.container">
    <Wrapper :class="$style.inner">
      <Title :title-params="primary.title" :class="$style.title" />
      <div>
        <RichText :text-params="primary.description" :class="$style.description" />
        <ArrowLink
          v-if="primary.ctaText"
          class="text-bluko-500 path-bluko-500"
          :link-object="primary.ctaLink"
          :class="$style.cta"
          :link-text="primary.ctaText"
        />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import ArrowLink from '@/components/ArrowLink'
export default {
  components: {
    ArrowLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 0 auto;
  padding: 130px 60px;
}

.inner {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 40px;
  @include below(small) {
    grid-template-columns: 1fr;
  }
}
.title {
  @include typo-title;
}
.description {
  color: $gray-700;
}
.cta {
  margin-top: 16px;
}
</style>
