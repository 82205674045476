<template>
  <SliceWrapper
    v-observe-visibility="{ callback: onVisible, once: true }"
    class="bg-bluko-800 py-[80px] px-[100px] sm:py-[40px] sm:px-[22px]"
    :slice="slice"
  >
    <Title :title-params="primary.title" class="m-0 mx-auto mb-[50px] max-w-[700px] text-center text-[40px] font-[300] leading-[46px] text-white" />
    <Wrapper class="flex flex-wrap justify-center gap-[40px]">
      <div v-for="(item, index) in items" :key="index" class="flex w-[400px] items-center justify-center gap-[20px] sm:flex-col">
        <PImage :image-object="imageLoad ? item.image : null" class="max-h-[100px] w-[100px] object-contain" sizes="xs:200px" lazy />
        <RichText :text-params="item.description" class="text-[14px] leading-[20px] text-white sm:text-center" />
      </div>
    </Wrapper>
    <Wrapper class="mt-[40px] text-center">
      <ArrowButton
        :link-object="primary.ctaLink"
        :target="primary.ctaLink.target"
        is-outlined
        is-white
        class="m-0 mx-auto sm:w-full"
        :link-text="primary.ctaText"
      />
    </Wrapper>
  </SliceWrapper>
</template>

<script>
import ArrowButton from '@/components/ArrowButton'

export default {
  name: 'CertificationSection',
  components: { ArrowButton },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageLoad: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    onVisible(visible) {
      if (visible) {
        this.imageLoad = true
      }
    },
  },
}
</script>
