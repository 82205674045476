<template>
  <Wrapper :class="$style.inner">
    <article class="self-center">
      <Title :title-params="primary.title" class="text-center typo-extra-large-title sm:typo-large-title" />
      <RichText :text-params="primary.description" class="mt-[56px] text-center !text-gray-700 typo-headline-bold" />
    </article>
    <aside>
      <ul class="flex flex-wrap gap-[20px] sm:flex-col sm:items-center">
        <li v-for="(item, idx) in items" :key="idx" :class="$style.item">
          <PLink class="flex w-full flex-col items-center rounded-[20px] bg-white py-[20px] px-[4px]" :link-object="item.ctaLink">
            <PImage :image-object="item.icon" class="h-[24px] w-[24px]" />
            <span class="mt-[10px] text-black typo-sub-text-bold">{{ item.cardText }}</span>
          </PLink>
          <RichText :text-params="item.description" />

          <div class="sm:text-center">
            <ArrowLink :link-object="item.ctaLink" class="text-bluko-500 path-bluko-500" :link-text="item.ctaText" />
          </div>
        </li>
      </ul>
    </aside>
  </Wrapper>
</template>
<script>
import ArrowLink from '@/components/ArrowLink'

export default {
  components: {
    ArrowLink,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
<style lang="scss" module>
.inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 74px auto;
  padding: 64px 54px;
  border-radius: 20px;

  column-gap: 4%;

  background-color: #fff4ee;
  @include below(large) {
    margin: 74px 24px;
  }
  @include below(medium) {
    grid-template-columns: 1fr;
    padding: 30px 80px;
    row-gap: 42px;
  }
  @include below(small) {
    padding: 42px 32px;
  }
}
.item {
  display: grid;
  flex: 1;
  grid-template-rows: 0.1fr auto auto;
  grid-template-columns: 1fr;
  justify-self: center;
  min-width: 174px;
  row-gap: 25px;

  @include below(small) {
    width: 80%;
    min-width: unset;
  }
}
</style>
