<template>
  <SliceWrapper :slice="slice">
    <PartnerAgency :slice="slice" />
  </SliceWrapper>
</template>

<script>
import PartnerAgency from './default/PartnerAgencyDefault.vue'

export default {
  components: {
    PartnerAgency,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
