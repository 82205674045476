<template>
  <SliceWrapper :slice="slice">
    <FullWidthImageDefault :slice="slice" />
  </SliceWrapper>
</template>

<script>
import FullWidthImageDefault from './default/'

export default {
  name: 'FullWidthImage',
  components: {
    FullWidthImageDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
