<template>
  <SliceWrapper :slice="slice" class="overflow-x-auto">
    <table :class="$style.table" class="min-w-[540px] max-w-[840px]">
      <tr v-for="(item, index) in slice.items" :key="index">
        <components :is="index === 0 ? 'th' : 'td'" :class="{ ['bg-gray-50']: index === 0 }">
          <RichText :text-params="item.col_1" />
        </components>
        <components :is="index === 0 ? 'th' : 'td'" :class="{ ['bg-gray-50']: index === 0 }">
          <RichText :text-params="item.col_2" />
        </components>
        <components :is="index === 0 ? 'th' : 'td'" :class="{ ['bg-gray-50']: index === 0 }">
          <RichText :text-params="item.col_3" />
        </components>
        <components :is="index === 0 ? 'th' : 'td'" :class="{ ['bg-gray-50']: index === 0 }">
          <RichText :text-params="item.col_4" />
        </components>
        <components :is="index === 0 ? 'th' : 'td'" :class="{ ['bg-gray-50']: index === 0 }">
          <RichText :text-params="item.col_5" />
        </components>
      </tr>
    </table>
  </SliceWrapper>
</template>

<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
.table {
  margin-top: 32px;
  border: 1px solid $gray-200;

  word-break: break-word;
  th,
  td {
    border: 1px solid $gray-200;
  }
  td {
    width: calc(100% / 5);
    padding: 5px;
  }
  h1 {
    margin: 64px 0 8px 0;

    color: $gray-1000;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
  }

  h2 {
    margin: 32px 0 8px 0;

    color: $gray-700;

    font-weight: normal;
    font-size: 28px;
    line-height: 40px;
  }

  h3 {
    margin: 24px 0 8px 0;

    color: $gray-1000;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
  }

  p {
    margin: 8px 0;

    color: $gray-1000;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: $gray-1000;
  }

  ul {
    display: block;
    margin-left: 40px;

    list-style: outside;

    list-style-type: disc;
    li {
      display: list-item;
      margin: 8px 0;

      color: $gray-1000;
      font-size: 16px;
      line-height: 24px;

      text-align: -webkit-match-parent;
    }
  }
}
</style>
