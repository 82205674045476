<template>
  <SliceWrapper v-observe-visibility="isVisible" :slice="slice">
    <HeroLaunchDarkblue v-if="slice.variation === 'darkblue'" :slice="slice" />
    <HeroLaunchSimple v-else-if="slice.variation === 'simple'" :slice="slice" />
    <HeroLaunchImageFull v-else-if="slice.variation === 'imageFull'" :slice="slice" />
    <HeroLaunchAB v-else-if="slice.variation === 'designA' || slice.variation === 'designB'" :slice="slice" />
    <HeroLaunchDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import HeroLaunchDefault from './default-slice'
import HeroLaunchDarkblue from './darkblue'
import HeroLaunchSimple from './simple'
import HeroLaunchImageFull from './imageFull'
import HeroLaunchAB from './designA'

export default {
  name: 'HeroLaunch',
  components: {
    HeroLaunchDefault,
    HeroLaunchDarkblue,
    HeroLaunchSimple,
    HeroLaunchImageFull,
    HeroLaunchAB,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>
