<template>
  <div class="my-[80px] lg:mx-[10vw] sm:mx-0">
    <Wrapper
      v-observe-visibility="{
        callback: sectionOnScreen,
        intersection: {
          threshold: 1,
        },
      }"
      class="flex max-w-[1440px] items-stretch justify-center bg-orange-25 lg:flex-col-reverse"
    >
      <div class="flex w-1/2 items-center py-[80px] px-[60px] lg:w-full sm:py-[32px] sm:px-[24px]">
        <Title class="mb-[10px] !font-[300] typo-extra-large-title sm:text-center sm:typo-large-title" :title-params="primary.title" />
        <RichText class="mx-auto text-gray-700 typo-body" :text-params="primary.description" />
        <div class="grid grid-cols-1 gap-[40px]">
          <div
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            :class="[$style.item, { [$style.itemActive]: isActive(itemIndex) }]"
            @click="setActiveIndex(itemIndex)"
            @mouseenter="onMouseEnter(itemIndex)"
            @mouseleave="onMouseLeave(itemIndex)"
          >
            <div class="mr-[24px] flex items-center justify-center rounded-[48px] sm:mr-[12px] sm:rounded-[24px]">
              <RadialProgressBar
                :class="$style.circleProgress"
                :completed-steps="getProgress(itemIndex)"
                :animate-speed="getProgressSpeed(itemIndex)"
                :diameter="72"
                :total-steps="100"
                inner-stroke-color="transparent"
                :stroke-width="4"
              >
                <div
                  :class="[
                    $style.itemIcon,
                    { [$style.itemActive]: isActive(itemIndex) },
                    {
                      ['!opacity-0']: itemIndex >= mediaItems.length,
                    },
                  ]"
                >
                  {{ itemIndex + 1 }}
                </div>
              </RadialProgressBar>
            </div>
            <div>
              <Title :title-params="item.title" class="typo-headline-bold sm:text-center" />
              <RichText :text-params="item.description" class="sm:text-center" />
            </div>
          </div>
        </div>
      </div>

      <div :class="$style.view">
        <template v-for="(item, itemIndex) in items">
          <template v-if="item.video && item.video.url">
            <video
              v-show="isActive(itemIndex)"
              :key="'video-' + itemIndex"
              :ref="'video-' + itemIndex"
              class="w-[360px] lg:absolute lg:top-[30px] lg:w-[40vw] sm:w-[160px]"
              muted="muted"
            >
              <source :src="item.video.url" type="video/mp4" />
            </video>
          </template>
          <template v-if="item.image && item.image.url">
            <PImage
              v-show="isActive(itemIndex)"
              :key="'image-' + itemIndex"
              :ref="'image-' + itemIndex"
              :image-object="item.image"
              class="w-[360px] lg:absolute lg:top-[30px] lg:w-[40vw] sm:w-[160px]"
            />
          </template>
        </template>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress/src/RadialProgressBar.vue'

export default {
  name: 'PhoneCarousel2021Q4',

  components: {
    RadialProgressBar,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hasPlayedOnce: false,
      activeIndex: 0,
      prog: {},
      timeoutFct: null,
      activeVideoProgression: 0,
      timerFct: null,
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    activeVideo() {
      const video = this.$refs['video-' + this.activeIndex]
      return video
        ? video[0]
        : {
            duration: 0,
            play() {},
            pause() {},
          }
    },

    progressions: {
      get() {
        return this.prog
      },
      set(newValue) {
        this.prog = newValue
      },
    },

    itemDuration() {
      return Math.round(this.activeVideo.duration + 4)
    },

    mediaItems() {
      return this.items.filter((item) => item.video?.url || item.image?.url)
    },
  },

  methods: {
    onMouseEnter(index) {
      if (this.isActive(index)) {
        this.activeVideo.pause()
        if (this.timerFct) clearInterval(this.timerFct)
      }
    },

    onMouseLeave(index) {
      if (this.isActive(index)) {
        this.activeVideo.play()
        this.startTimer()
      }
    },

    toNextVideo() {
      this.setActiveIndex((this.activeIndex + 1) % this.mediaItems.length)
    },

    sectionOnScreen(onScreen) {
      if (onScreen && !this.hasPlayedOnce) {
        this.setActiveIndex(0)
        this.hasPlayedOnce = true
      }
    },

    startTimer() {
      if (this.timerFct) clearInterval(this.timerFct)

      this.timerFct = setInterval(() => {
        this.activeVideoProgression += 0.2

        if (this.activeVideoProgression > this.itemDuration) {
          this.activeVideoProgression = 0
          clearInterval(this.timerFct)
          this.toNextVideo()
        }

        this.onVideoProgress()
      }, 200)
    },

    onVideoProgress() {
      const dataProg = { ...this.prog }
      dataProg[this.activeIndex] = (this.activeVideoProgression * 100) / this.itemDuration
      this.progressions = dataProg
    },

    resetVideoTimers() {
      this.activeVideo.currentTime = 0
      this.activeVideoProgression = 0
      this.onVideoProgress()
    },

    setActiveIndex(index) {
      if (index >= this.mediaItems.length) {
        index = this.mediaItems.length - 1
      }

      this.activeVideo.pause()
      this.resetVideoTimers()
      this.activeIndex = index
      this.startTimer()
      this.activeVideo.play()
    },

    isActive(index) {
      if (this.activeIndex >= this.mediaItems.length - 1 && index > this.activeIndex) return true

      return index === this.activeIndex
    },

    getProgress(index) {
      if (!this.isActive(index)) return 0
      return this.progressions[index]
    },

    getProgressSpeed(index) {
      if (!this.isActive(index)) return 1000
      return 200
    },
  },
}
</script>

<style lang="scss" module>
.view {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 788px;

  background-color: $bluko-800;
  background-image: url('@/assets/images/slice/scrollable-back.png');
  background-repeat: no-repeat;
  background-position: 65% 10%;
  background-size: auto 115%;

  @include below(large) {
    width: 100%;
    height: 360px;
    overflow: hidden;

    background-size: 100% auto;
  }
  @include below(small) {
    display: none;
  }
}

.item {
  display: flex;
  align-items: center;

  color: $gray-400;
  text-align: left;

  cursor: pointer;

  transition-duration: 0.3s;
  transition-property: color, background-colors;

  @include below(small) {
    flex-direction: column;
  }
}

.circleProgress circle:last-child {
  stroke: $gray-400 !important;
  @include below(small) {
    margin-bottom: 12px;
  }
}

.itemActive .circleProgress circle:last-child {
  stroke: $bluko-500 !important;
}

.itemIcon {
  @include typo-title;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  line-height: 48px;
  text-align: center;

  background-color: white;
  opacity: 0.5;

  transition-duration: 0.3s;
  transition-property: opacity, background-color;

  &.itemActive {
    color: $bluko-500;

    background-color: $bluko-50;
    opacity: 1;
  }
}

.itemActive {
  color: $gray-1000;
}
</style>
