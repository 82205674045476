<template>
  <div class="-mt-[64px] min-h-[615px] bg-orange-25 px-[50px] pt-[64px] pb-[24px] md:px-[24px]">
    <Wrapper class="flex h-full justify-center gap-[5%] pt-[56px] md:flex-col md:gap-0 sm:pt-[24px]">
      <div class="flex-1">
        <Breadcrumb v-if="primary.showBreadcrumb" class="mt-[16px]" />
        <Title class="mt-[16px] !font-[300] typo-mega-title sm:typo-extra-large-title" :title-params="primary.title" />
        <RichText class="!text-gray-700 typo-headline [&>p]:mt-[16px]" :text-params="primary.subtitle" />
        <HeroCta :data="primary" class="mt-[40px]" />
      </div>
      <PImage
        class="
          max-h-[460px]
          w-1/2
          flex-1
          rounded-bl-[300px]
          object-cover object-[80%]
          md:mt-[16px] md:h-[256px] md:max-h-[256px] md:w-full md:rounded-bl-[150px]
        "
        :image-object="primary.backgroundImage"
        sizes="sm:100vw"
      />
    </Wrapper>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import HeroCta from '@/components/HeroCta'

export default {
  name: 'HeroAdviceHub2021Q4',

  components: {
    Breadcrumb,
    HeroCta,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
