<template>
  <div class="m-0 my-[128px] flex w-full flex-col items-center justify-center sm:my-[80px] sm:bg-white sm:px-[20px]" :style="sectionStyle">
    <Title :class="$style.title" :title-params="primary.title" class="!font-[300] typo-extra-large-title sm:text-center" />
    <RichText
      class="mb-[20px] max-w-[840px] text-center !text-gray-500 typo-body md:max-w-[600px] [&>p]:my-[18px]"
      :text-params="primary.subDescription"
    />
    <template v-if="isAnimated">
      <div class="flex min-h-[400px] max-w-[1280px]" :class="$style.medium" data-aos="fade-up">
        <aside
          v-observe-visibility="{
            callback: visibilityChanged,
            once: false,
          }"
          class="flex-1"
        >
          <video v-if="primary.animationVideo" ref="video-giveback" class="h-[500px]" muted="muted">
            <source :src="primary.animationVideo.url" type="video/mp4" />
          </video>
        </aside>
        <ul class="flex flex-1 flex-col justify-center">
          <li :class="$style.thirtyDescription">
            <LinearProgressBar
              :class="$style.bar"
              :sec="2"
              :is-active="[0, 1, 2, 3].includes(currentStep)"
              :callback="onFinishTimer"
              :delay="0.1"
              :prefill="isPlaying"
            />
            <RichText :text-params="primary['30Description']" :class="[$style.description, { ['!text-black']: [0, 3].includes(currentStep) }]" />
          </li>
          <li :class="[$style.seventyDescription]">
            <LinearProgressBar
              :class="$style.bar"
              :sec="2.7"
              :is-active="[1, 2, 3].includes(currentStep)"
              :callback="onFinishTimer"
              :prefill="isPlaying"
            />
            <RichText :text-params="primary['70Description']" :class="[$style.description, { ['!text-black']: [1, 3].includes(currentStep) }]" />
          </li>
          <li :class="$style.remainDescription">
            <LinearProgressBar
              :class="$style.bar"
              :sec="2.3"
              :is-active="[2, 3].includes(currentStep)"
              :callback="onFinishTimer"
              :prefill="isPlaying"
            />
            <RichText :text-params="primary.remainingDescription" :class="[$style.description, { ['!text-black']: [2, 3].includes(currentStep) }]" />
          </li>
        </ul>
      </div>
    </template>

    <ul :class="$style.small" class="flex flex-col">
      <li data-aos="fade-up">
        <RichText :text-params="primary['30Description']" />
      </li>
      <li data-aos="fade-up">
        <RichText :text-params="primary['70Description']" />
      </li>
      <li data-aos="fade-up">
        <RichText :text-params="primary.remainingDescription" />
      </li>
    </ul>
    <Wrapper v-if="primary.ctaText" class="md:hidden">
      <ArrowButton is-outlined :link-object="primary.ctaLink" target="_blank" :link-text="primary.ctaText" />
    </Wrapper>
  </div>
</template>

<script>
import LinearProgressBar from '@/components/LinearProgressBar'
import ArrowButton from '@/components/ArrowButton'

export default {
  components: {
    ArrowButton,
    LinearProgressBar,
  },
  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isPlaying: false,
      currentStep: -1,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    sectionStyle() {
      const backgroundImage = this.primary.backgroundImage?.url ? `background-image:url(${this.primary.backgroundImage?.url})` : ''

      const backgroundColor = this.primary.backgroundColor ? `background-color: ${this.primary.backgroundColor};` : ''
      return `${backgroundColor} ${backgroundImage}`
    },
    isAnimated() {
      return !!this.primary.animationVideo
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      const isHidden = document.hidden || document.msHidden || document.webkitHidden

      if (isVisible && !isHidden) {
        this.$refs['video-giveback'].currentTime = 0
        this.$refs['video-giveback'].play()
        this.currentStep = 0
        this.isPlaying = true
      } else {
        this.currentStep = -1
        this.isPlaying = false
      }
    },
    onFinishTimer() {
      this.currentStep++
      if (this.currentStep === 3) this.isPlaying = false
    },
  },
}
</script>

<style lang="scss" module>
.medium {
  li:nth-child(2) {
    width: 400px;
  }
  li:nth-child(1),
  li:nth-child(3) {
    width: 25vw;
  }

  @include below(medium) {
    display: none;
  }
}

.description {
  color: $gray-400;

  transition: color 0.3s ease-in-out;
}

.bar {
  flex-shrink: 0;
}

.seventyDescription {
  display: flex;
  align-items: center;

  text-align: left;
  .bar {
    height: 100%;
  }
  .description {
    padding: 10px 20px;
  }
}

.thirtyDescription {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  .bar {
    height: 100%;
  }
  .description {
    padding: 10px 20px;
  }
}

.remainDescription {
  display: flex;
  align-items: center;
  .bar {
    height: 100%;
  }
  .description {
    padding: 10px 20px;
  }
}

ul.small {
  li {
    max-width: 323px;
    margin: 0;
    padding: 16px 0 16px 16px;
    border-left: 4px solid $bluko-500;
    &:nth-child(2) {
      border-left: 4px solid $bluko-300;
    }
    &:nth-child(3) {
      border-left: 4px solid $bluko-100;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  }
  @include above(medium) {
    display: none;
  }
}
</style>
