var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Wrapper',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.onVisible,
    intersection: {
      rootMargin: '100% 0px 100% 0px',
    },
    once: true,
  }),expression:"{\n    callback: onVisible,\n    intersection: {\n      rootMargin: '100% 0px 100% 0px',\n    },\n    once: true,\n  }"}],ref:"inner",staticClass:"my-[160px] h-full bg-[length:138px_196px] bg-right-bottom bg-no-repeat sm:my-[80px] sm:!bg-none sm:px-0"},[_c('Title',{staticClass:"m-0 mx-auto mb-[10px] max-w-[950px] px-[24px] text-center !font-[300] typo-mega-title hyphens sm:text-[40px] sm:leading-[48px]",attrs:{"title-params":_vm.primary.title}}),_vm._v(" "),_c('RichText',{staticClass:"m-0 mx-auto max-w-[950px] px-[24px] text-center text-gray-700 typo-body",attrs:{"text-params":_vm.primary.description}}),_vm._v(" "),_c('div',{staticClass:"mt-[48px] sm:mt-[40px]"},[_c('VueSlickCarousel',_vm._b({ref:"carousel",on:{"init":_vm.onInit,"beforeChange":_vm.onBeforeChange}},'VueSlickCarousel',_vm.slickOptions,false),_vm._l((_vm.items),function(item,itemIndex){
  var _obj, _obj$1, _obj$2, _obj$3, _obj$4;
return _c('div',{key:itemIndex},[_c('div',{staticClass:"relative mx-auto h-[440px] w-[90%] md:w-[95%] sm:h-[500px]",class:[_vm.$style.itemContainer, ( _obj = {}, _obj['!h-[600px] px-[24px]'] = _vm.items.length <= 3, _obj )],style:({ backgroundImage: _vm.load ? item.image.url : null, backgroundSize: 'cover' })},[_c('div',{staticClass:"absolute mx-auto w-full overflow-hidden rounded-t-[8px]",class:[_vm.$style.itemImageContainer, ( _obj$1 = {}, _obj$1['h-[80%]'] = !_vm.hasUsp(item), _obj$1 ), ( _obj$2 = {}, _obj$2['h-[60%] sm:h-[50%]'] = _vm.hasUsp(item), _obj$2 )]},[_c('PImage',{staticClass:"h-full w-full scale-[1.15] object-cover sm:scale-100",class:_vm.$style.itemImage,attrs:{"image-object":_vm.load ? item.image : null,"sizes":"xs:100vw"}})],1),_vm._v(" "),_c('div',{staticClass:"\n              itemTextContainer\n              absolute\n              bottom-0\n              flex\n              h-fit\n              w-full\n              flex-col\n              justify-between\n              rounded-b-[8px]\n              border-[1px] border-bluko-100\n              bg-white\n              p-[32px]\n              sm:p-[24px]\n            ",class:_vm.$style.itemText,style:({ minHeight: (_vm.minimumTextHeight + "px") })},[_c('PLink',{attrs:{"link-object":item.product_link}},[_c('RichText',{staticClass:"text-[32px] leading-[40px] hyphens sm:text-[24px] sm:leading-[32px]",class:( _obj$3 = {}, _obj$3['!text-[24px] !leading-[32px]'] = _vm.items.length > 3, _obj$3 ),attrs:{"text-params":item.title}}),_vm._v(" "),_c('RichText',{staticClass:"text-[24px] font-[300] leading-[32px] text-black text-opacity-[0.6] sm:text-[16px] sm:leading-[24px]",class:( _obj$4 = {}, _obj$4['!text-[16px] !leading-[24px]'] = _vm.items.length > 3, _obj$4 ),attrs:{"text-params":item.subtitle}})],1),_vm._v(" "),(_vm.hasUsp(item))?_c('ul',{staticClass:"mt-[18px] mb-[24px] grid grid-cols-2 gap-[10px] sm:grid-cols-1 sm:gap-[12px]",class:_vm.$style.itemTextList},_vm._l(([
                  { icon: item.usp1_icon, text: item.usp1_text },
                  { icon: item.usp2_icon, text: item.usp2_text },
                  { icon: item.usp3_icon, text: item.usp3_text },
                  { icon: item.usp4_icon, text: item.usp4_text } ]),function(it){return _c('li',{staticClass:"flex min-h-[24px] items-center gap-[8px]"},[_c('PImage',{staticClass:"h-[24px] w-[24px] shrink-0",class:_vm.$style.itemIconImage,attrs:{"image-object":it.icon}}),_vm._v(" "),_c('RichText',{staticClass:"text-black text-opacity-[0.6] typo-sub-text hyphens",attrs:{"text-params":it.text}})],1)}),0):_vm._e(),_vm._v(" "),(item.cta_text)?_c('LkButton',{attrs:{"link-object":item.cta_link,"is-small":""}},[_vm._v(_vm._s(item.cta_text))]):_vm._e()],1)])])}),0),_vm._v(" "),(!_vm.hideArrow)?_c('div',{staticClass:"mt-[48px] flex justify-center gap-[8px] sm:hidden"},[_c('button',{class:_vm.$style.button,attrs:{"disabled":_vm.slideIndex === _vm.currentSetting.initialSlide},on:{"click":_vm.goPrev}},[_c('ChevronLeftIcon',{staticClass:"path-bluko-500",class:( _obj = {}, _obj['path-bluko-150'] = _vm.slideIndex === _vm.currentSetting.initialSlide, _obj )})],1),_vm._v(" "),_c('button',{class:_vm.$style.button,attrs:{"disabled":_vm.slideIndex + _vm.currentSetting.slidesToShow === _vm.items.length},on:{"click":_vm.goNext}},[_c('ChevronRightIcon',{staticClass:"path-bluko-500",class:( _obj$1 = {}, _obj$1['path-bluko-150'] = _vm.slideIndex + _vm.currentSetting.slidesToShow === _vm.items.length, _obj$1 )})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-[48px] text-center sm:mt-[40px]"},[(_vm.primary.cta_text)?_c('LkButton',{attrs:{"link-object":_vm.primary.cta_link}},[_vm._v(_vm._s(_vm.primary.cta_text))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }