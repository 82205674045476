<template>
  <div
    class="flex flex-col items-center justify-center rounded-[16px] border-[2px] border-bluko-150 bg-white p-[32px] md:p-[24px]"
    :class="$style.container"
  >
    <BankIcon class="mb-[12px] h-[42px] w-[42px]" />
    <div class="text-bluko-500 typo-headline-bold">{{ $t('PriceSimulator.title') }}</div>
    <div class="mb-[16px]">
      <p class="relative mb-[-30px] text-[24px] font-[450] leading-[32px]">
        <span v-if="isEn" class="relative top-[-30px]">€</span>
        <span class="text-[72px] leading-[80px]">{{ parsedPrice.integerPart }}</span>
        <span class="relative top-[-30px] inline-flex flex-col text-left">
          <span class="relative">
            {{ parsedPrice.decimalWithSep }}
            <template v-if="!isEn">€</template>
          </span>
          <span class="relative">{{ $t('PriceSimulator.perMonth') }}</span>
        </span>
      </p>
      <p class="text-center font-[450] text-black text-opacity-60">
        {{ $t('PriceSimulator.secondPrice', { value: parsedPrice.secondPrice }) }}
      </p>
    </div>

    <p class="mb-[24px] text-center !font-[450] text-black text-opacity-60 typo-sub-text">
      {{ $t('PriceSimulator.description') }}
    </p>
    <LkButton class="mb-[16px] !w-full" data-luko-tracking="FinalizeQuote" @click="goOnboarding">
      {{ $t('PriceSimulator.goOnboarding') }}
    </LkButton>
    <span class="cursor-pointer font-[450] text-bluko-500 underline" data-luko-tracking="UpdateInfo" @click="onBackToSimulator">
      {{ $t('PriceSimulator.updateInfo') }}
    </span>
  </div>
</template>
<script>
import BankIcon from 'LkIcons/Bank.vue'
import get from 'lodash-es/get'

import { getOnboardingLink } from '@/mixins/getOnboardingLink'

const getMonthlyPrice = (cents) => ({
  total: Math.trunc(cents / 12) / 100,
  cents: Math.trunc(cents / 12),
  yearTotal: cents / 100,
})
const getPrice = (cents) => Math.trunc(cents / 12) / 100

export default {
  components: {
    BankIcon,
  },
  mixins: [getOnboardingLink],
  props: {
    primary: {
      type: Object,
      required: true,
    },
    showResult: {
      type: Boolean,
      required: true,
    },
    quote: {
      type: Object,
      default: null,
    },
    prospect: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:showResult', 'update:quote'],
  computed: {
    isEn() {
      return this.$i18n.locale === 'en'
    },
    parsedPrice() {
      if (!this.quote) return {}

      const { yearTotal, total } = getMonthlyPrice(this.quote?.price_monthly_billing)
      const mainPrice = this.$n(total, 'price')
      const decimalWithSep = mainPrice.slice(-3)
      const integerPart = mainPrice.slice(0, -3)
      const secondPrice = this.$n(yearTotal, 'currency')

      return {
        decimalWithSep,
        integerPart,
        secondPrice,
      }
    },
    onboardingUrl() {
      if (!this.prospect) return null

      const queryParams = [
        'product',
        'user.subscriber.birth_date',
        'mortgage.main_asset.bank_name',
        'mortgage.main_asset.purpose',
        'mortgage.main_asset.type',
        'loan.loan[0].initial_start_date',
        'loan.loan[0].initial_duration_in_months',
        'loan.loan[0].initial_amount',
      ].map((path) => [`$.${path}`, get(this.prospect, path)])

      return this.getOnboardingLink(null, { queryParams, type: 'mortgage' })
    },
  },
  mounted() {
    window.LukoTracking.trackEvent({
      id: 'Simulator Quote Seen',
      properties: {
        url: this.onboardingUrl,
        prospectData: this.prospect,
        pricing: {
          pack: this.prospect.package,
          monthly_price: parseFloat(getPrice(this.quote?.price_monthly_billing).toFixed(2)) || undefined,
        },
      },
    })
  },
  methods: {
    onBackToSimulator() {
      this.$emit('update:quote', null)
      this.$emit('update:showResult', false)
    },
    goOnboarding(event) {
      event.preventDefault()
      const url = this.onboardingUrl

      window.LukoTracking.trackEvent({
        id: 'Simulator Finalize Clicked',
        properties: {
          url,
          prospectData: this.prospect,
          pricing: {
            pack: this.prospect.package,
            monthly_price: parseFloat(getPrice(this.quote?.price_monthly_billing).toFixed(2)) || undefined,
          },
        },
      })

      window.location.href = url
    },
  },
}
</script>

<style lang="scss" module>
.container {
  box-shadow: 0px 0px 0px 4px $bluko-75;
}
</style>
