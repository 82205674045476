<template>
  <div class="my-[128px] mx-[32px]">
    <Wrapper
      v-observe-visibility="{
        callback: sectionOnScreen,
        intersection: {
          rootMargin: '-30% 0px -30% 0px',
        },
      }"
      class="flex max-w-[1120px]"
    >
      <transition-group name="cross" class="relative flex-1 text-center" tag="div">
        <PImage
          :key="'front' + currentIndex"
          :image-object="currentItem.imageFront"
          sizes="xs:600px"
          lazy
          class="front absolute left-0 z-10 h-full w-[min(420px,40vw)] object-contain"
        />
        <BackImage key="0" class="absolute top-[10%] left-0 h-[min(420px,40vw)] w-[min(420px,40vw)]" />
      </transition-group>
      <div class="mb-[80px] w-[50vw] max-w-[540px] md:w-auto">
        <Title class="mb-[36px] !font-[300] typo-mega-title" :title-params="primary.title" />
        <div v-for="(item, index) in items" :key="index" class="mb-[40px] flex cursor-pointer last:mb-0" @click="onClickItem(index)">
          <LinearProgressBar class="mr-[36px] shrink-0" :callback="afterCount" :is-active="index === currentIndex && isVisible" />
          <div class="text-gray-400">
            <Title :title-params="item.title" class="typo-headline-bold" :class="{ ['!text-black']: currentIndex === index && isVisible }" />
            <RichText
              :text-params="item.description"
              :class="{
                ['!text-gray-700']: currentIndex === index && isVisible,
              }"
            />
          </div>
        </div>
      </div>
    </Wrapper>
    <Wrapper v-if="primary.ctaText && primary.ctaLink" class="mt-[60px] flex justify-center">
      <ArrowButton :link-object="primary.ctaLink" :target="primary.ctaLink.target" is-outlined :link-text="primary.ctaText" />
    </Wrapper>
  </div>
</template>

<script>
import LinearProgressBar from '@/components/LinearProgressBar'
import ArrowButton from '@/components/ArrowButton'

import BackImage from './BackImage.svg?inline'

export default {
  name: 'MediaCarouselDesktop',
  components: {
    LinearProgressBar,
    ArrowButton,
    BackImage,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentIndex: 0,
      isVisible: false,
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => item.imageFront?.url)
    },
    currentItem() {
      return this.items[this.currentIndex]
    },
  },

  methods: {
    afterCount() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length
    },
    onClickItem(index) {
      this.currentIndex = index
    },
    sectionOnScreen(isVisible) {
      this.isVisible = isVisible
    },
  },
}
</script>

<style lang="scss" module></style>
<style lang="scss" scoped>
/* slide left */
.cross-enter.front {
  transform: translateX(100px);
  opacity: 0;
}
.cross-enter-to.front {
  transform: initial;
  opacity: 1;
}
.cross-leave.front {
  position: absolute;

  transform: translateX(0);

  opacity: 1;
}
.cross-leave-to.front {
  position: absolute;

  transform: translateX(-100px);
  opacity: 0;
}
.cross-enter-active.front {
  transition: all 0.5s ease-out;
  transition-delay: 0.7s;
}
.cross-leave-active.front {
  position: absolute;

  transition: all 0.5s ease-in;
}
</style>
