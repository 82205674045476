<template>
  <div class="my-[60px] mx-auto px-[20px] sm:px-[10px]">
    <Wrapper class="overflow-x-scroll rounded-[8px] border-b-2 border-solid border-gray-100">
      <table v-if="tableData" :class="$style.table">
        <thead :class="$style.thead">
          <tr v-for="(header, idx) in headers" :key="'header' + idx" :style="{ [$style.firstHeader]: idx === 0 }">
            <th v-for="(text, idx2) in header" :key="'head' + idx2">
              <img v-if="text && text.link" :src="text.link.url" :class="$style.logo" />
              <template v-else>{{ text ? text.content : '' }}</template>
            </th>
          </tr>
        </thead>
        <tbody :class="$style.tbody">
          <tr v-for="(body, idx) in bodies" :key="'body' + idx">
            <td v-for="(text, idx2) in body" :key="'body' + idx2">
              <img v-if="text && text.link" :src="text.link.url" :class="$style.logo" />
              <template v-else>{{ text ? text.content : '' }}</template>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        {{ error }}
      </div>
    </Wrapper>
  </div>
</template>

<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  async fetch() {
    try {
      const { Client } = await import('@notionhq/client')
      const notion = new Client({ auth: process.env.NOTION_API_KEY })
      const link = this.slice.primary.notionTableLink?.url
      if (!link) return

      const blockId = new URL(link).hash.replace('#', '')
      if (!blockId) return

      const response = await notion.blocks.children.list({
        block_id: blockId,
      })
      this.tableData = response.results
    } catch (error) {
      console.error(error)
      this.error = error.message
    }
  },
  data() {
    return {
      tableData: null,
      error: '',
    }
  },
  computed: {
    headers() {
      return this.tableData
        .slice(0, 2)
        .map((data) => data.table_row.cells)
        .map((cells) => cells.map((cell) => cell[0]?.text))
    },
    bodies() {
      return this.tableData
        .slice(2)
        .map((data) => data.table_row.cells)
        .map((cells) => cells.map((cell) => cell[0]?.text))
    },
  },
}
</script>
<style module lang="scss">
.table {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.thead {
  tr:first-of-type {
    background-color: $orange-50;
  }
  tr:nth-of-type(2) {
    border-right: 2px solid $gray-100;
    border-bottom: 2px solid $gray-100;
    border-left: 2px solid $gray-100;
  }

  tr:first-of-type th {
    padding: 35px 20px;

    font-weight: 500;
    @include below(small) {
      padding: 20px 10px;
    }
  }

  tr:nth-of-type(2) th {
    padding: 20px;

    color: $bluko-800;
    font-weight: 800;
    @include below(small) {
      padding: 10px;
    }
  }
}

.tbody {
  tr td {
    padding: 20px;

    text-align: center;
    @include below(small) {
      padding: 10px;
    }
  }
  tr {
    border-right: 2px solid $gray-100;
    border-bottom: 1px solid $gray-100;
    border-left: 2px solid $gray-100;
  }
  tr:last-of-type {
    border-bottom: none;
  }
}

.logo {
  width: 10vw;
  max-width: 120px;
  min-height: 30px;
  max-height: 40px;
}
</style>
