<template>
  <div :class="$style.emailContainer">
    <LkEmailField :placeholder="placeholder" :button-text="text" :class="$style.emailField" />
    <LkButton :link-object="linkObject" :class="$style.button" is-white class="sm:!w-full">
      {{ text }}
    </LkButton>
  </div>
</template>
<script>
import LkEmailField from '@/components/Lukomponents/LkEmailField'

export default {
  components: {
    LkEmailField,
  },
  props: {
    type: {
      type: String,
      default: 'None',
    },
    text: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    linkObject: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
<style lang="scss" module>
.emailContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  .button {
    flex-grow: 1;
    flex-shrink: 0;
  }

  @include below(small) {
    flex-direction: column;
  }
}
</style>
