<template>
  <section class="relative flex h-[540px] items-stretch lg:h-[750px] md:flex-col">
    <div
      class="flex h-full flex-1 bg-bluko-500 bg-cover bg-center bg-no-repeat md:h-[600px] md:w-full md:flex-initial sm:h-[350px]"
      :style="backgroundStyles(primary.image, 'lg:100vw')"
    ></div>
    <div class="flex flex-1 items-center bg-bluko-800 md:w-full md:p-0 md:py-[60px] md:text-center">
      <div class="p-0 px-[65px] md:w-full md:px-[32px]">
        <Title :title-params="primary.title" class="mb-[24px] text-[48px] font-[300] leading-[56px] text-white sm:text-[32px] sm:leading-[42px]" />
        <RichText :text-params="primary.description" class="text-bluko-200" />
        <div class="mt-[48px]">
          <PLink :link-object="primary.ctaAppStore" target="_blank" rel="noopener">
            <PImage :image-object="primary.imageAppStore" sizes="xs:120px" class="h-[40px] sm:h-[min(40px,11vw)]" />
          </PLink>
          <PLink :link-object="primary.ctaGooglePlay" target="_blank" rel="noopener">
            <PImage :image-object="primary.imageGooglePlay" sizes="xs:120px" class="ml-[8px] h-[40px] sm:h-[min(40px,11vw)]" />
          </PLink>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundStyles } from '@/mixins/backgroundStyles'

export default {
  name: 'AppBlockDefault',
  mixins: [backgroundStyles],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
  },
}
</script>
