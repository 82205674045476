var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-[24px]"},[_c('Wrapper',{staticClass:"my-[128px] flex items-center justify-between gap-[40px] sm:my-[64px] sm:flex-col sm:gap-[32px]",class:( _obj = {}, _obj['flex-row-reverse'] = _vm.primary.imagePosition, _obj )},[_c('PImage',{staticClass:"w-1/2 flex-1 rounded sm:w-full",attrs:{"lazy":"","sizes":"md:1000px xs:600px","quality":90,"image-object":_vm.primary.image}}),_vm._v(" "),_c('div',{staticClass:"flex-1 px-[32px] sm:px-[24px]"},[_c('Title',{staticClass:"typo-large-title sm:typo-title",attrs:{"title-params":_vm.primary.subtitle}}),_vm._v(" "),_c('RichText',{staticClass:"mt-[16px] text-gray-700",attrs:{"text-params":_vm.primary.text}}),_vm._v(" "),(_vm.primary.highlightIcon && _vm.primary.highlightIcon.url)?_c('div',{staticClass:"mt-[24px] flex items-center gap-[16px] rounded-[8px] border-[1px] border-bluko-100 p-[24px]"},[_c('PImage',{staticClass:"h-[32px] w-[32px]",attrs:{"image-object":_vm.primary.highlightIcon,"sizes":"xs:64px"}}),_vm._v(" "),_c('RichText',{staticClass:"text-[14px] leading-[22px] text-bluko-500",attrs:{"text-params":_vm.primary.highlightText}})],1):_vm._e(),_vm._v(" "),(
          (!_vm.primary.ctaStyle || _vm.primary.ctaStyle === 'arrow') &&
          (!_vm.primary.ctaType || _vm.primary.ctaType === 'link') &&
          _vm.primary.ctaLink &&
          _vm.primary.ctaLink.url &&
          _vm.primary.ctaText
        )?_c('ArrowLink',{staticClass:"mt-[40px] text-bluko-500 path-bluko-500",attrs:{"link-object":_vm.primary.ctaLink,"link-text":_vm.primary.ctaText}}):((!_vm.primary.ctaStyle || _vm.primary.ctaStyle === 'arrow') && _vm.primary.ctaType === 'intercom' && _vm.primary.ctaText)?_c('ArrowLink',{staticClass:"mt-[40px] text-bluko-500 path-bluko-500",attrs:{"simple-url":"#/","link-text":_vm.primary.ctaText},on:{"click":_vm.showIntercom}}):(_vm.primary.ctaStyle === 'button' && (!_vm.primary.ctaType || _vm.primary.ctaType === 'link') && _vm.primary.ctaText)?_c('ArrowButton',{staticClass:"mt-[32px] sm:!w-full",attrs:{"link-object":_vm.primary.ctaLink,"link-text":_vm.primary.ctaText,"is-outlined":_vm.primary.buttonOutlined,"has-icon-right":""}}):(_vm.primary.ctaStyle === 'button' && _vm.primary.ctaType === 'intercom' && _vm.primary.ctaText)?_c('ArrowButton',{staticClass:"mt-[32px] sm:!w-full",attrs:{"link-text":_vm.primary.ctaText,"is-outlined":_vm.primary.buttonOutlined,"has-icon-right":""},on:{"click":_vm.showIntercom}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }