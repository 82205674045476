<template>
  <div>
    <ArticleIconListSmall v-if="slice.variation === 'small'" :slice="slice" />
    <ArticleIconListDefault v-else :slice="slice" />
  </div>
</template>

<script>
import ArticleIconListSmall from './small/'
import ArticleIconListDefault from './default/'

export default {
  components: {
    ArticleIconListSmall,
    ArticleIconListDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
