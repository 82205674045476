<template>
  <div v-observe-visibility="{ callback: onVisible, once: true }" class="flex flex-col justify-center p-[20px] pt-[60px] pb-[80px]">
    <Wrapper class="flex flex-col gap-[40px]">
      <div v-for="(item, index) in items" :key="index" class="flex flex-col items-center" data-aos="fade-up">
        <div class="relative flex h-[min(300px,40vw)] w-full flex-col items-center justify-center">
          <PImage
            :image-object="loadImage ? item.imageBack : null"
            sizes="xs:100vw"
            class="absolute h-[min(300pw,40vw)] w-[min(300px,40vw)]"
            :quality="80"
            lazy
          />
          <PImage
            :image-object="loadImage ? item.imageFront : null"
            sizes="xs:100vw"
            class="absolute z-10 h-[min(300pw,40vw)] w-[min(300px,40vw)]"
            :quality="80"
            lazy
          />
        </div>
        <RichText :text-params="item.description" class="max-w-[400px] pt-[40px] text-center [&>p]:text-gray-700 [&>h3]:typo-headline" />
      </div>
    </Wrapper>

    <Wrapper v-if="primary.ctaText && primary.ctaLink" class="flex w-full justify-center pt-[60px]">
      <ArrowButton is-outlined :link-object="primary.ctaLink" class="w-full" :link-text="primary.ctaText" />
    </Wrapper>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton'

export default {
  name: 'MediaCarouselMobile',
  components: {
    ArrowButton,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadImage: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => item.imageFront.url && item.imageBack.url)
    },
  },
  methods: {
    onVisible(visible) {
      if (visible) {
        this.loadImage = true
      }
    },
  },
}
</script>
