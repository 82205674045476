<template>
  <div>
    <ArrowButton
      v-if="ctaType === 'link'"
      :link-object="primary.ctaLink"
      :is-outlined="isOutlined"
      :is-white="isWhite"
      class="text-bluko-500 path-bluko-500 sm:!w-full"
      target="_blank"
      :link-text="primary.ctaText"
    />

    <ArrowButton
      v-else-if="ctaType === 'mailto' && primary.ctaText && primary.mailto"
      :simple-url="`mailto:${primary.mailto}`"
      :is-outlined="isOutlined"
      :is-white="isWhite"
      class="sm:!w-full"
      :link-text="primary.ctaText"
    />

    <ArrowButton
      v-else-if="ctaType === 'intercom' && primary.ctaText"
      simple-url="#/"
      :is-outlined="isOutlined"
      :is-white="isWhite"
      class="sm:!w-full"
      :link-text="primary.ctaText"
      @click="showIntercom"
    />
  </div>
</template>
<script>
import { isLoggedIn } from '@/mixins/isLoggedIn'
import ArrowButton from '@/components/ArrowButton'
import { showIntercom } from '@/mixins/showIntercom'

export default {
  name: 'CtaBox',
  components: {
    ArrowButton,
  },

  mixins: [isLoggedIn, showIntercom],

  props: {
    primary: {
      type: Object,
      required: true,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ctaType() {
      return this.primary.ctaType || 'link'
    },
  },
}
</script>
