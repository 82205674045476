<template>
  <section class="-mt-[64px] sm:h-auto sm:min-h-[480px]">
    <Wrapper
      class="h-full min-h-[615px] max-w-[1440px] bg-cover bg-center bg-no-repeat sm:min-h-[480px]"
      :style="backgroundStyles(primary.backgroundImage)"
    >
      <div
        class="flex min-h-[615px] max-w-[1000px] flex-col justify-center px-[120px] pt-[128px] pb-[68px] sm:px-[32px] sm:pb-[42px]"
        :class="[{ [$style.backgroundShadow]: primary.backgroundShadow }]"
      >
        <Title :title-params="primary.title" class="w-[85%] !text-white typo-mega-title sm:w-full sm:typo-large-title" />
        <RichText :text-params="primary.description" class="mt-[16px] w-[85%] !text-white typo-headline sm:w-full sm:typo-body" />
        <HeroCta class="mt-[58px]" :data="primary" is-white />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { backgroundStyles } from '@/mixins/backgroundStyles'
import HeroCta from '@/components/HeroCta'

export default {
  components: { HeroCta },

  mixins: [backgroundStyles],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    apps() {
      return [
        {
          image: this.primary.imageAppStore,
          link: this.primary.ctaAppStore,
        },
        {
          image: this.primary.imageGooglePlay,
          link: this.primary.ctaGooglePlay,
        },
      ]
    },
  },
}
</script>

<style lang="scss" module>
.backgroundShadow {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
}
</style>
