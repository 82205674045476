<template>
  <div class="mt-[80px] rounded-[8px] border-[1px] border-solid border-bluko-500 py-[24px] px-[32px] sm:my-[64px]">
    <Title :title-params="primary.title" class="typo-headline-bold" />
    <RichText :text-params="primary.description" class="mt-[4px] mb-[24px] !text-gray-700 typo-body" />
    <form class="flex gap-[12px] sm:flex-col" @submit.prevent="onSubmit">
      <LkEmailField v-model="email" :placeholder="primary.placeholder" :has-error="!!errorMessage" @error="onEmailError" />
      <LkButton v-if="primary.ctaText" class="shrink-0 sm:!w-full" :loading="isLoading" type="submit">
        {{ primary.ctaText }}
      </LkButton>
    </form>
    <transition name="fade-down">
      <LkError v-if="errorMessage" :error="errorMessage" />
    </transition>
  </div>
</template>

<script>
import { delay } from '@fxts/core'

import { COUNTRIES } from '@/utils/constants.js'
import LkEmailField from '@/components/Lukomponents/LkEmailField'
import LkError from '@/components/Lukomponents/LkError'

export default {
  name: 'NewsLetterDefault',
  components: { LkEmailField, LkError },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hasError: false,
      errorMessage: '',
      email: '',
      isLoading: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    onEmailError(hasError) {
      this.hasError = hasError
    },
    onSubmit() {
      if (this.hasError || !this.email) return (this.errorMessage = this.$t('Error.invalidEmail'))
      this.errorMessage = ''
      const categoryUid = this.$store.state.categoryUid

      this.sendWaitlistToBraze(this.email, categoryUid)
    },
    async sendWaitlistToBraze(email, categoryUid) {
      const notification = {
        type: 'success',
        text: this.$t('Newsletter.success'),
      }
      try {
        this.isLoading = true
        await delay(1000)

        window.LukoTracking.trackEvent({
          id: `Newsletter Request`,
          properties: {
            subscriber: {
              email,
              category: Object.values(this.$store.state.adviceCategoryData).find((c) => c.uid === categoryUid)?.category_name || '',
              uid: this.$route.params.uid,
              lang: this.$i18n.localeProperties.iso,
              country: COUNTRIES[process.env.CURRENT_DOMAIN],
            },
          },
        })
      } catch (e) {
        console.error(e)
        notification.type = 'danger'
        notification.text = this.$t('Newsletter.fail')
      } finally {
        this.isLoading = false
        this.$store.commit('notification/SET_NOTIFICATION', notification)
      }
    },
  },
}
</script>
