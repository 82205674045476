<template>
  <div class="px-[24px]">
    <Wrapper
      class="my-[128px] flex items-center justify-between gap-[40px] sm:my-[64px] sm:flex-col sm:gap-[32px]"
      :class="{ ['flex-row-reverse']: primary.imagePosition }"
    >
      <PImage class="w-1/2 flex-1 rounded sm:w-full" lazy sizes="md:1000px xs:600px" :quality="90" :image-object="primary.image" />

      <div class="flex-1 px-[32px] sm:px-[24px]">
        <Title :title-params="primary.subtitle" class="typo-large-title sm:typo-title" />
        <RichText :text-params="primary.text" class="mt-[16px] text-gray-700" />

        <div
          v-if="primary.highlightIcon && primary.highlightIcon.url"
          class="mt-[24px] flex items-center gap-[16px] rounded-[8px] border-[1px] border-bluko-100 p-[24px]"
        >
          <PImage :image-object="primary.highlightIcon" sizes="xs:64px" class="h-[32px] w-[32px]" />
          <RichText :text-params="primary.highlightText" class="text-[14px] leading-[22px] text-bluko-500" />
        </div>

        <ArrowLink
          v-if="
            (!primary.ctaStyle || primary.ctaStyle === 'arrow') &&
            (!primary.ctaType || primary.ctaType === 'link') &&
            primary.ctaLink &&
            primary.ctaLink.url &&
            primary.ctaText
          "
          :link-object="primary.ctaLink"
          class="mt-[40px] text-bluko-500 path-bluko-500"
          :link-text="primary.ctaText"
        />
        <ArrowLink
          v-else-if="(!primary.ctaStyle || primary.ctaStyle === 'arrow') && primary.ctaType === 'intercom' && primary.ctaText"
          simple-url="#/"
          class="mt-[40px] text-bluko-500 path-bluko-500"
          :link-text="primary.ctaText"
          @click="showIntercom"
        />

        <ArrowButton
          v-else-if="primary.ctaStyle === 'button' && (!primary.ctaType || primary.ctaType === 'link') && primary.ctaText"
          class="mt-[32px] sm:!w-full"
          :link-object="primary.ctaLink"
          :link-text="primary.ctaText"
          :is-outlined="primary.buttonOutlined"
          has-icon-right
        />
        <ArrowButton
          v-else-if="primary.ctaStyle === 'button' && primary.ctaType === 'intercom' && primary.ctaText"
          class="mt-[32px] sm:!w-full"
          :link-text="primary.ctaText"
          :is-outlined="primary.buttonOutlined"
          has-icon-right
          @click="showIntercom"
        />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton'
import ArrowLink from '@/components/ArrowLink'
import { showIntercom } from '@/mixins/showIntercom'

export default {
  name: 'SimpleSlice',
  components: {
    ArrowLink,
    ArrowButton,
  },
  mixins: [showIntercom],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
