var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SliceWrapper',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.onVisible,
  }),expression:"{\n    callback: onVisible,\n  }"}],class:( _obj = {}, _obj['bg-bluko-800'] = _vm.isDarkblue, _obj ),attrs:{"slice":_vm.slice}},[_c('Wrapper',{staticClass:"px-[24px]",class:( _obj$1 = {}, _obj$1['py-[160px]'] = !_vm.isDarkblue, _obj$1['py-[80px]'] = _vm.isDarkblue, _obj$1 )},[_c('Title',{staticClass:"mx-auto max-w-[840px] text-center !font-[300] typo-mega-title xlg:typo-extra-large-title",class:( _obj$2 = {}, _obj$2['text-white'] = _vm.isDarkblue, _obj$2 ),attrs:{"title-params":_vm.primary.title}}),_vm._v(" "),_c('RichText',{staticClass:"mx-auto mt-[8px] max-w-[840px] text-center",class:( _obj$3 = {}, _obj$3['text-black text-opacity-60'] = !_vm.isDarkblue, _obj$3['text-bluko-200'] = _vm.isDarkblue, _obj$3 ),attrs:{"text-params":_vm.primary.description}}),_vm._v(" "),_c('div',{staticClass:"mt-[64px] flex gap-[56px] md:mt-[40px] md:flex-col"},[_c('div',{staticClass:"w-[60%] xlg:w-full"},[_c('div',{staticClass:"flex items-center gap-[32px] xlg:flex-col"},[_c('ul',{staticClass:"flex flex-col items-center gap-[16px] xlg:hidden"},_vm._l((_vm.items),function(_,idx){
  var _obj;
return _c('li',{key:idx,staticClass:"h-[8px] w-[8px] cursor-pointer rounded-[50%]",class:( _obj = {}, _obj['!h-[10px] !w-[10px]'] = idx === _vm.activeIndex, _obj['!bg-white'] = idx === _vm.activeIndex && _vm.isDarkblue, _obj['!bg-bluko-500'] = idx === _vm.activeIndex && !_vm.isDarkblue, _obj['bg-bluko-300'] = _vm.isDarkblue, _obj['bg-bluko-75'] = !_vm.isDarkblue, _obj ),on:{"click":function($event){return _vm.toNextVideo(idx)}}})}),0),_vm._v(" "),_c('div',{staticClass:"w-full"},[_c('VueSlickCarousel',_vm._b({ref:"carousel",staticClass:"w-full",on:{"beforeChange":_vm.onBeforeChange}},'VueSlickCarousel',_vm.slickOptions,false),_vm._l((_vm.items),function(item,idx){
              var _obj, _obj$1;
return _c('div',{key:'video-' + idx,staticClass:"h-[405px] overflow-hidden rounded-[4px] xlg:h-auto"},[_c('figure',{staticClass:"relative h-full xlg:mx-auto xlg:max-w-[680px]"},[_c('video',{staticClass:"relative mx-auto h-full w-full rounded-[4px] object-fill xlg:h-auto xlg:object-contain",attrs:{"autoplay":idx === 0,"preload":"none","muted":"","playsinline":"","src":item.video.url},domProps:{"muted":true}},[_c('source',{attrs:{"type":"video/mp4"}})]),_vm._v(" "),_c('figcaption',[_c('progress',{staticClass:"absolute bottom-0 left-0 z-10 h-[4px] w-full xlg:h-[2px]",class:_vm.$style.progress,attrs:{"max":"100"},domProps:{"value":_vm.progressions[idx]}})])]),_vm._v(" "),_c('div',{staticClass:"mx-auto mt-[64px] max-w-[680px] text-center sm:mt-[16px] up-xlg:hidden"},[_c('Title',{staticClass:"typo-large-title sm:text-[20px] sm:leading-[28px]",class:( _obj = {}, _obj['text-bluko-500'] = !_vm.isDarkblue, _obj['text-white'] = _vm.isDarkblue, _obj ),attrs:{"title-params":item.title}}),_vm._v(" "),_c('RichText',{staticClass:"mt-[4px] typo-sub-text",class:( _obj$1 = {}, _obj$1['text-white'] = _vm.isDarkblue, _obj$1 ),attrs:{"text-params":item.description}})],1)])}),0)],1),_vm._v(" "),_c('div',{staticClass:"w-full up-xlg:hidden"},[_c('ul',{staticClass:"mt-[25px] flex items-center justify-center gap-[16px] md:gap-[8px] up-xlg:hidden"},_vm._l((_vm.items),function(_,idx){
              var _obj;
return _c('li',{key:idx,staticClass:"h-[8px] w-[8px] cursor-pointer rounded-[50%]",class:( _obj = {}, _obj['!h-[10px] !w-[10px]'] = idx === _vm.activeIndex, _obj['!bg-bluko-500'] = idx === _vm.activeIndex && !_vm.isDarkblue, _obj['!bg-white'] = idx === _vm.activeIndex && _vm.isDarkblue, _obj['bg-bluko-75'] = !_vm.isDarkblue, _obj['bg-bluko-300'] = _vm.isDarkblue, _obj ),on:{"click":function($event){return _vm.toNextVideo(idx)}}})}),0)])])]),_vm._v(" "),_c('ul',{staticClass:"flex w-[40%] flex-col justify-center gap-[24px] xlg:hidden"},_vm._l((_vm.items),function(item,idx){
                var _obj, _obj$1;
return _c('li',{key:idx,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toNextVideo(idx)}}},[_c('Title',{staticClass:"transition-all duration-300 typo-headline",class:( _obj = {}, _obj['!text-[24px] !leading-[32px]'] = idx === _vm.activeIndex, _obj['!text-bluko-500 !text-opacity-100'] = !_vm.isDarkblue && idx === _vm.activeIndex, _obj['!text-white'] = _vm.isDarkblue && idx === _vm.activeIndex, _obj['text-black text-opacity-40'] = !_vm.isDarkblue, _obj['text-bluko-300'] = _vm.isDarkblue, _obj ),attrs:{"title-params":item.title}}),_vm._v(" "),_c('Transition',{attrs:{"name":"pop"}},[(idx === _vm.activeIndex)?_c('RichText',{staticClass:"mt-[4px]",class:( _obj$1 = {}, _obj$1['text-white'] = _vm.isDarkblue, _obj$1 ),attrs:{"text-params":item.description}}):_vm._e()],1)],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"mt-[64px] text-center md:mt-[40px]"},[_c('LkButton',{attrs:{"link-object":_vm.primary.cta_link,"is-white":_vm.isDarkblue}},[_vm._v(_vm._s(_vm.primary.cta_text))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }