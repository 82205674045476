<template>
  <div
    v-show="!showResult"
    class="mx-[24px] my-[80px] max-w-[560px] rounded-[16px] border-[2px] border-bluko-150 bg-white p-[32px] md:my-[40px] md:p-[24px]"
  >
    <Title :title-params="primary.simulator_title" class="text-center text-[24px] font-[500] leading-[32px]" />
    <form class="mt-[40px] grid grid-cols-2 gap-y-[24px] gap-x-[8px]" @submit.prevent="onSubmit">
      <LkDateField
        v-model="computedBirthDate"
        :placeholder="$t('PriceSimulator.datePlaceholder')"
        :label="$t('PriceSimulator.birth_date')"
        :max="new Date(subYears(new Date(), 18))"
        :min="new Date(subYears(new Date(), 71))"
        class="col-span-full"
        :error="errorBirthDate"
        hide-date-picker
        @error:lessThanMin="onInputerror('birthDateError', 'errorBirthDate')"
        @error:moreThanMax="onInputerror('birthDateError', 'errorBirthDate')"
        @error:invalidDate="computedBirthDate = $event"
        @input="errorBirthDate = undefined"
      />
      <LkDateField
        v-model="computedStartDate"
        :placeholder="$t('PriceSimulator.datePlaceholder')"
        :label="$t('PriceSimulator.initial_start_date')"
        class="sm:col-span-full"
        :error="errorInitialStartDate"
        :min="new Date(subYears(new Date(), initial_duration_in_months / 12))"
        :max="new Date(addYears(new Date(), 40))"
        hide-date-picker
        @error:lessThanMin="onInputerror('initialStartDateError', 'errorInitialStartDate')"
        @error:moreThanMax="onInputerror('initialStartDateError', 'errorInitialStartDate')"
        @error:invalidDate="computedStartDate = $event"
        @input="errorInitialStartDate = undefined"
      />
      <LkSelect
        v-model="bank_name"
        :options="bankOptions"
        :label="$t('PriceSimulator.bank')"
        :placeholder="$t('PriceSimulator.bankPlaceholder')"
        class="sm:col-span-full"
      />
      <LkSlider
        v-model="computedAmount"
        :label="$t('PriceSimulator.initial_amount')"
        class="col-span-full"
        tooltip-active="always"
        :steps="initialAmountSteps"
        :tooltip-formatter="(v) => $n(v, 'currency')"
        :tooltip-placement="tooltipPlacement"
      />
      <LkSlider
        v-model="computedMonth"
        :label="$t('PriceSimulator.duration')"
        class="col-span-full"
        tooltip-active="always"
        :steps="yearSteps"
        :tooltip-formatter="(v) => $tc('year', v, { value: v })"
        :tooltip-placement="tooltipPlacement"
      />
      <div class="col-span-full">
        <LkLabel :label="$t('PriceSimulator.purpose.label')" class="mb-[8px]" />
        <div class="grid grid-cols-3 gap-[16px] sm:grid-cols-1">
          <LkChoice v-for="p in purposeOptions" :key="p.value" v-model="purpose" :value="p.value" :wording="p.wording" name="purpose">
            <template #icon>
              <component :is="p.icon" class="h-[24px] w-[24px] path-bluko-500" />
            </template>
          </LkChoice>
        </div>
      </div>
      <div class="col-span-full mt-[16px] flex w-full flex-col items-center md:mt-0">
        <LkButton :disabled="!isValid" :loading="loading" class="sm:!w-full" type="submit" data-luko-tracking="Simulate">
          {{ primary.simulate_text || $t('PriceSimulator.simulate_text') }}
        </LkButton>
        <LkError :message="errorApi" />
      </div>
    </form>
  </div>
</template>
<script>
import { pipe, range, toArray, map } from '@fxts/core'
import HouseIcon from 'LkIcons/House.vue'
import KeyIcon from 'LkIcons/Key.vue'
import BeachIcon from 'LkIcons/Beach.vue'
import { parse, format, subYears, addYears, isPast } from 'date-fns'

import LkError from '@/components/Lukomponents/LkError'
import QuoteService from '@/api/quote'
import LkDateField from '@/components/Lukomponents/LkDateField'
import LkSelect from '@/components/Lukomponents/LkSelect'
import LkChoice from '@/components/Lukomponents/LkChoice'
import LkLabel from '@/components/Lukomponents/LkLabel'
import LkSlider from '@/components/Lukomponents/LkSlider'

const apiToDate = (date) => parse(date, 'yyyy-MM-dd', new Date())
const dateToApi = (date) => format(date, 'yyyy-MM-dd')

const bankList = [
  'Crédit Agricole',
  "Caisse d'Epargne",
  'Banque Populaire',
  'Boursorama',
  'LCL',
  'BNP Paribas',
  'La Banque Postale',
  'Société Générale',
  'Crédit Mutuel',
  'CIC',
  'HSBC',
  'Fortuneo',
  'Crédit Mutuel Arkea',
  'Crédit du Nord',
  'Crédit Foncier',
  'ING',
  'AXA Banque',
  'BRED Banque Populaire',
  'BforBANK',
  'Orange Bank',
  'Banque Française Mutualiste',
  'Crédit Immobilier de France',
  'Banque Palatine',
  'Cetelem',
  'Federal Finance',
]

export default {
  components: {
    LkLabel,
    LkDateField,
    LkSelect,
    LkChoice,
    HouseIcon,
    KeyIcon,
    BeachIcon,
    LkError,
    LkSlider,
  },
  props: {
    primary: {
      type: Object,
      required: true,
    },
    showResult: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:showResult', 'update:quote', 'update:prospect'],
  data() {
    const rangeAmount = [80000, 1000000]
    const stepAmount = 10000
    const rangeYear = [10, 25]

    return {
      loading: false,
      birth_date: undefined,
      initial_start_date: undefined,
      bank_name: '',
      initial_amount: 500000 * 100,
      initial_duration_in_months: 15 * 12,
      purpose: '',
      bankOptions: bankList.sort((a, b) => a.localeCompare(b)).map((v) => ({ wording: v, value: v })),
      initialAmountSteps: pipe(
        range((rangeAmount[1] - rangeAmount[0]) / stepAmount + 1),
        map((v) => v * stepAmount + rangeAmount[0]),
        toArray
      ),
      yearSteps: pipe(
        range(rangeYear[1] - rangeYear[0] + 1),
        map((v) => v + rangeYear[0]),
        toArray
      ),
      purposeOptions: [
        { value: 'main', wording: this.$t(`PriceSimulator.purpose.main`), icon: 'HouseIcon' },
        { value: 'investment', wording: this.$t(`PriceSimulator.purpose.investment`), icon: 'KeyIcon' },
        { value: 'secondary', wording: this.$t(`PriceSimulator.purpose.secondary`), icon: 'BeachIcon' },
      ],
      subYears,
      addYears,
      errorBirthDate: '',
      errorInitialStartDate: '',
      errorApi: '',
      tooltipPlacement: 'top',
    }
  },
  computed: {
    computedBirthDate: {
      get() {
        return this.birth_date ? apiToDate(this.birth_date) : undefined
      },
      set(v) {
        this.birth_date = v ? dateToApi(v) : undefined
      },
    },
    computedStartDate: {
      get() {
        return this.initial_start_date ? apiToDate(this.initial_start_date) : undefined
      },
      set(v) {
        this.initial_start_date = v ? dateToApi(v) : undefined
      },
    },
    computedAmount: {
      get() {
        return this.initial_amount / 100
      },
      set(v) {
        this.initial_amount = v * 100
      },
    },
    computedMonth: {
      get() {
        return this.initial_duration_in_months / 12
      },
      set(v) {
        this.initial_duration_in_months = v * 12
      },
    },
    mortgageType() {
      return isPast(apiToDate(this.initial_start_date)) ? 'insured_or_reinsured' : 'new'
    },
    isValid() {
      return [
        this.birth_date,
        this.initial_start_date,
        this.bank_name,
        this.initial_amount,
        this.initial_duration_in_months,
        this.purpose,
        !this.errorBirthDate,
        !this.errorInitialStartDate,
      ].every((e) => !!e)
    },
  },
  mounted() {
    this.tooltipPlacement = window.innerWidth < 540 ? 'top' : 'bottom'
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        this.errorApi = ''

        if (!this.isValid) {
          throw new Error('PriceSimulator: Invalid input')
        }
        const prospect = {
          product: 'FR_MORTGAGE',
          package: 'france-mortgage-adaptive-package',
          user: {
            subscriber: {
              is_smoker: false,
              profession_category: 'Cadre / Assimilé Cadre',
              mortgage_quotity: 100,
              birth_date: this.birth_date,
            },
          },
          mortgage: {
            main_asset: {
              bank_name: this.bank_name,
              purpose: this.purpose,
              type: this.mortgageType,
            },
          },
          loan: {
            loan: [
              {
                itt_deductible: 90,
                deferral_in_months: 0,
                initial_start_date: this.initial_start_date,
                initial_duration_in_months: this.initial_duration_in_months,
                initial_amount: this.initial_amount,
                type: 'depreciable',
                deferral_type: 'none',
                rate_percentage: 1,
              },
            ],
          },
        }

        window.LukoTracking.trackEvent({
          id: 'Simulator Simulate Clicked',
          properties: {
            prospectData: prospect,
          },
        })

        const res = await QuoteService.createQuote(prospect)
        this.$emit('update:quote', res)
        this.$emit('update:prospect', prospect)
        this.$emit('update:showResult', true)
      } catch (error) {
        this.$emit('update:prospect', null)
        this.errorApi = this.$t('PriceSimulator.errorApi')
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    onInputerror(errorType, errorName) {
      this[errorName] = this.$t(`PriceSimulator.${errorType}`)
    },
  },
}
</script>
