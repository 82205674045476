<template>
  <section class="-mt-[64px] min-h-[615px] overflow-hidden bg-bluko-800 px-[80px] pt-[64px] md:p-[24px]">
    <Wrapper class="relative flex h-full items-stretch justify-between gap-[30px] pt-[56px] md:flex-col md:gap-0 sm:pt-[24px]">
      <div class="w-1/2 pb-[46px] md:w-full">
        <Breadcrumb v-if="primary.showBreadcrumb" class="mb-[16px]" :is-dark="isDark" :custom-name="primary.title[0].text" />
        <div>
          <Title class="inline !text-white typo-mega-title sm:typo-extra-large-title" :title-params="primary.title" />
          <span
            v-if="primary.tag"
            class="mt-[10px] inline-block -translate-y-[10px] rounded-[20px] bg-gold-500 py-[8px] px-[16px] typo-body sm:hidden"
          >
            {{ primary.tag }}
          </span>
        </div>
        <div v-if="primary.cobrandedLogo && primary.cobrandedLogo.url" class="mt-[16px] flex items-end gap-[8px]">
          <p class="!text-bluko-300 typo-headline">
            {{ $t('with') }}
          </p>
          <PImage :image-object="primary.cobrandedLogo" />
        </div>
        <RichText class="!text-bluko-200 typo-headline [&>p]:mt-[16px]" :text-params="primary.description" />
        <HeroCta class="mt-[40px]" :data="primary" />
        <RichText class="mt-[16px] !text-bluko-300 typo-body" :text-params="primary.subDescription" />
        <ul v-if="items.length" class="mt-[40px]">
          <li v-for="(item, linkIndex) in items" :key="linkIndex" class="flex flex-col gap-[16px]">
            <ArrowLink :link-object="item.link" class="text-white path-white" :link-text="item.text" />
          </li>
        </ul>
      </div>
      <div
        class="absolute right-0 h-[calc(100%-55px)] w-[45%] overflow-y-hidden md:static md:h-[340px] md:w-full md:max-w-none md:overflow-y-visible"
      >
        <div
          class="
            m-0
            h-[85%]
            min-h-[430px]
            w-full
            rounded-bl-[300px]
            border-[20px] border-solid border-bluko-500
            md:mt-[16px] md:mb-[-24px] md:h-[300px] md:min-h-[auto] md:w-full md:max-w-none md:rounded-bl-[150px]
          "
        >
          <PImage
            class="absolute bottom-0 left-[50%] h-[450px] -translate-x-[50%] md:-bottom-[24px] md:h-[250px]"
            :image-object="primary.backgroundImage"
            sizes="sm:100vw"
          />
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import ArrowLink from '@/components/ArrowLink'
import HeroCta from '@/components/HeroCta'

export default {
  name: 'HeroLaunchDarkblue',

  components: {
    HeroCta,
    Breadcrumb,
    ArrowLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => item.text)
    },
  },
}
</script>
