<template>
  <div class="hidden"></div>
</template>

<script>
export default {
  name: 'MetaData',
  props: {
    slice: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    if (this.slice) {
      console.error('MetaData not removed from slice list')
    }
    return {}
  },
}
</script>
