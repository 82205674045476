<template>
  <div class="flex items-center gap-[2px]">
    <Star v-for="(ratio, idx) in ratioArray" :key="idx" :ratio="ratio" :size="size" :color="color" :bg-color="bgColor" />
  </div>
</template>
<script>
import Star from '@/components/Star'

export default {
  components: {
    Star,
  },
  props: {
    score: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 32,
    },
    color: {
      type: String,
      default: 'yellow',
    },
    bgColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    ratioArray() {
      let score = this.score
      return new Array(5).fill(0).map((_) => {
        if (score > 1) {
          score -= 1
          return 1
        }
        const tmp = score
        score = 0
        return tmp
      })
    },
  },
}
</script>
