<template>
  <div class="relative">
    <StarSvg :width="size" :height="size" />
    <div class="absolute top-0 overflow-hidden" :style="{ width: `${ratio * 100}%` }">
      <StarSvg :width="size" :height="size" :class="{ [$style.yellow]: color === 'yellow', [$style.blue]: color === 'blue' }" />
    </div>
  </div>
</template>

<script>
import StarSvg from '@/assets/star/star.svg?inline'
export default {
  components: {
    StarSvg,
  },
  props: {
    color: {
      type: String,
      default: 'yellow', // or blue
    },
    ratio: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 22,
    },
    bgColor: {
      type: String,
      default: 'transparent',
    },
  },
  computed: {
    width() {
      return `-${this.size - this.size * this.ratio}px`
    },
  },
}
</script>

<style lang="scss" module>
.yellow {
  path {
    fill: #e9b857 !important;
  }
}
.blue {
  path {
    fill: #2d50e6;
  }
}
</style>
