<template>
  <ul class="mb-[22px] flex flex-wrap gap-[4px]" :class="[{ [$style.isDark]: isDark }]" data-luko-tracking="Breadcrumb">
    <li v-for="(crumb, crumbIndex) in crumbs" :key="crumbIndex" :class="$style.item">
      <span v-if="isSpan(crumb.url)">{{ crumb.name }}</span>
      <PLink v-else :simple-url="crumb.url">{{ crumb.name }}</PLink>
    </li>
  </ul>
</template>

<script>
import { pipe, map, filter, toArray, dropRight } from '@fxts/core'
const GUIDE_STRINGS = ['guide', 'guia', 'tipps']

export default {
  name: 'Breadcrumb',
  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: this.crumbs
        .map(({ name, url }, index) => {
          return {
            '@type': 'ListItem',
            position: index,
            name,
            item: url,
          }
        })
        .filter((e, index) => e && index),
    }
  },
  props: {
    categoryUid: {
      type: String,
      default: '',
    },
    excludedPath: {
      type: String,
      default: '',
    },
    customName: {
      type: String,
      default: '',
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    categoryName() {
      return Object.values(this.$store.state.adviceCategoryData || {}).find((c) => c.uid === this.categoryUid)?.category_name || ''
    },
    crumbs() {
      const defaultLocale = process.env.CURRENT_DOMAIN
      const locale = this.$i18n.locale
      const params = this.$route.path.split('/').filter((e) => e)
      if (params[0] !== locale) params.unshift(locale)

      let path = '/'
      const crumbs = params.map((param, idx) => {
        path += `${param}/`
        if (path.startsWith(`/${defaultLocale}/`)) {
          path = path.replace(`/${defaultLocale}/`, '/')
        }

        let name = param === locale ? this.$t('home') : param

        if (idx === params.length - 1 && this.customName) {
          name = this.customName
        }

        return {
          name: GUIDE_STRINGS.includes(name) ? this.categoryName : name,
          url: process.env.URL + path.replace(/\/\//g, '/'),
        }
      })

      return pipe(
        crumbs,
        map((crumb) => ({
          name: crumb.name.replace(this.categoryUid || null, this.categoryName),
          url: crumb.url.replace(new RegExp(`(${GUIDE_STRINGS.map((str) => `/${str}/`).join('|')})$`), `/${this.categoryUid}/`),
        })),
        map((crumb) => ({
          ...crumb,
          name: crumb.name.charAt(0).toUpperCase() + crumb.name.slice(1),
        })),
        filter((crumb) => !crumb.url.endsWith('/release/')),
        filter((crumb) => !crumb.url.endsWith('/article/')),
        filter((crumb) => !crumb.url.includes('/p/')),
        dropRight(1),
        toArray
      )
    },
  },

  methods: {
    formatPathName(pathName) {
      if (pathName === this.$i18n.locale) return this.$t('home')
      if (GUIDE_STRINGS.includes(pathName)) {
        return this.categoryName
      }
      return pathName
    },
    isSpan(url) {
      if (this.$i18n.localeProperties.prismicIso === 'en-ES' && url.endsWith('/en/insurance/')) {
        return true
      }
      if (this.$i18n.localeProperties.prismicIso === 'es-ES' && url.endsWith('/seguros/')) {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss" module>
.item {
  @include typo-body;
  position: relative;

  color: $gray-700;
  font-weight: 450;

  &:not(:last-child)::after {
    content: ' /';
  }

  a,
  span {
    color: $gray-700;

    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.isDark {
  .item {
    &:not(:last-child)::after {
      color: $bluko-200;
    }

    a,
    span {
      color: $bluko-200;
    }
  }
}
</style>
