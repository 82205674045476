<template>
  <transition name="fade" mode="">
    <div v-if="message" class="mt-[8px] flex gap-[8px]">
      <WarningIcon class="h-[16px] w-[16px] translate-y-[1px] path-terracota-500" />
      <span class="text-[14px] font-[450] leading-[20px] text-terracota-500">{{ message }}</span>
    </div>
  </transition>
</template>

<script>
import WarningIcon from 'LkIcons/Warning.vue'

export default {
  components: {
    WarningIcon,
  },
  props: {
    message: {
      type: String,
      default: undefined,
    },
  },
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transform: translateY(0);

  transition: all 0.6s;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(4px);

  opacity: 0;
}
</style>
