<template>
  <SliceWrapper :slice="slice">
    <CustomerService2021Q4 v-if="slice.variation === 'customerService2021Q4'" :slice="slice" />
    <CustomerServicePink v-else-if="slice.variation === 'customerServicePink'" :slice="slice" />
    <CustomerServicePink v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import CustomerService2021Q4 from './customerService2021Q4/CustomerService2021Q4.vue'
import CustomerServicePink from './customerServicePink/CustomerServicePink.vue'

export default {
  name: 'Hero',
  components: {
    CustomerService2021Q4,
    CustomerServicePink,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
