<template>
  <div
    v-observe-visibility="{
      callback: onVisible,
      once: true,
    }"
    class="min-h-[490px] bg-bluko-800 py-[80px] px-[32px] sm:px-[24px]"
  >
    <Wrapper class="flex gap-[48px] sm:flex-col">
      <div class="flex flex-col justify-between gap-[40px]">
        <div class="min-w-[300px] max-w-[500px] sm:min-w-[unset] sm:max-w-none">
          <Title
            :title-params="primary.title"
            class="!font-[300] !text-white typo-mega-title sm:text-center sm:typo-extra-large-title [&_strong]:font-[500]"
          />
          <RichText :text-params="primary.description" class="mt-[32px] text-bluko-150 sm:text-center [&_strong]:text-white" />
        </div>
        <div class="mt-[24px] flex items-center gap-[24px] sm:hidden">
          <PImage :image-object="loadImage ? primary.logo : null" lazy class="h-[88px] w-[52px] shrink-0 sm:h-[64px] sm:w-[38px]" />
          <RichText :text-params="primary.logoDescription" class="w-[224px] text-bluko-200 sm:w-[180px]" />
        </div>
      </div>
      <div class="max-w-[640px]">
        <ul>
          <li v-for="(item, idx) in items" :key="idx" class="mb-[32px] last-of-type:mb-0">
            <div
              class="flex cursor-pointer items-center justify-between border-b-[1px] border-solid border-bluko-500 pb-[8px]"
              @click="onActive(idx)"
            >
              <Title :title-params="item.title" class="mb-0 !font-[300] !text-white typo-title" />
              <CrossIcon
                height="20px"
                width="20px"
                class="shrink-0 rotate-45 transition-transform duration-200 path-white"
                :class="{ '!rotate-0': idx === Math.abs(activeItem) - 1 && activeItem > 0 }"
              />
            </div>
            <div ref="bodies" class="h-0 overflow-hidden transition-[height] duration-200">
              <div ref="contents">
                <RichText :text-params="item.description" class="p-0 py-[16px] text-bluko-100 typo-body" />
                <ArrowLink
                  v-if="item.ctaText && item.ctaLink && item.ctaLink.url"
                  :link-object="item.ctaLink"
                  class="text-bluko-100 path-white sm:path-bluko-100"
                  :link-text="item.ctaText"
                />

                <div v-if="item.logo1 && item.logo1.url" class="mt-[24px] flex gap-[24px]">
                  <div class="flex items-center gap-[16px]">
                    <PImage :image-object="item.logo1" class="h-[64px] w-[64px]" />
                    <RichText :text-params="item.logoText1" class="!text-bluko-200 typo-caption" />
                  </div>
                  <div class="flex items-center gap-[16px]">
                    <PImage :image-object="item.logo2" class="h-[64px] w-[64px]" />
                    <RichText :text-params="item.logoText2" class="!text-bluko-200 typo-caption" />
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="mx-auto mt-[56px] flex max-w-[320px] items-center gap-[24px] up-sm:hidden">
          <PImage :image-object="loadImage ? primary.logo : null" lazy class="shrink-0" />
          <RichText :text-params="primary.logoDescription" class="text-bluko-200" />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import CrossIcon from 'LkIcons/Cross.vue'

import ArrowLink from '@/components/ArrowLink'

import { componentLogic } from '../componentLogic.mixins'

export default {
  components: {
    CrossIcon,
    ArrowLink,
  },
  mixins: [componentLogic],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeItem: 0, // negative: close, positive: open
      loadImage: false,
    }
  },
}
</script>
