<template>
  <div class="bg-bluko-50 py-[80px] px-[24px]">
    <Wrapper>
      <div v-if="primary.trustpilotLogo" class="mb-[24px] flex items-center justify-center gap-[20px] sm:flex-col">
        <TrustpilotLogo class="h-[36px] w-[144px]" />
        <TrustpilotScore :score="score.stars" color="#2A8C7C" animation />
      </div>
      <RichText
        :text-params="primary.title"
        class="mx-auto max-w-[840px] text-center !font-[300] typo-mega-title sm:!leading-[48px] sm:typo-extra-large-title"
      />
      <RichText :text-params="primary.description" class="mx-auto mt-[8px] max-w-[840px] text-center text-black text-opacity-60" />
      <div class="mt-[64px] flex flex-wrap justify-evenly gap-[40px] sm:gap-[16px]">
        <div
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          class="flex min-w-[260px] max-w-[400px] flex-1 flex-col items-center p-[24px]"
          data-aos="fade-up"
        >
          <PImage :image-object="item.icon" sizes="xs:100px" class="mb-[16px] h-[48px] w-[48px]" />
          <RichText :text-params="item.iconCaption" :class="$style.iconCaption" />
          <Title :title-params="item.title" class="text-center typo-headline-bold" />
          <RichText :text-params="item.description" class="mt-[8px] text-center text-black text-opacity-60" />
          <ArrowLink class="mt-[16px] text-bluko-500 path-bluko-500" :link-object="item.ctaLink" :link-text="item.ctaText" />
        </div>
      </div>
      <div v-if="primary.ctaText" class="mt-[60px] text-center">
        <LkButton :link-object="primary.ctaLink">{{ primary.ctaText }}</LkButton>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import score from '@@/script/trustpilot/score.json'

import TrustpilotScore from '@/components/TrustpilotScore'
import ArrowLink from '@/components/ArrowLink'
import TrustpilotLogo from '@/assets/images/trustpilot/tp-light-bg-logo.svg?inline'

export default {
  components: {
    TrustpilotScore,
    ArrowLink,
    TrustpilotLogo,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      score,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.iconCaption {
  color: $bluko-300;
  font-size: 16px;
  line-height: 24px;
}
</style>
