<template>
  <SliceWrapper :slice="slice">
    <!-- <SomeComponent v-if="slice.variation === 'variation'" :slice="slice" /> -->
  </SliceWrapper>
</template>

<script>
export default {
  components: {
    // SomeComponent,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
