<template>
  <SliceWrapper class="py-[80px] px-[32px] sm:px-[24px]" :slice="slice" :style="{ backgroundColor: getColor(slice.variation) }">
    <Wrapper class="flex gap-[120px] md:flex-col md:gap-[40px]">
      <aside class="flex flex-1 flex-col">
        <Title :title-params="primary.title" class="!font-[300] typo-mega-title hyphens md:max-w-[580px] [&>strong]:font-[500]" />
        <RichText :text-params="primary.description" class="mt-[32px] md:max-w-[580px]" />
        <div v-if="primary.learnMoreText" class="mt-[64px] flex flex-col md:items-center">
          <PLink :link-object="primary.learnMoreLink" class="font-[18px] text-bluko-500 hover:text-bluko-700 sm:mx-auto">
            {{ primary.learnMoreText }}
          </PLink>
        </div>
      </aside>
      <div class="flex-1">
        <ul>
          <li v-for="(item, idx) in items" :key="idx" class="mb-[32px] last-of-type:mb-0">
            <div
              class="flex cursor-pointer items-center justify-between pb-[8px]"
              :style="{ borderBottom: slice.variation === 'pastelRed' ? '1px solid #F5C9B0' : '1px solid #ACBEF7' }"
              @click="onActive(idx)"
            >
              <div class="flex items-center gap-[16px]">
                <PImage :image-object="item.icon" class="h-[32px] w-[32px]" />
                <Title :title-params="item.title" class="typo-title hyphens" />
              </div>
              <ChevronDownIcon
                class="ml-[8px] flex-shrink-0 transition-transform duration-200 path-bluko-500"
                height="20px"
                width="20px"
                :class="{ 'rotate-180': idx === Math.abs(activeItem) - 1 && activeItem > 0 }"
              />
            </div>
            <div ref="bodies" class="h-0 overflow-hidden transition-all duration-200">
              <div ref="contents">
                <RichText :text-params="item.description" class="py-[16px] !text-gray-700 typo-body" />
              </div>
            </div>
          </li>
        </ul>
        <div
          v-if="primary.infobox_text && primary.infobox_text.length"
          class="mt-[40px] flex gap-[16px] rounded-[8px] bg-bluko-25 py-[24px] px-[32px] text-bluko-500"
        >
          <PImage :image-object="primary.infobox_icon" class="h-[32px] w-[32px] shrink-0" />
          <RichText :text-params="primary.infobox_text" />
        </div>
      </div>
    </Wrapper>
    <div v-if="primary.ctaText" class="mt-[80px] text-center md:mt-[40px]">
      <ArrowButton :link-text="primary.ctaText" :link-object="primary.ctaLink" is-outlined class="sm:!w-full" />
    </div>
  </SliceWrapper>
</template>

<script>
import ChevronDownIcon from 'LkIcons/ChevronDown.vue'

import ArrowButton from '@/components/ArrowButton'

export default {
  name: 'CoverageSection',
  components: {
    ArrowButton,
    ChevronDownIcon,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeItem: 0, // negative: close, positive: open
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    getColor() {
      if (this.slice.variation === 'pastelRed') return '#FFEDE3'
      if (this.slice.variation === 'pastelBlue') return '#EBF5FF'
      return 'white'
    },
    onActive(activeIdx) {
      if (Math.abs(this.activeItem) - 1 !== activeIdx) {
        this.activeItem = activeIdx + 1
      } else {
        this.activeItem = -this.activeItem
      }

      this.$refs.contents.forEach((c, idx) => {
        if (Math.abs(this.activeItem) - 1 === idx) {
          this.activeItem > 0 ? this.open(idx, c.offsetHeight) : this.close(idx)
        } else {
          this.close(idx)
        }
      })
    },
    open(idx, height) {
      this.$refs.bodies[idx].setAttribute('style', `height: ${height}px`)
    },
    close(idx) {
      this.$refs.bodies[idx].setAttribute('style', `height: 0`)
    },
  },
}
</script>
