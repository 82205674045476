<template>
  <div :class="$style.container">
    <Wrapper :class="$style.inner">
      <Title :title-params="primary.title" :class="$style.title" />
      <div>
        <RichText :text-params="primary.description" :class="$style.description" />
        <ArrowLink
          v-if="primary.ctaText"
          class="text-bluko-500 path-bluko-500"
          :link-object="primary.ctaLink"
          :class="$style.cta"
          :link-text="primary.ctaText"
        />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import ArrowLink from '@/components/ArrowLink'
export default {
  components: {
    ArrowLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 130px auto;
  padding: 0 32px;
}

.inner {
  max-width: 840px;
}
.title {
  font-weight: 200;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  @include below(small) {
    font-size: 32px;
    line-height: 45px;
  }
}
.description {
  @include typo-body;

  margin-top: 8px;

  color: $gray-700;
  font-weight: 400;
  text-align: center;
}
.cta {
  display: inline-flex;
  margin-top: 16px;
}
</style>
