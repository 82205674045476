<template>
  <form class="flex w-full items-start gap-[8px] xs:flex-col" @submit.prevent="onSubmit">
    <LkEmailField v-if="isValidWaitlistId" v-model="email" :placeholder="placeholder" :error="errorMessage" @error="onEmailError" />
    <LkButton
      v-if="isValidWaitlistId"
      class="xs:!w-full"
      :loading="isLoading"
      :is-light="isLight"
      :is-white="isWhite"
      :is-outlined="isOutlined"
      type="submit"
    >
      {{ text }}
    </LkButton>
    <span v-else-if="!isValidWaitlistId" class="!text-terracota-500 typo-headline-bold"> WaitlistID should be `Waitlist XXX` format </span>
    <span v-else class="!text-terracota-500 typo-headline-bold"> WaitlistID is missing, please fill this field in prismic </span>
  </form>
</template>
<script>
import { delay } from '@fxts/core'

import LkEmailField from '@/components/Lukomponents/LkEmailField'
export default {
  components: {
    LkEmailField,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    waitlistId: {
      type: String,
      required: true,
    },
    isProductPage: {
      type: Boolean,
      default: false,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasError: false,
      errorMessage: '',
      email: '',
      isLoading: false,
    }
  },
  computed: {
    isValidWaitlistId() {
      return !!this.waitlistId.match(/Waitlist .+/)
    },
  },
  methods: {
    onEmailError(hasError) {
      this.hasError = hasError
    },
    onSubmit() {
      if (this.hasError || !this.email) {
        this.errorMessage = this.$t('Error.invalidEmail')
        return
      }
      this.errorMessage = ''

      this.sendWaitlistToBraze(this.email, this.waitlistId)
    },
    async sendWaitlistToBraze(email, waitlistId) {
      const notification = {
        type: 'success',
        text: this.$t('Waitlist.success'),
      }
      try {
        this.isLoading = true
        await delay(1000)

        window.LukoTracking.trackEvent({
          id: this.isProductPage ? 'Waitlist Product' : waitlistId,
          properties: { subscriber: { email }, brazeCustomEvent: waitlistId },
        })
      } catch (_) {
        notification.type = 'danger'
        notification.text = this.$t('Waitlist.fail')
      } finally {
        this.isLoading = false
        this.$store.commit('notification/SET_NOTIFICATION', notification)
      }
    },
  },
}
</script>
