<template>
  <SliceWrapper :slice="slice">
    <PriceSimulatorDefault :slice="slice" />
  </SliceWrapper>
</template>

<script>
import PriceSimulatorDefault from './default/PriceSimulatorDefault.vue'
export default {
  components: {
    PriceSimulatorDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
