var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"grid gap-[8px]",class:( _obj = {}, _obj['grid-cols-3 xs:grid-cols-2'] = _vm.cards.length === 3, _obj['grid-cols-4 sm:grid-cols-2'] = _vm.cards.length === 4, _obj )},_vm._l((_vm.cards),function(card,index){
var _obj;
return _c('li',{key:index,staticClass:"flex min-h-[104px] items-center justify-center rounded-[8px] bg-white text-center leading-[24px]",class:( _obj = {}, _obj['xs:first-of-type:col-span-2'] = _vm.cards.length === 3, _obj ),attrs:{"data-luko-tracking":card.i18nKey}},[_c('PLink',{staticClass:"flex !w-full flex-col items-center justify-center p-[16px] no-underline",attrs:{"simple-url":_vm.getOnboardingLink(null, {
            type: card.onboardingType,
            queryParams: card.queryParams,
          })},on:{"click":function($event){return _vm.onCardClick(card.product)}}},[_c(card.icon,{tag:"component"}),_vm._v(" "),_c('span',{staticClass:"mt-[10px] font-[450] text-black"},[_vm._v("\n          "+_vm._s(_vm.$t(("HomePage.Cards." + (card.i18nKey))))+"\n        ")])],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }