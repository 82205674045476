import { getCookie } from '@/utils/cookies'
import { COOKIE } from '@/utils/constants'
import { useUrl } from '@/composables/useUrl'

const { appendQuery } = useUrl()

const categoryMap = {
  fr: {
    'pret-immobilier': (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard?$.product=FR_MORTGAGE`,
      text: 'Faire ma simulation',
      onboarding_type: 'FR_MORTGAGE',
    }),
    'projet-immobilier': (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard?$.product=FR_MORTGAGE`,
      text: 'Faire ma simulation',
      onboarding_type: 'FR_MORTGAGE',
    }),
    'garantir-ses-loyers': (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard?$.product=FR_RENT`,
      text: 'Obtenir mon prix',
      onboarding_type: 'FR_RENT',
    }),
    'investissement-locatif': (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard?$.product=FR_HOME&$.home.main_asset.is_main=false&$.home.main_asset.occupant_type=tenant&$.home.main_asset.owner_type=landlord`,
      text: 'Obtenir mon prix',
      onboarding_type: 'FR_LANDLORD',
    }),
    copropriete: () => ({
      url: `https://mri.luko.eu/`,
      text: 'Obtenir mon prix',
      onboarding_type: 'MRI',
    }),
    nvei: (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard/escooter`,
      text: 'Obtenir mon prix',
      onboarding_type: 'FR_ESCOOTER',
    }),
    default: (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard`,
      text: '',
      onboarding_type: 'FR_HOME',
    }),
  },
  de: {
    hundeclub: (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard/pets/`,
      text: 'Zum Angebot',
      onboarding_type: 'DE_PET',
    }),
    katzeclub: (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard/pets/`,
      text: 'Zum Angebot',
      onboarding_type: 'DE_PET',
    }),
    'dog-club': (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard/pets/`,
      text: 'Get covered',
      onboarding_type: 'DE_PET',
    }),
    'bike-club': (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard`,
      text: 'Get covered',
      onboarding_type: 'DE_HOME',
    }),
    'expats-in-germany': (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard`,
      text: 'Get covered',
      onboarding_type: 'DE_HOME',
    }),
    'home-club': (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard`,
      text: 'Get covered',
      onboarding_type: 'DE_HOME',
    }),
    'home-insurance': (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard`,
      text: 'Get covered',
      onboarding_type: 'DE_HOME',
    }),
    default: (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard`,
      text: '',
      onboarding_type: 'DE_HOME',
    }),
  },
  es: {
    default: (lang) => ({
      url: `https://www.luko.eu/${lang}/onboard`,
      text: '',
      onboarding_type: 'ES_HOME',
    }),
  },
}

const productMap = {
  appartement: {
    url: `https://www.luko.eu/fr/onboard?$.product=FR_HOME&$.home.main_asset.type=flat`,
  },
  maison: {
    url: `https://www.luko.eu/fr/onboard?$.product=FR_HOME&$.home.main_asset.type=house`,
  },
  flat: {
    url: `https://www.luko.eu/en/onboard?$.product=FR_HOME&$.home.main_asset.type=flat`,
  },
  house: {
    url: `https://www.luko.eu/en/onboard?$.product=FR_HOME&$.home.main_asset.type=house`,
  },
  'loyer-impaye': {
    url: `https://www.luko.eu/fr/onboard?$.product=FR_RENT`,
  },
  'residence-secondaire': {
    url: `https://www.luko.eu/fr/onboard/?$.product=FR_HOME&$.home.main_asset.is_main=false&$.home.main_asset.owner_type=null`,
  },
  'second-home': {
    url: `https://www.luko.eu/en/onboard/?$.product=FR_HOME&$.home.main_asset.is_main=false&$.home.main_asset.owner_type=null`,
  },
  'proprietaire-non-occupant': {
    url: `https://www.luko.eu/fr/onboard/?$.product=FR_HOME&$.home.main_asset.is_main=false&$.home.main_asset.occupant_type=tenant&$.home.main_asset.owner_type=landlord`,
  },
  landlord: {
    url: `https://www.luko.eu/en/onboard/?$.product=FR_HOME&$.home.main_asset.is_main=false&$.home.main_asset.occupant_type=tenant&$.home.main_asset.owner_type=landlord`,
  },
  'pret-immobilier': {
    url: `https://www.luko.eu/fr/onboard?$.product=FR_MORTGAGE`,
  },
  mortgage: {
    url: `https://www.luko.eu/en/onboard?$.product=FR_MORTGAGE`,
  },
  'trottinette-electrique-nvei': {
    url: `https://www.luko.eu/fr/onboard/escooter`,
  },
  'electric-scooter': {
    url: `https://www.luko.eu/en/onboard/escooter`,
  },
  tierkrankenversicherung: {
    url: `https://www.luko.eu/de/onboard/pets/?$.product=DE_PET_HEALTH`,
  },
  hundehaftpflicht: {
    url: `https://www.luko.eu/de/onboard/pets/?$.product=DE_LIABILITY_DOG&$.pet.main_asset.type=dog`,
  },
  'pet-health': {
    url: `https://www.luko.eu/en/onboard/pets/?$.product=DE_PET_HEALTH`,
  },
  'dog-liability': {
    url: `https://www.luko.eu/en/onboard/pets/?$.product=DE_LIABILITY_DOG&$.pet.main_asset.type=dog`,
  },
}

export const getNavCtaLinkByCategoryUid = (categoryUid, lang) => {
  const link = categoryMap[process.env.CURRENT_DOMAIN]?.[categoryUid]?.(lang) || categoryMap[process.env.CURRENT_DOMAIN].default(lang)

  if (process.env.IS_DEV === 'true') {
    link.url = link.url.replace('www.luko.eu', 'devnoscope.luko.eu')
  }
  return link
}

export const getNavCtaLinkByProductUid = (uid, lang) => {
  const link = productMap[uid] || { url: `https://www.luko.eu/${lang}/onboard` }
  if (process.env.IS_DEV === 'true') {
    link.url = link.url.replace('www.luko.eu', 'devnoscope.luko.eu')
  }
  if (link.url.includes('/pets/')) {
    const cmsCookie = process.client ? getCookie(COOKIE.LK_CMS) : null
    const cmsJson = cmsCookie ? JSON.parse(cmsCookie) : {}
    link.url = appendQuery(link.url, { '$.pet.main_asset.name': cmsJson.petName })
  }
  return link
}
