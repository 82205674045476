<template>
  <SliceWrapper v-observe-visibility="isVisible" :slice="slice">
    <HeroHome2021Q4 v-if="slice.variation === 'home2021Q4'" :slice="slice" />
    <HeroHomeDe v-else-if="slice.variation === 'heroHomeDe'" :slice="slice" />
    <HeroPartner v-else-if="slice.variation === 'partnerHero'" :slice="slice" />
    <HeroProduct2021Q4 v-else-if="slice.variation === 'product2021Q4'" :slice="slice" />
    <HeroProductDe v-else-if="slice.variation === 'productDe'" :slice="slice" :category-name="categoryName" :category-uid="categoryUid" />
    <HeroPartner2021Q4 v-else-if="slice.variation === 'partner2021Q4'" :slice="slice" />
    <HeroTestimonials2021Q4 v-else-if="slice.variation === 'testimonials2021Q4'" :slice="slice" />
    <HeroConfirm2021Q4 v-else-if="slice.variation === 'confirm2021Q4'" :slice="slice" />
    <HeroPressHub2021Q4 v-else-if="slice.variation === 'pressHub2021Q4'" :slice="slice" />
    <HeroAdviceHub2021Q4 v-else-if="slice.variation === 'adviceHub2021Q4'" :slice="slice" />
    <HeroAdviceCategory2021Q4
      v-else-if="slice.variation === 'adviceCategory2021Q4'"
      :slice="slice"
      :category-name="categoryName"
      :category-uid="categoryUid"
    />
    <HeroHome2021Q4 v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import HeroHome2021Q4 from './home2021Q4'
import HeroProduct2021Q4 from './product2021Q4'
import HeroPartner2021Q4 from './partner2021Q4'
import HeroTestimonials2021Q4 from './testimonials2021Q4'
import HeroConfirm2021Q4 from './confirm2021Q4'
import HeroPressHub2021Q4 from './pressHub2021Q4'
import HeroAdviceHub2021Q4 from './adviceHub2021Q4'
import HeroAdviceCategory2021Q4 from './adviceCategory2021Q4'
import HeroHomeDe from './heroHomeDe'
import HeroPartner from './partnerHero'
import HeroProductDe from './productDe'

export default {
  components: {
    HeroHome2021Q4,
    HeroProduct2021Q4,
    HeroPartner2021Q4,
    HeroTestimonials2021Q4,
    HeroConfirm2021Q4,
    HeroPressHub2021Q4,
    HeroAdviceHub2021Q4,
    HeroAdviceCategory2021Q4,
    HeroHomeDe,
    HeroPartner,
    HeroProductDe,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
    categoryName: {
      type: String,
      default: '',
    },
    categoryUid: {
      type: String,
      default: '',
    },
  },
  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>
