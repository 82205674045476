<template>
  <div class="my-[128px] mx-[32px]">
    <Wrapper
      v-observe-visibility="{
        callback: sectionOnScreen,
        intersection: {
          rootMargin: '-30% 0px -30% 0px',
        },
      }"
      class="flex max-w-[1120px]"
    >
      <transition-group name="cross" class="relative flex-1 text-center" tag="div">
        <PImage
          :key="'front' + currentIndex"
          :image-object="currentItem.imageFront"
          class="front absolute bottom-0 left-0 z-10 h-[min(480px,40vw)] w-[min(480px,40vw)]"
          sizes="xs:600px"
          :quality="50"
          lazy
        />
        <PImage
          :key="'back' + currentIndex"
          :image-object="currentItem.imageBack"
          :class="$style.imageBack"
          class="back absolute bottom-0 left-0 h-[min(480px,40vw)] w-[min(480px,40vw)]"
          :quality="80"
          sizes="xs:100px"
          lazy
        />
      </transition-group>
      <div class="mb-[80px] max-w-[452px]">
        <Title class="mb-[36px] !font-[300] typo-mega-title" :title-params="primary.title" />
        <div v-for="(item, index) in items" :key="index" class="mb-[40px] flex cursor-pointer last:mb-0" @click="onClickItem(index)">
          <LinearProgressBar class="mr-[36px] shrink-0" :callback="afterCount" :is-active="index === currentIndex && isVisible" />
          <RichText
            :text-params="item.description"
            :class="{
              [$style.description]: true,
              [$style.active]: currentIndex === index && isVisible,
            }"
          />
        </div>
      </div>
    </Wrapper>
    <Wrapper v-if="primary.ctaText && primary.ctaLink" class="mt-[60px] flex justify-center">
      <ArrowButton :link-object="primary.ctaLink" :target="primary.ctaLink.target" is-outlined :link-text="primary.ctaText" />
    </Wrapper>
  </div>
</template>

<script>
import LinearProgressBar from '@/components/LinearProgressBar'
import ArrowButton from '@/components/ArrowButton'

export default {
  name: 'MediaCarouselDesktop',
  components: {
    LinearProgressBar,
    ArrowButton,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentIndex: 0,
      isVisible: false,
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => item.imageFront.url && item.imageBack.url)
    },
    currentItem() {
      return this.items[this.currentIndex]
    },
  },

  methods: {
    afterCount() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length
    },
    onClickItem(index) {
      this.currentIndex = index
    },
    sectionOnScreen(isVisible) {
      this.isVisible = isVisible
    },
  },
}
</script>

<style lang="scss" module>
.description {
  color: $gray-400;

  h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
  }
  p {
    font-size: 18px;
    line-height: 24px;
  }
  &.active {
    h3 {
      color: black;
    }
    p {
      color: $gray-700;
    }
  }
}
</style>
<style lang="scss" scoped>
/* slide left */
.cross-enter {
  opacity: 0;
  &.front {
    transform: translateX(-100px);
  }
  &.back {
    transform: translateX(100px);
  }
}
.cross-enter-to {
  transform: initial;
  opacity: 1;
}
.cross-leave {
  position: absolute;

  transform: translateX(0);

  opacity: 1;
}
.cross-leave-to {
  position: absolute;

  opacity: 0;
  &.front {
    transform: translateX(100px);
  }
  &.back {
    transform: translateX(-100px);
  }
}
.cross-enter-active {
  transition: all 1s cubic-bezier(0.07, 0.6, 0.47, 1.04);
  transition-delay: 0.7s;
}

.cross-leave-active {
  position: absolute;

  transition: all 1s cubic-bezier(0.07, 0.6, 0.47, 1.04);
}
</style>
