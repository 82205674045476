var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-[128px] mx-[32px]"},[_c('Wrapper',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.sectionOnScreen,
      intersection: {
        rootMargin: '-30% 0px -30% 0px',
      },
    }),expression:"{\n      callback: sectionOnScreen,\n      intersection: {\n        rootMargin: '-30% 0px -30% 0px',\n      },\n    }"}],staticClass:"flex max-w-[1120px]"},[_c('transition-group',{staticClass:"relative flex-1 text-center",attrs:{"name":"cross","tag":"div"}},[_c('PImage',{key:'front' + _vm.currentIndex,staticClass:"front absolute bottom-0 left-0 z-10 h-[min(480px,40vw)] w-[min(480px,40vw)]",attrs:{"image-object":_vm.currentItem.imageFront,"sizes":"xs:600px","quality":50,"lazy":""}}),_vm._v(" "),_c('PImage',{key:'back' + _vm.currentIndex,staticClass:"back absolute bottom-0 left-0 h-[min(480px,40vw)] w-[min(480px,40vw)]",class:_vm.$style.imageBack,attrs:{"image-object":_vm.currentItem.imageBack,"quality":80,"sizes":"xs:100px","lazy":""}})],1),_vm._v(" "),_c('div',{staticClass:"mb-[80px] max-w-[452px]"},[_c('Title',{staticClass:"mb-[36px] !font-[300] typo-mega-title",attrs:{"title-params":_vm.primary.title}}),_vm._v(" "),_vm._l((_vm.items),function(item,index){
    var _obj;
return _c('div',{key:index,staticClass:"mb-[40px] flex cursor-pointer last:mb-0",on:{"click":function($event){return _vm.onClickItem(index)}}},[_c('LinearProgressBar',{staticClass:"mr-[36px] shrink-0",attrs:{"callback":_vm.afterCount,"is-active":index === _vm.currentIndex && _vm.isVisible}}),_vm._v(" "),_c('RichText',{class:( _obj = {}, _obj[_vm.$style.description] = true, _obj[_vm.$style.active] = _vm.currentIndex === index && _vm.isVisible, _obj ),attrs:{"text-params":item.description}})],1)})],2)],1),_vm._v(" "),(_vm.primary.ctaText && _vm.primary.ctaLink)?_c('Wrapper',{staticClass:"mt-[60px] flex justify-center"},[_c('ArrowButton',{attrs:{"link-object":_vm.primary.ctaLink,"target":_vm.primary.ctaLink.target,"is-outlined":"","link-text":_vm.primary.ctaText}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }