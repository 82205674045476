<template>
  <Transition name="fade">
    <div v-if="displayed">
      <div :class="$style.bubbleWrapper">
        <div :class="$style.bubbleShadow">
          <div :class="$style.bubble"></div>
        </div>
      </div>

      <div :class="$style.wrapper">
        <div :class="$style.header">
          <div
            :class="{
              [$style.chevron]: true,
              [$style.disabled]: isMinMonthViewed || monthpickerPrevDisabled,
            }"
            @click="prev"
          >
            <ChevronFarLeftIcon
              class="h-[24px] w-[24px]"
              :class="{ ['path-gray-400']: disableLeftChevron, ['path-bluko-500']: !disableLeftChevron }"
            />
          </div>
          <span :class="$style.headerText" @click="toggleMonthpicker">
            {{ monthpickerOpened ? firstVisibleYear : formatMonth(currentlyViewed) }}
            <ChevronDownIcon class="h-[16px] w-[16px] path-gray-400" :class="[$style.monthSwitcher, monthpickerOpened && $style.reversed]" />
          </span>
          <div
            :class="{
              [$style.chevron]: true,
              [$style.disabled]: isMaxMonthViewed || monthpickerNextDisabled,
            }"
            @click="next"
          >
            <ChevronFarRightIcon
              class="h-[24px] w-[24px]"
              :class="{ ['path-gray-400']: disableRightChevron, ['path-bluko-500']: !disableRightChevron }"
            />
          </div>
        </div>
        <div :class="$style.main">
          <div ref="monthpicker" :class="[$style.monthpicker, !monthpickerOpened && $style.invisible]">
            <div
              v-for="(month, index) in monthsToScroll"
              :id="month === currentlyViewed ? 'activeMonth' : undefined"
              :key="`month-${index}`"
              :class="[$style.monthInList, month === currentlyViewed && $style.active]"
              :data-year="month.getFullYear()"
              @click="pickMonth(month)"
            >
              {{ formatMonth(month) }}
            </div>
          </div>
          <div :class="[$style.days, monthpickerOpened && $style.invisible]">
            <div v-for="day in weekDays" :key="`day-${day}`" :class="$style.day">{{ day }}</div>
          </div>
          <div :class="[$style.dates, monthpickerOpened && $style.invisible]">
            <div v-for="date in visibleFromPrevMonth" :key="`prevDate-${date}`" :class="[$style.item, $style.disabled]">
              <div :class="$style.num">
                <span>{{ date }}</span>
              </div>
            </div>
            <div
              v-for="date in currentlyViewedMonthDates"
              :key="`curDate-${date}`"
              :class="[$style.item, outOfRange(date) && $style.disabled]"
              @click="pickDate(date)"
            >
              <div
                :class="{
                  [$style.num]: true,
                  [$style.active]: pickedDayIsInCurrentMonth && pickedDayDate === date,
                }"
              >
                <span>{{ date }}</span>
              </div>
            </div>
            <div v-for="date in visibleFromNextMonth" :key="`nextDate-${date}`" :class="[$style.item, $style.disabled]">
              <div :class="$style.num">
                <span>{{ date }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { addDays, addMonths, differenceInMonths, endOfMonth, isAfter, isBefore, max, min, startOfMonth } from 'date-fns'
import ChevronFarLeftIcon from 'LkIcons/ChevronFarLeft.vue'
import ChevronFarRightIcon from 'LkIcons/ChevronFarRight.vue'
import ChevronDownIcon from 'LkIcons/ChevronDown.vue'

import { useDateTime } from '@/composables/useDateTime'

const { constructUTC } = useDateTime()
const MONTHPICKER_ITEM_HEIGHT = 40
const SCROLL_THRESHOLD = 700
const MONTHS_IN_A_YEAR = 12
const DEFAULT_SCROLL_LENGTH = 48

export default {
  components: {
    ChevronFarLeftIcon,
    ChevronFarRightIcon,
    ChevronDownIcon,
  },
  props: {
    displayed: { type: Boolean, default: false },
    value: { type: Date, default: undefined },
    min: { type: Date, default: undefined },
    max: { type: Date, default: undefined },
  },
  data() {
    const currentlyViewed = this.value || this.getDefaultDate()
    return {
      currentlyViewed: this.value || this.getDefaultDate(),
      monthpickerOpened: false,
      monthsInScrollBefore: DEFAULT_SCROLL_LENGTH,
      monthsInScrollAfter: DEFAULT_SCROLL_LENGTH,
      firstVisibleYear: currentlyViewed.getFullYear(),
    }
  },
  computed: {
    isMinMonthViewed() {
      return this.min && startOfMonth(this.currentlyViewed) <= startOfMonth(this.min)
    },
    isMaxMonthViewed() {
      return this.max && endOfMonth(this.currentlyViewed) >= endOfMonth(this.max)
    },
    disableLeftChevron() {
      return this.isMinMonthViewed || this.monthpickerPrevDisabled
    },
    disableRightChevron() {
      return this.isMaxMonthViewed || this.monthpickerNextDisabled
    },
    firstDayOfMonth() {
      return startOfMonth(this.currentlyViewed)
    },
    lastDayOfMonth() {
      return endOfMonth(this.currentlyViewed)
    },
    currentlyViewedMonthDates() {
      return Array.from(Array.from({ length: endOfMonth(this.currentlyViewed).getDate() }, (_, i) => i + 1))
    },
    pickedDayIsInCurrentMonth() {
      return this.value?.getFullYear() === this.currentlyViewed.getFullYear() && this.value?.getMonth() === this.currentlyViewed.getMonth()
    },
    pickedDayDate() {
      return this.value?.getDate()
    },
    weekDays() {
      const justMonday = constructUTC(2017, 0, 2)
      const arr = []
      for (let i = 0; i < 7; i++) {
        arr.push(justMonday.toLocaleDateString(this.$i18n.locale, { weekday: 'short' }).replace('.', ''))
        justMonday.setDate(justMonday.getDate() + 1)
      }
      return arr
    },
    visibleFromPrevMonth() {
      const firstDay = this.firstDayOfMonth.getDay()
      const res = []
      if (firstDay === 1) {
        return res
      }
      const daysToAdd = (firstDay + 6) % 7 // getDay returns 0 – Sunday to 6 – Saturday
      const lastDayOfPrevMonth = addDays(this.firstDayOfMonth, -1).getDate()
      for (let i = lastDayOfPrevMonth; i > lastDayOfPrevMonth - daysToAdd; i--) {
        res.unshift(i)
      }
      return res
    },
    visibleFromNextMonth() {
      const lastDay = this.lastDayOfMonth.getDay()
      const res = []
      if (lastDay === 0) {
        return res
      }
      for (let i = 1; i <= 7 - lastDay; i++) {
        res.push(i)
      }
      return res
    },
    monthpickerPrevDisabled() {
      return this.monthpickerOpened && this.min && this.firstVisibleYear <= this.min.getFullYear()
    },
    monthpickerNextDisabled() {
      return this.monthpickerOpened && this.max && this.firstVisibleYear >= this.max.getFullYear()
    },
    monthsToScroll() {
      const arr = [this.currentlyViewed]

      for (let i = 1; i <= this.monthsInScrollBefore; i++) {
        const m = addMonths(this.currentlyViewed, -i)
        if (this.min && isBefore(m, startOfMonth(this.min))) {
          break
        }
        arr.unshift(m)
      }

      for (let i = 1; i <= this.monthsInScrollAfter; i++) {
        const m = addMonths(this.currentlyViewed, i)
        if (this.max && isAfter(m, endOfMonth(this.max))) {
          break
        }
        arr.push(m)
      }

      return arr
    },
    disableInCurrentMonthBefore() {
      if (!this.min || !this.isMinMonthViewed) {
        return -1
      }
      return this.min.getDate()
    },
    disableInCurrentMonthAfter() {
      if (!this.max || !this.isMaxMonthViewed) {
        return 32
      }
      return this.max.getDate()
    },
  },
  watch: {
    monthpickerOpened(newVal) {
      if (newVal) {
        this.$refs.monthpicker?.addEventListener('scroll', this.monthpickerScrolled)
      } else {
        this.$refs.monthpicker?.removeEventListener('scroll', this.monthpickerScrolled)
      }
    },
    displayed(newVal) {
      if (!newVal) {
        this.monthpickerOpened = false
      }
    },
    value(newVal) {
      if (newVal) {
        this.currentlyViewed = newVal
      }
    },
  },
  methods: {
    getDefaultDate() {
      let res = new Date()
      if (this.min) {
        res = max([this.min, res])
      }
      if (this.max) {
        res = min([this.max, res])
      }
      return res
    },
    formatMonth(dt) {
      return `${dt.toLocaleDateString(this.$i18n.locale, { month: 'long' })}, ${dt.toLocaleDateString(this.$i18n.locale, { year: 'numeric' })}`
    },
    toggleMonthpicker() {
      this.monthpickerOpened = !this.monthpickerOpened
      if (this.monthpickerOpened && this.$refs.monthpicker) {
        const activeMonth = document.getElementById('activeMonth')
        if (activeMonth) {
          // scroll the list so the active month is in the middle
          this.$refs.monthpicker.scrollTop = activeMonth.offsetTop - this.$refs.monthpicker.clientHeight / 2 + MONTHPICKER_ITEM_HEIGHT / 2
        } else {
          this.$refs.monthpicker.scrollTop = this.$refs.monthpicker.scrollHeight * 0.5 - MONTHPICKER_ITEM_HEIGHT / 2
        }
      }
    },
    prev() {
      if (this.isMinMonthViewed || this.monthpickerPrevDisabled) {
        return
      }
      if (this.monthpickerOpened && this.$refs.monthpicker) {
        const numOfMonthsToAdd = this.min ? Math.min(MONTHS_IN_A_YEAR, differenceInMonths(this.monthsToScroll[0], this.min)) : MONTHS_IN_A_YEAR
        if (this.currentlyViewed.getFullYear() >= this.firstVisibleYear) {
          this.monthsInScrollBefore += numOfMonthsToAdd
          this.$refs.monthpicker.scrollBy({
            top: numOfMonthsToAdd * MONTHPICKER_ITEM_HEIGHT,
            behavior: 'auto',
          })
        }

        const scrollLen =
          this.$refs.monthpicker.scrollTop / (MONTHS_IN_A_YEAR * MONTHPICKER_ITEM_HEIGHT) >= 2
            ? MONTHS_IN_A_YEAR * MONTHPICKER_ITEM_HEIGHT
            : this.$refs.monthpicker.scrollTop
        this.$refs.monthpicker.scrollBy({ top: -scrollLen, behavior: 'smooth' })
      } else {
        this.currentlyViewed = addMonths(this.currentlyViewed, -1)
      }
    },
    next() {
      if (this.isMaxMonthViewed || this.monthpickerNextDisabled) {
        return
      }
      if (this.monthpickerOpened && this.$refs.monthpicker) {
        const numOfMonthsToAdd = this.max
          ? Math.min(MONTHS_IN_A_YEAR, differenceInMonths(this.max, this.monthsToScroll[this.monthsToScroll.length - 1]))
          : MONTHS_IN_A_YEAR

        const scrollLen =
          (this.$refs.monthpicker.scrollHeight - this.$refs.monthpicker.scrollTop) / (MONTHS_IN_A_YEAR * MONTHPICKER_ITEM_HEIGHT) >= 2
            ? MONTHS_IN_A_YEAR * MONTHPICKER_ITEM_HEIGHT
            : this.$refs.monthpicker.scrollHeight - this.$refs.monthpicker.scrollTop
        this.$refs.monthpicker.scrollBy({ top: scrollLen, behavior: 'smooth' })

        if (this.currentlyViewed.getFullYear() <= this.firstVisibleYear) {
          this.monthsInScrollAfter += numOfMonthsToAdd
        }
      } else {
        this.currentlyViewed = addMonths(this.currentlyViewed, 1)
      }
    },
    pickDate(date) {
      if (!this.outOfRange(date)) {
        this.$emit('input', constructUTC(this.currentlyViewed.getFullYear(), this.currentlyViewed.getMonth(), date))
      }
    },
    pickMonth(dt) {
      this.currentlyViewed = dt
      this.monthpickerOpened = false
      this.monthsInScrollBefore = DEFAULT_SCROLL_LENGTH
      this.monthsInScrollAfter = DEFAULT_SCROLL_LENGTH
    },
    updateFirstVisibleYear(pickerPosition) {
      const firstVisibleMonth = document.elementFromPoint(
        pickerPosition.left + pickerPosition.width / 2,
        pickerPosition.top + MONTHPICKER_ITEM_HEIGHT - 1
      )
      const yearAttribute = firstVisibleMonth?.getAttribute('data-year')
      this.firstVisibleYear = yearAttribute ? parseInt(yearAttribute) : this.currentlyViewed.getFullYear()
    },
    monthpickerScrolled() {
      if (this.$refs.monthpicker) {
        const pos = this.$refs.monthpicker.getBoundingClientRect()
        this.updateFirstVisibleYear(pos)
        if (this.$refs.monthpicker.scrollTop <= SCROLL_THRESHOLD) {
          const numOfMonthsToAdd = this.min
            ? Math.min(DEFAULT_SCROLL_LENGTH, differenceInMonths(this.monthsToScroll[0], this.min))
            : DEFAULT_SCROLL_LENGTH
          this.monthsInScrollBefore += numOfMonthsToAdd
          this.$refs.monthpicker.scrollTop += numOfMonthsToAdd * MONTHPICKER_ITEM_HEIGHT
        }

        if (this.$refs.monthpicker.scrollTop >= this.$refs.monthpicker.scrollHeight - this.$refs.monthpicker.offsetHeight - SCROLL_THRESHOLD) {
          const numOfMonthsToAdd = this.max
            ? Math.min(DEFAULT_SCROLL_LENGTH, differenceInMonths(this.max, this.monthsToScroll[this.monthsToScroll.length - 1]))
            : DEFAULT_SCROLL_LENGTH
          this.monthsInScrollAfter += numOfMonthsToAdd
        }
      }
    },
    outOfRange(date) {
      return date < this.disableInCurrentMonthBefore || date > this.disableInCurrentMonthAfter
    },
  },
}
</script>
<style lang="scss" module>
.bubbleWrapper {
  position: relative;

  width: 320px;
  height: 10px;
  overflow: hidden;

  .bubbleShadow {
    position: absolute;
    top: 2px;
    left: 148px;

    filter: drop-shadow(0px 2px 16px rgba(44, 35, 2, 0.12));

    .bubble {
      width: 24px;
      height: 8px;

      background-color: #fff;
      -webkit-mask-image: url('./assets/CalendarBubble.svg');
      mask-image: url('./assets/CalendarBubble.svg');
    }
  }
}
.wrapper {
  box-sizing: border-box;
  width: 320px;
  padding-top: 24px;
  border-radius: 8px;

  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(44, 35, 2, 0.12);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    margin-bottom: 24px;
    padding: 0 24px;

    user-select: none;

    .headerText {
      @include typo-body_bold;
      position: relative;

      padding: 0 4px;

      text-transform: uppercase;

      cursor: pointer;

      & > .monthSwitcher {
        position: absolute;
        top: 50%;
        right: -14px;

        transition: all 200ms;

        &:not(.reversed) {
          transform: translateY(-50%);
        }

        &.reversed {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      @supports (-moz-appearance: none) {
        .monthSwitcher {
          top: calc(50% - 2px);

          transform: none;
        }
      }
    }

    & > .chevron {
      &:hover:not(.disabled) {
        cursor: pointer;
      }
    }
  }

  .main {
    position: relative;

    .monthpicker {
      position: absolute;
      top: 0;
      left: 0;

      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 0 24px;
      overflow: scroll;

      text-align: center;

      .monthInList {
        @include typo-body;
        box-sizing: border-box;
        height: 40px; // MONTHPICKER_ITEM_HEIGHT
        padding: 8px 24px;
        border-radius: 8px;

        text-transform: capitalize;

        cursor: pointer;

        &:hover:not(.active) {
          background-color: $gray-100;
        }

        &.active {
          color: $white;

          background-color: $bluko-500;
        }
      }
    }

    .days {
      display: flex;
      gap: 8px;
      align-items: stretch;
      justify-content: space-around;
      margin-bottom: 8px;
      padding: 0 24px;

      user-select: none;

      .day {
        @include typo-caption;
        width: 32px;

        color: $gray-500;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .dates {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: stretch;
      max-width: 320px;
      padding: 0 24px 24px;

      .item {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 8px;
        overflow: hidden;

        color: black;

        cursor: pointer;

        user-select: none;

        &.disabled {
          color: $gray-400;

          cursor: default;
        }

        & > div {
          @include typo-body;
          display: inline-flex;
          width: 100%;
          height: 100%;

          & > span,
          & > svg {
            margin: auto;
          }
        }

        &:not(.disabled) > .num {
          &:hover:not(.active) {
            background-color: $gray-100;
          }

          &.active {
            color: $white;

            background-color: $bluko-500;
            cursor: default;
          }
        }
      }
    }
  }
}

.invisible {
  visibility: hidden;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease 25ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
