<template>
  <div>
    <HeroDesktop :slice="slice" class="sm:hidden" />
    <HeroMobile :slice="slice" class="hidden sm:block" />
  </div>
</template>

<script>
import HeroDesktop from './components/HeroDesktop.vue'
import HeroMobile from './components/HeroMobile.vue'

export default {
  name: 'HeroHome2021Q4',

  components: {
    HeroDesktop,
    HeroMobile,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
