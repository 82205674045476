<template>
  <SliceWrapper
    v-observe-visibility="{
      callback: onVisible,
      intersection: {
        rootMargin: '100% 0px 100% 0px',
      },
      once: true,
    }"
    class="m-0 mx-auto"
    :slice="slice"
  >
    <Wrapper ref="inner" class="h-full bg-[length:138px_196px] bg-right-bottom bg-no-repeat px-[24px] py-[160px] sm:!bg-none sm:px-0 sm:py-[80px]">
      <Title
        :title-params="primary.title"
        class="m-0 mx-auto mb-[10px] max-w-[950px] px-[24px] text-center !font-[300] typo-mega-title sm:text-[40px] sm:leading-[48px]"
      />
      <RichText :text-params="primary.description" class="m-0 mx-auto max-w-[950px] px-[24px] text-center text-gray-700 typo-body" />
      <div v-if="load" class="relative mt-[64px] min-h-[180px]">
        <!-- Arrow Button -->
        <div class="absolute left-0 z-10 h-full w-[64px] bg-gradient-to-r from-white to-transparent" :class="{ ['invisible']: hideArrow }">
          <button
            class="relative top-[50%] left-[24px] flex h-[40px] w-[40px] -translate-y-1/2 items-center justify-center rounded-[50%] bg-white"
            @click="() => $refs.carousel.prev()"
          >
            <ChevronLeftIcon class="path-bluko-500" />
          </button>
        </div>
        <div class="absolute right-0 z-10 h-full w-[64px] bg-gradient-to-l from-white to-transparent" :class="{ ['invisible']: hideArrow }">
          <button
            class="relative top-[50%] right-[24px] flex h-[40px] w-[40px] -translate-y-1/2 items-center justify-center rounded-[50%] bg-white"
            @click="() => $refs.carousel.next()"
          >
            <ChevronRightIcon class="path-bluko-500" />
          </button>
        </div>

        <VueSlickCarousel
          ref="carousel"
          class="min-h-[180px]"
          v-bind="slickOptions"
          :class="{ ['invisible']: !areResonsiveSettingsApplied }"
          @init="onInit"
        >
          <div v-for="(item, itemIndex) in items" :key="itemIndex">
            <div class="mx-[5%] flex min-w-[150px] flex-col items-center rounded-[8px] bg-orange-50 p-[16px] sm:p-[20px] sm:pb-[24px]">
              <PImage :image-object="item.profileImage" sizes="xs:144px" class="h-[72px] w-[72px] rounded-[50%]" />
              <RichText :text-params="item.name" class="mt-[16px]" />
              <RichText :text-params="item.description" class="text-center text-[14px] leading-[18px] text-gray-700" />
            </div>
          </div>
        </VueSlickCarousel>
      </div>

      <div v-if="primary.ctaText" class="mt-[48px] text-center">
        <ArrowButton is-outlined :link-text="primary.ctaText" data-luko-tracking="ShowIntercom" @click="showIntercom" />
      </div>
    </Wrapper>
  </SliceWrapper>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import ChevronLeftIcon from 'LkIcons/ChevronLeft.vue'
import ChevronRightIcon from 'LkIcons/ChevronRight.vue'
import VueSlickCarousel from 'vue-slick-carousel'

import ArrowButton from '@/components/ArrowButton'
import { showIntercom } from '@/mixins/showIntercom'

export default {
  name: 'CustomerServiceCarousel',
  components: {
    ArrowButton,
    VueSlickCarousel,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  mixins: [showIntercom],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      responsiveSettings: [
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '24%',
          },
        },
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 2,
            centerMode: true,
            centerPadding: '15%',
          },
        },
      ],
      areResonsiveSettingsApplied: false,
      hideArrow: true,
      slickOptions: {
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: false,
        slidesToShow: this.slice.items.length < 5 ? this.slice.items.length : 5,
        slidesToScroll: 1,
        responsive: [],
        lazyLoad: 'ondemand',
      },
      activeSlide: 0,
      load: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    beforeChange(oldIndex, newIndex) {
      this.activeSlide = newIndex
    },
    isActive(index) {
      return this.activeSlide === index
    },
    initCarousel() {
      this.activeSlide = 0
    },
    onInit() {
      if (this.areResonsiveSettingsApplied === false) {
        this.slickOptions.responsive = this.responsiveSettings
        this.areResonsiveSettingsApplied = true
      }
      this.setupArrow()
    },
    setupArrow() {
      if (process.client) {
        const slidesToShow =
          this.responsiveSettings.find((s) => s.breakpoint >= window.innerWidth)?.settings.slidesToShow || this.slickOptions.slidesToShow
        this.hideArrow = slidesToShow >= this.items.length
      }
    },
    onVisible(visible) {
      if (!this.load && visible) {
        this.load = true
      }
    },
  },
}
</script>
