<template>
  <SliceWrapper v-observe-visibility="isVisible" :slice="slice">
    <HeroPriceDefault :slice="slice" />
  </SliceWrapper>
</template>

<script>
import HeroPriceDefault from './default'

export default {
  name: 'HeroPrice',
  components: {
    HeroPriceDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>
