<template>
  <SliceWrapper :slice="slice" class="pt-[40px]">
    <div class="mb-[20px] flex items-center gap-[16px]">
      <PImage :image-object="primary.icon" sizes="xs:64px" class="h-[32px] w-[32px]" />
      <Title :title-params="primary.title" class="text-[24px] font-[450] leading-[30px]" />
    </div>
    <ul>
      <li v-for="(item, idx) in items" :key="idx" class="mb-[32px] last-of-type:mb-0">
        <Dropdown>
          <template #head>
            <Title :title-params="item.title" class="text-[20px] font-[400] leading-[32px]" />
            <ChevronDownIcon
              height="24px"
              width="24px"
              class="shrink-0 transition-transform duration-200"
              :class="{ '!rotate-180': idx === Math.abs(activeItem) - 1 && activeItem > 0 }"
            />
          </template>
          <template #body>
            <RichText :text-params="item.description" :class="$style.dropdownContent" />
          </template>
        </Dropdown>
      </li>
    </ul>
  </SliceWrapper>
</template>

<script>
import ChevronDownIcon from 'LkIcons/ChevronDown.vue'

import Dropdown from './components/Dropdown.vue'
export default {
  components: {
    ChevronDownIcon,
    Dropdown,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeItem: 0, // negative: close, positive: open
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style module lang="scss">
.dropdownContent {
  color: $gray-600;
  font-weight: 450;
  h5 {
    @include typo-body;
    padding-top: 16px;

    color: $gray-1000;
    font-weight: 500;
  }
  p {
    @include typo-body;
    padding-top: 8px;

    font-weight: 450;
  }
  li {
    display: list-item;
    padding-top: 8px;
  }
  ul {
    li:before {
      padding-right: 16px;

      color: $gray-400;

      content: '•';
    }
  }
}
</style>
