<template>
  <div v-if="data.ctaType && data.ctaType !== 'none' && data.ctaType !== 'None'" class="min-h-[40px]" data-luko-tracking="HeroCtaType">
    <AddressForm
      v-if="data.ctaType === 'Address'"
      :data-luko-tracking="data.ctaType"
      :placeholder="data.inputPlaceholder"
      :button-text="data.ctaText"
      class="mt-[24px] sm:mt-[40px]"
      :is-light="finalIsLight"
      :is-white="finalIsWhite"
      :is-outlined="finalIsOutlined"
      :query-params="queryParams"
    />
    <CardsCta v-else-if="data.ctaType === 'Cards'" :data-luko-tracking="data.ctaType" />
    <Waitlist
      v-else-if="data.ctaType === 'Waitlist'"
      :data-luko-tracking="data.ctaType"
      :placeholder="data.inputPlaceholder"
      :text="data.ctaText"
      :waitlist-id="data.waitlistId"
      :is-product-page="isProductPage"
      :is-light="finalIsLight"
      :is-white="finalIsWhite"
      :is-outlined="finalIsOutlined"
    />
    <LkButton
      v-else-if="data.ctaType === 'Button' && data.ctaText"
      class="md:!w-full"
      :link-object="data.ctaLink && data.ctaLink.url ? data.ctaLink : { url: getOnboardingLink() }"
      data-cta="calendly"
      :is-light="finalIsLight"
      :is-white="finalIsWhite"
      :is-outlined="finalIsOutlined"
    >
      {{ data.ctaText }}
    </LkButton>
    <GliForm
      v-else-if="data.ctaType === 'GLI'"
      :data-luko-tracking="data.ctaType"
      :placeholder="data.inputPlaceholder"
      :button-text="data.ctaText"
      :query-params="queryParams"
      :is-light="finalIsLight"
      :is-white="finalIsWhite"
      :is-outlined="finalIsOutlined"
    />
    <PetNameForm
      v-else-if="data.ctaType === 'PetName'"
      :data-luko-tracking="data.ctaType"
      :placeholder="data.inputPlaceholder"
      :text="data.ctaText"
      :is-light="finalIsLight"
      :is-white="finalIsWhite"
      :is-outlined="finalIsOutlined"
    />
    <EmailForm
      v-else-if="data.ctaType === 'Email'"
      :data-luko-tracking="data.ctaType"
      :placeholder="data.inputPlaceholder"
      :text="data.ctaText"
      :is-light="finalIsLight"
      :is-white="finalIsWhite"
      :is-outlined="finalIsOutlined"
      :link-object="data.ctaLink"
    />
    <div v-else-if="data.ctaType === 'App' && apps.length > 0" :class="$style.appsContainer" :data-luko-tracking="data.ctaType">
      <PLink v-for="(app, appIndex) in apps" :key="appIndex" :link-object="app.link" target="_blank" rel="noopener">
        <PImage :image-object="app.image" :class="$style.appImage" sizes="xs:320px" />
      </PLink>
    </div>
  </div>
</template>

<script>
import AddressForm from '@/components/AddressForm'
import GliForm from '@/components/GliForm'
import { getOnboardingLink } from '@/mixins/getOnboardingLink'

import CardsCta from './components/CardsCta.vue'
import Waitlist from './components/Waitlist.vue'
import PetNameForm from './components/PetNameForm.vue'
import EmailForm from './components/EmailForm.vue'

export default {
  components: {
    AddressForm,
    CardsCta,
    Waitlist,
    GliForm,
    PetNameForm,
    EmailForm,
  },

  mixins: [getOnboardingLink],

  props: {
    data: {
      type: Object,
      required: true,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    isProductPage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    finalIsLight() {
      return this.isLight || this.data.buttonStyle === 'light'
    },
    finalIsWhite() {
      return this.isWhite || this.data.buttonStyle === 'outlined-white'
    },
    finalIsOutlined() {
      return ['outlined-primary', 'outlined-white'].includes(this.data.buttonStyle)
    },
    queryParams() {
      if (this.link && this.link.url) {
        return Array.from(new URL(this.link.url).searchParams.entries())
      }
      return []
    },
    apps() {
      return [
        {
          image: this.data.imageAppStore,
          link: this.data.ctaAppStore,
        },
        {
          image: this.data.imageGooglePlay,
          link: this.data.ctaGooglePlay,
        },
      ]
    },
  },
}
</script>
<style lang="scss" module>
.appsContainer {
  display: flex;
  gap: 24px;

  @include below(small) {
    justify-content: center;
  }
}

.appImage {
  max-width: 152px;
  height: 48px;

  @include below(small) {
    width: 40vw;
    max-width: 152px;
    height: auto;
    min-height: initial;
  }
}
</style>
