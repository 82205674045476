<template>
  <form class="flex w-full gap-[8px] sm:flex-col" @submit.prevent="submitAddress">
    <AddressBar v-model="addressObj" class="flex-1" :placeholder="placeholder" :is-required="isRequired" />
    <LkButton class="sm:!w-full" type="submit" :is-light="isLight" :is-outlined="isOutlined" :is-white="isWhite" data-luko-tracking="Onboarding">
      {{ buttonText }}
    </LkButton>
  </form>
</template>

<script>
import { getOnboardingLink } from '@/mixins/getOnboardingLink'
import AddressBar from '@/components/AddressBar'
import { useUrl } from '@/composables/useUrl'
import { useAddress } from '@/composables/useAddress'

const { forwardUrl } = useUrl()
const { getAddressMain } = useAddress()

export default {
  name: 'AddressForm',

  components: {
    AddressBar,
  },

  mixins: [getOnboardingLink],

  props: {
    buttonText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    queryParams: {
      type: Array,
      default: () => [],
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      addressObj: {},
    }
  },

  computed: {
    isAddressValid() {
      return (
        this.addressObj &&
        this.addressObj.street &&
        this.addressObj.postcode &&
        this.addressObj.city &&
        this.addressObj.formatted &&
        this.addressObj.country &&
        ['fr', 'es', 'de'].includes(this.addressObj.country_code)
      )
    },

    isMri() {
      return !!this.$store.state.navCta.url?.includes('https://mri')
    },
  },

  methods: {
    onInputAddressObj(value) {
      this.addressObj = value
    },

    submitAddress() {
      window.location.href = forwardUrl(
        this.getOnboardingLink(
          this.isAddressValid
            ? {
                address_main: getAddressMain(this.addressObj),
                city: this.addressObj.city,
                country: this.addressObj.country,
                country_code: this.addressObj.country_code.toUpperCase(),
                postcode: this.addressObj.postcode,
                address_longitude: this.addressObj.lon,
                address_latitude: this.addressObj.lat,
              }
            : null,
          { queryParams: this.queryParams, type: this.isMri ? 'mri' : 'home' }
        )
      )
    },
  },
}
</script>
<style lang="scss" module>
.addressInput {
  border-color: $bluko-600;

  color: white;

  background-color: $bluko-700;

  &::placeholder {
    color: white;
  }

  &:hover {
    border-color: $bluko-300;
  }

  &:focus {
    border-color: $bluko-300;

    box-shadow: 0 0 0 4px $bluko-500;
  }
}
</style>
