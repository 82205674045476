<template>
  <SliceWrapper :slice="slice">
    <GivebackSchemaWithImage v-if="slice.variation === 'givebackSchemaWithImage'" :slice="slice" />
    <GivebackSchema2021Q4 v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import GivebackSchema2021Q4 from './givebackSchema2021Q4/GivebackSchema2021Q4.vue'
import GivebackSchemaWithImage from './givebackSchemaWithImage/GivebackSchemaWithImage.vue'

export default {
  name: 'Hero',
  components: {
    GivebackSchema2021Q4,
    GivebackSchemaWithImage,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
