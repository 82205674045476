<template>
  <form class="flex gap-[16px] sm:flex-col" @submit.prevent="onSubmit">
    <LkNumber v-model="monthly_rent_with_charges" :has-error="dirty && hasError" :placeholder="placeholder" />
    <LkButton
      class="shrink-0 sm:!w-full"
      type="submit"
      :is-white="isWhite"
      :is-light="isLight"
      :is-outlined="isOutlined"
      data-luko-tracking="Onboarding"
    >
      {{ buttonText }}
    </LkButton>
  </form>
</template>
<script>
import LkNumber from '@/components/Lukomponents/LkNumber'
import { getOnboardingLink } from '@/mixins/getOnboardingLink'
import { useUrl } from '@/composables/useUrl'

const { forwardUrl } = useUrl()

export default {
  components: {
    LkNumber,
  },
  mixins: [getOnboardingLink],
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dirty: false,
      monthly_rent_with_charges: null,
    }
  },
  computed: {
    hasError() {
      const value = this.monthly_rent_with_charges
      if (value === null || value === undefined) return false
      return value < 20 || value > 5000
    },
  },
  methods: {
    onSubmit() {
      this.dirty = true

      if (this.hasError) {
        return
      }

      window.location.href = forwardUrl(
        this.getOnboardingLink(null, {
          queryParams: [
            ['$.product', 'FR_RENT'],
            ['$.home.main_asset.monthly_rent_with_charges_in_cents', this.monthly_rent_with_charges * 100],
          ],
          type: 'gli',
        })
      )
    },
  },
}
</script>
