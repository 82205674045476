<template>
  <SliceWrapper :slice="slice">
    <QuinconceSpaceBetween v-if="slice.variation === 'spaceBetween'" :slice="slice" />
    <QuinconceDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import QuinconceDefault from './default-slice'
import QuinconceSpaceBetween from './spaceBetween'

export default {
  name: 'QuinconceSlice',
  components: {
    QuinconceDefault,
    QuinconceSpaceBetween,
  },
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
}
</script>
