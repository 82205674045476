<template>
  <section class="-mt-[64px] min-h-[615px] bg-bluko-500 py-[64px] px-[80px] md:p-[24px] md:pt-[64px]">
    <Wrapper class="pt-[56px] md:flex-col sm:gap-[24px]">
      <div class="w-full text-center">
        <Breadcrumb v-if="primary.showBreadcrumb" class="mb-[16px]" :is-dark="isDark" :custom-name="primary.title[0].text" />
        <div>
          <Title class="mb-[16px] inline !text-white typo-mega-title sm:typo-extra-large-title" :title-params="primary.title" />
          <span
            v-if="primary.tag"
            class="mt-[10px] inline-block -translate-y-[10px] rounded-[20px] bg-gold-500 py-[8px] px-[16px] typo-body sm:hidden"
          >
            {{ primary.tag }}
          </span>
        </div>
        <div v-if="primary.cobrandedLogo && primary.cobrandedLogo.url" class="mt-[16px] flex items-end gap-[8px] typo-headline">
          <p class="!text-bluko-300 typo-headline">
            {{ $t('with') }}
          </p>
          <PImage :image-object="primary.cobrandedLogo" />
        </div>
        <RichText class="mt-[16px] !text-bluko-100 typo-headline" :text-params="primary.description" />
        <HeroCta :data="primary" class="mt-[40px]" is-white />
        <RichText class="mt-[16px] !text-bluko-300 typo-body" :text-params="primary.subDescription" />
        <ul v-if="items.length" class="mt-[40px]">
          <li v-for="(item, linkIndex) in items" :key="linkIndex" class="flex gap-[16px]">
            <ArrowLink :link-object="item.link" class="text-white path-white" :link-text="item.text" />
          </li>
        </ul>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import ArrowLink from '@/components/ArrowLink'
import HeroCta from '@/components/HeroCta'

export default {
  name: 'HeroLaunchSimple',

  components: {
    HeroCta,
    Breadcrumb,
    ArrowLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => item.text)
    },
    isDark() {
      return this.$store.state.page.settings.theme === 'dark'
    },
    apps() {
      return [
        {
          image: this.primary.imageAppStore,
          link: this.primary.ctaAppStore,
        },
        {
          image: this.primary.imageGooglePlay,
          link: this.primary.ctaGooglePlay,
        },
      ]
    },
  },
}
</script>
