<template>
  <div v-observe-visibility="isVisible" class="relative bg-orange-50 md:bg-bluko-800">
    <aside
      class="
        relative
        left-0
        z-10
        flex
        min-h-[500px]
        w-[50vw]
        justify-end
        bg-bluko-800
        py-[64px]
        px-[80px]
        text-white
        md:static md:min-h-[unset] md:w-full md:max-w-none md:justify-center md:px-[24px] md:pt-[48px] md:pb-0
      "
    >
      <div class="flex max-w-[640px] flex-col justify-evenly gap-[30px] md:items-center">
        <div>
          <Title
            class="!font-[300] !text-white typo-mega-title lg:!leading-[46px] lg:typo-extra-large-title md:text-center sm:typo-large-title"
            :title-params="primary.title"
          />
          <RichText v-if="primary.description" class="mt-[32px] !text-white typo-body md:hidden" :text-params="primary.description" />
        </div>
        <ArrowButton
          :link-object="primary.ctaLink"
          :target="primary.ctaLink.target"
          class="md:mt-[42px] sm:mt-[32px] sm:!w-full"
          :link-text="primary.ctaText"
        />
        <div class="flex flex-col gap-[16px] md:hidden">
          <TrustpilotWidget color="#acbef7" />
          <GoogleReviewWidget is-dark class="path-[#4050a4]" />
        </div>
      </div>
    </aside>

    <div class="absolute top-[calc(50%-123px)] h-full w-full overflow-x-hidden md:relative md:min-h-[406px] md:py-[80px]">
      <div
        ref="carousel"
        :class="[$style.carousel]"
        @mouseenter.passive="onMouseenter"
        @mouseleave.passive="onMouseleave"
        @mousemove.passive="onMousemove"
        @mousedown.passive="onMousedown"
        @click="onClick"
        @touchstart.passive="onTouchstart"
        @touchmove.passive="onTouchmove"
        @touchend.passive="onTouchend"
      >
        <!-- card group 1-->
        <div
          v-for="(item, index) in items"
          :key="'card' + index"
          class="mr-[40px] flex min-h-[246px] cursor-pointer select-none flex-col justify-between gap-[20px] rounded-[8px] bg-white p-[24px]"
          :class="$style.card"
          @click="goToReviewLink(item.link)"
        >
          <div class="flex items-center">
            <ProfileThumbnail
              :image="item.consumer.profileImage ? item.consumer.profileImage.href : ''"
              :name="item.consumer.displayName"
              :size="32"
              class="shrink-0"
            />
            <div>
              <div class="ml-[10px] !text-gray-800 typo-caption">
                {{ item.consumer.displayName }}
              </div>
              <div v-if="item.createdAt" class="ml-[10px] !text-gray-500 typo-caption-bold">
                {{ item.createdAt }}
              </div>
            </div>
          </div>
          <div class="text-[20px] leading-[30px]">
            {{ item.text }}
          </div>
          <StarScore :score="item.stars" color="blue" :size="20" />
        </div>
        <!-- card group 2-->
        <div
          v-for="(item, index) in items"
          :key="'card-second' + index"
          class="mr-[40px] flex min-h-[246px] cursor-pointer select-none flex-col justify-between gap-[20px] rounded-[8px] bg-white p-[24px]"
          :class="$style.card"
          @click="goToReviewLink(item.link)"
        >
          <div class="flex items-center">
            <ProfileThumbnail
              :image="item.consumer.profileImage ? item.consumer.profileImage.href : ''"
              :name="item.consumer.displayName"
              :size="32"
              class="shrink-0"
            />
            <div>
              <div class="ml-[10px] !text-gray-800 typo-caption">
                {{ item.consumer.displayName }}
              </div>
              <div v-if="item.createdAt" class="ml-[10px] !text-gray-500 typo-caption-bold">
                {{ item.createdAt }}
              </div>
            </div>
          </div>
          <div class="text-[20px] leading-[30px]">
            {{ item.text }}
          </div>
          <StarScore :score="item.stars" color="blue" :size="20" />
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center gap-[16px] pb-[80px] up-md:hidden">
      <TrustpilotWidget color="#acbef7" />
      <GoogleReviewWidget is-dark class="path-[#4050a4]" />
    </div>
  </div>
</template>

<script>
import score from '@@/script/trustpilot/score.json'
import { toArray, pipe, map } from '@fxts/core'

import ArrowButton from '@/components/ArrowButton'
import ProfileThumbnail from '@/components/ProfileThumbnail'
import StarScore from '@/components/StarScore'
import TrustpilotWidget from '@/components/TrustpilotWidget'
import GoogleReviewWidget from '@/components/GoogleReviewWidget'

const REVIEW_LIMIT = 85
const SPEED = -0.5 // has to be int

export default {
  name: 'TrustpilotCarousel2021Q4',
  components: {
    ProfileThumbnail,
    ArrowButton,
    StarScore,
    TrustpilotWidget,
    GoogleReviewWidget,
  },
  mixins: [],

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      score,
      isTouchDevice: false,
      startDrag: false,
      startX: null,
      animationID: null,
      speed: SPEED,
      timeStamp: null,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      let _items = this.slice.items
        .map((i) => {
          return {
            stars: 5,
            text: i.text.reduce((acc, p) => (p.type === 'paragraph' ? acc + p.text : acc), ''),
            consumer: {
              displayName: i.info.reduce((acc, p) => (p.type === 'paragraph' ? acc + p.text : acc), ''),
              profileImage: {
                href: i.profileImage.url,
              },
            },
          }
        })
        .filter((i) => i.text?.length)

      if (!_items.length) {
        _items = require(`@@/script/trustpilot/reviews-${this.$i18n.locale}.json`)
      }

      // make items at least 10
      let items = _items
      while (items.length < 10) {
        items = [...items, ..._items]
      }

      return pipe(
        items,
        map((i) => {
          if (i.text.length > REVIEW_LIMIT) {
            i.text = i.text.substr(0, REVIEW_LIMIT - 5)
            i.text = i.text.replace(/\s\S+$/, ' ...')
          }
          return {
            ...i,
          }
        }),
        map((i) => ({
          ...i,
          createdAt: i.createdAt ? new Date(i.createdAt).toLocaleDateString(this.$i18n.localeProperties.prismicIso) : null,
        })),
        toArray
      )
    },
  },
  mounted() {
    const carousel = this.$refs.carousel
    carousel.style.setProperty('--carousel-items', this.items.length)
    carousel.style.setProperty('display', 'flex')
  },
  methods: {
    isVisible(visible) {
      if (visible) return this.startAnimation()
      return this.stopAnimation()
    },
    startAnimation() {
      this.animationID = requestAnimationFrame(this.loop)
    },
    stopAnimation() {
      this.animationID = cancelAnimationFrame(this.animationID)
    },
    loop() {
      if (!this.animationID) return
      this.moveCarousel()
      this.animationID = requestAnimationFrame(this.loop)
    },
    moveCarousel() {
      const el = this.$refs.carousel
      const currentTranslateX = parseFloat(window.getComputedStyle(el).transform.split(',')[4])

      let newTranslateX = (currentTranslateX + this.speed) % (340 * this.items.length)
      if (newTranslateX > 0) {
        newTranslateX = 0
        this.speed = SPEED
      }
      el.style.transform = `translateX(${newTranslateX}px)`

      // Friction when user scroll right
      if (this.speed > 0) return (this.speed -= 0.2)

      // Friction when user scroll left
      if (this.speed < 0) {
        this.speed += 0.2
        if (this.speed > SPEED) return (this.speed = SPEED)
      }
    },
    onMouseenter() {
      if (this.isTouchDevice) return
      this.speed = 0
    },
    onMouseleave() {
      if (this.isTouchDevice) return
      this.startDrag = false

      if (this.speed === 0) this.speed = SPEED
    },
    onMousemove(e) {
      if (this.isTouchDevice) return
      if (!this.startDrag) return
      const time = e.timeStamp - this.timeStamp
      const distance = e.pageX - this.startX
      this.speed = (distance / time) * 5
      this.moveCarousel()

      this.timeStamp = e.timeStamp
      this.startX = e.pageX
    },
    onMousedown(e) {
      if (this.isTouchDevice) return
      this.startDrag = true
      this.startX = e.pageX
      this.timeStamp = e.timeStamp
    },
    onClick() {
      if (this.isTouchDevice) return
      this.startDrag = false
    },
    onTouchstart(e) {
      this.isTouchDevice = true
      this.startDrag = true
      this.startX = e.targetTouches[0].pageX
      this.timeStamp = e.timeStamp
      this.speed = 0
    },
    onTouchend() {
      this.isTouchDevice = true
      this.startDrag = false
      if (this.speed === 0) this.speed = SPEED
    },
    onTouchmove(e) {
      if (!this.startDrag) return
      const time = e.timeStamp - this.timeStamp
      const distance = e.targetTouches[0].pageX - this.startX
      this.speed = (distance / time) * 5
      this.moveCarousel()

      this.timeStamp = e.timeStamp
      this.startX = e.targetTouches[0].pageX
    },
    goToReviewLink(link) {
      if (link) window.open(link)
    },
  },
}
</script>

<style lang="scss" module>
.carousel {
  --carousel-items: 0;
  --carousel-item-width: 320px;

  display: none;
  width: calc((var(--carousel-item-width) + 40px) * var(--carousel-items) * 2);

  transform: translateX(0);
}
.card {
  width: var(--carousel-item-width);
}
</style>
