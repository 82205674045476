<template>
  <div class="m-[64px] sm:m-[24px]">
    <Wrapper class="rounded-[20px] py-[64px] px-[32px] sm:py-[80px] sm:px-[24px]" :style="{ backgroundColor: primary.backgroundColor || '#fff4ee' }">
      <Title :title-params="primary.title" class="mx-auto max-w-[800px] text-center !font-[300] typo-large-title sm:typo-title" />
      <RichText :text-params="primary.description" class="mt-[14px] text-center" />
      <div v-if="primary.ctaText && primary.ctaLink" class="text-center">
        <ArrowButton
          class="mt-[50px] sm:!w-full"
          :is-outlined="primary.isOutlinedButton"
          :is-arrow="primary.isArrowButton"
          :link-object="primary.ctaLink"
          :link-text="primary.ctaText"
        />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton'
export default {
  name: 'SimpleCtaWhite',
  components: { ArrowButton },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
