<template>
  <SliceWrapper :slice="slice">
    <ProcessArrowDarkblue v-if="slice.variation === 'darkblue'" :slice="slice" />
    <ProcessArrowDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import ProcessArrowDefault from './default-slice/'
import ProcessArrowDarkblue from './darkblue/'

export default {
  name: 'ProcessArrow',
  components: {
    ProcessArrowDefault,
    ProcessArrowDarkblue,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
