<template>
  <SliceWrapper :slice="slice">
    <SimpleCtaWhite v-if="slice.variation == 'white'" :slice="slice" />
    <SimpleCtaPink v-else-if="slice.variation == 'pink'" :slice="slice" />
    <SimpleCtaDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import SimpleCtaDefault from './default-slice/'
import SimpleCtaWhite from './white'
import SimpleCtaPink from './pink'

export default {
  name: 'SimpleCta',
  components: {
    SimpleCtaDefault,
    SimpleCtaWhite,
    SimpleCtaPink,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
