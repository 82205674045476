<template>
  <label
    class="
      flex
      cursor-pointer
      flex-col
      items-center
      justify-center
      rounded-[8px]
      border-[2px] border-bluko-150
      py-[24px]
      px-[16px]
      font-[450]
      transition-all
      duration-200
    "
    :class="{ [$style.active]: value === modelValue }"
  >
    <input v-model="computedInput" type="radio" :value="value" :name="name" :class="$style.input" />
    <div class="mb-[8px]">
      <slot name="icon" />
    </div>
    <div class="text-center">
      <slot />
      {{ wording }}
    </div>
  </label>
</template>
<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    wording: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    computedInput: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
}
</script>
<style lang="scss" module>
.input {
  margin: 0;

  background-color: #fff;

  -webkit-appearance: none;
  appearance: none;
}
.active {
  border: 2px solid $bluko-500;

  box-shadow: 0px 0px 0px 4px #d6e3fd;
}
</style>
