<template>
  <SliceWrapper :slice="slice" class="min-h-[800px]">
    <client-only>
      <VuePdfEmbed
        :source="primary.pdf.url"
        class="sm:[&>div]:mt-20px my-[160px] sm:my-[20px] [&>div]:mt-[48px]"
        :class="$style.embedPdf"
        disable-text-layer
        disable-annotation-layer
        @rendering-failed="onFail"
        @loading-failed="onFail"
      />
    </client-only>
  </SliceWrapper>
</template>

<script>
import speedkitHydrate from 'nuxt-speedkit/hydrate'

export default {
  name: 'EmbedPdf',
  components: { VuePdfEmbed: speedkitHydrate(() => (process.client ? import('vue-pdf-embed/dist/vue2-pdf-embed') : null)) },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
  },
  methods: {
    onFail(e) {
      window.LukoTracking.trackEvent({
        id: 'Error',
        properties: {
          error: {
            message: 'Fail to load PDF file',
          },
        },
      })
    },
  },
}
</script>

<style lang="scss" module>
.embedPdf {
  canvas {
    width: 100% !important;
    max-width: 800px !important;
    height: 100% !important;
    margin: 0 auto;

    box-shadow: 0px 5px 16px rgba(44, 35, 2, 0.12);
  }
}
</style>
