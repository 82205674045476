<template>
  <div :class="$style.root" data-luko-tracking="EmailField">
    <LkLabel :label="label" :for="lukid" />
    <LkInput
      :id="lukid"
      :value="localeValue"
      type="email"
      :is-success="isSuccess"
      :readonly="isReadonly"
      :has-error="hasError"
      :has-autofocus="hasAutofocus"
      v-bind="$attrs"
      v-on="{
        ...$listeners,
        input: (event) => (localeValue = event),
      }"
    />
    <transition name="fade-down">
      <LkError v-if="error" :error="error" />
    </transition>
  </div>
</template>

<script>
import { EMAIL_REGEX } from '@/utils/constants'
import LkLabel from '@/components/Lukomponents/LkLabel'
import LkInput from '@/components/Lukomponents/LkInput'
import LkError from '@/components/Lukomponents/LkError'

import { autoLukid } from '../autoLukid.mixin'

export default {
  components: {
    LkLabel,
    LkInput,
    LkError,
  },
  mixins: [autoLukid],
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    hasAutofocus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      EMAIL_REGEX,
      localeValue: '',
    }
  },
  computed: {
    isError() {
      return Boolean(this.error || this.isEmailInvalid)
    },
    isEmailInvalid() {
      return this.localeValue?.length > 0 && !EMAIL_REGEX.test(this.localeValue)
    },
  },
  watch: {
    localeValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        setTimeout(() => (this.localeValue = this.localeValue.toLowerCase()), 200)
      }

      this.$emit('error', this.isEmailInvalid)

      if (!this.isEmailInvalid) {
        this.$emit('input', newVal)
      }
    },
    value: {
      handler(newVal, oldVal) {
        if (newVal !== null && newVal !== oldVal) {
          this.localeValue = newVal
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" module>
.root {
  display: block;
  width: 100%;

  text-align: left;
}
</style>
