<template>
  <SliceWrapper :slice="slice">
    <AppBlockDefault :slice="slice" />
  </SliceWrapper>
</template>

<script>
import AppBlockDefault from './default-slice'

export default {
  name: 'AppBlock',
  components: {
    AppBlockDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
