export const useGeoApi = () => {
  return {
    async getZipFranceLocation(zip) {
      const url = `https://api.geoapify.com/v1/geocode/search?text=${zip}%20France&lang=fr&type=postcode&format=json&apiKey=${process.env.GEOAPIFY_KEY}`
      const call = await fetch(url)
      const data = await call.json()

      return {
        lat: data.results[0].lat,
        lon: data.results[0].lon,
      }
    },
    async getIPLocation() {
      const url = `https://api.geoapify.com/v1/ipinfo?apiKey=${process.env.GEOAPIFY_KEY}`
      const call = await fetch(url)
      const data = await call.json()
      return {
        lat: data?.location?.latitude,
        lon: data?.location?.longitude,
      }
    },
    async getPlacePredictions(input, option = {}) {
      const defaultOption = { locale: undefined, lat: undefined, lon: undefined }
      option = Object.assign(defaultOption, option)

      let url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(input)}&lang=${
        option.locale
      }&type=amenity&limit=5&filter=countrycode:${process.env.CURRENT_DOMAIN}&format=json&apiKey=${process.env.GEOAPIFY_KEY}`

      if (option.lat && option.lon) {
        url += `&bias=countrycode:auto`
      } else {
        url += `&bias=proximity:${option.lon},${option.lat}`
      }

      const call = await fetch(url)
      const data = await call.json()

      if (data.statusCode === 401 && process.env.IS_DEV === 'true') {
        alert('Hey Lukooms ! Enable your VPN.')
      }
      return data.results
    },
  }
}
