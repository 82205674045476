<template>
  <SliceWrapper :slice="slice">
    <EngagementDonut v-if="slice.variation === 'donut'" :slice="slice" />
    <EngagementCard v-else-if="slice.variation === 'card'" :slice="slice" />
    <EngagementLine v-else-if="slice.variation === 'line'" :slice="slice" />
  </SliceWrapper>
</template>

<script>
import EngagementDonut from './donut/EngagementDonut.vue'
import EngagementCard from './card/EngagementCard.vue'
import EngagementLine from './line/EngagementLine.vue'

export default {
  name: 'Engagement',
  components: {
    EngagementDonut,
    EngagementCard,
    EngagementLine,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
