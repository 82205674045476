<template>
  <Wrapper class="my-[128px] flex items-center justify-center gap-[80px] px-[24px] lg:my-[80px] lg:flex-col lg:gap-[40px]">
    <div class="flex-1">
      <Title class="!font-[300] typo-mega-title md:text-center md:typo-extra-large-title" :title-params="primary.title" />
      <RichText :text-params="primary.description" class="mt-[16px] text-[#808080] md:text-center sm:mt-[10px]" />
      <ArrowLink
        :link-object="primary.cta_link"
        class="mt-[40px] text-bluko-500 path-bluko-500 md:mx-auto md:mt-[24px]"
        :link-text="primary.cta_text"
      />
    </div>
    <div class="mt-[40px] grid flex-1 grid-cols-[300px_300px] grid-rows-[auto_auto] gap-[40px] sm:grid-cols-1 sm:gap-[24px]">
      <div
        v-for="(item, index) in items"
        :key="`card-${index}`"
        class="flex flex-col items-center justify-between gap-[16px] rounded-[8px] border-[1px] border-bluko-100 bg-bluko-25 p-[24px] text-center"
      >
        <div class="flex flex-col items-center">
          <PImage class="w-[48px]" :image-object="item.icon" />
          <Title class="mt-[16px] mb-[4px] typo-headline-bold" :title-params="item.title" />
          <RichText :text-params="item.description" class="text-black text-opacity-60" />
        </div>
        <LkButton v-if="item.cta_text" is-small :link-object="item.cta_link" is-outlined>{{ item.cta_text }}</LkButton>
      </div>
    </div>
  </Wrapper>
</template>

<script>
import ArrowLink from '@/components/ArrowLink'

export default {
  components: {
    ArrowLink,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
