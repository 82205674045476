<template>
  <div v-if="items.length" class="w-full overflow-hidden sm:hidden" :style="{ backgroundColor: primary.backgroundColor || '#2d50e5' }">
    <Wrapper class="mx-auto flex justify-center gap-x-[75px] py-[44px] px-[24px]">
      <PImage v-for="(item, index) in items" :key="index" :image-object="item.logo" class="h-[44px] object-contain" />
    </Wrapper>
  </div>
</template>
<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
