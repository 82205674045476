<template>
  <div>
    <ArrowLink
      v-if="ctaType === 'link' && item.ctaText && item.ctaLink"
      :link-object="blockLink(item.ctaLink, item.link_url_connected_user)"
      class="text-bluko-500 path-bluko-500"
      :link-text="blockLinkText(item.ctaText, item.link_text_connected_user)"
    />

    <ArrowLink
      v-else-if="ctaType === 'mailto' && item.ctaText && item.mailTo"
      :simple-url="`mailto:${item.mailTo}`"
      class="text-bluko-500 path-bluko-500"
      :link-text="item.ctaText"
    />

    <ArrowLink
      v-else-if="ctaType === 'intercom' && item.ctaText"
      simple-url="#/"
      class="text-bluko-500 path-bluko-500"
      :link-text="item.ctaText"
      @click="showIntercom"
    />
  </div>
</template>
<script>
import { isLoggedIn } from '@/mixins/isLoggedIn'
import ArrowLink from '@/components/ArrowLink'
import { showIntercom } from '@/mixins/showIntercom'

export default {
  name: 'CtaBox',

  components: {
    ArrowLink,
  },

  mixins: [isLoggedIn, showIntercom],

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      lastname: null,
      firstname: null,
    }
  },

  computed: {
    ctaType() {
      return this.item.ctaType || 'link'
    },
  },

  methods: {
    blockLink(normalLink, connectedLink) {
      if (connectedLink?.url && this.isLoggedIn) return connectedLink

      return normalLink
    },

    blockLinkText(normalLinkText, connectedLinkText) {
      if (connectedLinkText && this.isLoggedIn) return connectedLinkText

      return normalLinkText
    },
  },
}
</script>
