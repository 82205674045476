<template>
  <div
    v-observe-visibility="{
      callback: onVisible,
      intersection: {
        rootMargin: '100% 0px 100% 0px',
      },
      once: true,
    }"
    class="min-h-[300px] bg-bluko-800"
  >
    <Wrapper ref="inner" class="h-full max-w-[1400px] px-[24px] py-[80px]">
      <div v-if="load" class="relative">
        <!-- Arrow Button -->
        <div class="absolute left-0 z-10 h-full w-[32px] md:hidden">
          <button
            class="relative top-[25%] flex h-[32px] w-[32px] -translate-y-1/2 items-center justify-center rounded-[50%] bg-bluko-700"
            @click="() => $refs.carousel.prev()"
          >
            <ChevronLeftIcon class="h-[16px] w-[16px] path-white" />
          </button>
        </div>
        <div class="absolute right-0 z-10 h-full w-[32px] md:hidden">
          <button
            class="relative top-[25%] flex h-[32px] w-[32px] -translate-y-1/2 items-center justify-center rounded-[50%] bg-bluko-700"
            @click="() => $refs.carousel.next()"
          >
            <ChevronRightIcon class="h-[16px] w-[16px] path-white" />
          </button>
        </div>

        <VueSlickCarousel ref="carousel" v-bind="slickOptions">
          <div v-for="(item, itemIndex) in items" :key="itemIndex">
            <div class="flex flex-col items-center px-[64px] md:px-0">
              <RichText
                :text-params="item.quote"
                class="max-w-[1056px] text-center !font-[300] !italic text-white typo-large-title lg:text-[24px] lg:leading-[32px]"
              />
              <div class="mt-[42px] flex items-center justify-center gap-[14px] md:mt-[24px] md:flex-col">
                <PImage :image-object="item.profile" sizes="xs:100px" class="h-[58px] w-[58px] rounded-[50%]" />
                <div class="md:text-center">
                  <span class="text-white typo-headline-bold lg:typo-body-bold">{{ item.name }}</span>
                  <RichText :text-params="item.profile_description" class="max-w-[320px] text-bluko-200 md:text-[14px]" />
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
      <!-- Arrow Button -->
      <div class="mt-[40px] flex justify-center gap-[24px] sm:gap-[16px] up-md:hidden">
        <button class="flex h-[34px] w-[34px] items-center justify-center rounded-[50%] bg-bluko-700" @click="() => $refs.carousel.prev()">
          <ChevronLeftIcon class="h-[16px] w-[16px] path-white" />
        </button>
        <button class="flex h-[34px] w-[34px] items-center justify-center rounded-[50%] bg-bluko-700" @click="() => $refs.carousel.next()">
          <ChevronRightIcon class="h-[16px] w-[16px] path-white" />
        </button>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import ChevronLeftIcon from 'LkIcons/ChevronLeft.vue'
import ChevronRightIcon from 'LkIcons/ChevronRight.vue'
import VueSlickCarousel from 'vue-slick-carousel'

import { showIntercom } from '@/mixins/showIntercom'

export default {
  name: 'CustomerServiceCarousel',
  components: {
    VueSlickCarousel,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  mixins: [showIntercom],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      slickOptions: {
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
      },
      load: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    onVisible(visible) {
      if (!this.load && visible) {
        this.load = true
      }
    },
  },
}
</script>
