<template>
  <Wrapper v-observe-visibility="{ callback: onVisible, once: true }" class="mt-[80px] mb-[120px]" data-luko-tracking="TestimonialList">
    <Title
      :title-params="primary.title"
      class="mx-auto mb-[8px] max-w-[800px] px-[24px] text-center !font-[300] typo-mega-title md:typo-extra-large-title"
    />
    <RichText :text-params="primary.description" class="mx-auto max-w-[800px] px-[24px] text-center text-gray-700" />

    <transition-group
      tag="div"
      name="popping"
      class="
        mx-auto
        mt-[64px]
        grid
        min-h-[300px]
        max-w-[1330px]
        grid-cols-5
        justify-items-center
        gap-[32px]
        px-[24px]
        lg:grid-cols-4 lg:gap-[24px]
        md:grid-cols-3
        sm:grid-cols-2 sm:gap-[10px]
      "
    >
      <div v-for="(columnItems, idx) in screenItems" :key="'group' + idx" class="flex flex-col gap-[32px] md:gap-[24px] sm:gap-[10px]">
        <Card v-for="item in columnItems" :key="'card' + item.id" :item="item" />
      </div>
    </transition-group>

    <div class="mt-[48px] flex justify-center">
      <ArrowButton is-outlined :link-object="primary.cta_link" :link-text="primary.cta_text" />
    </div>
  </Wrapper>
</template>

<script>
import throttle from 'lodash-es/throttle'

import ArrowButton from '@/components/ArrowButton'

import Card from './components/Card.vue'

export default {
  components: {
    Card,
    ArrowButton,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    let i = 0
    return {
      items: this.slice.items.map((item) => {
        item.id = i++
        return item
      }),
      screen: null,
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    screenSize() {
      if (!this.screen || this.screen > 1280) return 'lg'
      if (this.screen > 960) return 'md'
      if (this.screen > 720) return 'sm'
      if (this.screen > 540) return 'xs'
      return 'xs'
    },
    screenItems() {
      if (this.screenSize === 'lg') return this.groupByColumns(this.items, 5, 10)
      if (this.screenSize === 'md') return this.groupByColumns(this.items, 4, 8)
      if (this.screenSize === 'sm') return this.groupByColumns(this.items, 3, 9)
      return this.groupByColumns(this.items, 2, 6)
    },
  },
  mounted() {
    this.screen = window.innerWidth
    window.addEventListener('resize', this.throttleResize)
  },
  unmounted() {
    this.throttleResize.cancel()
    window.removeEventListener('resize', this.throttleResize)
  },

  methods: {
    throttleResize: throttle(function () {
      this.screen = window.innerWidth
    }, 200),
    onVisible(visible) {
      this.visible = visible
    },
    groupByColumns(items, nb_column, limit) {
      const groupedItems = new Array(nb_column).fill(0).map(() => [])
      const copied_items = [...items]

      let i = 0
      let cnt = 0
      while (copied_items.length) {
        const _item = copied_items.shift()
        groupedItems[i].push(_item)
        i = (i + 1) % nb_column
        cnt++

        if (cnt >= limit) break
      }
      return groupedItems
    },
  },
}
</script>

<style lang="scss" scoped>
/* enter */
.popping-enter-from {
  transform: scale(0);
  opacity: 0;
}
.popping-enter-active {
  transform: scale(0);

  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}
.popping-enter-to {
  transform: scale(1);
  opacity: 1;
}

/* leave */
.popping-leave-from {
  position: absolute;

  display: none;
}
.popping-leave-active {
  position: absolute;

  display: none;
}
.popping-leave-to {
  position: absolute;

  display: none;
}
</style>
