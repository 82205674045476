<template>
  <div :class="$style.root" @click="focusInput">
    <input
      ref="inputElement"
      :data-luko-tracking="dataLukoTracking"
      :value="value"
      :type="type"
      v-bind="$attrs"
      :placeholder="placeholder"
      :class="{
        [$style.input]: true,
        ['!pr-[32px]']: !!$slots.append,
        [$style['input--error']]: hasError,
        [$style['input--success']]: isSuccess,
        ['placeholder-black']: readonly,
        ['placeholder-gray-700']: !readonly,
        ['cursor-pointer']: pointer,
      }"
      :readonly="readonly"
      :required="required"
      v-on="{
        ...$listeners,
        input: (event) => $emit('input', event.target.value),
      }"
    />
    <div v-if="$slots.append" class="absolute right-[12px] top-1/2 -translate-y-1/2" @click="focusInput">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    dataLukoTracking: {
      type: String,
      default: 'Input',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    pointer: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$nextTick(this.focusInput())
    }
  },
  methods: {
    focusInput() {
      const input = this.$refs.inputElement

      input.focus()
    },
  },
}
</script>

<style lang="scss" module>
.root {
  position: relative;

  display: block;
  width: 100%;

  text-align: left;
}

.input {
  @include typo-body;
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 14px;
  border: 2px solid $gray-100;
  border-radius: 8px;

  color: $gray-1000;
  font-weight: 450;
  font-family: inherit;

  background-color: white;
  outline: none;
  box-shadow: 0 0 0 0 $bluko-200;

  transition-duration: 200ms;
  transition-property: border-color, box-shadow;

  &:hover {
    border-color: $gray-300;
  }

  &.input--success {
    border-color: $mint-500;
  }

  &.input--error {
    border-color: $terracota-500 !important;
    &:focus {
      box-shadow: 0 0 0 4px $terracota-200;
    }
  }

  &:focus {
    border-color: $bluko-500;

    box-shadow: 0 0 0 4px $bluko-100;
  }

  &:disabled {
    border-color: $gray-100;

    color: $gray-500;

    background-color: $gray-50;
    cursor: not-allowed;
  }
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  margin: 0;

  -webkit-appearance: none;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}

input.input {
  height: 48px;
}
</style>
