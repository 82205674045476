<template>
  <div class="m-0 my-[144px] lg:my-[80px]">
    <Wrapper class="flex gap-[10%] p-[24px] lg:max-w-[720px] lg:flex-col lg:gap-[70px]" :class="{ ['flex-row-reverse']: primary.reverse }">
      <aside class="flex-1">
        <p class="mb-[8px] text-[14px] font-[700] uppercase leading-[16px] text-gray-200 sm:hidden">{{ primary.caption }}</p>
        <Title :title-params="primary.title" class="mb-[24px] text-[40px] font-[300] leading-[48px] sm:text-center" />
        <RichText :text-params="primary.description" class="font-[450] sm:text-center [&>p]:mb-[8px] sm:[&>p]:mb-[24px]" />
        <div v-if="primary.ctaText" class="mt-[24px] lg:text-center">
          <ArrowButton :link-object="primary.ctaLink" is-outlined :link-text="primary.ctaText" />
        </div>
      </aside>
      <div
        v-observe-visibility="{
          callback: isVisible,
          once: true,
          intersection: {
            rootMargin: '-30% 0px -30% 0px',
          },
        }"
        class="flex flex-1 flex-col justify-center"
      >
        <Title
          :title-params="primary.compareTitle"
          class="mb-[32px] text-center text-[22px] leading-[32px] text-bluko-800 sm:text-[14px] sm:leading-[18px] [&>strong]:font-[500]"
        />
        <div class="flex justify-center gap-[24px]">
          <div class="w-[236px] scale-0 shadow-lg transition-transform duration-500 lg:w-full lg:max-w-[440px]" :class="{ ['scale-100']: animated }">
            <div class="flex h-[68px] items-center justify-center rounded-tl-[8px] rounded-tr-[8px] bg-bluko-50 p-[24px] text-[16px] font-[500]">
              <LukoLogoBlue />
            </div>
            <div class="rounded-br-8px rounded-bl-[8px] p-[20px] py-[24px] sm:p-[12px]">
              <p class="text-center text-[46px] leading-[46px] sm:text-[26px] sm:leading-[26px]">{{ primary.lukoPriceEvolution }}</p>
              <p class="mt-[10px] text-center text-[12px] font-[700] uppercase leading-[18px] text-gray-200 sm:text-[8px]">
                {{ primary.lukoPriceEvolutionSub }}
              </p>
            </div>
          </div>
          <div class="w-[236px] scale-0 shadow-lg transition-transform duration-500 lg:w-full lg:max-w-[440px]" :class="{ ['scale-100']: animated }">
            <div class="flex h-[68px] items-center justify-center rounded-tl-[8px] rounded-tr-[8px] bg-gray-100 p-[24px] text-[16px] font-[500]">
              <p>Others</p>
            </div>
            <div class="rounded-br-8px rounded-bl-[8px] p-[20px] py-[24px] sm:p-[12px]">
              <p class="text-center text-[46px] leading-[46px] sm:text-[26px] sm:leading-[26px]">{{ primary.othersPriceEvolution }}</p>
              <p class="mt-[10px] text-center text-[12px] font-[700] uppercase leading-[18px] text-gray-200 sm:text-[8px]">
                {{ primary.othersPriceEvolutionSub }}
              </p>
            </div>
          </div>
        </div>
        <p v-if="primary.source" class="sm:mt-24px mt-[60px] text-center text-[8px] text-gray-300">{{ primary.source }}</p>
      </div>
    </Wrapper>
  </div>
</template>
<script>
import ArrowButton from '@/components/ArrowButton'
import LukoLogoBlue from '@/assets/icons/luko-logo-blue.svg?inline'

export default {
  components: {
    ArrowButton,
    LukoLogoBlue,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      animated: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    isVisible(visible) {
      if (visible) {
        this.animated = visible
      }
    },
  },
}
</script>
