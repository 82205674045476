<template>
  <div class="flex min-h-[380px] items-end md:mt-[64px] md:items-center">
    <ul class="flex h-0 w-full items-center justify-between border-[1px] border-solid border-bluko-100">
      <li v-for="(item, itemIdx) in items" :key="itemIdx">
        <div v-if="item.bridge" class="relative">
          <span
            class="absolute inline-block -translate-y-1/2 bg-white !text-gray-300 typo-headline-bold"
            :class="{ ['p-[10px]']: item.year, ['-translate-x-[50%]']: itemIdx !== 0 && itemIdx !== items.length }"
          >
            {{ item.year }}
          </span>
        </div>
        <div v-else class="relative h-[64px] w-[64px] bg-white p-[10px]">
          <div
            class="absolute bottom-[100%] w-0 translate-x-[22px] border-[1px] border-solid border-bluko-100"
            :class="{ ['h-[170px]']: !item.highBar, ['h-[250px]']: item.highBar }"
          >
            <div class="absolute top-0 min-w-[186px] -translate-x-[50%] bg-white pb-[10px]">
              <RichText :text-params="item.dateText" class="text-center font-[500] text-bluko-300" />
              <RichText :text-params="item.description" class="text-center !leading-[24px] typo-headline" />
            </div>
          </div>
          <PImage :image-object="item.icon" class="h-full w-full" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
