<template>
  <div class="-mt-[64px] min-h-[615px] bg-orange-25 p-0 px-[50px] md:p-[24px] md:pt-[64px]">
    <Wrapper class="flex h-full justify-between gap-[5%] pt-[128px] md:flex-col md:pt-[24px]">
      <div class="max-w-[600px] flex-1 md:w-full md:max-w-none">
        <Breadcrumb v-if="primary.showBreadcrumb" class="mb-[16px]" :custom-name="primary.title[0].text" />
        <div>
          <Title class="mt-[16px] inline-block !font-[300] typo-mega-title sm:!font-[300] sm:typo-extra-large-title" :title-params="primary.title" />
          <span
            v-if="primary.tag"
            class="mt-[10px] inline-block -translate-y-[10px] rounded-[30px] bg-gold-500 p-[8px] px-[16px] typo-body sm:hidden"
          >
            {{ primary.tag }}
          </span>
        </div>
        <RichText class="mt-[16px] !text-gray-700 typo-headline [&>p]:pb-[16px]" :text-params="primary.subtitle" />

        <HeroCta class="mt-[40px]" :data="primary" />

        <ul v-if="items.length" class="mt-[40px]">
          <li v-for="(item, idx) in items" :key="idx" class="mt-[16px]">
            <ArrowLink :link-object="item.ctaLink" class="text-bluko-500 path-bluko-500" :link-text="item.ctaText" />
          </li>
        </ul>
      </div>
      <PImage
        class="
          max-h-[460px]
          w-1/2
          flex-1
          rounded-tl-[50%] rounded-tr-[50%]
          object-cover
          md:mt-[16px] md:max-h-[256px] md:w-full md:rounded-bl-[150px] md:rounded-tl-[0] md:rounded-tr-[0]
        "
        :image-object="primary.backgroundImage"
        sizes="sm:100vw"
      />
    </Wrapper>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import ArrowLink from '@/components/ArrowLink'
import HeroCta from '@/components/HeroCta'

export default {
  name: 'HeroPressHub2021Q4',

  components: {
    HeroCta,
    Breadcrumb,
    ArrowLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
