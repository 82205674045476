var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-[128px] mx-[32px]"},[_c('Wrapper',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.sectionOnScreen,
      intersection: {
        rootMargin: '-30% 0px -30% 0px',
      },
    }),expression:"{\n      callback: sectionOnScreen,\n      intersection: {\n        rootMargin: '-30% 0px -30% 0px',\n      },\n    }"}],staticClass:"flex max-w-[1120px]"},[_c('transition-group',{staticClass:"relative flex-1 text-center",attrs:{"name":"cross","tag":"div"}},[_c('PImage',{key:'front' + _vm.currentIndex,staticClass:"front absolute left-0 z-10 h-full w-[min(420px,40vw)] object-contain",attrs:{"image-object":_vm.currentItem.imageFront,"sizes":"xs:600px","lazy":""}}),_vm._v(" "),_c('BackImage',{key:"0",staticClass:"absolute top-[10%] left-0 h-[min(420px,40vw)] w-[min(420px,40vw)]"})],1),_vm._v(" "),_c('div',{staticClass:"mb-[80px] w-[50vw] max-w-[540px] md:w-auto"},[_c('Title',{staticClass:"mb-[36px] !font-[300] typo-mega-title",attrs:{"title-params":_vm.primary.title}}),_vm._v(" "),_vm._l((_vm.items),function(item,index){
    var _obj, _obj$1;
return _c('div',{key:index,staticClass:"mb-[40px] flex cursor-pointer last:mb-0",on:{"click":function($event){return _vm.onClickItem(index)}}},[_c('LinearProgressBar',{staticClass:"mr-[36px] shrink-0",attrs:{"callback":_vm.afterCount,"is-active":index === _vm.currentIndex && _vm.isVisible}}),_vm._v(" "),_c('div',{staticClass:"text-gray-400"},[_c('Title',{staticClass:"typo-headline-bold",class:( _obj = {}, _obj['!text-black'] = _vm.currentIndex === index && _vm.isVisible, _obj ),attrs:{"title-params":item.title}}),_vm._v(" "),_c('RichText',{class:( _obj$1 = {}, _obj$1['!text-gray-700'] = _vm.currentIndex === index && _vm.isVisible, _obj$1 ),attrs:{"text-params":item.description}})],1)],1)})],2)],1),_vm._v(" "),(_vm.primary.ctaText && _vm.primary.ctaLink)?_c('Wrapper',{staticClass:"mt-[60px] flex justify-center"},[_c('ArrowButton',{attrs:{"link-object":_vm.primary.ctaLink,"target":_vm.primary.ctaLink.target,"is-outlined":"","link-text":_vm.primary.ctaText}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }