<template>
  <ul
    v-show="show && (otherOption || options.length)"
    tabindex="-1"
    :class="{
      [$style.root]: true,
      [$style.rootBig]: isBig,
      [$style.rootExtraLarge]: isExtraLarge,
    }"
  >
    <li
      v-for="(option, i) in options"
      :key="'option-' + i"
      :class="{
        [$style.option]: true,
        [$style.optionIsSelected]: option.value === value,
      }"
      @click="onClickOnOption(option)"
      v-html="option.wording"
    />
    <li v-if="otherOption" :class="$style.option" @click="onClickOnOther">
      {{ otherOption }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LkDropdown',
  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    otherOption: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
    isExtraLarge: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'other', 'close', 'focus'],
  data() {
    return {
      optionElements: [],
    }
  },
  beforeUpdate() {
    // Make sure to reset the refs before each update.
    this.optionElements = []
  },
  methods: {
    onClickOnOption(option) {
      this.$emit('input', option.value)
      this.$emit('focus')
      this.$emit('close')
    },
    onClickOnOther() {
      this.$emit('input', null)
      this.$emit('other')
      this.$emit('close')
      this.$emit('focus')
    },
  },
}
</script>

<style lang="scss" module>
.root {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  left: 0;
  z-index: 10;

  max-height: 232px;
  padding: 4px;
  border-radius: 8px;
  overflow-y: auto;

  background-color: white;
  box-shadow: 0px 2px 16px rgba(0, 0, 108, 0.12);
  -webkit-overflow-scrolling: touch;
}

.rootBig {
  top: 72px;
}
.rootExtraLarge {
  top: 96px;
}

.option {
  padding: 8px 12px;
  border-radius: 4px;
  overflow: hidden;

  color: black;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;

  cursor: pointer;

  &:hover {
    background-color: $gray-50;
  }
}

.optionIsSelected {
  color: $bluko-500;
}
</style>
