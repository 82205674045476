<template>
  <div
    v-observe-visibility="{
      callback: onVisible,
      intersection: {
        rootMargin: '100% 0px 100% 0px',
      },
      once: true,
    }"
    class="overflow-x-hidden bg-bluko-50 py-[64px] sm:py-[32px]"
  >
    <Title
      :title-params="primary.title"
      class="mx-auto mb-[16px] max-w-[1024px] text-center !font-[300] typo-extra-large-title sm:typo-large-title"
    />

    <RichText :text-params="primary.description" class="mx-auto mt-[10px] mb-[24px] max-w-[1024px] text-center !text-white typo-body" />
    <div v-if="primary.ctaText && primary.ctaLink && primary.ctaLink.url" class="mb-[54px] px-[24px] text-center">
      <ArrowButton
        is-outlined
        :link-object="primary.ctaLink"
        :target="primary.ctaLink.target"
        class="sm:!w-full"
        is-white
        :link-text="primary.ctaText"
      />
    </div>
    <Wrapper class="relative mt-[54px] min-h-[64px] overflow-hidden">
      <div class="absolute top-0 left-[-5px] z-10 h-full w-[40px] bg-gradient-to-r from-bluko-50 via-bluko-50 to-transparent"></div>
      <div ref="carousel" :class="[$style.carousel, { [$style.animate]: animate }]">
        <div v-for="(item, index) in items" :key="index" :class="$style.logoContainer">
          <PImage :image-object="imageLoad ? item.logo : null" :class="$style.logo" />
        </div>
        <div v-for="(item, index) in items" :key="'second-' + index" :class="$style.logoContainer">
          <PImage :image-object="imageLoad ? item.logo : null" :class="$style.logo" />
        </div>
      </div>
      <div class="absolute top-0 right-[-5px] z-10 h-full w-[40px] bg-gradient-to-l from-bluko-50 via-bluko-50 to-transparent"></div>
    </Wrapper>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton'

import { useSlice } from '../useSlice'
const { makeMoreThan10 } = useSlice()

export default {
  name: 'PressListCarouselBlue',
  components: {
    ArrowButton,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageLoad: false,
      animate: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return makeMoreThan10(this.slice.items).filter((item) => item.logo?.url)
    },
  },
  mounted() {
    const carousel = this.$refs.carousel
    carousel.style.display = 'block'
    carousel.style.setProperty('--carousel-items', this.items.length)
  },
  methods: {
    onVisible(visible) {
      if (!this.imageLoad && visible) {
        this.imageLoad = true
      }
      this.animate = visible
    },
  },
}
</script>

<style lang="scss" module>
.carousel {
  --carousel-items: 0;
  --carousel-item-width: 180px;
  --carousel-item-margin: 80px;

  display: none;
  width: calc(var(--carousel-items) * (var(--carousel-item-width) + var(--carousel-item-margin)) * 2 + 4.1px); // I don't know why there is 4.1px
}
.animate {
  animation: scroll 50s linear infinite;
}

.logoContainer {
  display: inline-block;
  width: var(--carousel-item-width);
  margin: 0;
  margin-right: var(--carousel-item-margin);
  padding: 0;

  text-align: center;
}
.logo {
  width: var(--carousel-item-width);
  height: 64px;
  margin: 0;
  padding: 0;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * var(--carousel-items) * (var(--carousel-item-width) + var(--carousel-item-margin))));
  }
}
</style>
