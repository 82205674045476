<template>
  <div class="m-0 my-[144px] lg:my-[80px]">
    <Wrapper class="flex gap-[10%] p-[24px] lg:max-w-[720px] lg:flex-col lg:gap-[70px]" :class="{ ['flex-row-reverse']: primary.reverse }">
      <aside class="flex-1">
        <p class="mb-[8px] text-[14px] font-[700] uppercase leading-[16px] text-gray-200 sm:hidden">{{ primary.caption }}</p>
        <Title :title-params="primary.title" class="mb-[24px] text-[40px] font-[300] leading-[48px] sm:text-center" />
        <RichText :text-params="primary.description" class="font-[450] sm:text-center [&>p]:mb-[8px] sm:[&>p]:mb-[24px]" />
        <div v-if="primary.ctaText" class="mt-[24px] lg:text-center">
          <ArrowButton :link-object="primary.ctaLink" is-outlined :link-text="primary.ctaText" />
        </div>
      </aside>
      <div
        v-observe-visibility="{
          callback: isVisible,
          once: true,
          intersection: {
            rootMargin: '-30% 0px -30% 0px',
          },
        }"
        class="flex flex-1 flex-col items-center justify-center"
      >
        <Title
          :title-params="primary.graphTitle"
          class="mb-[32px] text-center text-[22px] leading-[32px] text-bluko-800 sm:text-[14px] sm:leading-[18px] [&>strong]:font-[500]"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :width="graph.width"
          :height="graph.height"
          :viewBox="`0 0 ${graph.width} ${graph.height}`"
          fill="none"
          class="h-full w-full"
        >
          <!-- yAxis -->
          <text
            v-for="(label, idx) in yAxis"
            :key="'y-' + idx"
            :x="20"
            :y="graph.height - graph.baseHeight - idx * ((graph.height - graph.baseHeight) / (yAxis.length - 1)) + graph.paddingHeight"
            fill="#6B6651"
            class="translate-y-[5px] text-[12px]"
            text-anchor="middle"
          >
            {{ label }}{{ [0, yAxis.length - 1, parseInt(yAxis.length / 2)].includes(idx) ? '€' : '' }}
          </text>
          <!-- xAxis -->
          <path
            :d="`M${graph.baseWidth - 10} ${graph.height - graph.baseHeight + graph.paddingHeight} H${graph.width}`"
            stroke="#F4F3EF"
            stroke-width="1"
          ></path>
          <text
            v-for="(label, idx) in xAxis"
            :key="'x-' + idx"
            :x="graph.baseWidth + idx * ((graph.width - graph.baseWidth) / xAxis.length)"
            :y="graph.height - graph.baseHeight + 40"
            fill="black"
            class="text-[12px]"
            text-anchor="middle"
          >
            {{ label }}
          </text>
          <!-- vertical line -->
          <path
            v-for="(label, idx) in xAxis"
            :key="'xv-' + idx"
            :d="`M${graph.baseWidth + idx * ((graph.width - graph.baseWidth) / xAxis.length)} ${graph.paddingHeight} V${
              graph.height - graph.baseHeight
            }`"
            stroke="#2C2302"
            stroke-width="2"
            stroke-dasharray="2, 8"
            stroke-opacity="0.1"
          ></path>
          <!-- min max line -->
          <path :d="`M${graph.baseWidth - 10} ${minCoordinate.y} H${graph.width}`" stroke-width="1" stroke="#F4F3EF"></path>
          <path :d="`M${graph.baseWidth - 10} ${maxCoordinate.y} H${graph.width}`" stroke-width="1" stroke="#F4F3EF"></path>
          <!-- plot data -->
          <path
            :d="smoothD(coordinates)"
            stroke-width="4"
            stroke="#D95762"
            stroke-linecap="round"
            :class="{ [$style.drawPath]: animated }"
            stroke-dasharray="1000"
            stroke-dashoffset="1000"
          ></path>
          <!-- bubble -->
          <g :class="{ [$style.circle]: true, [$style.circleBegin]: animated }">
            <circle :cx="minCoordinate.x" :cy="minCoordinate.y" r="18" fill="white" filter="drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15))" />
            <text :x="minCoordinate.x" :y="minCoordinate.y" text-anchor="middle" fill="#A0353D" class="translate-y-[4px] text-[14px] font-[500]">
              {{ minCoordinate.value }}€
            </text>
          </g>
          <g :class="{ [$style.circle]: true, [$style.circleEnd]: animated }">
            <circle :cx="maxCoordinate.x" :cy="maxCoordinate.y" r="18" fill="white" filter="drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15))" />
            <text :x="maxCoordinate.x" :y="maxCoordinate.y" text-anchor="middle" fill="#A0353D" class="translate-y-[4px] text-[14px] font-[500]">
              {{ maxCoordinate.value }}€
            </text>
          </g>
        </svg>
        <p v-if="primary.source" class="mt-[15px] text-center text-[8px] text-gray-300">{{ primary.source }}</p>
        <span
          v-if="primary.graphDescription"
          class="
            mt-[30px]
            rounded-[36px]
            border-[1px] border-solid border-terracota-600
            p-[6px]
            py-[18px]
            text-center text-terracota-600
            sm:text-[2vw]
          "
          >{{ primary.graphDescription }}</span
        >
      </div>
    </Wrapper>
  </div>
</template>
<script>
import ArrowButton from '@/components/ArrowButton'
import { useSvg } from '@/composables/useSvg'

const { smoothPath } = useSvg()

export default {
  components: {
    ArrowButton,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      animated: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    graph() {
      return {
        width: 640,
        height: 400,
        data: this.slice.items.map((d) => ({ ...d, value: parseInt(d.value) })),
        baseHeight: 55,
        baseWidth: 70,
        paddingHeight: 8,
      }
    },
    valueMinMax() {
      // [min, max]
      return this.graph.data.reduce((res, d) => [Math.min(res[0], d.value), Math.max(res[1], d.value)], [Infinity, -Infinity])
    },
    yAxis() {
      const min = this.valueMinMax[0] % 10 > 5 ? Math.floor(this.valueMinMax[0] / 10) * 10 + 5 : Math.floor(this.valueMinMax[0] / 10) * 10
      const max = this.valueMinMax[0] % 10 > 5 ? Math.ceil(this.valueMinMax[1] / 10) * 10 : Math.ceil(this.valueMinMax[1] / 10) * 10 - 5
      const res = []
      let i = min
      while (i !== max) {
        res.push(i)
        i += 5
      }
      return res
    },
    xAxis() {
      return this.graph.data.map((d) => d.year)
    },
    coordinates() {
      return this.graph.data.map((d, idx) => ({
        x: this.graph.baseWidth + idx * ((this.graph.width - this.graph.baseWidth) / this.graph.data.length),
        y:
          ((this.graph.height - this.graph.baseHeight) * (d.value - this.yAxis[this.yAxis.length - 1])) /
            (this.yAxis[0] - this.yAxis[this.yAxis.length - 1]) +
          this.graph.paddingHeight,
        value: d.value,
      }))
    },
    minCoordinate() {
      return this.coordinates.find((d) => d.value === this.valueMinMax[0])
    },
    maxCoordinate() {
      return this.coordinates.find((d) => d.value === this.valueMinMax[1])
    },
  },
  methods: {
    smoothD(points) {
      return smoothPath(points)
    },
    isVisible(visible) {
      if (visible) {
        this.animated = visible
      }
    },
  },
}
</script>
<style lang="scss" module>
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.drawPath {
  animation: dash 6s ease-in-out forwards;
}

.circle {
  transform: translateY(-20px);
  opacity: 0;
}

.circleBegin {
  transform: translateY(0);
  opacity: 1;

  transition: 0.2s all ease-in-out;
  transition-delay: 3.5s;
}
.circleEnd {
  transform: translateY(0);
  opacity: 1;

  transition: 0.2s all ease-in-out;
  transition-delay: 4s;
}
</style>
