<template>
  <SliceWrapper :class="$style.container" :slice="slice">
    <Wrapper :class="$style.inner">
      <RichText :text-params="primary.title" class="typo-large-title" />
      <ul :class="$style.list">
        <li
          v-for="(item, itemIndex) in articles"
          :key="itemIndex"
          :class="$style.item"
          data-aos="fade-up"
          :data-aos-delay="(itemIndex % 4) * 100"
          data-aos-duration="800"
        >
          <PLink :link-object="{ type: item.type, link_type: 'Document', uid: item.uid, lang: item.lang }" :class="$style.link">
            <PImage :class="$style.thumbnail" :image-object="item.thumbnail" />
            <div :class="$style.content">
              <PImage :class="$style.icon" :image-object="item.icon" />
              <p :class="$style.thumbnailTitle">{{ item.title }}</p>
            </div>
          </PLink>
        </li>
      </ul>
    </Wrapper>
  </SliceWrapper>
</template>

<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  async fetch() {
    const ids = this.slice.items.map((item) => item.article.id).filter((id) => !!id)

    const articles = await this.$prismic.api.getByIDs(ids)
    this.articles = articles.results.map(({ uid, type, lang, data }, id) => {
      const heroSlice = data.body.find((s) => ['hero', 'hero_launch'].includes(s.slice_type))

      return {
        uid,
        type,
        lang,
        title: heroSlice?.primary.title[0].text,
        thumbnail: heroSlice?.primary.backgroundImage,
        icon: this.items[id].icon,
      }
    })
  },

  data() {
    return {
      articles: [],
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 128px 0;
  padding: 0 24px;
}

.list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
  margin-top: 24px;

  @include above(xsmall) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include above(small) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include above(medium) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.item {
  @include above(small) {
    &:nth-child(n + 4) {
      display: none;
    }
  }
  @include above(medium) {
    &:nth-child(n + 4) {
      display: block;
    }
  }
}

.thumbnail {
  display: block;
  width: 100%;
  height: 160px;
  border-radius: 8px;
  object-fit: cover;
}

.link {
  display: block;
  width: 100%;

  color: $gray-1000;
  text-decoration: none;
}

.content {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  margin-top: 8px;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.thumbnailTitle {
  margin-left: 8px;

  font-weight: 500;
}
</style>
