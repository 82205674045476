<template>
  <div>
    <ul class="grid gap-[8px]" :class="{ ['grid-cols-3 xs:grid-cols-2']: cards.length === 3, ['grid-cols-4 sm:grid-cols-2']: cards.length === 4 }">
      <li
        v-for="(card, index) in cards"
        :key="index"
        class="flex min-h-[104px] items-center justify-center rounded-[8px] bg-white text-center leading-[24px]"
        :class="{ ['xs:first-of-type:col-span-2']: cards.length === 3 }"
        :data-luko-tracking="card.i18nKey"
      >
        <PLink
          class="flex !w-full flex-col items-center justify-center p-[16px] no-underline"
          :simple-url="
            getOnboardingLink(null, {
              type: card.onboardingType,
              queryParams: card.queryParams,
            })
          "
          @click="onCardClick(card.product)"
        >
          <component :is="card.icon" />
          <span class="mt-[10px] font-[450] text-black">
            {{ $t(`HomePage.Cards.${card.i18nKey}`) }}
          </span>
        </PLink>
      </li>
    </ul>
  </div>
</template>
<script>
import HouseIcon from 'LkIcons/House.vue'
import eScooterIcon from 'LkIcons/eScooter.vue'
import BankIcon from 'LkIcons/Bank.vue'
import KeyIcon from 'LkIcons/Key.vue'
import BeachIcon from 'LkIcons/Beach.vue'
import PawIcon from 'LkIcons/Paw.vue'
import CouchIcon from 'LkIcons/Couch.vue'
import PersonIcon from 'LkIcons/Person.vue'
import CashIcon from 'LkIcons/Cash.vue'

import { ONBOARDING_TYPE } from '@/utils/constants'
import { getOnboardingLink } from '@/mixins/getOnboardingLink'

const CardList = {
  fr: [
    {
      i18nKey: 'Housing',
      icon: 'HouseIcon',
      onboardingType: ONBOARDING_TYPE.home,
      product: 'FR_HOME',
      queryParams: [['$.product', 'FR_HOME']],
    },
    {
      i18nKey: 'Mortgage',
      icon: 'BankIcon',
      onboardingType: ONBOARDING_TYPE.mortgage,
      product: 'FR_MORTGAGE',
      queryParams: [['$.product', 'FR_MORTGAGE']],
    },
    {
      i18nKey: 'Rent',
      icon: 'CashIcon',
      onboardingType: ONBOARDING_TYPE.gli,
      product: 'FR_RENT',
      queryParams: [['$.product', 'FR_RENT']],
    },
    {
      i18nKey: 'eScooter',
      icon: 'eScooterIcon',
      onboardingType: ONBOARDING_TYPE.escooter,
      product: 'FR_ESCOOTER',
    },
  ],
  es: [
    {
      i18nKey: 'Home',
      icon: 'HouseIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [
        ['$.home.main_asset.is_main', 'true'],
        ['$.product', 'ES_HOME'],
      ],
      product: 'ES_HOME',
    },
    {
      i18nKey: 'SecondaryHome',
      icon: 'BeachIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [
        ['$.home.main_asset.is_main', 'false'],
        ['$.product', 'ES_SECONDARY'],
      ],
      product: 'ES_SECONDARY',
    },
    {
      i18nKey: 'Landlord',
      icon: 'KeyIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [
        ['$.home.main_asset.owner_type', 'landlord'],
        ['$.product', 'ES_LANDLORD'],
      ],
      product: 'ES_LANDLORD',
    },
  ],
  de: [
    {
      i18nKey: 'Pet',
      icon: 'PawIcon',
      onboardingType: ONBOARDING_TYPE.pet,
      product: 'PET',
    },
    {
      i18nKey: 'HomeContents',
      icon: 'CouchIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [['$.product', 'DE_HOME_CONTENT']],
      product: 'DE_HOME_CONTENT',
    },
    {
      i18nKey: 'PrivateLiability',
      icon: 'PersonIcon',
      onboardingType: ONBOARDING_TYPE.home,
      queryParams: [['$.product', 'DE_LIABILITY_PRIVATE']],
      product: 'DE_LIABILITY_PRIVATE',
    },
  ],
}

export default {
  components: {
    HouseIcon,
    eScooterIcon,
    BankIcon,
    KeyIcon,
    BeachIcon,
    PawIcon,
    CouchIcon,
    PersonIcon,
    CashIcon,
  },

  mixins: [getOnboardingLink],

  props: {
    type: {
      type: String,
      default: 'None',
    },

    text: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cards: CardList[process.env.CURRENT_DOMAIN],
    }
  },
  methods: {
    onCardClick(product) {
      window.LukoTracking.trackEvent({
        id: 'CTA Clicked',
        properties: { onboarding: { product, step: 'homepage' } },
      })
    },
  },
}
</script>
