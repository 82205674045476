<template>
  <main>
    <component :is="slice.slice_type" v-for="(slice, sliceIndex) in slices" :key="sliceIndex" :slice="slice" />
  </main>
</template>

<script>
import {
  Hero,
  LogoList,
  MediaCarousel,
  TrustpilotCarousel,
  HighlightCounter,
  TrustpilotComparison,
  CustomerService,
  PressListCarousel,
  CertificationSection,
  GivebackSchema,
  CommitmentSection,
  CustomerServiceCarousel,
  ProcessArrow,
  ProductCarousel,
  InstagramUgc,
} from '@@/shared-slices'

export default {
  name: 'HomeModule',
  components: {
    hero: Hero,
    logo_list: LogoList,
    media_carousel: MediaCarousel,
    trustpilot_carousel: TrustpilotCarousel,
    highlight_counter: HighlightCounter,
    trustpilot_comparison: TrustpilotComparison,
    customer_service: CustomerService,
    press_list_carousel: PressListCarousel,
    giveback_schema: GivebackSchema,
    certification_section: CertificationSection,
    commitment_section: CommitmentSection,
    customer_service_carousel: CustomerServiceCarousel,
    process_arrow: ProcessArrow,
    product_carousel: ProductCarousel,
    instagram_ugc: InstagramUgc,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    slices() {
      return this.data.body
    },
  },
}
</script>
