<template>
  <div v-observe-visibility="{ callback: isVisible, once: true }" class="my-[128px] lg:my-[64px]">
    <Wrapper class="flex gap-[4%] p-[24px] lg:!flex-col lg:gap-[60px]" :class="{ ['flex-row-reverse']: primary.reverse }">
      <aside class="mx-auto flex max-w-[800px] flex-1 flex-col justify-center lg:items-center">
        <Counter
          v-if="primary.title"
          :text="primary.title"
          :launch="visible"
          class="mb-[24px] !font-[300] typo-extra-large-title lg:text-center sm:typo-large-title"
          :sec="2"
        />
        <RichText :text-params="primary.description" class="font-[450] lg:text-center [&>p]:mb-[8px] lg:[&>p]:mb-[24px]" />
        <InfoTooltip v-if="primary.info" :tooltip-text-params="primary.infoText" class="mt-[24px]">
          {{ primary.info }}
        </InfoTooltip>
      </aside>
      <figure
        v-observe-visibility="{
          callback: isVisibleGraph,
          once: true,
          intersection: {
            rootMargin: '-30% 0px -30% 0px',
          },
        }"
        class="flex flex-1 flex-col items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :width="graph.width"
          :height="graph.height"
          :viewBox="`0 0 ${graph.width} ${graph.height}`"
          fill="none"
          class="h-full w-full max-w-[700px]"
        >
          <!-- xAxis -->
          <text
            v-for="(c, idx) in coordinates"
            :key="'x-' + idx"
            :x="c.x"
            :y="graph.height - 10"
            fill="black"
            class="text-[12px]"
            text-anchor="middle"
          >
            {{ xAxis[idx] }}
          </text>
          <!-- vertical line -->
          <path
            v-for="(c, idx) in coordinates"
            :key="'xv-' + idx"
            :d="`M${c.x} ${graph.paddingHeight} V${graph.height - graph.paddingHeight}`"
            stroke="#2C23024D"
            stroke-width="2"
            stroke-dasharray="2, 8"
          ></path>
          <!-- plot data -->
          <path
            :d="smoothD(pathCoordinates)"
            stroke-width="4"
            stroke="#2D50E6"
            :class="{ [$style.drawPath]: visibleGraph }"
            stroke-linecap="round"
            stroke-dasharray="1000"
            stroke-dashoffset="1000"
          ></path>
          <!-- circle -->
          <g
            v-for="(c, idx) in coordinates"
            :key="'circle-' + idx"
            class="scale-0 opacity-0 transition-transform delay-[2s] duration-1000"
            :class="{ [$style.circleAnimation]: visibleGraph }"
          >
            <circle :cx="c.x" :cy="c.y" r="16" fill="white" filter="drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.15))" />
            <text :x="c.x" :y="c.y" text-anchor="middle" fill="#122182" class="translate-y-[4px] text-[12px] font-[600]">
              {{ c.text }}
            </text>
          </g>
        </svg>
        <span
          v-if="primary.graphDescription"
          class="mt-[30px] rounded-[36px] border-[1px] border-solid border-bluko-500 py-[6px] px-[18px] text-center text-bluko-500 sm:text-[2vw]"
        >
          {{ primary.graphDescription }}
        </span>
        <figcaption v-if="primary.source" class="mt-[48px] text-center text-[8px] text-gray-300">{{ primary.source }}</figcaption>
      </figure>
    </Wrapper>
  </div>
</template>

<script>
import Counter from '@/components/Counter'
import InfoTooltip from '@/components/InfoTooltip'
import { useSvg } from '@/composables/useSvg'

const { smoothPath } = useSvg()

export default {
  components: {
    InfoTooltip,
    Counter,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      visibleGraph: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    graph() {
      const paddingWidth = 40
      const xGap = 65
      return {
        data: this.slice.items.map((d) => ({ ...d, label: d.value, value: parseInt(d.value) })),
        width: this.slice.items.length * xGap * 2 + paddingWidth * 2,
        height: 280,
        paddingHeight: 35,
        paddingWidth,
        valueMin: parseInt(this.primary.valueMin) || 0,
        valueMax: parseInt(this.primary.valueMax) || 100,
        xGap,
      }
    },
    xAxis() {
      return this.graph.data.map((d) => d.month)
    },
    coordinates() {
      return this.graph.data.map((d, idx) => {
        return {
          x: idx * this.graph.xGap * 2 + this.graph.xGap + this.graph.paddingWidth,
          y:
            ((this.graph.height - this.graph.paddingHeight * 2) * (this.graph.valueMax - d.value)) / (this.graph.valueMax - this.graph.valueMin) +
            this.graph.paddingHeight,
          value: d.value,
          text: d.label,
        }
      })
    },
    pathCoordinates() {
      const first = this.coordinates[0]
      const last = this.coordinates[this.coordinates.length - 1]
      const res = [...this.coordinates]

      if (this.primary.tailData) {
        res.unshift({ ...first, x: first.x - 30 })
        res.push({ ...last, x: last.x + 30 })
      }
      return res
    },
  },
  methods: {
    smoothD(points) {
      return smoothPath(points)
    },
    isVisible(visible) {
      if (visible) this.visible = visible
    },
    isVisibleGraph(visible) {
      if (visible) this.visibleGraph = visible
    },
  },
}
</script>
<style lang="scss" module>
.circleAnimation {
  transform: scale(1) !important;
  transform-origin: top;
  opacity: 1 !important;

  transform-box: fill-box;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.drawPath {
  animation: dash 4s ease-in-out forwards;
}
</style>
