<template>
  <div class="my-[128px] px-[24px]">
    <Title :title-params="primary.title" class="mx-auto mt-[128px] mb-[80px] max-w-[840px] text-center typo-extra-large-title" />
    <Wrapper class="flex flex-col gap-[128px] sm:gap-[80px]">
      <div
        v-for="(item, itemIndex) in items"
        :id="blockId(item.title[0].text)"
        :key="itemIndex"
        :class="[$style.content, (primary.is_reverse || isReverse) && $style.reverseContent]"
        data-aos="fade-up"
      >
        <div class="max-w-[620px] flex-1">
          <PImage class="max-h-[640px] !w-full rounded-[8px] object-cover" :image-object="item.image" sizes="sm:90vw" :quality="80" />
        </div>
        <div class="flex-1 px-[60px] md:p-0 sm:mt-[32px]">
          <PImage v-if="item.icon && item.icon.url" class="mb-[16px] h-[56px] w-[56px] md:hidden" :image-object="item.icon" />
          <div>
            <Title class="mb-[10px] inline !font-[300] typo-large-title [&>h3]:inline" :title-params="item.title" />
            &nbsp;
            <span
              v-if="item.tag"
              class="inline-block -translate-y-[4px] rounded-[4px] bg-bluko-100 p-[6px] text-[14px] font-[500] uppercase text-bluko-500"
            >
              {{ item.tag }}
            </span>
          </div>
          <RichText :text-params="item.text" :class="$style.contentDescription" />
          <div v-if="item.trustpilot" class="mt-[10px] flex items-center">
            <RichText :text-params="item.trustpilot_text" class="text-black sm:text-[12px]" />
            <img src="@/assets/icons/socials/trustpilot-stars.svg" alt="trustpilot icon" class="mx-[10px]" />
            <img src="@/assets/icons/socials/trustpilot-star.svg" alt="trustpilot icon" />
          </div>

          <CtaBox :item="item" />

          <div v-if="item.has_app_link" class="flex gap-[24px] sm:justify-center sm:gap-[16px]">
            <PLink :link-object="primary.cta_app_store">
              <PImage :image-object="primary.image_app_store" :class="$style.app_badge" sizes="xs:200px" />
            </PLink>
            <PLink :link-object="primary.cta_google_play">
              <PImage :image-object="primary.image_google_play" :class="$style.app_badge" sizes="xs:200px" />
            </PLink>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import PImage from '@/components/PImage'

import CtaBox from './components/CtaBox.vue'

export default {
  name: 'QuinconceSlice',
  components: {
    PImage,
    CtaBox,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },

  methods: {
    blockId(title) {
      const formatedTitle = title
        .split(' ')
        .map((e) => e.split(''))
        .filter((e) => e.length > 1)
        .map((e, i, a) => e.join(''))
        .join('-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      return formatedTitle
    },
  },
}
</script>

<style lang="scss" module>
.content {
  &:first-child {
    margin-top: 0;
  }

  @include above(small) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    column-gap: 24px;

    &:nth-child(2n + 1) {
      flex-direction: row-reverse;
    }

    @include above(small) {
      column-gap: 32px;
    }
    @include above(large) {
      column-gap: 40px;
    }
  }
}

.reverseContent {
  @include above(small) {
    &:nth-child(2n + 1) {
      flex-direction: row !important;
    }
    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
  }
}

.contentDescription {
  margin-top: 16px;

  color: $gray-700;

  ul > li {
    padding-top: 0;
    padding-bottom: 8px;
  }
  b,
  strong {
    font-weight: 500;
  }
}

.app_badge {
  height: 48px;

  @media screen and (max-width: 374px) {
    width: 40vw;
    height: auto;
  }
}
</style>
