<template>
  <SliceWrapper
    v-observe-visibility="{ callback: lazyLoadImage, once: true }"
    class="relative mt-[156px] flex h-[710px] items-stretch lg:h-[600px] md:flex-col md:items-center sm:mt-[60px]"
    :slice="slice"
  >
    <div class="flex h-full w-1/2 flex-1 items-center justify-end bg-orange-50 lg:justify-center lg:p-[32px] md:w-full md:flex-initial sm:p-[24px]">
      <div :class="$style.table">
        <div v-observe-visibility="isVisible">
          <h2 class="pt-[32px] text-center text-[84px] font-[300] lg:pt-[30px] lg:text-[64px]">
            {{ $n(trustScore, 'score') }}<span class="text-[64px] text-gray-300 lg:text-[26px]">/5</span>
          </h2>
        </div>
        <p :class="$style.description">
          {{ primary.description.replace(/\{.*\}/, scoreData.numberOfReviews) }}
        </p>

        <div class="my-[46px] mx-auto w-4/5 md:my-[26px] md:text-[14px]">
          <div :class="$style.item">
            <LukoLogoBlue class="h-[28px] md:h-[18px] md:w-[40px]" :class="[{ [$style.animation]: visible }]" />
            <TrustpilotScore :score="scoreData.trustScore" :size="32" :class="$style.starScore" animation :get-animation-score="getAnimationScore" />
          </div>
          <div v-for="(item, index) in items" :key="index" :class="$style.item">
            <div>{{ item.assurance }}</div>
            <TrustpilotScore :score="item.score" :size="32" is-gray animation :class="$style.starScore" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="h-full w-1/2 flex-1 bg-bluko-500 bg-cover bg-center bg-no-repeat md:h-[225px] md:w-full md:flex-initial"
      :style="backgroundStyles(loadImage ? primary.image : null, 'sm:120vw')"
    ></div>
  </SliceWrapper>
</template>

<script>
import scoreData from '@@/script/trustpilot/score.json'

import { backgroundStyles } from '@/mixins/backgroundStyles'
import TrustpilotScore from '@/components/TrustpilotScore'
import LukoLogoBlue from '@/assets/icons/luko-logo-blue.svg?inline'

export default {
  name: 'TrustpilotComparison',
  components: {
    TrustpilotScore,
    LukoLogoBlue,
  },
  mixins: [backgroundStyles],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scoreData,
      trustScore: scoreData.trustScore,
      visible: false,
      loadImage: false,
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },

  methods: {
    isVisible(visible) {
      this.visible = visible
    },
    getAnimationScore(score) {
      this.trustScore = score
    },
    lazyLoadImage(visible) {
      if (visible) {
        this.loadImage = true
      }
    },
  },
}
</script>

<style lang="scss" module>
.table {
  width: 503px;
  margin-right: 88px;
  border-radius: 50% 50% 0 0;

  background-color: white;

  @include below(large) {
    width: 440px;
    margin-right: 20px;
  }

  @include below(medium) {
    width: 330px;
    margin: 0;
  }
  @include below(small) {
    width: calc(min(330px, 90vw));
    margin: 0;
  }
}

.description {
  width: 50%;
  margin: 10px auto 0;

  font-weight: 200;

  font-size: 30px;
  line-height: 32px;
  text-align: center;

  @include below(medium) {
    width: 60%;
    margin: 10px auto 0;

    font-size: 24px;
    line-height: 32px;
  }
  @media screen and (max-width: 374px) {
    font-size: 20px;
    line-height: 22px;
  }
}
.item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 20px 0;

  color: $gray-400;
  font-size: 24px;
  @include below(large) {
    margin: 16px 0 0;

    font-size: 20px;
  }
  @include below(medium) {
    margin: 12px 0 0;

    font-size: 15px;
  }
  @media screen and (max-width: 374px) {
    margin: 10px 0 0;

    font-size: 12px;
  }
}
.starScore {
  display: flex;
  align-items: center;
  justify-content: right;
  @include below(large) {
    svg {
      width: 25px;
      height: 25px;
    }
  }
  @include below(medium) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 375px) {
    svg {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }
}
.animation {
  animation: jump 0.5s ease-in-out 2;
}
@keyframes jump {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
