<template>
  <div>
    <div class="flex cursor-pointer items-center justify-between border-b-[1px] border-solid border-bluko-100 pb-[8px]" @click="onActive">
      <slot name="head"></slot>
    </div>
    <div ref="body" class="h-0 overflow-hidden transition-[height] duration-200">
      <div ref="content">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    onActive() {
      this.isActive = !this.isActive

      const c = this.$refs.content
      this.isActive ? this.open(c.offsetHeight) : this.close()
    },
    open(height) {
      this.$refs.body.setAttribute('style', `height: ${height}px`)
    },
    close() {
      this.$refs.body.setAttribute('style', `height: 0`)
    },
  },
}
</script>
