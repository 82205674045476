<template>
  <div v-if="items.length">
    <ul v-if="isCard" class="mt-[40px] flex flex-wrap justify-around gap-[10px] sm:justify-center">
      <li v-for="(item, idx) in items" :key="idx" class="flex-1 flex-wrap">
        <PLink
          :link-object="item.link"
          class="flex h-full min-w-[140px] flex-col items-center gap-[10px] rounded-[20px] bg-white p-[20px] text-center"
        >
          <PImage :image-object="item.icon" class="h-[22px] w-[22px]" />
          <span class="!font-[500] typo-sub-text">{{ item.text }}</span>
        </PLink>
      </li>
    </ul>
    <ul v-else class="mt-[40px]">
      <li v-for="(item, idx) in items" :key="idx" class="flex flex-col gap-[16px]">
        <ArrowLink :link-object="item.link" class="text-bluko-500 path-bluko-500" :link-text="item.text" />
      </li>
    </ul>
  </div>
</template>
<script>
import ArrowLink from '@/components/ArrowLink'

export default {
  name: 'HeroItems',
  components: {
    ArrowLink,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isCard() {
      return this.items.find((item) => item.icon.url)
    },
  },
}
</script>
