<template>
  <div class="py-[80px] px-[24px]" :class="[{ 'bg-orange-25': slice.variation === 'default' }, { 'bg-bluko-50': slice.variation === 'lightBlue' }]">
    <Wrapper>
      <Title :title-params="primary.title" class="mx-auto text-center !font-[300] typo-mega-title sm:hidden" />
      <Title :title-params="primary.mobile_title" class="mx-auto text-center !font-[300] typo-large-title up-sm:hidden" />
      <RichText :text-params="primary.description" class="mx-auto mt-[24px] max-w-[800px] text-center opacity-[0.6] sm:hidden" />
      <transition-group
        class="mx-auto mt-[64px] max-w-[1024px] transition-all sm:mt-[40px]"
        :class="[$style.cards, { 'max-w-[720px]': items.length < 3 }]"
        name="list"
      >
        <div v-for="item in items" :key="item.icon.url" :class="$style.card">
          <InfoIcon class="absolute right-[14px] top-[14px] h-[14px] w-[14px] path-bluko-200" :class="$style.infoIcon" />
          <PImage :image-object="item.icon" class="mx-auto h-[30px] w-[30px]" />
          <RichText :text-params="item.title" class="mt-[10px]" />
          <RichText :text-params="item.info_text" :class="$style.infoTooltip" />
        </div>
      </transition-group>

      <div class="text-center">
        <div
          v-if="slice.items.length > 6 && !isEnlarged"
          class="relative cursor-pointer justify-center font-[500] text-bluko-500"
          @click="isEnlarged = true"
        >
          <div :class="$style.cards" class="mt-[24px] h-[100px] overflow-hidden">
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <div v-for="_ in [1, 2]" :class="$style.card">
              <InfoIcon class="absolute right-[14px] top-[14px] h-[14px] w-[14px] path-bluko-200" :class="$style.infoIcon" />
            </div>
          </div>
          <div
            class="absolute bottom-0 flex w-full items-center justify-center bg-gradient-to-b from-transparent via-orange-25 to-orange-25 pt-[48px]"
          >
            <ChevronDownIcon /> {{ primary.see_more_options }}
          </div>
        </div>
        <LkButton v-if="primary.cta_link && primary.cta_link.url" :link-object="primary.cta_link" class="mt-[64px]">
          {{ primary.cta_text }}
        </LkButton>
      </div>
    </Wrapper>
  </div>
</template>
<script>
import InfoIcon from 'LkIcons/Info.vue'
import ChevronDownIcon from 'LkIcons/ChevronDown.vue'
import { debounce } from 'lodash-es'

export default {
  components: { InfoIcon, ChevronDownIcon },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSmall: false,
      isEnlarged: true,
      resizeCallback: null,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      if (this.isSmall && this.slice.items.length > 6 && !this.isEnlarged) {
        return this.slice.items.slice(0, 6)
      }
      return this.slice.items
    },
  },
  mounted() {
    if (window.innerWidth < 540) {
      this.isSmall = true
      this.isEnlarged = false
    }
    this.resizeCallback = debounce(() => {
      this.isSmall = window.innerWidth < 540
      this.isEnlarged = !this.isSmall
    }, 200)

    window.addEventListener('resize', this.resizeCallback)
  },
  unmount() {
    window.removeEventListener(this.resizeCallback)
  },
}
</script>
<style module lang="scss">
.cards {
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(135px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 24px;
  @include below(xsmall) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
.infoTooltip {
  @apply py-[8px] px-[12px] shadow-xl transition-all duration-300;
  position: absolute;
  bottom: calc(100% + 20px);
  left: 50%;

  width: calc(min(260px, 50vw));
  border-radius: 8px;

  background-color: white;
  transform: translateX(-50%);
  opacity: 0;

  pointer-events: none;
}

.infoTooltip::after {
  position: absolute;
  left: 50%;

  width: 30px;
  height: 10px;

  background-image: url('~assets/icons/header/menu-pointer.svg');
  background-repeat: no-repeat;
  transform: translateX(-50%) translateY(5px) rotate(180deg);

  content: ' ';
}
.card {
  @apply flex-1 shrink-0 cursor-pointer rounded-[8px] py-[24px] px-[12px];
  position: relative;

  width: 100%;
  border: 2px solid transparent;

  text-align: center;

  background: white;
}
.card:hover {
  @apply border-bluko-500;
  .infoTooltip {
    opacity: 1;
  }
  .infoIcon {
    path {
      fill: $bluko-500;
    }
  }
}
</style>
<style scoped>
.list-enter-active,
.list-leave-active {
  transform: translateY(0);

  transition: opacity 0.8s, transform 0.8s;
}
.list-leave-to {
  display: none;
}
.list-enter /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);

  opacity: 0;
}
</style>
