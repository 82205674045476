<template>
  <div class="relative mt-[-64px] h-[800px] overflow-x-hidden bg-cover bg-left-top md:h-[720px] sm:h-auto" :class="$style.root">
    <Wrapper class="flex h-full gap-[40px] pt-[120px] sm:flex-col sm:gap-[20px] sm:pt-[96px]">
      <div class="flex flex-[1.2] flex-col justify-between gap-[100px] pl-[24px] sm:mx-auto sm:flex-none sm:gap-[38px] sm:px-[24px]">
        <div class="flex h-full flex-col justify-between gap-[24px]">
          <Breadcrumb v-if="primary.showBreadcrumb" :is-dark="isDark" :custom-name="primary.title[0].text" class="order-1" />

          <div class="order-2 max-w-[610px] md:max-w-[unset]">
            <Title class="mb-[16px] !text-white typo-mega-title md:typo-large-title sm:typo-extra-large-title" :title-params="primary.title" />
            <RichText :text-params="primary.subtitle" :class="$style.subtitle" class="!text-white opacity-80 typo-headline md:typo-sub-text" />
          </div>

          <ArrowButton has-icon-right is-white :link-object="primary.ctaLink" :link-text="primary.ctaText" class="order-3 sm:order-4 sm:!w-full" />

          <div
            v-if="TpScore >= 4.3 || GoogleScore >= 4.0"
            class="order-4 flex items-center gap-[24px] sm:order-3 sm:mb-[18px] sm:mt-[12px] sm:justify-center"
          >
            <TrustpilotWidget class="inline-flex" color="white" />
            <GoogleReviewWidget is-dark class="inline-flex path-bluko-300" />
          </div>
        </div>

        <!-- USP -->
        <div>
          <div v-if="items.length" class="flex w-full justify-evenly border-t-[1px] border-solid border-bluko-300">
            <RichText v-for="(item, index) in items" :key="index" :text-params="item.text" :class="$style.itemText" />
          </div>
          <div
            v-else-if="primary.logoStripe && primary.logoStripe.url"
            class="bottom-0 border-t-[1px] border-solid border-bluko-300 py-[24px] text-center"
          >
            <PImage :image-object="primary.logoStripe" sizes="sm:100vw" class="h-[40px] xs:hidden" />
            <PImage :image-object="primary.mobileLogoStripe" sizes="xs:100vw" class="h-[128px] w-full up-xs:hidden" />
          </div>
        </div>
      </div>

      <div class="relative bottom-0 h-[100%] flex-1 shrink-0 sm:flex">
        <div
          class="absolute bottom-0 left-[10%] mt-[24px] h-[60vw] max-h-[660px] w-[60vw] max-w-[660px] overflow-hidden rounded-t-[50%] sm:hidden"
          :class="$style.image"
        >
          <PImage preload :image-object="primary.backgroundImage" class="h-full w-full object-cover" sizes="xs:100vw md:100vw lg:100vw" />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import TpScore from '@@/script/trustpilot/score.json'
import GoogleScore from '@@/script/googleReview/score.json'

import TrustpilotWidget from '@/components/TrustpilotWidget'
import GoogleReviewWidget from '@/components/GoogleReviewWidget'
import ArrowButton from '@/components/ArrowButton'
import Breadcrumb from '@/components/Breadcrumb'

export default {
  components: {
    TrustpilotWidget,
    GoogleReviewWidget,
    ArrowButton,
    Breadcrumb,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      TpScore: TpScore.trustScore,
      GoogleScore: GoogleScore.stars,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((i) => i.text.length)
    },
    isDark() {
      return this.$store.state.page.settings.theme === 'dark'
    },
  },
}
</script>

<style lang="scss" module>
.root {
  @apply bg-bluko-700 sm:bg-[#002D88];
}
.subtitle {
  max-width: 650px;
  margin: 0;

  ul {
    padding: 0;
    li {
      padding-left: 35px;

      font-size: 24px;
    }
    li:before {
      content: '✓';
    }
  }
}

.itemText {
  padding: 20px 0;

  color: white;
  h3 {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
  }
  p {
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  @include below(medium) {
    h3 {
      font-size: 16px;
      line-height: 22px;
    }
    p {
      font-size: 11px;
      line-height: 18px;
    }
  }
}
.image {
  box-shadow: 0 0 0 12px #091161, 0 0 0 24px #0d1972, 0 0 0 36px #122182;
}
</style>
