<template>
  <ul class="mt-[50px] flex flex-col items-center">
    <li v-for="(item, itemIdx) in items" :key="itemIdx" class="p-0">
      <span v-if="item.year" class="inline-block py-[6px] !text-gray-300 typo-headline-bold">
        {{ item.year }}
      </span>
      <div v-else-if="item.dateText" class="py-[6px]">
        <PImage :image-object="item.icon" class="mx-auto block h-[64px] w-[64px]" />
        <div class="max-w-[260px] text-center">
          <RichText :text-params="item.dateText" class="font-[500] text-bluko-300" />
          <RichText :text-params="item.description" class="!leading-[24px] typo-headline" />
        </div>
      </div>
      <div class="mx-auto h-[40px] w-[1px] border-[1px] border-solid border-bluko-100"></div>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
