<template>
  <div class="flex w-full flex-col gap-[8px]">
    <LkLabel :label="label" :for="lukid" />
    <div ref="rootElement" class="relative">
      <LkInput
        :id="lukid"
        :value="value"
        :placeholder="placeholder"
        readonly
        pointer
        data-luko-tracking="SelectInput"
        @click="toggleDropdownDisplay"
        @keydown.stop="onKeyDown"
      >
        <template #append>
          <ChevronDownIcon class="h-[16px] w-[16px] transition-transform path-gray-400" :class="{ ['rotate-180']: isDropdownShown }" />
        </template>
      </LkInput>
      <LkDropdown
        :value="value"
        :show="isDropdownShown"
        :options="options"
        @input="(v) => $emit('input', v)"
        @other="$emit('other')"
        @close="isDropdownShown = false"
      />
    </div>
  </div>
</template>
<script>
import ChevronDownIcon from 'LkIcons/ChevronDown.vue'

import LkLabel from '@/components/Lukomponents/LkLabel'
import LkInput from '@/components/Lukomponents/LkInput'
import LkDropdown from '@/components/Lukomponents/LkDropdown'

import { autoLukid } from '../autoLukid.mixin'

export default {
  components: {
    LkLabel,
    LkInput,
    LkDropdown,
    ChevronDownIcon,
  },
  mixins: [autoLukid],
  emits: ['other', 'clear', 'blur'],
  props: {
    value: {
      type: [String, Number, Boolean, Object, null],
      default: '',
    },
    options: { type: Array, required: true },
    label: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
  },
  data() {
    return {
      isDropdownShown: false,
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick)
  },
  unmounted() {
    document.removeEventListener('click', this.handleOutsideClick)
  },
  methods: {
    handleOutsideClick(event) {
      const outsideClick = !this.$refs.rootElement?.contains(event.target)
      if (outsideClick) this.isDropdownShown = false
    },
    toggleDropdownDisplay() {
      this.isDropdownShown = !this.isDropdownShown
    },
    selectNextOption() {
      const idx = this.options.findIndex((op) => op.value === this.value)
      this.$emit('input', this.options[(idx + 1) % this.options.length].value)
    },
    selectPreviousOption() {
      let idx = this.options.findIndex((op) => op.value === this.value)
      if (idx <= 0) idx = this.options.length
      this.$emit('input', this.options[idx - 1].value)
    },
    onKeyDown(event) {
      const ENTER_KEY_CODE = 'Enter'
      const ESCAPE_KEY_CODE = 'Escape'
      const SPACE_KEY_CODE = 'Space'
      const UP_KEY_CODE = 'ArrowUp'
      const DOWN_KEY_CODE = 'ArrowDown'
      const TAB = 'Tab'

      switch (event.code) {
        case SPACE_KEY_CODE:
        case ENTER_KEY_CODE:
          event.preventDefault()
          this.isDropdownShown = !this.isDropdownShown
          break

        case TAB:
        case ESCAPE_KEY_CODE:
          this.isDropdownShown = false
          break

        case DOWN_KEY_CODE:
          event.preventDefault()
          this.isDropdownShown = true

          this.selectNextOption()
          break

        case UP_KEY_CODE:
          event.preventDefault()
          this.isDropdownShown = true

          this.selectPreviousOption()
          break
      }
    },
  },
}
</script>
