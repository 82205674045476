import uniqBy from 'lodash-es/uniqBy'

import { useUrl } from '@/composables/useUrl'
import frPages from '@/config/router/domain-pages/fr-pages'
import esPages from '@/config/router/domain-pages/es-pages'
import dePages from '@/config/router/domain-pages/de-pages'

const pagesMap = {
  fr: frPages,
  es: esPages,
  de: dePages,
}

const { getHostByCountry } = useUrl()
const getCountry = (langIso) => {
  return langIso.split('-')[1].toLowerCase()
}
const getLanguage = (langIso) => {
  return langIso.split('-')[0]
}

export const useHreflangs = () => {
  return {
    getHreflangs: ({ uid, type, current_lang, alternate_languages, page }) => {
      const allExistingLanguages = [{ lang: current_lang, uid }, ...alternate_languages].sort((_, b) => (b.lang.includes('fr') ? 1 : -1)) // ['fr-fr', 'en-fr', 'es-es', en-es', ...]

      const res = []
      const hrefLangPrototype = allExistingLanguages
        .map((obj) => {
          const country = getCountry(obj.lang)

          if (!pagesMap[country][type]?.[obj.lang]) return null
          let url = `${getHostByCountry(country)}${pagesMap[country][type][obj.lang]}/${obj.uid || ''}/`.replace(/\/\//g, '/').replace(/\/\//g, '/')

          if (page !== undefined) {
            url = `${url}/p/${page}/`.replace(/\/\//g, '/')
          }

          return {
            href: `https://${url}`,
            hreflang: `${getLanguage(obj.lang)}-${country.toUpperCase()}`,
            rel: 'alternate',
          }
        })
        .filter((e) => e)

      hrefLangPrototype.forEach((obj) => {
        res.push({ ...obj })
        res.push({ ...obj, hreflang: getLanguage(obj.hreflang) })
      })

      return uniqBy(res, (obj) => obj.hreflang)
    },
  }
}
