<template>
  <SliceWrapper :slice="slice">
    <SimpleSliceDefault :slice="slice" />
  </SliceWrapper>
</template>

<script>
import SimpleSliceDefault from './default-slice/SimpleSliceDefault.vue'

export default {
  name: 'SimpleSlice',
  components: {
    SimpleSliceDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.section {
  position: relative;

  padding: 4em;

  color: #111;
  text-align: center;

  background: #f7f7f7;
}
a {
  color: #111;
}
.title {
  margin-bottom: 2em;
}
</style>
