<template>
  <div class="py-[128px] px-[32px] sm:py-[80px] sm:px-[24px]" :style="{ backgroundColor: primary.backgroundColor || '#122182' }">
    <Wrapper class="max-w-[1024px]">
      <Title :title-params="primary.title" class="mx-auto text-center !font-[300] !text-white typo-mega-title sm:typo-large-title" />
      <RichText :text-params="primary.description" class="mx-auto mt-[14px] text-center text-white" />
      <div v-if="primary.ctaText" class="text-center">
        <ArrowButton class="mt-[50px] sm:!w-full" is-white is-outlined is-arrow :link-object="primary.ctaLink" :link-text="primary.ctaText" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton/ArrowButton.vue'
export default {
  name: 'SimpleCta',
  components: { ArrowButton },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
