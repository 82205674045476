<template>
  <SliceWrapper :slice="slice">
    <PressListCarouselBlue v-if="slice.variation === 'blue'" :slice="slice" />
    <PressListCarouselLightBlue v-else-if="slice.variation === 'lightBlue'" :slice="slice" />
    <PressListCarouselDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import PressListCarouselDefault from './default-slice'
import PressListCarouselBlue from './blue'
import PressListCarouselLightBlue from './lightBlue'

export default {
  name: 'PressListCarousel',
  components: {
    PressListCarouselDefault,
    PressListCarouselBlue,
    PressListCarouselLightBlue,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>
