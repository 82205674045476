<template>
  <div class="relative -mt-[64px] min-h-[800px] bg-[#002D88] p-0 md:pt-[64px]">
    <PImage
      :image-object="primary.backgroundImage"
      sizes="lg:200vw"
      :quality="80"
      class="absolute left-0 top-0 h-full w-full object-cover md:bottom-0 md:hidden"
    />
    <Wrapper class="relative z-10 h-full justify-between px-[24px] pt-[128px] md:flex-col md:pt-[24px]">
      <div class="h-full w-1/2 md:w-full">
        <Breadcrumb v-if="primary.showBreadcrumb" class="mb-[16px]" :custom-name="primary.title[0].text" />
        <Title class="mt-[16px] max-w-[620px] text-white typo-mega-title md:max-w-none sm:typo-extra-large-title" :title-params="primary.title" />
        <RichText
          class="mt-[16px] max-w-[540px] text-bluko-150 typo-headline md:max-w-none md:text-bluko-100 [&>p]:pb-[16px]"
          :text-params="primary.subtitle"
        />

        <div class="mt-[64px] flex flex-col gap-[68px] md:mt-[36px] md:flex-col-reverse md:gap-[32px]">
          <div v-if="items.length" class="flex gap-[16px] md:flex-col">
            <ArrowButton
              v-for="(item, idx) in items"
              :key="idx"
              has-icon-right
              :data-luko-tracking="trackingText(item.cta_text)"
              :link-object="item.cta_link"
              :link-text="item.cta_text"
              is-white
              :is-outlined="idx === 1"
              class="md:!w-full"
            />
          </div>
          <div class="flex md:justify-center">
            <GoogleReviewWidget is-dark class="inline-flex path-bluko-300" />
          </div>
        </div>
      </div>
    </Wrapper>
    <PImage
      :image-object="primary.backgroundMobile"
      sizes="sm:100vw md:120vw"
      class="w-full object-cover md:-mt-[300px] sm:-mt-[150px] up-md:hidden"
    />
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import GoogleReviewWidget from '@/components/GoogleReviewWidget'
import ArrowButton from '@/components/ArrowButton'

export default {
  name: 'HeroPartner2021Q4',

  components: {
    Breadcrumb,
    GoogleReviewWidget,
    ArrowButton,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    trackingText(text) {
      return text?.trim().replace(/\s/g, '_')
    },
  },
}
</script>
