<template>
  <LkInput
    v-model="date"
    v-bind="$attrs"
    :placeholder="placeholder"
    :disabled="disabled"
    :autofocus="autofocus"
    data-luko-tracking="DateInput"
    :has-error="!!error"
    @focus="handleFocus"
    @keypress="$emit('keydown', $event)"
  >
    <template v-if="showIcon" #append>
      <CalendarIcon :class="{ ['path-black']: !disabled, ['path-gray-500']: disabled }" />
    </template>
  </LkInput>
</template>
<script>
import CalendarIcon from 'LkIcons/Calendar.vue'
import { format, isValid, parse } from 'date-fns'

import LkInput from '@/components/Lukomponents/LkInput'
import { useDateTime } from '@/composables/useDateTime'

import { autoLukid } from '../autoLukid.mixin'

const strFormat = 'dd/MM/yyyy'
const { constructUTC, dateToUTCDayStart } = useDateTime()
const pad2Digit = (val, maxAllowedFirstDigit) => (parseInt(val) <= maxAllowedFirstDigit ? val.padEnd(2, '_') : val.padStart(2, '0'))

export default {
  components: {
    LkInput,
    CalendarIcon,
  },
  mixins: [autoLukid],
  inheritAttrs: false,
  emits: ['input', 'focus', 'keydown', 'error:lessThanMin', 'error:moreThanMax', 'error:invalidDate'],
  props: {
    value: { type: Date, default: undefined },
    min: { type: Date, default: undefined },
    max: { type: Date, default: undefined },
    label: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    showIcon: { type: Boolean, default: false },
    placeholder: { type: String, default: 'DD/MM/YYYY' },
    error: { type: String, default: undefined },
  },
  data() {
    const justSomeDate = new Date(1999, 11, 30).toLocaleDateString(this.$i18n.locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    return {
      date: '',
      separator: justSomeDate.match(/\D/)?.[0] || '/',
      day: '__',
      month: '__',
      year: '____',
      isDayValid: false,
      isMonthValid: false,
      isYearValid: false,
      input: undefined,
      hasError: false,
    }
  },
  computed: {
    isDayInProgress() {
      return this.day.match(/\d_/)?.length
    },
    isMonthInProgress() {
      return this.month.match(/\d_/)?.length
    },
    isYearInProgress() {
      return this.year.match(/\d{1,3}_/)?.length
    },
  },
  watch: {
    value() {
      this.date = this.value && isValid(this.value) ? format(this.value, strFormat) : ''
    },
    date(newVal) {
      if (!newVal) {
        this.day = ''
        this.month = ''
        this.year = ''
        this.setCaret()
        this.emitOrInvalidate()
        return
      }
      const splitted = newVal?.split(this.separator)
      const dayTrimmed = splitted[0]?.replace(/\D/g, '') || ''
      const monthTrimmed = splitted[1]?.replace(/\D/g, '') || ''
      const yearTrimmed = splitted[2]?.replace(/\D/g, '') || ''

      this.day = dayTrimmed.length ? pad2Digit(dayTrimmed, 3) : '__'
      this.month = monthTrimmed.length ? pad2Digit(monthTrimmed, 1) : '__'
      this.year = yearTrimmed.padEnd(4, '_').substring(0, 4) || '____'

      this.$nextTick(() => {
        this.date = this.formatDate()
        this.setCaret()
        this.emitOrInvalidate()
      })
    },
    day(newVal) {
      this.isDayValid = !newVal.includes('_') && parseInt(newVal) <= 31
      // set flags to false to allow to rewrite what already has been set
      this.isMonthValid = false
      this.isYearValid = false
    },

    month(newVal) {
      this.isMonthValid = !newVal.includes('_') && parseInt(newVal) <= 12
      this.isYearValid = false
    },

    year(newVal) {
      if (newVal.includes('_') >= 0) {
        this.isYearValid = false
        return
      }
      const intVal = parseInt(newVal)
      this.isYearValid = intVal >= 1900 && intVal < 2100
    },
  },
  methods: {
    formatDate() {
      return `${this.day}${this.separator}${this.month}${this.separator}${this.year}`
    },
    setCaret() {
      if (this.isDayInProgress || this.isMonthInProgress || this.isYearInProgress) {
        const firstPlaceholder = this.date.indexOf('_')
        this.input?.setSelectionRange(firstPlaceholder, firstPlaceholder)
      } else if (!this.isDayValid) {
        this.input?.setSelectionRange(0, 2)
      } else if (!this.isMonthValid) {
        this.input?.setSelectionRange(3, 5)
      } else if (!this.isYearValid) {
        this.input?.setSelectionRange(6, 10)
      }
    },
    handleFocus(e) {
      this.input = e.target
      if (isValid(parse(this.date, strFormat, new Date()))) {
        this.input?.setSelectionRange(0, 2)
      } else {
        this.date = this.formatDate()
        this.setCaret()
      }
      this.$emit('focus')
    },
    emitOrInvalidate() {
      if (!this.date?.includes('_')) {
        const parsedDate = parse(this.date, strFormat, new Date())
        const utcDate = new Date(constructUTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()))

        this.hasError = true
        if (isValid(utcDate)) {
          if (this.min && utcDate < dateToUTCDayStart(this.min)) {
            this.$emit('error:lessThanMin', utcDate)
          } else if (this.max && utcDate > dateToUTCDayStart(this.max)) {
            this.$emit('error:moreThanMax', utcDate)
          } else {
            this.hasError = false
            this.$emit('input', utcDate)
          }
        } else {
          this.$emit('error:invalidDate', this.date)
        }
      } else {
        this.$emit('error:invalidDate', undefined)
      }
    },
  },
}
</script>
