<template>
  <SliceWrapper :slice="slice">
    <HighlightTextHorizontal v-if="slice.variation === 'horizontal'" :slice="slice" />
    <HighlightTextDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import HighlightTextHorizontal from './horizontal'
import HighlightTextDefault from './default-slice'

export default {
  name: 'HighlightText',
  components: {
    HighlightTextHorizontal,
    HighlightTextDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
