<template>
  <div class="w-full overflow-hidden md:hidden" :style="{ backgroundColor: primary.backgroundColor || '#ffede3' }">
    <Wrapper class="mx-auto flex justify-center gap-x-[75px] py-[44px] px-[24px]">
      <PImage :image-object="primary.logo_stripe" class="h-[44px] object-contain" />
    </Wrapper>
  </div>
</template>
<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
  },
}
</script>
