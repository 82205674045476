<template>
  <section v-html="htmlCode"></section>
</template>

<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    htmlCode() {
      return this.slice.primary.html?.[0].text
    },
  },
}
</script>
