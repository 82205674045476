<template>
  <div :class="$style.container" class="relative inline-flex w-max cursor-pointer">
    <div class="flex items-center gap-[4px]">
      <InfoIcon class="h-[14px] w-[14px]" />
      <span class="font-[500] text-bluko-500">
        <slot></slot>
      </span>
    </div>
    <RichText
      :text-params="tooltipTextParams"
      :class="$style.tooltip"
      class="absolute left-[50%] w-max max-w-[256px] rounded-[8px] bg-white py-[8px] px-[12px] shadow-lg transition-all duration-200"
    />
  </div>
</template>
<script>
import InfoIcon from 'LkIcons/Info.vue'

export default {
  components: {
    InfoIcon,
  },
  props: {
    tooltipTextParams: {
      type: Array,
      required: true,
    },
  },
}
</script>
<style lang="scss" module>
.container {
  &:hover {
    .tooltip {
      transform: translateX(-50%) translateY(calc(-100% - 15px));
      visibility: visible;
      opacity: 1;
    }
  }
}

.tooltip {
  transform: translateX(-50%) translateY(calc(-100% - 10px));
  visibility: hidden;
  opacity: 0;
}
.tooltip::after {
  position: absolute;
  left: 50%;

  width: 30px;
  height: 10px;

  background-image: url('~assets/icons/header/menu-pointer.svg');
  background-repeat: no-repeat;
  transform: translateX(-50%) translateY(5px) rotate(180deg);

  content: ' ';
}
</style>
