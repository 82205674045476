<template>
  <div id="trustpilot-carousel" v-observe-visibility="carouselAutoSliding" class="my-[80px] px-[24px]">
    <Wrapper>
      <div class="grid grid-cols-2 overflow-hidden rounded-[8px] bg-mint-50 py-[80px] px-[70px] md:grid-cols-1 sm:py-[32px] sm:px-[24px]">
        <div class="pr-[90px] md:pb-[64px] md:pr-0 md:text-center">
          <img class="mr-[10px] h-[32px] object-contain md:mx-auto" src="@/assets/images/trustpilot/tp-light-bg-logo.svg" />
          <img class="mt-[8px] h-[32px] md:mx-auto" :src="score.starImage" />
          <Title class="mt-[24px] typo-large-title sm:typo-title" :title-params="primary.title" />
          <RichText v-if="primary.description" class="text-gray-700 typo-body" :text-params="primary.description" />
          <ArrowLink :link-object="primary.ctaLink" class="mt-[48px] text-mint-500 path-mint-500 md:mx-auto" simple-url="#" @click="showIntercom" />
        </div>

        <div
          class="
            border-l-[1px] border-solid border-mint-100
            pl-[90px]
            text-mint-800
            md:border-t-[1px] md:border-l-0 md:pt-[64px] md:pl-0 md:text-center
          "
        >
          <div class="flex items-center justify-center">
            <button class="cursor-pointer border-0 bg-none p-0" @click="decrementStep">
              <ArrowLeft class="h-[20px] w-[20px]" :class="{ 'path-mint-200': carouselStep < 1, 'path-mint-500': carouselStep >= 1 }" />
            </button>
            <transition-group :name="slideEffect">
              <ProfileThumbnail
                :key="currentStep.consumer.id"
                class="mx-[25px] sm:!h-[48px] sm:!w-[48px]"
                :name="currentStep.consumer.displayName"
                :image="currentStep.consumer.profileImage ? currentStep.consumer.profileImage.href : ''"
              />
            </transition-group>
            <button class="cursor-pointer border-0 bg-none p-0" @click="incrementStep">
              <ArrowRight
                class="h-[20px] w-[20px]"
                :class="{ 'path-mint-200': carouselStep + 1 === items.length, 'path-mint-500': carouselStep + 1 !== items.length }"
              />
            </button>
          </div>

          <transition-group :name="slideEffect" tag="div" class="relative mt-[24px]">
            <div :key="currentStep.consumer.id" class="w-full">
              <RichText v-if="Array.isArray(currentStep.text)" :text-params="currentStep.text" class="text-center" />
              <span v-else>
                {{ currentStep.text }}
              </span>
              <div class="mt-[24px] text-center text-mint-1000 typo-body">— {{ currentStep.consumer.displayName }} —</div>
            </div>
          </transition-group>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import ArrowRight from 'LkIcons/ArrowRight.vue'
import ArrowLeft from 'LkIcons/ArrowLeft.vue'
import score from '@@/script/trustpilot/score.json'

import ProfileThumbnail from '@/components/ProfileThumbnail'
import ArrowLink from '@/components/ArrowLink'
import { showIntercom } from '@/mixins/showIntercom'

export default {
  name: 'TrustpilotCarouselDefault',

  components: {
    ArrowLink,
    ArrowRight,
    ArrowLeft,
    ProfileThumbnail,
  },
  mixins: [showIntercom],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      carouselStep: 0,
      slideEffect: 'slide-right',
      intervalId: null,
      score,
    }
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      let items = require(`@@/script/trustpilot/reviews-${this.$i18n.locale}.json`)
      if (this.slice.items?.length) {
        items = this.slice.items.map((item, idx) => {
          item.consumer = {
            id: idx,
            profileImage: {
              href: item.profileImage?.url,
            },
            displayName: item.info[0]?.text,
          }
          return item
        })
      }
      return items
    },

    currentStep() {
      return this.items[this.carouselStep]
    },
  },

  watch: {
    carouselStep(newStep, preStep) {
      if (newStep > preStep) this.slideEffect = 'slide-left'
      else this.slideEffect = 'slide-right'
      clearInterval(this.intervalId)
      this.intervalId = this.setCarouselAutoSliding(8000)
    },
  },

  beforeDestroy() {
    clearInterval(this.intervalId)
  },

  methods: {
    decrementStep() {
      if (this.carouselStep - 1 < 0) return
      this.carouselStep--
    },

    incrementStep() {
      if (this.carouselStep + 1 === this.items.length) return
      this.carouselStep++
    },

    carouselAutoSliding(visible) {
      if (!visible) return clearInterval(this.intervalId)

      this.intervalId = this.setCarouselAutoSliding(8000)
    },

    setCarouselAutoSliding(ms) {
      return setInterval(() => {
        this.carouselStep = (this.carouselStep + 1) % this.items.length
      }, ms)
    },
  },
}
</script>
<style lang="scss" scoped>
/* slide left */
.slide-left-enter {
  transform: translateX(60px);

  opacity: 0;
}
.slide-left-enter-to {
  transform: initial;

  opacity: 1;
}
.slide-left-leave {
  position: absolute;

  transform: translateX(0);

  opacity: 1;
}
.slide-left-leave-to {
  position: absolute;

  transform: translateX(-60px);

  opacity: 0;
}
.slide-left-enter-active {
  transition: all 0.5s ease-in-out;
  transition-delay: 0.5s;
}
.slide-left-leave-active {
  position: absolute;

  transition: all 0.5s ease-in-out;
}

/* slide right */
.slide-right-enter {
  transform: translateX(-60px);

  opacity: 0;
}
.slide-right-enter-to {
  transform: initial;

  opacity: 1;
}
.slide-right-leave {
  position: absolute;

  transform: translateX(0);

  opacity: 1;
}
.slide-right-leave-to {
  position: absolute;

  transform: translateX(60px);

  opacity: 0;
}
.slide-right-enter-active {
  transition: all 0.5s ease-in-out;
  transition-delay: 0.5s;
}
.slide-right-leave-active {
  position: absolute;

  transition: all 0.5s ease-in-out;
}
</style>
