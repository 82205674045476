export const showIntercom = {
  methods: {
    showIntercom() {
      if (window.Intercom) {
        window.Intercom('show')
      } else {
        window.Didomi?.notice.show()
      }
    },
  },
}
