<template>
  <Wrapper class="my-[120px] flex max-w-[1080px] flex-col items-center sm:my-[30px]">
    <Title
      :title-params="primary.title"
      class="mb-[30px] max-w-[800px] p-0 px-[20px] text-center typo-large-title sm:mx-auto sm:mt-[40px] sm:mb-[20px] sm:typo-headline"
    />
    <PImage :image-object="primary.image" sizes="sm:100vw md:120vw lg:120vw" class="w-full" />
  </Wrapper>
</template>
<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
  },
}
</script>
