<template>
  <SliceWrapper :slice="slice">
    <FaqSlice2021Q4 v-if="slice.variation === '2021Q4'" :slice="slice" />
    <FaqSliceLightBlue v-else-if="slice.variation === 'lightBlue'" :slice="slice" />
    <FaqSliceDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import FaqSliceDefault from './default-slice/'
import FaqSlice2021Q4 from './2021Q4'
import FaqSliceLightBlue from './lightBlue'

export default {
  name: 'FaqSlice',
  components: {
    FaqSliceDefault,
    FaqSlice2021Q4,
    FaqSliceLightBlue,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
