<template>
  <RichText :text-params="primary.title" />
</template>

<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
