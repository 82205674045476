<template>
  <Wrapper
    ref="inner"
    v-observe-visibility="{
      callback: onVisible,
      intersection: {
        rootMargin: '100% 0px 100% 0px',
      },
      once: true,
    }"
    class="my-[160px] h-full bg-[length:138px_196px] bg-right-bottom bg-no-repeat sm:my-[80px] sm:!bg-none sm:px-0"
  >
    <Title
      :title-params="primary.title"
      class="m-0 mx-auto mb-[10px] max-w-[950px] px-[24px] text-center !font-[300] typo-mega-title hyphens sm:text-[40px] sm:leading-[48px]"
    />
    <RichText :text-params="primary.description" class="m-0 mx-auto max-w-[950px] px-[24px] text-center text-gray-700 typo-body" />
    <div class="mt-[48px] sm:mt-[40px]">
      <VueSlickCarousel ref="carousel" v-bind="slickOptions" @init="onInit" @beforeChange="onBeforeChange">
        <div v-for="(item, itemIndex) in items" :key="itemIndex">
          <div
            class="relative mx-auto h-[440px] w-[95%] sm:h-[500px]"
            :class="[$style.itemContainer, { ['!h-[600px] px-[24px]']: items.length <= 3 }]"
            :style="{ backgroundImage: load ? item.image.url : null, backgroundSize: 'cover' }"
          >
            <div
              class="absolute mx-auto w-full overflow-hidden rounded-t-[8px]"
              :class="[$style.itemImageContainer, { ['h-[80%]']: !hasUsp(item) }, { ['h-[60%] sm:h-[50%]']: hasUsp(item) }]"
            >
              <PImage
                :image-object="load ? item.image : null"
                sizes="xs:100vw"
                class="h-full w-full scale-[1.15] object-cover sm:scale-100"
                :class="$style.itemImage"
              />
            </div>
            <div
              class="
                itemTextContainer
                absolute
                bottom-0
                flex
                h-fit
                w-full
                flex-col
                justify-between
                rounded-b-[8px]
                border-[1px] border-orange-100
                bg-white
                p-[32px]
                sm:p-[24px]
              "
              :class="$style.itemText"
              :style="{ minHeight: `${minimumTextHeight}px` }"
            >
              <PLink :link-object="item.product_link">
                <RichText
                  :text-params="item.title"
                  class="text-[32px] leading-[40px] hyphens sm:text-[24px] sm:leading-[32px]"
                  :class="{ ['!text-[24px] !leading-[32px]']: items.length > 3 }"
                />
                <RichText
                  :text-params="item.subtitle"
                  class="text-[24px] font-[300] leading-[32px] text-black text-opacity-[0.6] sm:text-[16px] sm:leading-[24px]"
                  :class="{ ['!text-[16px] !leading-[24px]']: items.length > 3 }"
                />
              </PLink>
              <ul
                v-if="hasUsp(item)"
                class="mt-[18px] mb-[24px] grid grid-cols-2 gap-[10px] sm:grid-cols-1 sm:gap-[12px]"
                :class="$style.itemTextList"
              >
                <li
                  v-for="it in [
                    { icon: item.usp1_icon, text: item.usp1_text },
                    { icon: item.usp2_icon, text: item.usp2_text },
                    { icon: item.usp3_icon, text: item.usp3_text },
                    { icon: item.usp4_icon, text: item.usp4_text },
                  ]"
                  class="flex min-h-[24px] items-center gap-[8px]"
                >
                  <PImage :image-object="it.icon" class="h-[24px] w-[24px] shrink-0" :class="$style.itemIconImage" />
                  <RichText :text-params="it.text" class="text-black text-opacity-[0.6] typo-sub-text hyphens" />
                </li>
              </ul>

              <LkButton v-if="item.cta_text" :link-object="item.cta_link" is-small>{{ item.cta_text }}</LkButton>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
      <div v-if="!hideArrow" class="mt-[48px] flex justify-center gap-[8px] sm:hidden">
        <button :class="$style.button" :disabled="slideIndex === currentSetting.initialSlide" @click="goPrev">
          <ChevronLeftIcon class="path-bluko-500" :class="{ ['path-bluko-150']: slideIndex === currentSetting.initialSlide }" />
        </button>
        <button :class="$style.button" :disabled="slideIndex + currentSetting.slidesToShow === items.length" @click="goNext">
          <ChevronRightIcon class="path-bluko-500" :class="{ ['path-bluko-150']: slideIndex + currentSetting.slidesToShow === items.length }" />
        </button>
      </div>
      <div class="mt-[48px] text-center sm:mt-[40px]">
        <LkButton v-if="primary.cta_text" is-outlined :link-object="primary.cta_link">{{ primary.cta_text }}</LkButton>
      </div>
    </div>
  </Wrapper>
</template>
<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import VueSlickCarousel from 'vue-slick-carousel'
import ChevronLeftIcon from 'LkIcons/ChevronLeft.vue'
import ChevronRightIcon from 'LkIcons/ChevronRight.vue'

import { componentLogic } from '../componentLogic.mixin'

export default {
  components: {
    VueSlickCarousel,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  mixins: [componentLogic],
}
</script>
<style lang="scss" module>
.itemContainer,
.itemImage,
.itemImageContainer,
.itemText,
.itemCardImage,
.itemTextList {
  @apply transition-all duration-[250ms];
}
.itemTextList {
  max-height: 0;
  overflow: hidden;

  opacity: 0;
}

.itemContainer {
  @include above(small) {
    &:hover {
      .itemImageContainer {
        .itemImage {
          transform: scale(1);
        }
      }
      .itemText {
        @apply border-orange-25 bg-orange-25;
        flex: unset;
      }
      .itemTextList {
        max-height: 150px;
        overflow: unset;

        opacity: 1;
      }
    }
  }

  @include below(small) {
    .itemText {
      @apply border-orange-25 bg-orange-25;
    }
    .itemTextList {
      max-height: 150px;
      overflow: unset;

      opacity: 1;
    }
  }
}
.button {
  @apply flex h-[40px] w-[40px] items-center justify-center rounded-[50%] border-[2px] border-orange-25 bg-orange-25;
}
.button:disabled {
  @apply bg-white;
}
</style>
