<template>
  <PLink v-if="score.trustScore >= 4.3" class="flex flex-wrap items-center" :simple-url="getTrustpilotLink" target="_blank" rel="nofollow">
    <div class="mr-[10px] flex items-center sm:mr-0">
      <span class="mr-[10px] text-[18px] font-[500] sm:text-[14px]" :style="{ color: color }">
        {{ $t(`trustpilot.score.${score.stars}`) }}
      </span>
      <TrustpilotScore :score="score.stars" class="[&_svg]:h-[18px] [&_svg]:w-[18px]" color="#00B67A" />
    </div>
    <div class="mr-[10px] flex items-center sm:mr-0">
      <!-- <span :class="{ ['!text-white']: isDark }" class="mr-[10px] sm:mr-[5px] sm:text-[12px]">
        {{ $t('trustpilot.review', { value: score.numberOfReviews }) }}
      </span> -->
      <TpLogo class="h-[20px] w-[20px]" />
      <span class="translate-y-[3px] font-[500]" :style="{ color: color }">Trustpilot</span>
    </div>
  </PLink>
</template>

<script>
import score from '@@/script/trustpilot/score.json'

import { getTrustpilotLink } from '@/mixins/getTrustpilotLink'
import TrustpilotScore from '@/components/TrustpilotScore'
import TpLogo from '@/assets/images/trustpilot/tp-logo.svg?inline'

export default {
  components: {
    TrustpilotScore,
    TpLogo,
  },
  mixins: [getTrustpilotLink],
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },

  data() {
    return {
      score,
    }
  },
}
</script>
