var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.onVisible,
    intersection: {
      rootMargin: '100% 0px 100% 0px',
    },
    once: true,
  }),expression:"{\n    callback: onVisible,\n    intersection: {\n      rootMargin: '100% 0px 100% 0px',\n    },\n    once: true,\n  }"}],staticClass:"overflow-x-hidden bg-bluko-50 py-[64px] sm:py-[32px]"},[_c('Title',{staticClass:"mx-auto mb-[16px] max-w-[1024px] text-center !font-[300] typo-extra-large-title sm:typo-large-title",attrs:{"title-params":_vm.primary.title}}),_vm._v(" "),_c('RichText',{staticClass:"mx-auto mt-[10px] mb-[24px] max-w-[1024px] text-center !text-white typo-body",attrs:{"text-params":_vm.primary.description}}),_vm._v(" "),(_vm.primary.ctaText && _vm.primary.ctaLink && _vm.primary.ctaLink.url)?_c('div',{staticClass:"mb-[54px] px-[24px] text-center"},[_c('ArrowButton',{staticClass:"sm:!w-full",attrs:{"is-outlined":"","link-object":_vm.primary.ctaLink,"target":_vm.primary.ctaLink.target,"is-white":"","link-text":_vm.primary.ctaText}})],1):_vm._e(),_vm._v(" "),_c('Wrapper',{staticClass:"relative mt-[54px] min-h-[64px] overflow-hidden"},[_c('div',{staticClass:"absolute top-0 left-[-5px] z-10 h-full w-[40px] bg-gradient-to-r from-bluko-50 via-bluko-50 to-transparent"}),_vm._v(" "),_c('div',{ref:"carousel",class:[_vm.$style.carousel, ( _obj = {}, _obj[_vm.$style.animate] = _vm.animate, _obj )]},[_vm._l((_vm.items),function(item,index){return _c('div',{key:index,class:_vm.$style.logoContainer},[_c('PImage',{class:_vm.$style.logo,attrs:{"image-object":_vm.imageLoad ? item.logo : null}})],1)}),_vm._v(" "),_vm._l((_vm.items),function(item,index){return _c('div',{key:'second-' + index,class:_vm.$style.logoContainer},[_c('PImage',{class:_vm.$style.logo,attrs:{"image-object":_vm.imageLoad ? item.logo : null}})],1)})],2),_vm._v(" "),_c('div',{staticClass:"absolute top-0 right-[-5px] z-10 h-full w-[40px] bg-gradient-to-l from-bluko-50 via-bluko-50 to-transparent"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }