<template>
  <SliceWrapper :slice="slice">
    <CompetitorsComparisonBar v-if="slice.variation === 'bar'" :slice="slice" />
    <CompetitorsComparisonCenter v-else-if="slice.variation === 'center'" :slice="slice" />
  </SliceWrapper>
</template>

<script>
import CompetitorsComparisonBar from './bar'
import CompetitorsComparisonCenter from './center'
export default {
  name: 'CompetitorsComparison',
  components: {
    CompetitorsComparisonBar,
    CompetitorsComparisonCenter,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
