<template>
  <div>
    <LkLabel :label="label" />
    <VueSlider
      :tooltip="localTooltipActive"
      :tooltip-placement="tooltipPlacement"
      :value="value"
      v-bind="sliderOptions"
      :class="{ ['mb-[48px]']: tooltipPlacement === 'bottom', ['mt-[48px]']: tooltipPlacement === 'top' }"
      @change="(v) => $emit('input', v)"
      @drag-start="isDragging = true"
      @drag-end="isDragging = false"
    >
      <template #dot>
        <div class="flex h-full w-full items-center justify-center rounded-[50%]" :class="[isDragging && $style.dotShadow]">
          <SliderSvg class="h-[40px] w-[40px] cursor-pointer" />
        </div>
      </template>
      <template #tooltip="{ value: sliderValue }">
        <div class="flex flex-col items-center gap-0" :class="{ ['!flex-col-reverse']: tooltipPlacement === 'bottom' }">
          <div :class="[$style.tooltip]">{{ tooltipFormatter(sliderValue) }}</div>
          <TooltipTailBottomSvg class="m-[-1px] h-[8px] w-[24px]" :class="{ ['rotate-180']: tooltipPlacement === 'bottom' }" />
        </div>
      </template>
      <template #label="{ label: sliderLabel, active }">
        <div class="vue-slider-mark-label">
          <div v-if="sliderLabel" :class="[$style.label, $style.labelContainer, active && $style.active]">
            {{ sliderLabel }}
          </div>
        </div>
      </template>
    </VueSlider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'

import 'vue-slider-component/dist-css/vue-slider-component.css'
import LkLabel from '@/components/Lukomponents/LkLabel'

import TooltipTailBottomSvg from './assets/tooltip-tail-bottom.svg?inline'
import SliderSvg from './assets/slider.svg?inline'

export default {
  components: {
    VueSlider,
    TooltipTailBottomSvg,
    SliderSvg,
    LkLabel,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    steps: {
      type: Array,
      required: true,
    },
    marks: {
      type: [Array, Boolean],
      required: false,
      default: false,
    },
    tooltipActive: {
      type: String, // PropType<'active' | 'always' | 'none'>,
      default: 'none',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sliderDotSize: {
      type: Number,
      default: 40,
    },
    sliderHeight: {
      type: Number,
      default: 8,
    },
    tooltipPlacement: {
      type: String,
      default: 'top', // 'left', 'right', 'top', 'bottom'
    },
    tooltipFormatter: {
      type: Function,
      default: (v) => v,
    },
    label: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      isDragging: false,
      localTooltipActive: 'none',
      sliderOptions: {
        data: this.steps,
        lazy: true,
        height: this.sliderHeight,
        dotSize: this.sliderDotSize,
        contained: true,
        disabled: this.disabled,
        tooltipFormatter: this.tooltipFormatter,
        stepStyle: {
          display: 'none',
        },
        marks: this.marks,
        processStyle: { backgroundColor: '#2D50E6', borderRadius: '8px' },
        railStyle: { backgroundColor: '#F4F3EF', borderRadius: '8px' },
      },
    }
  },
  mounted() {
    this.localTooltipActive = this.tooltipActive
  },
}
</script>
<style lang="scss" module>
.dotShadow {
  box-shadow: 0px 0px 0px 4px rgba(45, 80, 230, 0.2), 0px 2px 8px rgba(44, 35, 2, 0.32);
}
.labelContainer {
  position: relative;
  left: 50%;

  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 16px 0 0 0;

  transform: -50%;

  cursor: pointer;
}

.label {
  @include typo-body;
  display: flex;
  gap: 10px;
  align-items: center;

  color: $gray-400;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;

  transform: translate(-50%, 5px);

  &::before {
    display: block;
    width: 2px;
    height: 8px;
    border-radius: 1px;

    background-color: $gray-300;

    content: '';
  }

  &.active {
    color: $bluko-500;
    &::before {
      background-color: $bluko-500;
    }
  }
}

.tooltip {
  @include typo-body;

  width: max-content;
  padding: 8px 16px;
  border: 0;
  border-radius: 8px;

  color: $gray-1000;
  font-weight: 450;

  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(0, 0, 108, 0.12);
}
</style>
<style lang="scss">
.vue-slider-dot-tooltip-top {
  top: -6px;
}
.vue-slider-dot-tooltip-bottom {
  bottom: -6px;
}
</style>
