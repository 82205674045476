<template>
  <div v-observe-visibility="isVisible" class="bg-bluko-800">
    <Wrapper
      class="grid grid-cols-4 items-start justify-items-center gap-[40px] py-[56px] px-[72px] md:grid-cols-2 md:py-[40px] md:px-[24px] sm:grid-cols-1"
    >
      <div v-for="(item, index) in items" :key="index" class="w-full sm:text-center">
        <Counter v-if="item.title" :text="item.title" :launch="launchCounter" class="text-center !text-white typo-extra-large-title" />
        <RichText :text-params="item.description" class="mt-[8px] text-center !text-bluko-100 typo-headline-bold" />
        <ArrowLink :link-object="item.cta_link" class="text-white path-white" :link-text="item.cta_title" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Counter from '@/components/Counter'
import ArrowLink from '@/components/ArrowLink'

export default {
  name: 'CounterBannerDefault',

  components: {
    ArrowLink,
    Counter,
  },

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      launchCounter: false,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
  methods: {
    isVisible(visible) {
      if (visible) this.launchCounter = visible
    },
  },
}
</script>
