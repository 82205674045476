<template>
  <SliceWrapper :slice="slice">
    <CompareWithLukoTable v-if="slice.variation === 'compareWithLuko'" :slice="slice" />
    <DataWithFilterTable v-else-if="slice.variation === 'dataWithFilter'" :slice="slice" />
    <div v-else>variation template({{ slice.variation }}) not exist</div>
  </SliceWrapper>
</template>

<script>
import CompareWithLukoTable from './compareWithLuko/'
import DataWithFilterTable from './dataWithFilter/'

export default {
  name: 'TableSlice',
  components: {
    CompareWithLukoTable,
    DataWithFilterTable,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
