<template>
  <div :class="$style.root" data-luko-tracking="TextField" @click="$emit('focusin', $event)">
    <LkLabel :label="label" :disabled="disabled" :for="lukid">
      {{ label }}
    </LkLabel>
    <LkInput
      :id="lukid"
      type="number"
      :value="value"
      :is-success="isSuccess"
      :has-error="!!error || hasError"
      :disabled="disabled"
      :readonly="isReadonly"
      :placeholder="placeholder"
      :has-autofocus="hasAutofocus"
      v-bind="$attrs"
      v-on="{
        ...$listeners,
        input: (event) => $emit('input', event),
      }"
    />
    <LkError :error="error" />
  </div>
</template>

<script lang="ts">
import LkLabel from '@/components/Lukomponents/LkLabel'
import LkError from '@/components/Lukomponents/LkError'
import LkInput from '@/components/Lukomponents/LkInput'

import { autoLukid } from '../autoLukid.mixin'

export default {
  components: {
    LkLabel,
    LkInput,
    LkError,
  },
  mixins: [autoLukid],
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasAutofocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" module>
.root {
  display: block;
  width: 100%;

  text-align: left;
}
</style>
