<template>
  <SliceWrapper :slice="slice">
    <CommitmentSectionBcorp v-if="slice.variation === 'bcorp'" :slice="slice" />
    <CommitmentSectionBcorpWhite v-else-if="slice.variation === 'bcorpWhite'" :slice="slice" />
    <CommitmentSectionDefault v-else :slice="slice" />
  </SliceWrapper>
</template>
<script>
import CommitmentSectionDefault from './default'
import CommitmentSectionBcorp from './bcorp'
import CommitmentSectionBcorpWhite from './bcorpWhite'

export default {
  components: {
    CommitmentSectionDefault,
    CommitmentSectionBcorp,
    CommitmentSectionBcorpWhite,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
