<template>
  <SliceWrapper :slice="slice">
    <HighlightIconsWhite v-if="slice.variation === 'white'" :slice="slice" />
    <HighlightIconsDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import HighlightIconsDefault from './default-slice/'
import HighlightIconsWhite from './white'

export default {
  name: 'HighlightSection',
  components: {
    HighlightIconsDefault,
    HighlightIconsWhite,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
