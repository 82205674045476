<template>
  <div class="relative" :class="$style.questionsContainer">
    <Wrapper class="flex flex-col p-[24px] py-[80px] md:py-[40px]">
      <Title class="m-0 mx-auto max-w-[700px] text-center typo-large-title hyphens" :title-params="primary.title" :class="$style.title" />

      <ul class="mt-[56px] grid grid-cols-2 gap-[40px] md:mt-[40px] md:grid-cols-1 md:gap-[20px]">
        <li v-for="(item, itemIndex) in items" :key="itemIndex" :class="$style.item">
          <Title class="typo-headline" :title-params="item.questionTitle" />
          <RichText class="mt-[16px] text-gray-700 [&>p]:mt-[16px] [&>p>a]:!underline" :class="$style.richText" :text-params="item.questionText" />
          <ArrowLink
            v-if="item.ctaText && item.ctaLink.url"
            class="mt-[8px] text-bluko-500 path-bluko-500"
            :link-object="item.ctaLink"
            :class="$style.link"
            :link-text="item.ctaText"
          />
        </li>
      </ul>
      <LkButton v-if="primary.ctaText" class="mx-auto mt-[80px] mb-0" :link-object="primary.ctaLink">
        {{ primary.ctaText }}
      </LkButton>
    </Wrapper>
  </div>
</template>

<script>
import ArrowLink from '@/components/ArrowLink'

export default {
  name: 'FaqSlice',

  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: this.items.map(({ questionTitle, questionText }) => ({
        '@type': 'Question',
        name: questionTitle[0].text,
        acceptedAnswer: {
          '@type': 'Answer',
          text: questionText[0].text,
        },
      })),
    }
  },

  components: {
    ArrowLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => (item.questionTitle && item.questionTitle.length) || (item.questionText && item.questionText.length))
    },
  },
}
</script>

<style lang="scss" module>
.questionsContainer {
  background-color: #fcf7e8;
}

.item {
  &:not(:first-child) {
    margin-top: 48px;
  }

  @include above(medium) {
    flex-basis: calc(50% - 40px);

    &:not(:first-child) {
      margin-top: 0;
    }
  }
}

.richText {
  ul,
  ol {
    margin: 16px 0px;
    padding-left: 16px;

    list-style-type: disc;
  }
  li {
    margin-bottom: 8px;
  }
}
</style>
