<template>
  <div class="relative min-h-[500px] bg-orange-50 md:bg-bluko-800">
    <aside
      class="
        absolute
        left-0
        z-10
        flex
        h-full
        max-w-[50%]
        flex-col
        justify-center
        bg-bluko-800
        py-[100px]
        px-[80px]
        text-white
        md:static md:max-w-none md:px-[24px] md:pt-[48px] md:pb-0
      "
    >
      <Title
        class="!font-[300] !text-white typo-extra-large-title md:text-center sm:!font-[300] sm:!text-white sm:typo-large-title"
        :title-params="primary.title"
      />

      <RichText v-if="primary.description" class="mt-[32px] text-white typo-body md:hidden" :text-params="primary.description" />

      <div class="mt-[60px] flex flex-col items-start md:mt-[42px] md:items-center md:text-center sm:mt-[34px]">
        <img src="@/assets/images/trustpilot/tp-dark-bg-logo.svg" lazy class="h-[32px]" />
        <img :src="score.starImage" lazy class="mt-[12px] h-[40px]" />
        <PLink :simple-url="getTrustpilotLink" class="mt-[24px] text-white path-white" is-arrowed>{{ primary.ctaText }}</PLink>
      </div>
    </aside>

    <div class="h-full overflow-x-hidden py-[90px] md:py-[80px]">
      <div
        ref="carousel"
        class="flex translate-x-0"
        :style="{ width: `${(cardWidth + 40) * cardGroup * items.length}px` }"
        @mouseenter.passive="onMouseenter"
        @mouseleave.passive="onMouseleave"
        @mousemove.passive="onMousemove"
        @mousedown.passive="onMousedown"
        @click="onClick"
        @touchstart.passive="onTouchstart"
        @touchmove.passive="onTouchmove"
        @touchend.passive="onTouchend"
      >
        <template v-for="(gItems, gindex) in groupedItems">
          <div
            v-for="(item, index) in gItems"
            :key="'card' + gindex + index"
            class="ml-[40px] flex min-h-[500px] cursor-pointer select-none flex-col justify-between rounded-[8px] bg-white p-[24px]"
            :style="{ width: `${cardWidth}px` }"
          >
            <PImage :image-object="item.damageImage" class="h-[128px] rounded-[8px] object-cover" />
            <div>
              <RichText :text-params="item.text" class="text-[16px] leading-[24px]" />
            </div>
            <div class="flex items-center">
              <ProfileThumbnail :image="item.profileImage.url" :name="item.displayName" :size="32" />
              <div>
                <RichText :text-params="item.info" class="ml-[10px] text-[12px] text-gray-800" />
                <span class="ml-[10px] !text-gray-500 typo-caption">
                  {{ item.subInfo }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import score from '@@/script/trustpilot/score.json'

import ProfileThumbnail from '@/components/ProfileThumbnail'
import { getTrustpilotLink } from '@/mixins/getTrustpilotLink'

const CARD_GROUP = 5

export default {
  name: 'TrustpilotCarousel2021Q4',
  components: {
    ProfileThumbnail,
  },
  mixins: [getTrustpilotLink],

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      cardGroup: CARD_GROUP,
      score,
      isTouchDevice: false,
      startDrag: false,
      startX: null,
      animationID: null,
      speed: -1,
      timeStamp: null,
      cardWidth: 400,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => item.damageImage?.url)
    },
    groupedItems() {
      return new Array(CARD_GROUP).fill(0).map(() => this.items)
    },
  },
  mounted() {
    this.startAnimation()
  },
  methods: {
    startAnimation() {
      this.animationID = requestAnimationFrame(this.loop)
    },
    stopAnimation() {
      this.animationID = cancelAnimationFrame(this.animationID)
    },
    loop() {
      if (!this.animationID) return
      this.moveCarousel()
      this.animationID = requestAnimationFrame(this.loop)
    },
    moveCarousel() {
      const el = this.$refs.carousel
      const currentTranslateX = parseInt(window.getComputedStyle(el).transform.split(',')[4])
      let newTranslateX = (currentTranslateX + this.speed) % ((this.cardWidth + 40) * this.items.length)
      if (newTranslateX > 0) {
        newTranslateX = 0
        this.speed = -1
      }

      el.style.transform = `translateX(${newTranslateX}px)`

      // Friction when user scroll right
      if (this.speed > 0) return (this.speed -= 0.2)

      // Friction when user scroll left
      if (this.speed < 0) {
        this.speed += 0.2
        if (this.speed > -1) return (this.speed = -1)
      }
    },
    onMouseenter() {
      if (this.isTouchDevice) return
      this.speed = 0
    },
    onMouseleave() {
      if (this.isTouchDevice) return
      this.startDrag = false

      if (this.speed === 0) this.speed = -1
    },
    onMousemove(e) {
      if (this.isTouchDevice) return
      if (!this.startDrag) return
      const time = e.timeStamp - this.timeStamp
      const distance = e.pageX - this.startX
      this.speed = (distance / time) * 5
      this.moveCarousel()

      this.timeStamp = e.timeStamp
      this.startX = e.pageX
    },
    onMousedown(e) {
      if (this.isTouchDevice) return
      this.startDrag = true
      this.startX = e.pageX
      this.timeStamp = e.timeStamp
    },
    onClick() {
      if (this.isTouchDevice) return
      this.startDrag = false
    },
    onTouchstart(e) {
      this.isTouchDevice = true
      this.startDrag = true
      this.startX = e.targetTouches[0].pageX
      this.timeStamp = e.timeStamp
      this.speed = 0
    },
    onTouchend() {
      this.isTouchDevice = true
      this.startDrag = false
      if (this.speed === 0) this.speed = -1
    },
    onTouchmove(e) {
      if (!this.startDrag) return
      const time = e.timeStamp - this.timeStamp
      const distance = e.targetTouches[0].pageX - this.startX
      this.speed = (distance / time) * 5
      this.moveCarousel()

      this.timeStamp = e.timeStamp
      this.startX = e.targetTouches[0].pageX
    },
  },
}
</script>
