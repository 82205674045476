<template>
  <div :class="$style.container">
    <Wrapper>
      <div v-if="primary.trustpilotLogo" class="mb-[24px] flex items-center justify-center gap-[20px] sm:flex-col">
        <TrustpilotLogo class="h-[36px] w-[144px]" />
        <TrustpilotScore :score="score.stars" color="#2A8C7C" animation />
      </div>
      <RichText :text-params="primary.title" :class="$style.title" />
      <RichText :text-params="primary.description" :class="$style.description" />
      <div
        :class="$style.itemsContainer"
        :style="{
          marginTop:
            primary.description.length && primary.description[0] && primary.description[0].type && primary.description[0].text ? '32px' : undefined,
        }"
      >
        <div
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          class="flex min-w-[220px] max-w-[400px] flex-1 flex-col items-center"
          data-aos="fade-up"
        >
          <PImage :image-object="item.icon" sizes="xs:100px" :class="$style.icon" />
          <RichText :text-params="item.iconCaption" :class="$style.iconCaption" />
          <Title :title-params="item.title" :class="$style.itemTitle" />
          <RichText :text-params="item.description" :class="$style.itemDescription" />
          <ArrowLink class="text-bluko-500 path-bluko-500" :link-object="item.ctaLink" :class="$style.itemLink" :link-text="item.ctaText" />
        </div>
      </div>
      <div v-if="primary.ctaText" :class="$style.ctaContainer">
        <ArrowButton :link-object="primary.ctaLink" :class="$style.cta" :link-text="primary.ctaText" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import score from '@@/script/trustpilot/score.json'

import TrustpilotScore from '@/components/TrustpilotScore'
import ArrowButton from '@/components/ArrowButton'
import ArrowLink from '@/components/ArrowLink'
import TrustpilotLogo from '@/assets/images/trustpilot/tp-dark-bg-logo.svg?inline'

export default {
  components: {
    TrustpilotScore,
    ArrowLink,
    ArrowButton,
    TrustpilotLogo,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      score,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 80px 32px;

  background-color: $bluko-800;
  @include below(small) {
    padding: 80px 16px;
  }
}
.title p {
  max-width: 800px;
  margin: 0 auto 30px;

  color: white;
  font-weight: 200;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  @include below(small) {
    font-size: 32px;
    line-height: 42px;
  }
}
.description {
  max-width: 703px;
  margin: 8px auto 0;

  color: #d6e3fd;
  text-align: center;
}

.itemsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-evenly;
}
.item {
  flex: 1;
  min-width: 220px;
  max-width: 400px;

  text-align: center;
}
.itemTitle p {
  @include typo-title;
  margin-bottom: 8px;

  color: white;
  text-align: center;
  @include below(small) {
    @include typo-headline-bold;
    color: white;
  }
}

.itemLink {
  display: inline-block;
  margin-top: 24px;
}
.icon {
  width: 56px;
  height: 56px;
  margin-bottom: 16px;
}
.iconCaption {
  color: $bluko-300;
  font-size: 16px;
  line-height: 24px;
}

.itemDescription {
  text-align: center;
  h3 {
    @include typo-title;
    margin-bottom: 8px;

    color: white;
    @include below(small) {
      @include typo-headline-bold;
      color: white;
    }
  }
  p {
    color: #d6e3fd;
  }
}

.ctaContainer {
  margin-top: 60px;

  text-align: center;
}
.cta {
  @include below(small) {
    width: 100%;
  }
}
</style>
