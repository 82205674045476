<template>
  <PLink v-if="GoogleScore >= 4.3" class="flex flex-wrap items-center gap-[8px]" :simple-url="getGoogleReviewLink" target="_blank" rel="nofollow">
    <GoogleReviewsLogo class="h-[20px] sm:h-[16px]" :class="{ ['!path-white']: isDark, ['!path-black']: !isDark }" />
    <StarScore :score="GoogleScore" class="[&_svg]:!h-[18px] [&_svg]:!w-[18px] sm:[&_svg]:!h-[14px] sm:[&_svg]:!w-[14px]" color="yellow" />
  </PLink>
</template>

<script>
import GoogleScore from '@@/script/googleReview/score.json'

import { getGoogleReviewLink } from '@/mixins/getGoogleReviewLink'
import StarScore from '@/components/StarScore'

import GoogleReviewsLogo from './GoogleReviews.svg?inline'

export default {
  components: {
    GoogleReviewsLogo,
    StarScore,
  },
  mixins: [getGoogleReviewLink],
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GoogleScore: GoogleScore.stars,
    }
  },
}
</script>
