<template>
  <div ref="rootElement" class="flex">
    <div class="flex flex-1 flex-col items-end justify-center bg-bluko-800 md:hidden">
      <div class="flex w-full max-w-[720px] flex-col items-center justify-center">
        <div class="max-w-[550px] px-[24px]">
          <Title :title-params="primary.title" class="mb-[32px] text-[48px] font-[300] leading-[64px] text-white" />
          <RichText :text-params="primary.description" class="font-[450] leading-[24px] text-white" />
        </div>
      </div>
    </div>
    <div ref="simulator" class="flex flex-1 items-center justify-start bg-bluko-50 md:w-full md:max-w-none md:justify-center">
      <div class="flex w-full max-w-[720px] items-center justify-center">
        <transition name="fade">
          <CardSimulator
            v-show="!showResult"
            :show-result="showResult"
            :primary="primary"
            @update:showResult="(v) => (showResult = v)"
            @update:quote="(v) => (quote = v)"
            @update:prospect="(v) => (prospect = v)"
          />
        </transition>
        <transition name="fade">
          <div v-if="showResult" class="mx-[24px] my-[80px] flex max-w-[560px] flex-col items-center md:my-[40px] md:mx-[16px]">
            <CardResult
              :quote="quote"
              :primary="primary"
              :show-result="showResult"
              :prospect="prospect"
              @update:showResult="(v) => (showResult = v)"
              @update:quote="(v) => (quote = v)"
            />
            <div
              class="mt-[72px] flex cursor-pointer flex-col items-center gap-[8px] sm:hidden"
              data-luko-tracking="LearnMoreProduct"
              @click="goNextSection"
            >
              <p class="text-[14px] font-[450] leading-[20px] text-bluko-200">{{ $t('PriceSimulator.learnMore') }}</p>
              <span class="flex h-[32px] w-[32px] items-center justify-center rounded-[50%] bg-bluko-100">
                <ArrowDownIcon class="h-[16px] w-[16px]" :class="$style.arrowDown" />
              </span>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import ArrowDownIcon from 'LkIcons/ArrowDown.vue'

import CardSimulator from './CardSimulator.vue'
import CardResult from './CardResult.vue'

export default {
  components: {
    CardSimulator,
    CardResult,
    ArrowDownIcon,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showResult: false,
      quote: null,
      prospect: null,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
  },
  watch: {
    showResult(newValue) {
      if (newValue) {
        const isMd = window.innerWidth < 960
        if (isMd) {
          this.$refs.simulator.style.minHeight = 'none'
        } else {
          const height = this.$refs.simulator.clientHeight
          this.$refs.simulator.style.minHeight = `${height}px`
        }
        this.$refs.simulator.scrollIntoView?.()
      } else {
        this.$refs.simulator.style.minHeight = 'none'
      }
    },
  },
  methods: {
    onSubmit() {
      this.showResult = true
    },
    goNextSection() {
      this.$refs.rootElement.parentElement?.nextElementSibling?.scrollIntoView?.()
    },
  },
}
</script>
<style lang="scss" module>
.arrowDown {
  animation: goDownAnimation 1500ms ease-in-out infinite;
}

@keyframes goDownAnimation {
  0% {
    transform: translateY(-3px);
  }

  50% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(-3px);
  }
}
</style>
<style lang="scss" scope>
/* slide right */
.fade-enter {
  transform: translateY(10px);

  opacity: 0;
}
.fade-enter-to {
  transform: initial;

  opacity: 1;
}
.fade-leave {
  display: none;
}
.fade-leave-to {
  display: none;
}
.fade-enter-active {
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}
.fade-leave-active {
  display: none;
}
</style>
