<template>
  <div class="bg-bluko-800 py-[60px] px-[24px]">
    <Wrapper class="flex max-w-[800px] flex-1 items-center justify-center md:flex-col md:text-center">
      <PImage v-if="primary.logo" :image-object="primary.logo" :class="$style.logo" :style="logoStyle" />
      <div :class="$style.text">
        <RichText v-if="primary.text" :class="$style.text" :text-params="primary.text" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
export default {
  name: 'LogoBannerDefault',

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    logoStyle() {
      const logoSize = this.primary.logoSize
      return logoSize ? { width: `${logoSize}px`, height: `${logoSize}px` } : {}
    },
  },
}
</script>

<style lang="scss" module>
.logo {
  width: auto;
  height: 100px;
  margin-right: 40px;

  @include below(medium) {
    width: calc(min(30vw, 84px));
    margin: 0;
    margin-bottom: 32px;
  }
}

.text {
  h2 {
    @include typo-headline-bold;
    color: white;
  }

  p {
    margin-top: 10px;

    color: $bluko-100;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    @include below(medium) {
      color: $bluko-200;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
