<template>
  <div class="relative mt-[-64px] h-[800px] overflow-x-hidden bg-cover bg-left-top md:h-[720px] sm:h-auto" :class="$style.root">
    <Wrapper class="flex h-full gap-[40px] pt-[120px] sm:flex-col sm:gap-[20px] sm:pt-[96px]">
      <div class="flex flex-[1.2] flex-col justify-between pl-[24px] sm:mx-auto sm:flex-none sm:px-[24px]">
        <div class="flex flex-col">
          <div>
            <Title class="mb-[16px] !text-white typo-mega-title md:typo-large-title sm:typo-extra-large-title" :title-params="title" />
            <span
              v-if="primary.tag"
              class="mt-[10px] inline-block -translate-y-[10px] rounded-[30px] bg-gold-500 p-[8px] px-[16px] typo-body sm:hidden"
            >
              {{ primary.tag }}
            </span>
            <RichText :text-params="subtitle" :class="$style.subtitle" class="!text-white opacity-80 typo-headline md:typo-sub-text" />
          </div>
          <div>
            <HeroCta :data="primary" is-light class="mt-[24px]" />
            <div v-if="TpScore >= 4.3 || GoogleScore >= 4.3" class="mt-[36px] flex items-center gap-[24px] sm:hidden">
              <TrustpilotWidget class="inline-flex" color="white" />
              <GoogleReviewWidget is-dark class="inline-flex path-bluko-300" />
            </div>
            <div v-if="primary.trustBadge && primary.trustBadge.url" class="mt-[16px] sm:hidden">
              <PImage class="h-[64px]" :image-object="primary.trustBadge" sizes="xs:350px" />
            </div>
          </div>
        </div>

        <!-- USP -->
        <div class="sm:hidden">
          <div v-if="items.length" class="flex w-full justify-evenly border-t-[1px] border-solid border-bluko-200">
            <RichText v-for="(item, index) in items" :key="index" :text-params="item.text" :class="$style.itemText" />
          </div>
          <div v-else-if="primary.logoStripe && primary.logoStripe.url" class="bottom-0 border-t-[1px] border-solid border-bluko-200 py-[24px]">
            <PImage :image-object="primary.logoStripe" class="max-h-[40px]" sizes="xs:150px" />
          </div>
        </div>
      </div>

      <div class="relative bottom-0 h-[100%] flex-1 shrink-0 sm:flex">
        <div class="flex h-[100vw] max-h-[400px] flex-col justify-between pl-[24px] up-sm:hidden">
          <div v-if="TpScore >= 4.3 || GoogleScore >= 4.3" class="flex items-center gap-[24px]">
            <TrustpilotWidget class="inline-flex flex-col-reverse !items-start" color="white" />
            <GoogleReviewWidget is-dark class="inline-flex flex-col-reverse !items-start path-bluko-300" />
          </div>
          <PImage class="mb-[24px] max-h-[64px] w-[25vw] max-w-[134px]" :image-object="primary.trustBadge" />
        </div>
        <div
          class="
            absolute
            bottom-0
            left-[10%]
            mt-[24px]
            h-[60vw]
            max-h-[660px]
            w-[60vw]
            max-w-[660px]
            overflow-hidden
            rounded-t-[50%]
            sm:left-[50%] sm:h-[85vw] sm:max-h-[380px] sm:w-[85vw] sm:max-w-[380px] sm:translate-x-0
            xs:left-[40%]
          "
          :class="$style.image"
        >
          <PImage preload :image-object="primary.backgroundImage" class="h-full w-full object-cover" sizes="xs:100vw md:100vw lg:100vw" />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import TpScore from '@@/script/trustpilot/score.json'
import GoogleScore from '@@/script/googleReview/score.json'

import TrustpilotWidget from '@/components/TrustpilotWidget'
import GoogleReviewWidget from '@/components/GoogleReviewWidget'
import HeroCta from '@/components/HeroCta'

export default {
  components: {
    HeroCta,
    TrustpilotWidget,
    GoogleReviewWidget,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      TpScore: TpScore.trustScore,
      GoogleScore: GoogleScore.stars,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((i) => i.text.length)
    },
    title() {
      return this.$store.state.variations.values.HomePageTitle?.[process.env.CURRENT_DOMAIN]?.[this.$i18n.locale] || this.primary.title
    },
    subtitle() {
      return this.$store.state.variations.values.HomePageSubtitle?.[process.env.CURRENT_DOMAIN]?.[this.$i18n.locale] || this.primary.subtitle
    },
  },
}
</script>

<style lang="scss" module>
.root {
  background-image: url('./pattern.png');
}
.subtitle {
  max-width: 650px;
  margin: 0;

  ul {
    padding: 0;
    li {
      padding-left: 35px;

      font-size: 24px;
    }
    li:before {
      content: '✓';
    }
  }
}

.itemText {
  padding: 20px 0;

  color: white;
  h3 {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
  }
  p {
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  @include below(medium) {
    h3 {
      font-size: 16px;
      line-height: 22px;
    }
    p {
      font-size: 11px;
      line-height: 18px;
    }
  }
}
.image {
  box-shadow: 0 0 0 12px #122182, 0 0 0 24px #1b31a4, 0 0 0 36px #2441c5;
  @include below(medium) {
    box-shadow: 0 0 0 6px #122182, 0 0 0 12px #1b31a4, 0 0 0 18px #2441c5;
  }
}
</style>
