<template>
  <div class="m-[160px] mx-auto p-0 px-[24px] md:my-[80px]">
    <Wrapper class="flex bg-orange-50 md:!flex-col" :class="[{ ['flex-row-reverse']: primary.imagePosition }]">
      <PImage lazy :image-object="primary.image" class="w-1/2 flex-1 object-cover md:w-full" sizes="sm:100vw" />
      <div class="flex flex-1 flex-col justify-center p-[72px] px-[86px] lg:p-[80px] lg:px-[32px] sm:p-[24px]">
        <Title v-if="primary.title" :title-params="primary.title" class="!font-[300] typo-mega-title sm:text-[36px] sm:leading-[38px]" />
        <RichText :text-params="primary.description" class="[&>p]:mt-[16px]" />
        <div class="mt-[16px]">
          <div v-for="(item, index) in items" :key="index" class="mt-[16px] flex gap-[14px] text-gray-700">
            <PImage :image-object="item.icon" class="h-[24px] w-[24px]" sizes="xs:50px" />
            <RichText :text-params="item.text" class="text-black" />
          </div>
        </div>
        <CtaBox :primary="primary" class="mt-[64px] sm:mt-[32px]" is-outlined />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import RichText from '@/components/RichText'
import Title from '@/components/Title'
import PImage from '@/components/PImage'

import CtaBox from '../components/CtaBox'

export default {
  name: 'CustomerServicePink',

  components: {
    RichText,
    Title,
    PImage,
    CtaBox,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
