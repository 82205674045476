<template>
  <label
    v-if="label || hasSlot"
    class="inline-block cursor-pointer text-[14px] font-[450] leading-[20px] text-black"
    :class="{
      ['cursor-not-allowed']: disabled,
    }"
  >
    <template v-if="label">{{ label }}</template>
    <slot v-else />
  </label>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasSlot() {
      const slot = this.$slots.default

      return slot && slot.text
    },
  },
}
</script>
