<template>
  <div ref="rootElement" class="relative w-full">
    <input
      ref="addressInput"
      :value="textValue"
      class="w-full rounded-[8px] py-[12px] px-[24px]"
      :class="$style.addressInput"
      type="text"
      :placeholder="placeholder"
      data-luko-tracking="AddressBar"
      @input="onAddressInput"
      @focus="isDropdownShown = true"
    />
    <LkDropdown
      :value="value"
      :options="suggestions"
      :show="isDropdownShown"
      class="absolute top-[100%]"
      @input="onSelectAddress"
      @close="isDropdownShown = false"
    />
  </div>
</template>

<script>
import debounce from 'lodash-es/debounce'

import LkDropdown from '@/components/Lukomponents/LkDropdown'
import { useGeoApi } from '@/composables/useGeoApi'
const { getPlacePredictions, getIPLocation } = useGeoApi()

export default {
  name: 'AddressBar',
  components: {
    LkDropdown,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: '',
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      rootElement: null,
      textValue: '',
      addressInput: null,
      autocomplete: null,
      suggestions: [],
      lat: null,
      lon: null,
      isDropdownShown: true,
    }
  },
  computed: {
    objValue: {
      get() {
        return this.value
      },
      set(obj) {
        this.textValue = obj.formatted
        this.$emit('input', obj)
      },
    },
  },

  mounted() {
    if (this.focus) {
      this.$refs.addressInput.focus()
    }
    document.addEventListener('click', this.handleOutsideClick)
  },
  unmounted() {
    document.removeEventListener('click', this.handleOutsideClick)
  },

  methods: {
    handleOutsideClick(event) {
      const outsideClick = !this.$refs.rootElement.contains(event.target)
      if (outsideClick) this.isDropdownShown = false
    },
    onSelectAddress(obj) {
      this.textValue = obj.formatted
      this.$emit('input', obj)
    },

    onAddressInput(event) {
      this.textValue = event.target.value
      this.searchAddress()
    },
    searchAddress: debounce(async function () {
      if (!this.textValue) {
        this.suggestions = []
        return
      }

      if (!this.lat || !this.lon) {
        const { lat, lon } = await getIPLocation()
        this.lat = lat
        this.lon = lon
      }

      const matches = await getPlacePredictions(this.textValue, { lat: this.lat, lon: this.lon, locale: this.$i18n.locale })
      if (matches?.length) {
        this.suggestions = matches
          .filter((place) => place.postcode)
          .map((place) => {
            const wording = place.formatted
            return {
              wording,
              value: place,
            }
          })
      } else {
        this.suggestions = []
      }
    }, 400),
  },
}
</script>

<style lang="scss" module>
.addressInput {
  border: 2px solid $gray-100;

  color: $gray-1000;
  font-size: 16px;

  font-family: 'CircularXX', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

  line-height: 24px;

  background-color: white;

  box-shadow: 0 0 0 0px $bluko-100;

  transition-duration: 0.3s;

  transition-property: border-color, box-shadow;
  &::placeholder {
    color: $gray-700;

    opacity: 1;
  }

  &:hover {
    border-color: $gray-200;
  }

  &:focus {
    border-color: #2d50e6;

    box-shadow: 0 0 0 4px $bluko-100;
  }
}
</style>
