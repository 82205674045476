<template>
  <SliceWrapper :slice="slice">
    <PhoneCarousel2021Q4 v-if="slice.variation === '2021Q4'" :slice="slice" />
    <PhoneCarouselDefault v-else :slice="slice" />
  </SliceWrapper>
</template>

<script>
import PhoneCarouselDefault from './default'
import PhoneCarousel2021Q4 from './2021Q4/PhoneCarousel2021Q4.vue'

export default {
  name: 'PhoneCarousel',
  components: {
    PhoneCarouselDefault,
    PhoneCarousel2021Q4,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.section {
  padding: 4em;

  color: #111;
  text-align: center;

  background: #f7f7f7;
}
.title {
  margin-bottom: 2em;
}
</style>
