<template>
  <div class="p-0 px-[20px] sm:px-[10px]">
    <Wrapper class="my-[60px] mx-auto max-h-[70vh] overflow-y-scroll">
      <table v-if="tableData" class="relative w-full rounded-[8px]">
        <thead :class="$style.thead">
          <!-- without category -->
          <template v-if="headers.length < 2">
            <tr>
              <th
                v-for="(cell, columnIndex) in headers[0]"
                :key="'head' + columnIndex"
                :style="{ background: cell.option.background }"
                class="flex items-center justify-center"
                :class="{
                  'rounded-tl-[8px]': cell.option.roundedTopLeft,
                  'rounded-tr-[8px]': cell.option.roundedTopRight,
                  [$style.filterEnabled]: Math.abs(filterColumnIndex) === columnIndex + 1,
                  ['sm:hidden']: cell.option.hideMobile === 'true',
                }"
                @click="onFilter(columnIndex)"
              >
                <img v-if="cell.link" :src="cell.link.url" class="h-[24px] w-[24px]" />
                <template v-else>{{ cell ? cell.content : '' }}</template>
              </th>
            </tr>
          </template>
          <!-- with category -->
          <template v-else>
            <tr>
              <th
                v-for="(cell, columnIndex) in headers[0]"
                :key="'head' + columnIndex"
                :style="{ background: cell.option.background }"
                :class="{
                  'rounded-tl-[8px]': cell.option.roundedTopLeft,
                  'rounded-tr-[8px]': cell.option.roundedTopRight,
                  [$style.category]: true,
                  ['sm:hidden']: cell.option.hideMobile === 'true',
                }"
                :colspan="cell.option.colspan"
              >
                {{ cell.content }}
              </th>
            </tr>
            <tr>
              <th
                v-for="(cell, columnIndex) in headers[1]"
                :key="'head' + columnIndex"
                :style="{ background: cell.option.background }"
                :class="{
                  'rounded-tl-[8px]': cell.option.roundedTopLeft,
                  'rounded-tr-[8px]': cell.option.roundedTopRight,
                  [$style.filterEnabled]: Math.abs(filterColumnIndex) === columnIndex + 1,
                  ['sm:hidden']: cell.option.hideMobile === 'true',
                }"
                @click="onFilter(columnIndex)"
              >
                <img v-if="cell.link" :src="cell.link.url" class="mx-auto h-[24px] w-[24px]" />
                <template v-else>{{ cell ? cell.content : '' }}</template>
              </th>
            </tr>
          </template>
        </thead>
        <tbody :class="$style.tbody">
          <tr v-for="(body, idx) in bodies" :key="'body' + idx">
            <td
              v-for="(cell, columnIndex) in body"
              :key="'body' + columnIndex"
              :style="{ background: cell.option.background }"
              :class="{ ['sm:hidden']: headers[headers.length - 1][columnIndex].option.hideMobile === 'true' }"
            >
              <img v-if="cell.link" :src="cell.link.url" :class="$style.logo" />
              <template v-else>{{ cell ? cell.content : '' }}</template>
              <CheckIcon v-if="cell.option.check === 'yes'" width="14px" height="14px" class="mx-auto h-[14px] w-[14px] path-black" />
              <CrossIcon v-if="cell.option.check === 'no'" width="14px" height="14px" class="mx-auto h-[14px] w-[14px] path-black" />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        {{ error }}
      </div>
    </Wrapper>
  </div>
</template>

<script>
// How to use
// https://www.notion.so/luko/How-to-import-your-table-99fff3cacfa849f9bd723900ca409fc0#72f744d7614a4d91a65d1dc375475f5e

import CheckIcon from 'LkIcons/Check.vue'
import CrossIcon from 'LkIcons/Cross.vue'

const optionParser = (optionString) => {
  if (!optionString) return {}

  return optionString
    .replace(/\{|\}/g, '')
    .split(',')
    .reduce((obj, str) => {
      const [key, value] = str.split(':')
      return {
        ...obj,
        [key?.trim()]: value?.trim().toLowerCase(),
      }
    }, {})
}
const rowParser = (row) =>
  row
    .map((cell) =>
      cell.reduce(
        (res, c) => {
          return [
            {
              link: c.text?.link ? c.text.link : res[0].link,
              content: res[0].content + c.text.content,
            },
          ]
        },
        [{ content: '' }]
      )
    )
    .map((cell) => cell[0])

export default {
  components: {
    CheckIcon,
    CrossIcon,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  async fetch() {
    try {
      const { Client } = await import('@notionhq/client')
      const notion = new Client({ auth: process.env.NOTION_API_KEY })
      const link = this.slice.primary.notionTableLink?.url
      if (!link) return

      const blockId = new URL(link).hash.replace('#', '')
      if (!blockId) return

      const response = await notion.blocks.children.list({
        block_id: blockId,
      })
      this.tableData = response.results
    } catch (error) {
      console.error(error)
      this.error = error.message
    }
  },
  data() {
    return {
      tableData: null,
      error: '',
      filterColumnIndex: null,
    }
  },
  computed: {
    headers() {
      let hasCategory = false

      let rows = this.tableData
        .slice(0, 1)
        .map((data) => data.table_row.cells)
        .map((row) =>
          rowParser(row).map((cell) => {
            const content = cell.content?.replace(/\{.*\}/, '')
            const option = optionParser(cell.content?.match(/\{.*\}/)?.[0])
            if (!hasCategory && option.category) hasCategory = true

            return {
              content,
              option,
              link: cell.link,
            }
          })
        )

      if (hasCategory) {
        const categoryHeader = []
        let prevCategory = null
        for (const cell of rows[0]) {
          if (!cell.option.category || prevCategory !== cell.option.category) {
            categoryHeader.push({
              content: cell.option.category || '',
              option: {
                background: cell.option.category ? undefined : 'white',
                hideMobile: cell.option.hideMobile,
                colspan: 1,
              },
            })
            prevCategory = cell.option.category || null
          } else {
            categoryHeader[categoryHeader.length - 1].option.colspan += 1
          }
        }

        rows = [categoryHeader, rows[0]]
      }
      rows.forEach((r) => {
        const first = r.find((h) => h.option.background !== 'white')
        if (first) first.option.roundedTopLeft = true
        const last = r.reverse().find((h) => h.option.background !== 'white')
        if (last) last.option.roundedTopRight = true
        r.reverse()
      })
      return rows
    },
    bodies() {
      const rows = this.tableData
        .slice(1)
        .map((data) => data.table_row.cells)
        .map((row) =>
          rowParser(row).map((cell) => {
            const content = cell.content?.replace(/\{.*\}/, '')
            const option = optionParser(cell?.content?.match(/\{.*\}/)?.[0])
            let value = parseFloat(content)
            if (isNaN(value)) value = content || null
            if (!value) value = option.check

            return {
              content,
              option,
              link: cell.link,
              value,
            }
          })
        )
      if (this.filterColumnIndex != null) {
        return rows.sort((a, b) => {
          const column = Math.abs(this.filterColumnIndex) - 1
          const direction = a[column].value > b[column].value ? 1 : -1
          return this.filterColumnIndex > 0 ? direction : -direction
        })
      }

      return rows
    },
  },
  methods: {
    onFilter(columnIndex) {
      if (Math.abs(this.filterColumnIndex) === columnIndex + 1) this.filterColumnIndex = -this.filterColumnIndex
      else this.filterColumnIndex = columnIndex + 1
    },
  },
}
</script>
<style module lang="scss">
.thead {
  position: sticky;
  top: 0;

  box-shadow: 0 1px 2px $gray-200;

  tr th {
    font-weight: 500;
  }
  tr:last-of-type th {
    padding: 25px 10px;

    background-color: $orange-50;
    cursor: pointer;
  }
  tr:last-of-type th:hover {
    background-color: $orange-75;
  }

  tr:last-of-type th.filterEnabled {
    background-color: $orange-100;
  }
  .category {
    padding: 18px 10px;
  }
  .category:nth-of-type(2n) {
    background-color: $orange-100;
  }
  .category:nth-of-type(2n-1) {
    background-color: $orange-75;
  }
}

.tbody {
  tr {
    border-bottom: 1px solid $gray-100;
  }
  tr td {
    padding: 20px 10px;

    text-align: center;
  }
}
</style>
