// Code generated by Slice Machine. DO NOT EDIT.

import AppBlock from './AppBlock';
import ArticleAnchor from "./ArticleAnchor";
import ArticleDropdown from "./ArticleDropdown"
import ArticleSecondTitle from "./ArticleSecondTitle"
import ArticleIconList from "./ArticleIconList"
import ArticleTitle from './ArticleTitle';
import BundleProduct from './BundleProduct';
import CertificationSection from './CertificationSection';
import CommitmentSection from './CommitmentSection';
import CompetitorsComparison from './CompetitorsComparison';
import CounterBanner from './CounterBanner';
import CoverageOptions from './CoverageOptions';
import CoverageSection from './CoverageSection';
import CustomerService from './CustomerService';
import CustomerServiceCarousel from './CustomerServiceCarousel';
import EmbedHtml from './EmbedHtml';
import EmbedPdf from './EmbedPdf';
import Engagement from './Engagement';
import FaqSlice from './FaqSlice';
import FullWidthImage from './FullWidthImage';
import GivebackSchema from './GivebackSchema';
import GlobalVariables from './GlobalVariables';
import Hero from './Hero';
import HeroLaunch from './HeroLaunch';
import HeroPrice from './HeroPrice';
import HighlightCards from './HighlightCards';
import HighlightCounter from './HighlightCounter';
import HighlightIcons from './HighlightIcons';
import HighlightSection from './HighlightSection';
import HighlightText from './HighlightText';
import HistoryTimeline from './HistoryTimeline';
import InstagramUgc from './InstagramUgc';
import LogoBanner from './LogoBanner';
import LogoList from './LogoList';
import LukofferCta from './LukofferCta';
import MediaCarousel from './MediaCarousel';
import MetaData from './MetaData';
import NewsLetter from './NewsLetter';
import NpsScore from './NpsScore';
import PageSettings from './PageSettings';
import PartnerAgency from './PartnerAgency';
import PartnersQuote from './PartnersQuote';
import PartnershipSection from './PartnershipSection';
import PhoneCarousel from './PhoneCarousel';
import PressListCarousel from './PressListCarousel';
import PriceSimulator from './PriceSimulator';
import ProcessArrow from './ProcessArrow';
import ProductCarousel from './ProductCarousel';
import QuinconceSlice from './QuinconceSlice';
import RefundSchema from './RefundSchema';
import SimpleCta from './SimpleCta';
import SimpleSlice from './SimpleSlice';
import SimpleTable from './SimpleTable';
import TableSlice from './TableSlice';
import TrustpilotCarousel from './TrustpilotCarousel';
import TrustpilotComparison from './TrustpilotComparison';
import VideoCarousel from './VideoCarousel';

export {
	AppBlock,
  ArticleAnchor,
  ArticleDropdown,
  ArticleSecondTitle,
  ArticleIconList,
	ArticleTitle,
	BundleProduct,
	CertificationSection,
	CommitmentSection,
	CompetitorsComparison,
	CounterBanner,
	CoverageOptions,
	CoverageSection,
	CustomerService,
	CustomerServiceCarousel,
	EmbedHtml,
	EmbedPdf,
	Engagement,
	FaqSlice,
	FullWidthImage,
	GivebackSchema,
	GlobalVariables,
	Hero,
	HeroLaunch,
	HeroPrice,
	HighlightCards,
	HighlightCounter,
	HighlightIcons,
	HighlightSection,
	HighlightText,
	HistoryTimeline,
	InstagramUgc,
	LogoBanner,
	LogoList,
	LukofferCta,
	MediaCarousel,
	MetaData,
	NewsLetter,
	NpsScore,
	PageSettings,
	PartnerAgency,
	PartnersQuote,
	PartnershipSection,
	PhoneCarousel,
	PressListCarousel,
	PriceSimulator,
	ProcessArrow,
	ProductCarousel,
	QuinconceSlice,
	RefundSchema,
	SimpleCta,
	SimpleSlice,
	SimpleTable,
	TableSlice,
	TrustpilotCarousel,
	TrustpilotComparison,
	VideoCarousel,
};

export const components = {
	app_block: AppBlock,
  article_anchor: ArticleAnchor,
  article_dropdown: ArticleDropdown,
  article_icon_list: ArticleIconList,
  article_second_title: ArticleSecondTitle,
	article_title: ArticleTitle,
	bundle_product: BundleProduct,
	certification_section: CertificationSection,
	commitment_section: CommitmentSection,
	competitors_comparison: CompetitorsComparison,
	counter_banner: CounterBanner,
	coverage_options: CoverageOptions,
	coverage_section: CoverageSection,
	customer_service: CustomerService,
	customer_service_carousel: CustomerServiceCarousel,
	embed_html: EmbedHtml,
	embed_pdf: EmbedPdf,
	engagement: Engagement,
	faq_slice: FaqSlice,
	full_width_image: FullWidthImage,
	giveback_schema: GivebackSchema,
	global_variables: GlobalVariables,
	hero: Hero,
	hero_launch: HeroLaunch,
	hero_price: HeroPrice,
	highlight_cards: HighlightCards,
	highlight_counter: HighlightCounter,
	highlight_icons: HighlightIcons,
	highlight_section: HighlightSection,
	highlight_text: HighlightText,
	history_timeline: HistoryTimeline,
	instagram_ugc: InstagramUgc,
	logo_banner: LogoBanner,
	logo_list: LogoList,
	lukoffer_cta: LukofferCta,
	media_carousel: MediaCarousel,
	meta_data: MetaData,
	news_letter: NewsLetter,
	nps_score: NpsScore,
	page_settings: PageSettings,
	partner_agency: PartnerAgency,
	partners_quote: PartnersQuote,
	partnership_section: PartnershipSection,
	phone_carousel: PhoneCarousel,
	press_list_carousel: PressListCarousel,
	price_simulator: PriceSimulator,
	process_arrow: ProcessArrow,
	product_carousel: ProductCarousel,
	quinconce_slice: QuinconceSlice,
	refund_schema: RefundSchema,
	simple_cta: SimpleCta,
	simple_slice: SimpleSlice,
	simple_table: SimpleTable,
	table_slice: TableSlice,
	trustpilot_carousel: TrustpilotCarousel,
	trustpilot_comparison: TrustpilotComparison,
	video_carousel: VideoCarousel,
};
