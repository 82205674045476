export const useSlice = () => {
  return {
    makeMoreThan10: (arr) => {
      if (arr.length === 0) return arr

      while (arr.length < 10) {
        arr = [...arr, ...arr]
      }
      return arr
    },
  }
}
