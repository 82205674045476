<template>
  <div ref="rootElement" class="relative flex w-full flex-col items-stretch gap-[8px] text-left">
    <LkLabel :label="label" :for="lukid" />
    <LkDateMaskedInput
      :id="lukid"
      :value="pickedDate"
      :min="min"
      :max="max"
      :error="error"
      :disabled="disabled"
      :autofocus="autofocus"
      :placeholder="placeholder"
      show-icon
      @focus="pickerDisplayed = true"
      @error:lessThanMin="emitError($event, 'error:lessThanMin')"
      @error:moreThanMax="emitError($event, 'error:moreThanMax')"
      @error:invalidDate="emitError($event, 'error:invalidDate')"
      @input="picked($event)"
      @keydown.tab="pickerDisplayed = false"
    />
    <LkError :message="error" />
    <LkDatePicker
      :displayed="!hideDatePicker && pickerDisplayed"
      :value="pickedDate"
      class="absolute top-[48px] z-50"
      :class="{ ['!top-[80px]']: label }"
      :min="min"
      :max="max"
      @input="picked($event)"
    />
  </div>
</template>
<script>
import LkError from '@/components/Lukomponents/LkError'
import LkLabel from '@/components/Lukomponents/LkLabel'
import LkDateMaskedInput from '@/components/Lukomponents/LkDateMaskedInput'
import LkDatePicker from '@/components/Lukomponents/LkDatePicker'

import { autoLukid } from '../autoLukid.mixin'

export default {
  components: {
    LkError,
    LkLabel,
    LkDateMaskedInput,
    LkDatePicker,
  },
  mixins: [autoLukid],
  props: {
    value: { type: Date, default: undefined },
    label: { type: String, default: undefined },
    theme: { type: String, default: undefined },
    min: { type: Date, default: undefined },
    max: { type: Date, default: undefined },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    error: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    hideDatePicker: { type: Boolean, default: false },
  },
  emits: ['input', 'error:lessThanMin', 'error:moreThanMax', 'error:invalidDate'],
  data() {
    return {
      pickerDisplayed: false,
      pickedDate: this.value || undefined,
    }
  },
  watch: {
    pickerDisplayed(newVal) {
      if (newVal) {
        document.addEventListener('click', this.handleOutsideClick)
      } else {
        document.removeEventListener('click', this.handleOutsideClick)
      }
    },
  },
  methods: {
    picked(date) {
      this.pickerDisplayed = false
      this.pickedDate = date || undefined

      this.$emit('input', date)
    },
    handleOutsideClick(e) {
      if (!e.composedPath().includes(this.$refs.rootElement)) {
        this.pickerDisplayed = false
      }
    },
    emitError($event, errorName) {
      this.pickerDisplayed = false
      this.$emit(errorName, $event)
    },
  },
}
</script>
