<template>
  <div class="my-[80px]">
    <Wrapper class="flex items-center justify-between gap-[100px] px-[32px] md:flex-col md:gap-[60px]">
      <div class="max-w-[550px] flex-1 md:max-w-none">
        <Title :title-params="primary.title" class="mb-[24px] text-[48px] font-[300] leading-[58px] sm:text-[32px] sm:leading-[42px]" />
        <RichText :text-params="primary.description" class="[&>p]:mb-[8px]" />
        <ArrowButton v-if="primary.ctaText" is-outlined :link-object="primary.ctaLink" class="mt-[40px] md:w-full" :link-text="primary.ctaText" />
      </div>
      <div class="flex-1">
        <ul
          :class="{ [$style.icon3x3Grid]: items.length > 2 }"
          class="grid max-w-[600px] flex-1 content-between gap-[40px] md:max-w-none sm:grid-cols-2"
        >
          <li
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            class="flex flex-col items-center"
            data-aos="zoom-in"
            :data-aos-delay="itemDelays[itemIndex]"
            data-aos-duration="150"
            data-aos-mirror="false"
          >
            <PImage :image-object="item.icon" sizes="xs:100px" class="mb-[8px] h-[56px] w-[56px]" />
            <Title :title-params="item.title" class="mb-[8px] text-center typo-body" />
            <RichText :text-params="item.description" class="text-center opacity-70 typo-sub-text" />
          </li>
        </ul>
        <RichText :text-params="primary.iconsSubtext" class="mt-[40px] text-center typo-sub-text" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import score from '@@/script/trustpilot/score.json'
import { pipe, range, toArray, map } from '@fxts/core'

import ArrowButton from '@/components/ArrowButton'

export default {
  components: {
    ArrowButton,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      score,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    itemDelays() {
      return pipe(
        range(this.items.length),
        map((a) => (a + 1) * 100),
        toArray,
        (arr) => {
          for (let i = 0; i < arr.length / 2; i++) {
            const randomIdx = Math.floor(Math.random() * this.items.length)
            const tmp = arr[i]
            arr[i] = arr[randomIdx]
            arr[randomIdx] = tmp
          }
          return arr
        }
      )
    },
  },
}
</script>

<style lang="scss" module>
.icon3x3Grid {
  grid-template-columns: 1fr 1fr 1fr;
  @include below(small) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
