<template>
  <SliceWrapper :slice="slice">
    <CounterBannerDefault :slice="slice" />
  </SliceWrapper>
</template>

<script>
import CounterBannerDefault from './default-slice'

export default {
  name: 'CounterBanner',
  components: {
    CounterBannerDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
