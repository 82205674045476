<template>
  <SliceWrapper :slice="slice">
    <NpsScoreLine v-if="slice.variation === 'line'" :slice="slice" />
    <NpsScoreCard v-else-if="slice.variation === 'card'" :slice="slice" />
  </SliceWrapper>
</template>

<script>
import NpsScoreLine from './line'
import NpsScoreCard from './card'
export default {
  name: 'NpsScore',
  components: {
    NpsScoreLine,
    NpsScoreCard,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
