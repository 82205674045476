<template>
  <div class="my-[80px] rounded-[8px] bg-bluko-800 py-[24px] px-[32px] sm:my-[64px]">
    <div class="mb-[24px] flex">
      <CheckIcon class="mr-[16px] h-[32px] w-[32px] rounded-[50%] bg-bluko-500 p-[8px] path-white" />
      <Title :title-params="primary.title" class="!text-orange-100 typo-headline-bold" />
    </div>
    <RichText :text-params="primary.description" :class="$style.description" />
    <div class="flex items-center justify-between sm:flex-col-reverse sm:items-start sm:gap-[20px]">
      <LkButton v-if="primary.buttonCtaText" is-small class="!sm:w-full" :link-object="primary.buttonCta">
        {{ primary.buttonCtaText }}
      </LkButton>
      <div v-else></div>
      <ArrowLink :link-object="primary.linkCta" class="!text-bluko-200 typo-sub-text path-bluko-200" :link-text="primary.linkCtaText" />
    </div>
  </div>
</template>

<script>
import CheckIcon from 'LkIcons/Check.vue'

import ArrowLink from '@/components/ArrowLink'

export default {
  components: { CheckIcon, ArrowLink },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
  },
}
</script>

<style lang="scss" module>
.description {
  padding-bottom: 32px;

  color: white;
  ul {
    position: relative;

    padding-top: 0;

    list-style: none;
  }
  ul li {
    padding-left: 32px;
  }
  ul li::before {
    position: absolute;
    left: 0;

    padding-left: 12px;

    content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.959519C-0.0976311 1.35004 -0.0976311 1.98321 0.292893 2.37373L3.91912 5.99996L0.292893 9.62619C-0.0976311 10.0167 -0.0976311 10.6499 0.292893 11.0404C0.683417 11.4309 1.31658 11.4309 1.70711 11.0404L6.04044 6.70707C6.43096 6.31654 6.43096 5.68338 6.04044 5.29285L1.70711 0.959519C1.31658 0.568995 0.683417 0.568995 0.292893 0.959519Z' fill='%23FCE1D2'/%3E%3C/svg%3E%0A");
  }
}
</style>
