<template>
  <SliceWrapper :slice="slice">
    <PartnersQuoteDefault :slice="slice" />
  </SliceWrapper>
</template>

<script>
import PartnersQuoteDefault from './default/PartnersQuoteDefault.vue'

export default {
  name: 'PartnersQuote',
  components: {
    PartnersQuoteDefault,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
