export const autoLukid = {
  data() {
    return {
      lukid: undefined,
    }
  },
  mounted() {
    this.$store.commit('lukid/UPDATE')
    this.lukid = this.$store.getters['lukid/getLukid']
  },
}
