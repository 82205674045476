<template>
  <div>
    <Wrapper class="py-[160px] px-[24px] sm:py-[80px]">
      <Title :title-params="primary.title" class="mx-auto max-w-[840px] text-center !font-[300] typo-mega-title md:typo-extra-large-title" />
      <RichText :text-params="primary.description" class="mx-auto mt-[8px] max-w-[840px] text-center text-black text-opacity-60" />
      <ul class="mt-[64px] flex flex-col gap-[40px]">
        <li v-for="(item, idx) in items" :key="idx" class="flex rounded-[8px] border-[1px] border-bluko-100 bg-bluko-25 sm:flex-col">
          <div class="flex w-[30%] max-w-[320px] items-center justify-center bg-white p-[24px] sm:min-h-[100px] sm:w-full sm:max-w-[unset]">
            <PImage :image-object="item.image" size="xs:300px" />
          </div>
          <div class="flex-1 p-[40px] sm:p-[24px]">
            <div v-if="item.tags && item.tags.split(',').length" class="mb-[24px] flex gap-[8px] sm:justify-center">
              <span v-for="tag in item.tags.split(',')" class="rounded-[24px] bg-bluko-75 px-[8px] py-[4px] !text-bluko-700 typo-caption">
                {{ tag.trim() }}
              </span>
            </div>

            <RichText :text-params="item.description" class="!italic typo-headline sm:text-center" />
            <div class="mt-[8px] flex sm:flex-col sm:items-center">
              <span class="typo-sub-text">{{ item.name }}</span> <span class="text-black text-opacity-40 typo-sub-text">{{ item.job }}</span>
            </div>
            <LkButton v-if="item.cta_text" is-small is-outlined :link-object="item.cta_link" class="mt-[24px] sm:!w-full">{{
              item.cta_text
            }}</LkButton>
          </div>
        </li>
      </ul>
    </Wrapper>
  </div>
</template>

<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>
