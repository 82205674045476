export const useAddress = () => {
  return {
    getAddressMain(addressObj) {
      const { street, housenumber, country_code } = addressObj
      if (!street || !housenumber) return ''
      if (country_code === 'de') {
        return `${street} ${housenumber}`.trim()
      }
      return `${housenumber} ${street}`.trim()
    },
  }
}
