<template>
  <div
    v-observe-visibility="{ callback: isVisible, once: true }"
    class="relative -mt-[64px] min-h-[780px] bg-bluko-500 bg-cover bg-bottom bg-no-repeat p-0 px-[24px] pb-[310px] xs:bg-contain"
    :style="backgroundVideo ? {} : { backgroundImage: `url(${backgroundImage})` }"
  >
    <video v-if="backgroundVideo" ref="video" preload="none" playsinline muted="muted" class="absolute bottom-0 left-0 w-screen object-cover">
      <source :src="backgroundVideo2" type="video/webm" />
      <source :src="backgroundVideo" type="video/mp4" />
    </video>
    <Wrapper class="relative min-h-[630px] pt-[120px]">
      <Title class="mb-[16px] !text-white typo-extra-large-title" :title-params="title" />
      <span v-if="primary.tag" class="mt-[10px] inline-block -translate-y-[10px] rounded-[30px] bg-gold-500 p-[8px] px-[16px] typo-body sm:hidden">
        {{ primary.tag }}
      </span>
      <RichText :text-params="subtitle" class="!text-bluko-100 typo-body" :class="$style.subtitle" />
      <HeroCta is-light :data="primary" class="mt-[30px]" />
      <div v-if="TpScore >= 4.3 || GoogleScore >= 4.3" class="mt-[40px] flex flex-wrap items-center gap-[12px_24px]">
        <TrustpilotWidget v-if="TpScore >= 4.3" color="white" />
        <GoogleReviewWidget v-if="GoogleScore >= 4.3" is-dark class="path-bluko-300" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import TpScore from '@@/script/trustpilot/score.json'
import GoogleScore from '@@/script/googleReview/score.json'

import TrustpilotWidget from '@/components/TrustpilotWidget'
import GoogleReviewWidget from '@/components/GoogleReviewWidget'
import HeroCta from '@/components/HeroCta'

export default {
  components: {
    HeroCta,
    TrustpilotWidget,
    GoogleReviewWidget,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      intervalId: null,
      videoLoad: false,
      TpScore: TpScore.trustScore,
      GoogleScore: GoogleScore.stars,
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
    backgroundImage() {
      if (!this.primary.backgroundImageMobile?.url) return ''

      return this.$img.getSizes(this.primary.backgroundImageMobile.url, {
        sizes: 'sm:900px',
        modifiers: {
          quality: 80,
        },
      }).src
    },
    backgroundVideo() {
      return this.primary.backgroundVideoMobile?.url
    },
    backgroundVideo2() {
      return this.primary.backgroundVideoMobile2?.url
    },
    title() {
      return this.$store.state.variations.values.HomePageTitle?.[process.env.CURRENT_DOMAIN]?.[this.$i18n.locale] || this.primary.title
    },
    subtitle() {
      return this.$store.state.variations.values.HomePageSubtitle?.[process.env.CURRENT_DOMAIN]?.[this.$i18n.locale] || this.primary.subtitle
    },
  },
  methods: {
    startVideo() {
      const isHidden = document.hidden || document.msHidden || document.webkitHidden
      if (!isHidden) this.$refs.video.play()

      this.intervalId = setInterval(() => {
        const isHidden = document.hidden || document.msHidden || document.webkitHidden
        if (!isHidden && this.videoLoad) {
          this.$refs.video.play()
        }
      }, 8000)
    },
    stopVideo() {
      clearInterval(this.intervalId)
    },
    isVisible(visible) {
      if (visible && this.$refs.video) {
        this.$refs.video.load()
        this.videoLoad = true
        this.startVideo()
      }
    },
  },
}
</script>

<style lang="scss" module>
.subtitle {
  li {
    font-size: 24px;
    font-display: 200;
  }
  li:before {
    color: white !important;
  }
}
</style>
