<template>
  <div v-observe-visibility="{ callback: onVisible, once: true }" class="flex flex-col justify-center py-[80px]">
    <Title class="mb-[36px] px-[24px] text-center !font-[300] typo-extra-large-title" :title-params="primary.title" />
    <div class="relative min-h-[600px]">
      <BackImage class="absolute left-[50%] top-[52%] mx-auto h-[min(400px,80vw)] w-[min(400px,80vw)] -translate-y-1/2 -translate-x-1/2" />
      <VueSlickCarousel v-if="load" v-bind="slickOptions">
        <div v-for="(item, idx) in items" :key="idx" class="relative cursor-grab">
          <Title :title-params="item.title" class="text-center typo-headline-bold" />
          <RichText :text-params="item.description" class="mx-auto w-[min(370px,90vw)] text-center text-gray-700" />
          <PImage
            :key="'front' + idx"
            :image-object="item.imageFront"
            class="pointer-events-none relative mx-auto h-[600px] object-contain"
            sizes="xs:600px"
            :quality="80"
            lazy
          />
        </div>
        <template #customPaging>
          <button class="h-[8px] w-[8px] rounded-[50%]" />
        </template>
      </VueSlickCarousel>
    </div>
    <Wrapper v-if="primary.ctaText && primary.ctaLink" class="flex justify-center">
      <ArrowButton :link-object="primary.ctaLink" :target="primary.ctaLink.target" is-outlined :link-text="primary.ctaText" />
    </Wrapper>
  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import VueSlickCarousel from 'vue-slick-carousel'

import ArrowButton from '@/components/ArrowButton'

import BackImage from './BackImage.svg?inline'

export default {
  name: 'MediaCarouselMobile',
  components: {
    VueSlickCarousel,
    BackImage,
    ArrowButton,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      load: false,
      slickOptions: {
        arrows: false,
        dots: true,
        dotsClass: 'slickMediaCarouselDots',
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        lazyLoad: 'ondemand',
      },
    }
  },
  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items.filter((item) => item.imageFront?.url)
    },
  },
  methods: {
    onVisible(visible) {
      if (visible) {
        this.load = true
      }
    },
  },
}
</script>
<style lang="scss">
.slickMediaCarouselDots {
  display: flex !important;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  height: 40px;

  li {
    display: flex;
  }

  button {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .slick-active button {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
