var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-0 px-[20px] sm:px-[10px]"},[_c('Wrapper',{staticClass:"my-[60px] mx-auto max-h-[70vh] overflow-y-scroll"},[(_vm.tableData)?_c('table',{staticClass:"relative w-full rounded-[8px]"},[_c('thead',{class:_vm.$style.thead},[(_vm.headers.length < 2)?[_c('tr',_vm._l((_vm.headers[0]),function(cell,columnIndex){
var _obj;
return _c('th',{key:'head' + columnIndex,staticClass:"flex items-center justify-center",class:( _obj = {
                'rounded-tl-[8px]': cell.option.roundedTopLeft,
                'rounded-tr-[8px]': cell.option.roundedTopRight
              }, _obj[_vm.$style.filterEnabled] = Math.abs(_vm.filterColumnIndex) === columnIndex + 1, _obj['sm:hidden'] = cell.option.hideMobile === 'true', _obj ),style:({ background: cell.option.background }),on:{"click":function($event){return _vm.onFilter(columnIndex)}}},[(cell.link)?_c('img',{staticClass:"h-[24px] w-[24px]",attrs:{"src":cell.link.url}}):[_vm._v(_vm._s(cell ? cell.content : ''))]],2)}),0)]:[_c('tr',_vm._l((_vm.headers[0]),function(cell,columnIndex){
              var _obj;
return _c('th',{key:'head' + columnIndex,class:( _obj = {
                'rounded-tl-[8px]': cell.option.roundedTopLeft,
                'rounded-tr-[8px]': cell.option.roundedTopRight
              }, _obj[_vm.$style.category] = true, _obj['sm:hidden'] = cell.option.hideMobile === 'true', _obj ),style:({ background: cell.option.background }),attrs:{"colspan":cell.option.colspan}},[_vm._v("\n              "+_vm._s(cell.content)+"\n            ")])}),0),_vm._v(" "),_c('tr',_vm._l((_vm.headers[1]),function(cell,columnIndex){
              var _obj;
return _c('th',{key:'head' + columnIndex,class:( _obj = {
                'rounded-tl-[8px]': cell.option.roundedTopLeft,
                'rounded-tr-[8px]': cell.option.roundedTopRight
              }, _obj[_vm.$style.filterEnabled] = Math.abs(_vm.filterColumnIndex) === columnIndex + 1, _obj['sm:hidden'] = cell.option.hideMobile === 'true', _obj ),style:({ background: cell.option.background }),on:{"click":function($event){return _vm.onFilter(columnIndex)}}},[(cell.link)?_c('img',{staticClass:"mx-auto h-[24px] w-[24px]",attrs:{"src":cell.link.url}}):[_vm._v(_vm._s(cell ? cell.content : ''))]],2)}),0)]],2),_vm._v(" "),_c('tbody',{class:_vm.$style.tbody},_vm._l((_vm.bodies),function(body,idx){return _c('tr',{key:'body' + idx},_vm._l((body),function(cell,columnIndex){
              var _obj;
return _c('td',{key:'body' + columnIndex,class:( _obj = {}, _obj['sm:hidden'] = _vm.headers[_vm.headers.length - 1][columnIndex].option.hideMobile === 'true', _obj ),style:({ background: cell.option.background })},[(cell.link)?_c('img',{class:_vm.$style.logo,attrs:{"src":cell.link.url}}):[_vm._v(_vm._s(cell ? cell.content : ''))],_vm._v(" "),(cell.option.check === 'yes')?_c('CheckIcon',{staticClass:"mx-auto h-[14px] w-[14px] path-black",attrs:{"width":"14px","height":"14px"}}):_vm._e(),_vm._v(" "),(cell.option.check === 'no')?_c('CrossIcon',{staticClass:"mx-auto h-[14px] w-[14px] path-black",attrs:{"width":"14px","height":"14px"}}):_vm._e()],2)}),0)}),0)]):_c('div',[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }