<template>
  <HomeModule :data="data" data-luko-tracking="Page-Home" />
  <!-- <WaitlistModule v-if="hasWaitlist" :data="data" /> -->
</template>

<script>
// import WaitlistModule from '@/modules/WaitlistPage'
import HomeModule from '@/modules/HomeModule'
import { useMetaData } from '@/composables/useMetaData'
import { useHreflangs } from '@/composables/useHreflangs'
import { useGlobalVariables } from '@/composables/useGlobalVariables'

const { getHreflangs } = useHreflangs()
const { getMetaData } = useMetaData()
const { replaceVariables } = useGlobalVariables()

export default {
  head() {
    const heroSlice = this.data.body.find((e) => e.slice_type === 'hero')?.primary
    const metaData = this.seo.find((e) => e.slice_type === 'meta_data')?.primary

    const hreflangs = getHreflangs({
      uid: this.uid,
      type: this.type,
      current_lang: this.$i18n.localeProperties.prismicIso.toLowerCase(),
      alternate_languages: this.alternate_languages,
    })

    return getMetaData(
      metaData,
      { title: heroSlice?.title?.[0]?.text, description: heroSlice?.subtitle?.[0]?.text },
      this.$route.path,
      hreflangs,
      this.$i18n.localeProperties.prismicIso.toLowerCase()
    )
  },

  components: {
    HomeModule,
    // WaitlistModule,
  },

  async asyncData({ app, $prismic, store, error }) {
    const hasWaitlist = process.env.HAS_WAITLIST === 'true'

    try {
      const {
        data: initialData,
        alternate_languages,
        type,
        lang,
      } = await $prismic.api.getSingle(hasWaitlist ? 'waitlist_page' : 'home_page', {
        lang: app.i18n.localeProperties.prismicIso,
      })

      const data = replaceVariables(initialData, lang)
      store.dispatch('setAlternateLanguages', alternate_languages)

      // Extract page settings from PageSettings slice.
      store.commit(
        'page/UPDATE',
        data.body.find((s) => s.slice_type === 'page_settings')
      )
      data.body = data.body.filter((s) => s.slice_type !== 'page_settings')

      return {
        data,
        seo: data.body1 || [],
        hasWaitlist,
        alternate_languages,
        type,
      }
    } catch (e) {
      console.error('Error in asyncData', e)
      throw e
    }
  },
}
</script>
