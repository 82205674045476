<template>
  <div class="bg-bluko-800 py-[128px] px-[24px]">
    <Wrapper class="text-center">
      <Title :title-params="primary.title" class="mb-[8px] !font-[300] !text-white typo-mega-title sm:typo-extra-large-title" />
      <RichText :text-params="primary.description" class="text-bluko-200" />
      <div class="mt-[60px] flex items-center justify-center lg:mt-[48px] lg:flex-col">
        <template v-for="(item, itemIndex) of items">
          <div
            :key="itemIndex"
            class="
              align-center
              m-[24px]
              flex
              min-h-[230px]
              max-w-[380px]
              flex-1
              shrink-0
              flex-col
              justify-start
              rounded-[8px]
              border-[1px] border-solid border-bluko-500
              p-[24px]
              lg:min-h-[auto] lg:w-[330px] lg:justify-center lg:p-[16px]
            "
            data-aos="fade-up"
            :data-aos-delay="itemIndex * 100"
            data-aos-duration="800"
          >
            <PImage v-if="item.icon && item.icon.url" :image-object="item.icon" class="mb-[16px] h-[48px] w-[48x] lg:mb-[8px]" />
            <span
              v-else
              class="mx-auto mb-[16px] flex h-[48px] w-[48px] items-center justify-center rounded-[50%] bg-bluko-500 !text-white typo-title"
            >
              {{ itemIndex + 1 }}
            </span>
            <Title :title-params="item.title" class="m-0 text-white typo-headline-bold" />
            <RichText :text-params="item.description" class="text-bluko-100 typo-headline [&_strong]:!text-white [&_strong]:typo-headline-bold" />
            <div v-if="item.ctaLink && item.ctaLink.url && item.ctaText" class="mt-[24px] flex justify-center">
              <ArrowLink
                :link-object="item.ctaLink"
                class="text-bluko-500 path-bluko-500"
                :targeted-section="item.targetedSection"
                :link-text="item.ctaText"
              />
            </div>
          </div>
          <div data-aos="fade-up" :data-aos-delay="itemIndex * 100 + 50" data-aos-duration="800">
            <ArrowRight
              v-if="!primary.hideArrow && itemIndex !== items.length - 1"
              :key="'arrow' + itemIndex"
              class="path-bluko-200 lg:rotate-90"
              width="20px"
              height="20px"
            />
          </div>
        </template>
      </div>

      <ArrowButton
        class="mt-[48px] inline-block no-underline lg:hidden"
        :link-object="primary.ctaLink"
        is-white
        is-outlined
        :link-text="primary.ctaText"
      />
    </Wrapper>
  </div>
</template>

<script>
import ArrowRight from 'LkIcons/ArrowRight.vue'

import ArrowLink from '@/components/ArrowLink'
import ArrowButton from '@/components/ArrowButton'

export default {
  name: 'ProcessArrowBlock',

  components: {
    ArrowLink,
    ArrowRight,
    ArrowButton,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    primary() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style module lang="scss">
.description {
  p {
    @include typo-body;

    color: $gray-700;
  }
  h3 {
    @include typo-headline-bold;
    margin-bottom: 8px;
  }
}
</style>
